import {createReducer} from 'reduxsauce';
import {CreationTypes} from './actions';

export interface ICreationState {
    creation: object,
    error: string,
    loginPopup: boolean,
    loading: boolean,
}

export const creations: ICreationState = {
    creation: {created:false},
    error: "",
    loginPopup: false,
    loading: false,
};

export const creation = (state) => {
    return {...state, loading: true, creation: {created:false}};
};
export const clickPopUpStart = (state) => {
    return {...state, loginPopup: !state.loginPopup};
};

export const creationSuccess = (state, action) => {
    return {...state, creation: action.data, loading: false};
};

export const creationFailure = (state, action) => {
    return {...state, error: action.error, creation: {created:false}, loading: false};
};



export const handlers = {
    [CreationTypes.CREATION_START]: creation,
    [CreationTypes.CREATION_SUCCESS]: creationSuccess,
    [CreationTypes.CREATION_FAILURE]: creationFailure,
    [CreationTypes.CLICK_POP_UP_START]: clickPopUpStart
};

export default createReducer(creations, handlers);
