import React from 'react';
import { getError, getBlogData, getRecommendedProductsData, getLoading, getFavoriteProducts } from "../redux/selectors";
import { BlogActionCreators } from "../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RecommendedProducts from "../../home/container/components/recommendedProducts";
import { BASE_API_GATEWAY } from "../../../utils/constants/service";
import CurrentPage from "../../../layouts/common/currentPage";
import './blog.scss';
import {
    getDataBySectionAndWidget,
    getLangPrefix,
} from "../../../helpers/helpers";
import { Link } from "react-router-dom";
import i18next from "i18next";
import Loading from "../../product/container/components/loading";
import FavoriteProducts from "./component/favoriteProducts";
import { getBanner } from "../../home/redux/selectors";
import LinkFullUrl from "../../../shared/linkFullUrl/linkFullUrl";


interface State {
    loading: boolean
}

interface Props {
    data: any,
    error: any,
    recommendedProducts: any,
    isLoading: boolean,
    blogFavoriteProductsStart?: any,
    banner?: any,
    products?: any,
}

class Blog extends React.Component<typeof BlogActionCreators & Props> {

    state: State = {
        loading: false
    };



    componentDidMount(): void {
        window.scrollTo(0, 0);
        const { menuItem, blogStart, products, blogFavoriteProductsStart } = this.props;
        blogStart(menuItem.gridId);
        this.props.recommendedProductsStart();
        if (!(products && products.length)) {
            blogFavoriteProductsStart(menuItem.menuId);
        }
    }

    render() {
        const { menuItem, data, recommendedProducts, isLoading, products, banner } = this.props;
        const sortedObj = Object.keys(data).reverse();


        return (
            <main>
                <CurrentPage selectedItem={menuItem} />
                {
                    isLoading ?
                        <Loading />
                        :
                        <section className="blogContent">
                            <div className="container">
                                <div className="desctopSize">
                                    <div className="blogsRow">
                                        {
                                            sortedObj && sortedObj.length && sortedObj.map((item, i) =>
                                                <React.Fragment key={i}>
                                                    <LinkFullUrl to={`${getLangPrefix() + "/magazine" + "/" + item}`} className="blogsRow--item--small">
                                                        <div className="blogsRow--item--small--imgBox">
                                                            {
                                                                getDataBySectionAndWidget(+item, 1, data[item], "PHOTO").value ?
                                                                    <img className="blogsRow--item--small--imgBox--img"
                                                                        src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(+item, 1, data[item], "PHOTO").value}`} /> : ""
                                                            }

                                                            <div className="blogsRow--item--infoBox">
                                                                <div className="blogsRow--item--small--infoBox--title">{<p
                                                                    dangerouslySetInnerHTML={{ __html: getDataBySectionAndWidget(+item, 1, data[item], "TEXT", "Title").value }} />}</div>
                                                            </div>
                                                        </div>
                                                    </LinkFullUrl>
                                                </React.Fragment>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="mobileSize">
                                    <RecommendedProducts recommendedProducts={sortedObj} sliderType="blog" data={data} />
                                </div>
                            </div>
                            <div className="container">
                                {
                                    products && products.catalogue && products.catalogue.content ?
                                        <div className="blogSlider">
                                            <h4 className="recommendedSlidercontent--title ">{i18next.t('blog.3')}</h4>
                                            <FavoriteProducts products={products.catalogue.content} banner={banner} />
                                        </div> : ""
                                }
                            </div>
                        </section>

                }
            </main>
        )
    }
}

const mapStateToProps = (state: any): Props => {
    return {
        data: getBlogData(state),
        error: getError(state),
        recommendedProducts: getRecommendedProductsData(state),
        isLoading: getLoading(state),
        products: getFavoriteProducts(state),
        banner: getBanner(state),
    }
};

const mapDispatchToProps = (dispatch): typeof BlogActionCreators => {
    return bindActionCreators(BlogActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
