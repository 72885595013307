import React, { Component, useEffect, useMemo } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { TimelineActionCreators } from '../redux/actions';
import { getTimeline } from "../redux/selectors";
import { getMenu } from "../../../layouts/main/header/redux/selectors";
import { BASE_API_GATEWAY } from "../../../utils/constants/service";
import CurrentPage from '../../../layouts/common/currentPage';
import { getDataBySectionAndWidget } from "../../../helpers/helpers";
//styles
import "./timeline.scss";
import yarnImg from "./../../../../src/assets/img/yarn.svg"
import needleImg from "./../../../../src/assets/img/needle.svg"
import ImgBlob from "../../home/container/components/imgBlob";

interface Props {
    canonicalUrl?: string,
    location?: any,
    history?: any,
    data?: any,
    menuList?: any,
    menuItem?: any,
    timelineStart?: any

}

const Timeline = ({ canonicalUrl, location, history, menuItem, timelineStart, data }: Props) => {
    useEffect(() => {
        if (canonicalUrl && location.pathname !== canonicalUrl) {
            history.push(canonicalUrl)

        }
        if (menuItem && menuItem.gridId) {
            timelineStart(menuItem.gridId)
        }
    }, [])

    const historyData = useMemo(() => {
        return (data || []).reduce((acc, item) => {
            if (acc[`${item.section}-${item.widget}`]) {
                acc[`${item.section}-${item.widget}`][item.type] = getDataBySectionAndWidget(item.section, item.widget, data, item.type);
            } else {
                acc[`${item.section}-${item.widget}`] = {};
                acc[`${item.section}-${item.widget}`][item.type] = getDataBySectionAndWidget(item.section, item.widget, data, item.type);
            }

            return acc;
        }, {})
    }, [data])

    return (
        <div>
            {
                menuItem && <CurrentPage selectedItem={menuItem} />
            }

            <section className="history">
                <div className="container">
                    <div className="history-content">

                        <h4 className="title-year history-item"></h4>
                        {
                            historyData && Object.keys(historyData).length && Object.keys(historyData).map((key, index) => (
                                <div className="history-item" key={index}>
                                    <div className="circle">
                                        <div className="circle-dot"></div>
                                    </div>
                                    <div className="history-item-content">
                                        <h4 className="history-title"><p
                                            dangerouslySetInnerHTML={{ __html: historyData[key]['PHOTO'] ? historyData[key]['PHOTO'].name : historyData[key]['TEXT'].name }} />
                                        </h4>
                                        {
                                            historyData[key]['PHOTO'] ? <div className="history-item-image-box">
                                                <ImgBlob src={`${BASE_API_GATEWAY}${historyData[key]['PHOTO'].value && historyData[key]['PHOTO'].value}`}
                                                    alt={historyData[key]['PHOTO'].altText || "history image"} />
                                                <p dangerouslySetInnerHTML={{ __html: historyData[key]['TEXT'] && historyData[key]['TEXT'].value }} />
                                            </div> : <div className="history-item-image-box"><p
                                                dangerouslySetInnerHTML={{ __html: historyData[key]['TEXT'].value }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className="history-end-image"><img src={needleImg} alt="end-logo" /></div>
                </div>
            </section>
            <section>
                <div></div>
                <div></div>
                <div></div>
            </section>
        </div>
    )
}


const mapStateToProps = (state: any): Props => ({
    data: getTimeline(state),
    menuList: getMenu(state),
});

const mapDispatchToProps = (dispatch): typeof TimelineActionCreators => {
    return bindActionCreators(TimelineActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
