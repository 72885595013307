import React from 'react';
import ErrorBoundaryRoute from "./shared/error/error-boundary-route";
import Login from "./modules/login/container/login";
import UserAccount from "./modules/account/container/account";
import MainLayout from "./layouts/main";
import { Switch, Route } from "react-router";
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();
history.listen((location, action) => {

});

const Routes = () => (
    <div className="view-routes">
        <Switch>
            <ErrorBoundaryRoute exact path="/login" component={Login} />
            <Route path="/activate/:activationKey" render={(props) => <UserAccount {...props} activate={true} />} />
            <ErrorBoundaryRoute path="/" component={MainLayout} />
        </Switch>
    </div>
);

export default Routes;