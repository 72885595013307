import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import i18next from "i18next";
import "./mesInformations.scss";
import { Button, Form, Input, Radio, Select, Icon } from 'semantic-ui-react'
import {
    getChangePass,
    getError,
    getMesInformationBrand,
    getMesInformationClient,
    getMesInformationSend,
    getStatus
} from "../redux/selectors";
import { MesInformationActionCreators } from "../redux/actions";
import { IMesInformationState } from "../redux/reducers";
import ResponseMessage from "../../../../../responseMessage/container"
import { getCurrency, getDateTimestamp, getFilterValue } from "../../../../../../helpers/helpers";
import { getBrandDetail } from "../../../../../../layouts/main/header/redux/selectors";
import { validateDiscount } from '../../../../../../utils/constants/service';

interface Props extends IMesInformationState {
    webClient: any,
    brand: any,
    mesInformationSend: any,
    error: any,
    status: any,
    changePass: any,
    brandDetail: any
}

class MesInformations extends Component<Props & typeof MesInformationActionCreators> {

    state = {
        gender: '',
        firstName: '',
        lastName: '',
        email: '',
        newsOptin: '',
        multisiteOptin: '',
        day: '',
        month: '',
        year: '',
        preferredStoreId: '',
        newsOptinChecked: false,
        multisiteOptinChecked: false,
        options: [],
        pass1: '',
        pass2: '',
        pass3: '',
        showPass1: false,
        showPass2: false,
        showPass3: false,
        confirmPassMessage: '',
        showMessage: false,
        validation: {
            required: false,
            day: false,
            month: false,
            year: false,
            requiredPass: false
        },
        discountValue: 0
    };

    componentDidMount(): void {
        this.props.mesInformationStart((data) => {
            const { webClient } = data;
            const { discountCodes = [], webClientId } = webClient;
            if (discountCodes.length) {
                this.validateDiscountClient(discountCodes[0], webClientId);
            }
        })
    }

    componentWillReceiveProps(nextProps): void {
        if (typeof nextProps.webClient === "object" && Object.keys(nextProps.webClient).length) {
            const birthDate = nextProps.webClient.birthDate ? nextProps.webClient.birthDate.split("-") : nextProps.webClient.birthdate && nextProps.webClient.birthdate.split("-");
            const day = birthDate && birthDate[2];
            const month = birthDate && birthDate[1];
            const year = birthDate && birthDate[0];
            this.setState({
                gender: nextProps.webClient.gender,
                firstName: nextProps.webClient.firstName,
                lastName: nextProps.webClient.lastName,
                email: nextProps.webClient.email,
                newsOptin: nextProps.webClient.newsOptinDate ? "Y" : "N",
                multisiteOptin: nextProps.webClient.multisiteOptInDate ? "Y" : "N",
                preferredStoreId: nextProps.webClient.preferredStoreId,
                newsOptinChecked: !!nextProps.webClient.newsOptinDate || nextProps.webClient.newsOptin === "Y",
                multisiteOptinChecked: !!nextProps.webClient.multisiteOptInDate || nextProps.webClient.multisiteOptin === "Y",
                day,
                month,
                year,
            })
        }

        if (Array.isArray(nextProps.brand) && nextProps.brand.length) {
            const options: any = [];
            // nextProps.brand && nextProps.brand.map(item => {
            //     if (item.storeType !== "G") {
            //         options.push({
            //             key: item.storeId,
            //             text: `${item.name}, ${item.address}, ${item.zip}, ${item.city}`.toUpperCase(),
            //             value: item.storeId
            //         })
            //     }
            // });

            nextProps.brand && nextProps.brand.map(item => {
                if (item.country === this.props.brandDetail.storeCountry) {
                    options.push({
                        key: item.storeId,
                        text: `${item.name}, ${item.address}, ${item.zip}, ${item.city}`.toUpperCase(),
                        value: item.storeId
                    })
                }
            });
            options.length && this.setState({ options: options })
        }

        if (nextProps.changePass && nextProps.changePass !== this.props.changePass) {
            setTimeout(() => {
                this.setState({ showMessage: false })
            }, 7000)
        }

    }

    validateDiscountClient = async (discountCode, webClientId) => {
        const result = await validateDiscount(discountCode, webClientId);
        const { value, valid } = result;

        if (valid) {
            this.setState({ discountValue: +value });
        }
    }

    handleChange = (e, { name, value, checked }) => {
        if (checked !== undefined && name === "newsOptin") {
            value = checked ? 'Y' : 'N';
            this.setState({ newsOptinChecked: checked })
        }
        if (checked !== undefined && name === "multisiteOptin") {
            value = checked ? 'Y' : 'N';
            this.setState({ multisiteOptinChecked: checked })
        }
        if (name === "day") {
            if (value.length > 2) {
                return
            }
            const valid = /[0-9]{2}/.test(value);
            if (value.length && (!valid || parseInt(value) < parseInt("01") || parseInt(value) > parseInt("31"))) {
                this.setState(() => ({
                    validation: {
                        ...this.state.validation,
                        day: true
                    }
                }))
            } else {
                this.setState(() => ({
                    validation: {
                        ...this.state.validation,
                        day: false
                    }
                }))
            }
            if (value.length > 2) {
                return
            }
        }

        if (name === "month") {
            if (value.length > 2) {
                return
            }
            const valid = /[0-9]{2}/.test(value);
            if (value.length && (!valid || parseInt(value) < parseInt("01") || parseInt(value) > parseInt("12"))) {
                this.setState(() => ({
                    validation: {
                        ...this.state.validation,
                        month: true
                    }
                }))
            } else {
                this.setState(() => ({
                    validation: {
                        ...this.state.validation,
                        month: false
                    }
                }))
            }
        }
        if (name === "year") {
            if (value.length > 4) {
                return
            }
            const valid = /[0-9]{4}/.test(value);
            if (value.length && (!valid || parseInt(value) < parseInt("1900") || parseInt(value) > new Date().getFullYear())) {
                this.setState(() => ({
                    validation: {
                        ...this.state.validation,
                        year: true
                    }
                }))
            } else {
                this.setState(() => ({
                    validation: {
                        ...this.state.validation,
                        year: false
                    }
                }))
            }
        }
        this.setState({ [name]: getFilterValue(value) })
    };

    handleSubmit = () => {
        let required = false;
        const { gender, firstName, lastName, email, newsOptin, day, month, year, preferredStoreId, validation, multisiteOptin } = this.state;
        const birthdate = `${year || 1900}-${month}-${day}`;
        const data = {
            firstName,
            lastName,
            gender,
            birthdate,
            "webClientRequest": {
                preferredStoreId,
                multisiteOptin,
                newsOptin,
            }
        };
        if (!firstName || firstName.length > 30 || !lastName || lastName.length > 30 || !email || !day || !month || !preferredStoreId ) {
            required = true;
            this.setState(() => ({
                validation: {
                    ...this.state.validation,
                    required: true
                }
            }))
        }
        if (!required) {
            this.props.mesInformationPostStart(data);
        }
    };
    handleSubmitPass = () => {
        const { pass1, pass2, pass3 } = this.state;
        if (pass1 && pass2 && pass2 === pass3) {
            const data = {
                currentPassword: pass1,
                newPassword: pass2
            };
            this.props.mesInformationChangePassStart(data);
            this.setState({ showMessage: true })
        }
        else if (!pass1 || !pass2 || !pass3) {
            this.setState(() => ({
                validation: {
                    ...this.state.validation,
                    requiredPass: true
                }
            }))

        }
        else {
            this.setState({ confirmPassMessage: i18next.t("mesInformations.20") })
        }

        if (pass2 === pass3) {
            this.setState({ confirmPassMessage: "" })
        }

    };

    showPass = (name) => {
        this.setState({ [name]: !this.state[name] })
    };

    render() {
        const {
            gender,
            firstName,
            lastName,
            email,
            preferredStoreId,
            newsOptinChecked,
            multisiteOptinChecked,
            day = '',
            month = '',
            year = '',
            options,
            pass1,
            pass2,
            pass3,
            showPass1,
            showPass2,
            showPass3,
            validation,
            confirmPassMessage,
            showMessage,
            discountValue
        } = this.state;
        const { required, requiredPass } = validation;
        const { status, changePass, brandDetail, webClient } = this.props;
        const { storeClientLoyalty: { loyaltyPoints = '', expirationDate = '' } = {} } = webClient;
        const detailExpiration = brandDetail.expirationDate ? new Date(brandDetail.expirationDate).getTime() : '';

        return (
            <div className="mes-informations">
                <div className="grey-bg">
                    <div className="container">
                        <div className="page-content">
                            {status && <ResponseMessage status={status} errorText={i18next.t("alertMessage.1")}
                                successText={i18next.t("alertMessage.2")} />}

                            <Form className="page-content-form" onSubmit={this.handleSubmit}>
                                <Form.Group inline className='input-box'>
                                    <label className="input-label">{i18next.t("mesInformations.4")}</label>
                                    <Form.Field
                                        name="gender"
                                        control={Radio}
                                        label={i18next.t("signup.sex.male")}
                                        value='F'
                                        checked={gender === 'F'}
                                        onChange={this.handleChange}
                                    />
                                    <Form.Field
                                        name="gender"
                                        control={Radio}
                                        label={i18next.t("signup.sex.female")}
                                        value='M'
                                        checked={gender === 'M'}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field
                                        error={required && !lastName || lastName.length > 30}
                                        control={Input}
                                        label={`${i18next.t("mesInformations.1")} *`}
                                        placeholder={i18next.t("mesInformations.1")}
                                        value={lastName}
                                        name='lastName'
                                        onChange={this.handleChange}
                                        className='input-box'
                                    />
                                    <Form.Field
                                        error={required && !firstName || firstName.length > 30}
                                        control={Input}
                                        label={`${i18next.t("mesInformations.2")} *`}
                                        placeholder={i18next.t("mesInformations.2")}
                                        value={firstName}
                                        name='firstName'
                                        onChange={this.handleChange}
                                        className='input-box'
                                    />
                                </Form.Group>
                                <Form.Field
                                    error={required && !email}
                                    control={Input}
                                    label={`${i18next.t("mesInformations.3")} *`}
                                    placeholder='Email'
                                    value={email}
                                    name='email'
                                    type='email'
                                    onChange={this.handleChange}
                                    className='input-box'
                                    disabled={true}
                                />
                                <Form.Group widths='equal'>
                                    <div>
                                        <div className="input-label">{i18next.t("mesInformations.5")}</div>
                                        <div className="date-naissance input-box">
                                            <Form.Field
                                                error={required && !day || validation.day}
                                                control={Input}
                                                className="input-style"
                                                placeholder={i18next.t("signup.date.day")}
                                                name="day"
                                                value={day}
                                                onChange={this.handleChange}
                                            />
                                            <span>/</span>
                                            <Form.Field
                                                error={required && !month || validation.month}
                                                control={Input}
                                                className="input-style"
                                                placeholder={i18next.t("signup.date.month")}
                                                name="month"
                                                value={month}
                                                onChange={this.handleChange}
                                            />
                                            <span>/</span>
                                            <Form.Field
                                                // error={required && !year || validation.year}
                                                control={Input}
                                                type="text"
                                                className="input-style"
                                                placeholder={i18next.t("signup.date.year")}
                                                name="year"
                                                value={year}
                                                onChange={this.handleChange}
                                            />
                                            <Icon name="calendar alternate outline" />
                                        </div>
                                        {
                                            validation.day || validation.month || validation.year ?
                                                <div className="error-text">{i18next.t("date.1")}</div> : ""
                                        }
                                    </div>
                                    <Form.Field
                                        error={required && !preferredStoreId}
                                        control={Select}
                                        label={i18next.t("mesInformations.6")}
                                        name='preferredStoreId'
                                        options={options}
                                        value={preferredStoreId}
                                        placeholder={i18next.t("mesInformations.13")}
                                        onChange={this.handleChange}
                                        className='input-box favorite-store'
                                    />
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <div className="input-label">
                                        <div className='discount-info'>
                                            <span className='mr-10'>{i18next.t('mesInformations.23')}:</span>
                                            <Input value={new Intl.NumberFormat('en-EN', { currency: 'EUR' }).format(loyaltyPoints)} />
                                        </div>
                                        <div className='discount-info'>
                                            <span className='mr-10'>{i18next.t('mesInformations.26')}:</span>
                                            <Input value={`${new Intl.NumberFormat('en-EN', { currency: 'EUR', minimumFractionDigits: 2 }).format(discountValue)} ${getCurrency('EUR')}`} />
                                        </div>
                                    </div>
                                    <div className="input-label">
                                        <span className='mr-10'>{i18next.t('mesInformations.24')}:</span>
                                        <Input value={expirationDate && getDateTimestamp(detailExpiration || expirationDate)} />
                                    </div>
                                </Form.Group>
                                <div className='mt_15 mb_10'>
                                    <span className='input-label'>{i18next.t("mesInformations.25")}</span>
                                </div>
                                <Form.Checkbox
                                    className='input-box'
                                    inline
                                    label={i18next.t("mesInformations.7")}
                                    name='newsOptin'
                                    value='Y'
                                    checked={newsOptinChecked}
                                    onChange={this.handleChange}
                                />
                                <Form.Checkbox
                                    className='input-box'
                                    inline
                                    label={i18next.t("signup.info.checkboxThird")}
                                    name='multisiteOptin'
                                    value='Y'
                                    checked={multisiteOptinChecked}
                                    onChange={this.handleChange}
                                />
                                <Form.Field className="btn-wrapper hoverButtonStyleWithLib"
                                    control={Button}>{i18next.t("mesInformations.8")}</Form.Field>
                            </Form>
                        </div>
                    </div>
                </div>
                <div className="password-changer-section">
                    <div className="container">
                        <div className="page-content pass-changer">
                            <h5 className="change-psw-title">{i18next.t("mesInformations.15")}</h5>
                            <Form className="page-content-form" onSubmit={this.handleSubmitPass}>
                                <Form.Field
                                    className='input-box'
                                    icon={<Icon name={showPass1 ? "eye" : "eye slash"} link
                                        onClick={() => this.showPass('showPass1')} />}
                                    control={Input}
                                    label={i18next.t("mesInformations.10")}
                                    type={showPass1 ? 'text' : 'password'}
                                    name='pass1'
                                    value={pass1}
                                    error={requiredPass && !pass1}
                                    onChange={this.handleChange}
                                    placeholder={i18next.t("mesInformations.14")}
                                />
                                <Form.Field
                                    className='input-box'
                                    icon={<Icon name={showPass2 ? "eye" : "eye slash"} link
                                        onClick={() => this.showPass('showPass2')} />}
                                    control={Input}
                                    label={i18next.t("mesInformations.11")}
                                    type={showPass2 ? 'text' : 'password'}
                                    name='pass2'
                                    value={pass2}
                                    error={requiredPass && !pass2}
                                    onChange={this.handleChange}
                                    placeholder={i18next.t("mesInformations.14")}
                                />
                                <Form.Field
                                    className='input-box'
                                    icon={<Icon name={showPass3 ? "eye" : "eye slash"} link
                                        onClick={() => this.showPass('showPass3')} />}
                                    control={Input}
                                    label={i18next.t("mesInformations.12")}
                                    type={showPass3 ? 'text' : 'password'}
                                    name='pass3'
                                    value={pass3}
                                    error={requiredPass && !pass3}
                                    onChange={this.handleChange}
                                    placeholder={i18next.t("mesInformations.14")}
                                />
                                {
                                    confirmPassMessage ? <div className="errorMessage">{confirmPassMessage}</div> : ""
                                }
                                <Form.Field
                                    className="btn-wrapper hoverButtonStyleWithLib"
                                    control={Button}
                                >
                                    {i18next.t("mesInformations.8")}
                                </Form.Field>
                            </Form>
                            {
                                showMessage && changePass ?
                                    <ResponseMessage
                                        status={changePass}
                                        errorText={i18next.t("mesInformations.18")}
                                        successText={i18next.t("mesInformations.19")}
                                    />
                                    : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any): Props => {
    return {
        webClient: getMesInformationClient(state),
        brand: getMesInformationBrand(state),
        mesInformationSend: getMesInformationSend(state),
        error: getError(state),
        status: getStatus(state),
        changePass: getChangePass(state),
        brandDetail: getBrandDetail(state)
    }
};

const mapDispatchToProps = (dispatch): typeof MesInformationActionCreators => {
    return bindActionCreators(MesInformationActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(MesInformations);
