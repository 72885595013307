import React from 'react';
import './currentPage.scss';
import {Link} from "react-router-dom";
import PageMetaData from "../../modules/common/pageMetaData/pageMetaData";
import {BASE_API_GATEWAY} from "../../utils/constants/service";
import ImgBlob from "../../modules/home/container/components/imgBlob";
import {getDataBySectionAndWidget} from "../../helpers/helpers";

export default function StyledCurrentPage({navTabs, selectedMenuItem, getData, data, productData, showPageMetaData}) {
    const photoS1W1 = getDataBySectionAndWidget(1, 1, data, "PHOTO");
    const photoS2W1 = getDataBySectionAndWidget(2, 1, data, "PHOTO");
    const photoS2W2 = getDataBySectionAndWidget(2, 2, data, "PHOTO");
    const photoS2W3 = getDataBySectionAndWidget(2, 3, data, "PHOTO");

    return <section className="product-info-box jardin-secret-box">
        {data &&
        <aside className="container">
            <div className="main-box">
                <ul className="categorye-list">
                    {navTabs &&
                    navTabs.map((tab, index) => (
                        <li key={index}>{tab && tab.url ? <Link to={tab.url}> {tab.name} </Link> :
                            <span> {tab.name}</span>}</li>
                    ))
                    }
                </ul>
                <div className="info-box">
                    <h1 className="title">{selectedMenuItem && selectedMenuItem.label}</h1>
                    <div className="flex-box align-items-start">
                        <div className="box-1">
                            {photoS1W1 && photoS1W1.value ? <ImgBlob src={BASE_API_GATEWAY + photoS1W1.value}
                                                                     alt={photoS1W1.altText || "PHOTO"}/> : null}
                            <p className="text-block" dangerouslySetInnerHTML={{__html: getData(1, 2, data).value}}/>
                        </div>
                        <div className="box-2 flex-box">
                            {photoS2W1 && photoS2W1.value ?
                                <ImgBlob src={BASE_API_GATEWAY + photoS2W1.value} alt={photoS2W1.altText || "PHOTO"}
                                         ElemclassName="first_pic"/> : null}
                            <div className="flex-box flex-column right-block">
                                {photoS2W2 && photoS2W2.value ?
                                    <ImgBlob src={BASE_API_GATEWAY + photoS2W2.value} alt={photoS2W2.altText || "PHOTO"}
                                             ElemclassName="second_pic"/> : null}
                                {photoS2W3 && photoS2W3.value ?
                                    <ImgBlob src={BASE_API_GATEWAY + photoS2W3.value} alt={photoS2W3.altText || "PHOTO"}
                                             ElemclassName="second_pic"/> : null}
                            </div>
                        </div>
                    </div>
                    <div className="text-wrapper">
                        <div className="expand-block">
                            <p className="text-block" dangerouslySetInnerHTML={{__html: getData(1, 3, data).value}}/>
                        </div>
                    </div>
                </div>
                {showPageMetaData ?
                    <PageMetaData
                        navTabs={navTabs}
                        selectedMenuItem={selectedMenuItem}
                        productData={productData}
                    />
                    : ""
                }
            </div>
        </aside>
        }
    </section>

}
