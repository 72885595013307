import React, { Component } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import { regForBr, stripHTMLreg } from "../../../helpers/helpers";
import './descriptionAcordion.scss'


interface Props {
    label?: any,
    content?: any,
}

interface State {
    activeIndex: number
}

class AccordionDescription extends Component<Props> {

    state: State = { activeIndex: 1 };

    handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex })
    };

    render() {
        const { activeIndex } = this.state;
        const { label, content } = this.props;
        return (
            <>
                {
                    content ?
                        <Accordion>
                            <Accordion.Title
                                active={activeIndex === 0}
                                index={0}
                                onClick={this.handleClick}>
                                {label ? label.replace(stripHTMLreg, "") : ""}
                                <Icon name={activeIndex === 0 ? 'minus' : 'plus'} className="pull-left" />
                            </Accordion.Title>

                            <Accordion.Content active={activeIndex === 0}>
                                <p className="paragraph-content" dangerouslySetInnerHTML={{ __html: content && content.replace(regForBr, "") }}></p>
                            </Accordion.Content>
                        </Accordion> : null

                }

            </>
        )
    }
}

export default AccordionDescription;