import React from 'react';
import './permanentSale.scss'

interface IProps {
  text: string,
  bgColor?: string,
  color?: string
}

const PermanentSale = ({
  text,
  bgColor = '#bebebe',
  color = '#fff'
}: IProps) => {
  return (
    <div className='permanent-sale' style={{
      backgroundColor: bgColor,
      color
    }}>
      {text}
    </div>
  );
};

export default PermanentSale;