import React from 'react'
import "./videoIcon.scss"
import { ReactComponent as Volume } from "../../../../assets/img/Volume.svg";

interface IProps {
  handleClick: (event?: any) => void,
  volume: number
}

const VideoMuteButton = ({ handleClick, volume }: IProps) => {

    return (
        <div className='videoIcon'>
            <div className="videoIcon__body">
                <button className='videoIcon__button' onClick={(e) => handleClick(e)}>{!volume ? <Volume fill="rgba(79,131,193,.5)" /> : <Volume fill="#4F83C1" />}</button>
            </div>
        </div>
    )
}
export default VideoMuteButton