import { createReducer } from 'reduxsauce';
import { OrderTypes } from './actions';
import { DISCOUNT_CODE, PRIVATE_DISCOUNT, PROMOTION_DISCOUNT, STORAGE_BASKET_INDEX, VALIDATE_DISCCOUNT } from "../../../utils/constants/variables"
import i18next from 'i18next';

export interface IOrderState {
    order: any,
    createOrder: any,
    updateOrder: any,
    error: string,
    status: string,
    orderStatus: string,
    discount: any,
    privateDiscount: any,
    privateDiscountStatus: string;
    isLocalDiscount: boolean;
    updateStatus: string,
    deliveryMethod: string;
    promotionDiscount: any;
    promotionError: any;
    validateDiscountError: string;
}

export const orderR: IOrderState = {
    order: [],
    createOrder: [],
    updateOrder: [],
    error: "",
    status: "",
    orderStatus: "",
    discount: null,
    privateDiscount: null,
    privateDiscountStatus: "",
    isLocalDiscount: false,
    updateStatus: "",
    deliveryMethod: "onlineDelivery",
    promotionDiscount: null,
    promotionError: "",
    validateDiscountError: "",
};

export const order = (state) => {
    return { ...state };
};

export const orderSuccess = (state, action) => {
    return { ...state, order: action.data };
};

export const orderFailure = (state, action) => {
    return { ...state, error: action.error, order: {} };
};

export const createOrder = (state) => {
    return { ...state, orderStatus: "" };
};

export const createOrderSuccess = (state, action) => {
    return { ...state, createOrder: action.data, orderStatus: "success" };
};

export const createOrderFailure = (state, action) => {
    return { ...state, error: action.error, createOrder: [], orderStatus: "failed" };
};

export const validateDiscount = (state) => {
    return { ...state, status: "", error: "" };
};

export const validateDiscountSuccess = (state, action) => {
    const { data, isApprovedDiscount } = action;
    if (!isApprovedDiscount && !data.isProductsInBarcode) return { ...state, discount: null, validateDiscountError: i18next.t("payment.19") }

    if (data.isProductsInBarcode) {
        return { ...state, discount: null, validateDiscountError: i18next.t("product.basket.39") }
    }

    if (!data.discountCode || !data.valid) {
        return { ...state, discount: null, status: "failed" };
    }

    localStorage.setItem(DISCOUNT_CODE, data.discountCode);

    return { ...state, discount: data, status: action.typeOfRequest ? "success" : "" };
};

export const validatePrivateDiscountSuccess = (state, action) => {
    return { ...state, privateDiscount: action.data, privateDiscountStatus: "success" };
}

export const annullerPrivateDiscount = (state) => {
    return { ...state, privateDiscount: null, privateDiscountStatus: "" };
}

export const resetErrorMessage = (state) => {
    return { ...state, validateDiscountError: "", error: "" };
}

export const annullerDiscountSuccess = (state) => {
    localStorage.removeItem(VALIDATE_DISCCOUNT);
    localStorage.removeItem(DISCOUNT_CODE);

    return { ...state, discount: null, status: "" };
}

export const validateDiscountFailure = (state, action) => {
    return { ...state, validateDiscountError: action.error, discount: null, status: "failed" };
};

export const promotionDiscountSuccess = (state, action) => {
    return { ...state, promotionDiscount: action.data, promotionError: "" };
};

export const annullerPromotionDiscountStart = (state) => {
    return { ...state, promotionDiscount: null, promotionError: "" };
}

export const updateOrder = (state) => {
    return { ...state, updateStatus: "" };
};

export const updateOrderSuccess = (state, action) => {
    const { orderStatus } = action.data
    if (orderStatus === "PAI") {
        localStorage.removeItem(STORAGE_BASKET_INDEX);
        localStorage.removeItem(VALIDATE_DISCCOUNT);
        localStorage.removeItem("freight_cost")
    }
    return { ...state, updateOrder: true, updateStatus: "success" };
};

export const updateOrderFailure = (state, action) => {
    return { ...state, error: action.error, updateOrder: [], updateStatus: "failed" };
};

export const selectedDeliveryMethod = (state, action) => {
    return { ...state, deliveryMethod: action.method }

}

export const cleareCreatedOrder = (state) => {
    return { ...state, orderStatus: "", createOrder: [] };
};

export const cleareValidateInitial = (state) => {
    return { ...state, discount: null };
};

export const setDiscountData = (state, action) => {
    return { ...state, unConditionalDiscount: action.data }
}

export const handlers = {
    [OrderTypes.ORDER_START]: order,
    [OrderTypes.ORDER_SUCCESS]: orderSuccess,
    [OrderTypes.ORDER_FAILURE]: orderFailure,
    [OrderTypes.CREATE_ORDER_START]: createOrder,
    [OrderTypes.CREATE_ORDER_SUCCESS]: createOrderSuccess,
    [OrderTypes.CREATE_ORDER_FAILURE]: createOrderFailure,
    [OrderTypes.PROMOTION_DISCOUNT_SUCCESS]: promotionDiscountSuccess,
    [OrderTypes.ANNULLER_PROMOTION_DISCOUNT_START]: annullerPromotionDiscountStart,
    [OrderTypes.VALIDATE_DISCOUNT_START]: validateDiscount,
    [OrderTypes.VALIDATE_DISCOUNT_SUCCESS]: validateDiscountSuccess,
    [OrderTypes.VALIDATE_PRIVATE_DISCOUNT_SUCCESS]: validatePrivateDiscountSuccess,
    [OrderTypes.VALIDATE_DISCOUNT_FAILURE]: validateDiscountFailure,
    [OrderTypes.UPDATE_ORDER_START]: updateOrder,
    [OrderTypes.UPDATE_ORDER_SUCCESS]: updateOrderSuccess,
    [OrderTypes.UPDATE_ORDER_FAILURE]: updateOrderFailure,
    [OrderTypes.SELECTED_DELIVERY_METHOD_START]: selectedDeliveryMethod,
    [OrderTypes.CLEARE_CREATED_ORDER_START]: cleareCreatedOrder,
    [OrderTypes.CLEARE_VALIDATE_INITIAL_START]: cleareValidateInitial,
    [OrderTypes.ANNULLER_DISCOUNT_SUCCESS]: annullerDiscountSuccess,
    [OrderTypes.ANNULLER_PRIVATE_DISCOUNT_SUCCESS]: annullerPrivateDiscount,
    [OrderTypes.SET_DISCOUNT_DATA]: setDiscountData,
    [OrderTypes.RESET_ERROR_MESSAGE]: resetErrorMessage
};

export default createReducer(orderR, handlers);
