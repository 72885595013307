import {createReducer} from 'reduxsauce';
import {BlogTypes} from './actions';


export interface IHomeState {
    data?: any,
    recommendedProducts?: any,
    banner?: any,
    isLoading?: boolean,
    instagramData?: any,
    favoriteProducts?: any
}

export const blogContent: IHomeState = {
    data: [],
    recommendedProducts: [],
    instagramData: [],
    banner: {},
    isLoading: false,
    favoriteProducts:{}
};

export const getBlogData = (state) => {
    return {...state, isLoading: true};
};

export const blogSuccess = (state, action) => {
    return {...state, data: action.data, isLoading:false};
};

export const blogFailure = (state, action) => {
    return {...state, error: action.error, data: [], isLoading: false};
};

export const getInstagramData = (state) => {
    return {...state};
};

export const blogInstagramSuccess = (state, action) => {
    return {...state, instagramData: action.data};
};

export const blogInstagramFailure = (state, action) => {
    return {...state, error: action.error, instagramData: []};
}

export const blogFavoriteProductsStart = (state) => {
    return {...state};
};

export const blogFavoriteProductsSuccess = (state, action) => {
    return {...state, favoriteProducts: action.data};
};

export const blogFavoriteProductsFailure = (state, action) => {
    return {...state, error: action.error, favoriteProducts: {}};
}

export const handlers = {
    [BlogTypes.BLOG_START]: getBlogData,
    [BlogTypes.BLOG_SUCCESS]: blogSuccess,
    [BlogTypes.BLOG_FAILURE]: blogFailure,
    [BlogTypes.BLOG_INSTAGRAM_START]: getInstagramData,
    [BlogTypes.BLOG_INSTAGRAM_SUCCESS]: blogInstagramSuccess,
    [BlogTypes.BLOG_INSTAGRAM_FAILURE]: blogInstagramFailure,
    [BlogTypes.BLOG_FAVORITE_PRODUCTS_START]: blogFavoriteProductsStart,
    [BlogTypes.BLOG_FAVORITE_PRODUCTS_SUCCESS]: blogFavoriteProductsSuccess,
    [BlogTypes.BLOG_FAVORITE_PRODUCTS_FAILURE]: blogFavoriteProductsFailure,
};

export default createReducer(blogContent, handlers);
