import React, { useRef, useState } from 'react';
import './accordion.scss'
import { Icon } from "semantic-ui-react";
import { LANGUAGE_PREFIX } from "../../utils/constants/variables";
import LinkFullUrl from '../../shared/linkFullUrl/linkFullUrl';


const AccordionItem = (props) => {
    const contentEl = useRef<HTMLDivElement>(null);
    const { handleToggle, active, item, handleToggleMenu } = props;
    const { menuId, children } = item;

    return (
        <div className="rc-accordion-card">
            <div className="rc-accordion-header">
                <div className={`rc-accordion-toggle p-3 ${active === menuId ? 'active' : ''}`}
                    onClick={() => handleToggle(item.menuId)}>
                    {
                        item.canonicalUrl ?
                            <div>
                                <LinkFullUrl to={`${LANGUAGE_PREFIX}${item.canonicalUrl}`}
                                    onClick={props.handleToggleMenu}>{item.label}</LinkFullUrl>
                                <Icon name="angle right" />
                            </div>
                            :
                            <h4 className="linge-de-lit-item-title">
                                {item.label}
                                <Icon name="angle right" />
                            </h4>
                    }
                    <i className="fa fa-chevron-down rc-accordion-icon" />
                </div>
            </div>
            <div
                ref={contentEl}
                className={`rc-collapse ${active === menuId ? 'show' : ''}`}
                style={active === menuId ? { fontWeight: "normal" } : { maxHeight: "0" }}
            >
                <div className="rc-accordion-body">
                    <ul>
                        {
                            children && children.length && children.map(el => (
                                !el.visuals || (el.visuals[0] && el.visuals[0].type === "CATALOGUE") ?
                                    <li key={el.menuId}>
                                        <LinkFullUrl to={`${LANGUAGE_PREFIX}${el.canonicalUrl}`}
                                            onClick={handleToggleMenu}>{el && el.label}</LinkFullUrl>
                                    </li>
                                    : ""
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}


const Accordion = ({ items, handleToggleMenu }) => {
    const [active, setActive] = useState(items[0].menuId);

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="card">
                    <div className="card-body">
                        {
                            items.map((item) => (
                                <AccordionItem key={item.menuId} active={active} handleToggle={handleToggle}
                                    item={item} handleToggleMenu={handleToggleMenu} />

                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Accordion;