import {ProductsActionCreators, ProductsTypes} from "./actions";
import {call, put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {PRODUCTS_URL} from "../../../utils/constants/service";
import {discountHeader} from "../../../helpers/helpers";

export function* products(param){
    const { selectedItem, pageSize, brandId } = param;
    try {
        const data = yield call(() => {
            if(param.filters){
                return axios.post(`${PRODUCTS_URL}${selectedItem.menuId}?page=${pageSize}&sort=ranking,asc`, JSON.parse(param.filters), discountHeader(brandId))
                    .then(res => res.data)
            } else {
                return axios.post(`${PRODUCTS_URL}${selectedItem.menuId}?page=${pageSize}&sort=ranking,asc`,{}, discountHeader(brandId))
                    .then(res => res.data)
            }
        });
        yield put(ProductsActionCreators.productsSuccess(data, selectedItem.id, param.filters, pageSize));
    } catch (e) {
        yield put(ProductsActionCreators.productsFailure(e.message));
    }
}

export function* productsSaga(){
    yield takeLatest(ProductsTypes.PRODUCTS_START, products);
}

export default productsSaga;