import React from 'react';
import { connect } from 'react-redux';
import i18next from "i18next";
import { Link, Redirect } from "react-router-dom";

import { findNodeByProperty, getLangPrefix, getRouteByMenuKey } from "../../../helpers/helpers";
import CurrentPage from '../../../layouts/common/currentPage';
import MesCommandes from "./components/mesCommandes/container";
import MesAddress from "./components/MesAddress/container";
import MesInformations from "./components/mesInformations/container";
// import MesRetours from "./components/Mesretours/container";
import { getMenu } from "../../connection/redux/selectors";
import { SpaceActionCreators } from "../redux/actions"
//styles
import "./monEspace.scss";


import userIcon from "./../../../assets/img/user.svg";
import shoppingBug from "./../../../assets/img/shoppingBug.svg";
import magasin from '../../../layouts/main/header/container/menu/container/submenu/img-search/magasin.svg';
import homeIcon from "./../../../assets/img/homeIcon.svg";
import { getUser } from "../../../layouts/main/header/redux/selectors";
import { bindActionCreators } from "redux";
import { isAuthenticated } from "../../login/redux/selectors";

interface Props {
    menuItem?: any,
    menuList: any,
    location?: any,
    canonicalUrl?: any,
    history?: any,
    user: any,
    showLoginPopup?: any,
    authenticated: boolean
}

interface State {
    found: any,
    parent: any,

}

class MySpace extends React.Component<Props & typeof SpaceActionCreators, State> {
    state: State = {
        found: "",
        parent: [],
    };

    componentDidMount() {
        this.props.canonicalUrl && this.props.location.pathname !== this.props.canonicalUrl ? this.props.history.push(this.props.canonicalUrl) : "";
        this.setParent();
    }

    setParent = () => {
        let parent: any = [];
        if (this.props.menuItem && this.props.menuItem.key !== "mon_compte") {
            const parentEl = findNodeByProperty(this.props.menuList, "mon_compte", "key");
            parent = parentEl ? [parentEl] : [];
        }
        this.setState({ parent });
    };
    handleLogout = () => {
        this.props.logoutStart(this.props.user);
        this.props.history.push(getLangPrefix());
    };

    render() {
        if (!this.props.authenticated) {
            return <Redirect to="/" />
        }
        const { parent } = this.state;
        const { menuItem, menuList } = this.props;

        return (
            <div className="mon-espace">
                {menuItem && Object.keys(menuItem).length ?
                    <CurrentPage selectedItem={menuItem} selectedParent={parent} />
                    : ""
                }
                {
                    menuItem.key === "mon_compte" ?
                        <div className="container">
                            <div className="monespace-content connect-block">
                                <div className="connect-block-inner">
                                    <div>
                                        <div className="space-item">
                                            <Link to={getRouteByMenuKey(menuList, "mes_commandes").route} >
                                                <img src={shoppingBug} className="icon" alt="PHOTO" />
                                                <h4 className="space-item-title">{i18next.t('space.1')}</h4>
                                            </Link>
                                        </div>
                                        <div className="space-item">
                                            <Link to={getRouteByMenuKey(menuList, "mes_informations").route} >
                                                <img src={userIcon} className="icon" alt="PHOTO" />
                                                <h4 className="space-item-title">{i18next.t('space.2')}</h4>
                                            </Link>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="space-item">
                                            <Link to={getRouteByMenuKey(menuList, "mes_adresses").route} >
                                                <img src={homeIcon} className="icon" alt="PHOTO" />
                                                <h4 className="space-item-title">{i18next.t('space.3')}</h4>
                                            </Link>
                                        </div>
                                        <div className="space-item" >
                                            <Link to={getRouteByMenuKey(menuList, "mes_tickets").route}  >
                                                <img src={magasin} className="icon magasin-icon" alt="PHOTO" />
                                                <h4 className="space-item-title">{i18next.t('space.4')}</h4>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <button className="button-style hoverButtonStyle" onClick={this.handleLogout}>
                                    {i18next.t('space.5')}
                                </button>
                            </div>
                        </div>
                        :
                        <>
                            {menuItem.key === "mes_commandes" && <MesCommandes menuKey={menuItem.key} />}
                            {menuItem.key === "mes_adresses" && <MesAddress />}
                            {menuItem.key === "mes_informations" && <MesInformations />}
                            {menuItem.key === "mes_tickets" && <MesCommandes menuKey={menuItem.key} />}
                        </>

                }

            </div>
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    menuList: getMenu(state),
    user: getUser(state),
    authenticated: isAuthenticated(state),
});

const mapDispatchToProps = (dispatch): typeof SpaceActionCreators => {
    return bindActionCreators(SpaceActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(MySpace);
