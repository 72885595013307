import { SpaceActionCreators } from "./actions";
import { put } from "redux-saga/effects";

export function* space() {
    try {
        yield put(SpaceActionCreators.spaceSuccess({ spaceList: 'space' }));
    } catch (e) {
        yield put(SpaceActionCreators.spaceFailure(e.message));
    }
}

export function* spaceSaga() {
    // yield takeLatest(ProductsTypes.GET_PRODUCT_LIST, products);
}

export default spaceSaga;