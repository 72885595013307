import i18next from "i18next";
import React from "react";
import { getCurrency, getDateTimestamp, replaceComma } from "../../helpers/helpers";
import './ticketDetails.scss'

interface Props {
    order?: any;
}
const TicketDetails = ({ order = {} }: Props) => {
    const { clientOrderDetails = [] } = order;

    return (
        <div className="stores-tickets small-container">
            <div className="ticket-details">
                <div className="ticket-info">
                    <div>
                        <span>{`${i18next.t('commandes.11')} : ${getDateTimestamp(order.orderDate)}`}</span>
                        <span className="seperator">|</span>
                        <span>N° {`${i18next.t('commandes.12')} : ${order.orderNumber}`}</span>
                    </div>
                    <span className="currency-info">
                        {`${i18next.t('commandes.7')} : ${replaceComma(order.sales)} ${getCurrency(order.currency)}`}
                    </span>
                </div>
                <div className="magasin-info">
                    <h4>{i18next.t('commandes.13')}&nbsp;:&nbsp;</h4>
                    <span>{order.storeName}</span>
                </div>
            </div>
            <div className="table">
                <div className="ticket-detail-header">
                    <div className="ticket-detail-header-column" style={{ width: '20%' }}>
                        {i18next.t('product.product.24')}
                    </div>
                    <div className="ticket-detail-header-column" style={{ width: '32%' }}>
                        {i18next.t('product.product.14')}
                    </div>
                    <div className="ticket-detail-header-column" style={{ width: '17%' }}>
                        {i18next.t('product.product.17')}
                    </div>
                    <div className="ticket-detail-header-column" style={{ width: '16%' }}>
                        {i18next.t('product.product.5')}
                    </div>
                    <div className="ticket-detail-header-column" style={{ width: '15%' }}>
                        {i18next.t('product.basket.summary.4')}
                    </div>
                </div>
                {clientOrderDetails.map((detail) => (
                    <div className="ticket-detail-body" key={detail.orderDate} >
                        <div className="ticket-detail-body-column" style={{ width: '20%' }}>{detail.category}</div>
                        <div className="ticket-detail-body-column" style={{ width: '32%' }}>{detail.productName}</div>
                        <div className="ticket-detail-body-column" style={{ width: '17%' }}>{detail.size}</div>
                        <div className="ticket-detail-body-column" style={{ width: '16%' }}>{detail.quantity}</div>
                        <div className="ticket-detail-body-column" style={{ width: '15%' }}>
                            <span className="bold-price">{`${detail.sales} ${getCurrency(detail.currency)}`}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TicketDetails