import {createReducer} from 'reduxsauce';
import {MesAddressTypes} from './actions';

export interface IMesAddressState {
    address:any,
    error: string,
    status: string,
    mesAddressSend: any,
}
export const mesAddress: IMesAddressState = {
    address:[],
    error: "",
    status: "",
    mesAddressSend: {}
};

export const getMesAddress = (state) => {
    return {...state};
};

export const mesAddressSuccess = (state, action) => {
    return {...state, address: action.data,  status: "", mesAddressSend: {}};
};

export const mesAddressFailure = (state, action) => {
    return {...state, error: action.error, status: "", mesAddressSend: {}};
};

export const getMesAddressPost = (state) => {
    return {...state};
};

export const mesAddressPostSuccess = (state, action) => {
    return {...state, mesAddressSend: action.data, status: "success"};
};

export const mesAddressPostFailure = (state, action) => {
    return {...state, error: action.error, status: "failed" };
};

export const getMesAddressRemove = (state) => {
    return {...state};
};
export const mesAddressRemoveSuccess = (state, action) => {
    return {...state, mesAddressRemoved: action.data, status: "success"};//TODO
};
export const mesAddressRemoveFailure = (state, action) => {
    return {...state, error: action.error, status: "failed" };
};




export const handlers = {
    [MesAddressTypes.MES_ADDRESS_START]: getMesAddress,
    [MesAddressTypes.MES_ADDRESS_SUCCESS]: mesAddressSuccess,
    [MesAddressTypes.MES_ADDRESS_FAILURE]: mesAddressFailure,
    [MesAddressTypes.MES_ADDRESS_POST_START]: getMesAddressPost,
    [MesAddressTypes.MES_ADDRESS_POST_SUCCESS]: mesAddressPostSuccess,
    [MesAddressTypes.MES_ADDRESS_POST_FAILURE]: mesAddressPostFailure,
    [MesAddressTypes.MES_ADDRESS_REMOVE_START]: getMesAddressRemove,
    [MesAddressTypes.MES_ADDRESS_REMOVE_SUCCESS]: mesAddressRemoveSuccess,
    [MesAddressTypes.MES_ADDRESS_REMOVE_FAILURE]: mesAddressRemoveFailure,
};

export default createReducer(mesAddress, handlers);