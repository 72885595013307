import React from "react";

import "../../creation/container/creation.scss";
import './checkboxList.scss'


interface Props {
    data?: any,
    text?: string,
    checked?: boolean,
    handleChange?: any,
    id?: any,
    name?: string,
    isEnable?: any,
    elemStyle?: string
}

const Checkbox = ({ text, id, name = "", checked = false, data = "", isEnable = false, elemStyle = "", handleChange }: Props) => {
    return (
        <label htmlFor={id} className={`connection-checkbox ${elemStyle}`}>
            <input type="checkbox"
                id={id}
                disabled={isEnable}
                checked={checked}
                name={name}
                onChange={handleChange} />
            <span></span>
            <p>{text}<>{data}</>
            </p>
        </label>
    );
}


export default Checkbox
