import {createReducer} from 'reduxsauce';
import {StoreLocationTypes} from './actions';

export interface IStoreLocator{
    userStore:any,
}

export const locationStore: IStoreLocator = {
    userStore: {},
};

export const setStoreLocationStart = (state,action) => {
    return {...state, userStore: action.data};
};

export const resetStoreLocationStart = (state,action) => {
    return {...state, userStore: {}};
};


export const handlers = {
    [StoreLocationTypes.STORE_LOCATION_START]: setStoreLocationStart,
    [StoreLocationTypes.RESET_STORE_LOCATION_START]: resetStoreLocationStart,
};
export default createReducer(locationStore, handlers);


