import React, {Component} from "react";
import {getBase64ImageFromUrl} from "../../../../helpers/helpers";
const { detect } = require('detect-browser');

interface Props {
    src: string,
    alt: string,
    ElemclassName?: string
}

interface State {
    src: string,
}

class ImgBlob extends Component <Props, State>{
    state: State = {
        src: this.props.src || "",
    }
    componentDidMount = () => {
        const browser = detect();
        if(browser.name === "ie" && this.props.src ) {
            getBase64ImageFromUrl(this.props.src).then(res => {
                return this.setState({src: res})
            })
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.src && prevProps.src !== this.props.src) {
            const browser = detect();
            if (browser.name === "ie" && this.props.src) {
                getBase64ImageFromUrl(this.props.src).then(res => {
                    return this.setState({src: res})
                })
            } else {
                this.setState({src: this.props.src})
            }
        }
    }

    render() {
        const {src} = this.state;
        const {alt,ElemclassName} = this.props;
        return (
            <img src={src} alt={alt || "img"} className={ElemclassName ? ElemclassName : ""}/>
        );
    }
}

export default ImgBlob;