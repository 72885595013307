import {MotdePasseTypes, MotdePasseActionCreators} from "./actions";
import { takeLatest, put, call } from "redux-saga/effects";
import { PASSWORD_RESET_FINISH_URL } from "../../../utils/constants/service";
import axios from 'axios';

export function* resetPassword(userInputData) {
    try {
        const headers = {
            headers: {
            }
        };
        const body = {
            key: userInputData.resetKey,
            newPassword: userInputData.password
        };
        const userInfo = yield call(() => {
            return axios.post(`${PASSWORD_RESET_FINISH_URL}`,body, headers)
                .then(res => res.data)
        });

        const userData = { resetPassword: false, changePassword: false, passwordChanged: true };

        yield put(MotdePasseActionCreators.motdePasseResetPasswordSuccess(userData));
    } catch (e) {
        yield put(MotdePasseActionCreators.motdePasseResetPasswordFailure(e.message));
    }
}


export function* motdePasseSaga() {
    yield takeLatest(MotdePasseTypes.MOTDE_PASSE_RESET_PASSWORD_START, resetPassword);
}

export default motdePasseSaga;
