import React from "react";
import {Component} from "react";
import {connect} from "react-redux";
import {HomeActionCreators} from "../../home/redux/actions";
import {findNodeByProperty, getItemMenu, getLangPrefix} from "../../../helpers/helpers";
import {getHome} from "../../home/redux/selectors";
import {getMenu} from "../../../layouts/main/header/redux/selectors";
import CurrentPage from "../../../layouts/common/currentPage";
import RetournerUnArticle from "./component/retournerUnArticle/container/index"

interface Props {
    menuItem?: any,
    menuList: any,
    location?: any,
    canonicalUrl?: any,
    history?: any,
    data?: any,
}

interface State {
    parent: any,
}

class Retour extends Component<Props & typeof HomeActionCreators> {
    state: State = {
        parent: [],
    };

    componentDidMount() {
        this.setParent();
    }

    setParent = () => {
        let parent: any = [];

        if (this.props.menuItem && this.props.menuItem.key !== "mes_commandes") {
            const parentEl = findNodeByProperty(this.props.menuList, "mes_commandes", "key");
            if (parentEl) {
                parent.push(parentEl)
            }
        }
        if (this.props.menuItem && this.props.menuItem.key !== "mon_compte") {
            const parentEl = findNodeByProperty(this.props.menuList, "mon_compte", "key");
            if (parentEl) {
                parent.push(parentEl)
            }
        }

        this.setState({parent});
    };
    getCanonicalUrl = (key) => {
        const {menuList} = this.props;
        const item = getItemMenu('CATALOGUE', key, menuList);
        if (item && typeof item === 'object') {
            this.setState({url: item.canonicalUrl})
        }
    };

    render() {
        const {parent} = this.state;
        const {menuItem,location} = this.props;
        const elem = location && location.state && location.state.element;
        if(!elem){
            this.props.history.push(`${getLangPrefix()}/`)
        }
        return (
            <>
                {menuItem && Object.keys(menuItem).length ?
                    <CurrentPage selectedItem={menuItem} selectedParent={parent}/>
                    : ""
                }
                <RetournerUnArticle data={elem}/>
            </>
        );
    }
}

const mapStateToProps = (collectionState: any): Props => ({
    data: getHome(collectionState),
    menuList: getMenu(collectionState),

});

export default connect(mapStateToProps)(Retour);

