import React from "react"
import i18next from "i18next";
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'
import { DISCOUNT_CODE, STORE_DEPOT } from "../../../../utils/constants/variables";
import { getCurrency, getFullCouponSpecialDiscount, getIsApprovedDiscount, getIsHaveAtLeastOneOldPrice, isProductsInBarcode, replaceComma } from "../../../../helpers/helpers";
import { getDeliveryMethod, getDiscount, getPromotionDiscount } from "../../../basket/redux/selectors";
import { getBrandDetail } from "../../../../layouts/main/header/redux/selectors";
import { OrderActionCreators } from "../../../basket/redux/actions";
import { getBasket } from "../../../addToCart/redux/selectors";

interface Props {
  basketData: Record<string, any>;
  basketStore: any;
  discount: any;
  deliveryMethod: string;
  brandDetail: any;
  promotionDiscount: any;
  dispatch: (...args: any) => void;
  basket: any;
}

export const Price: React.FC<Props> = ({
  basketData,
  discount,
  deliveryMethod,
  brandDetail,
  basketStore,
  promotionDiscount,
  dispatch,
  basket
}) => {
  const { price, totalPrice, livraison, totalQuantity, oldPrice, discountedSum } = basketData;
  const isStoreDeliveryMethod = deliveryMethod === "storeDelivery";

  const removeValidateDiscount = () => {
    dispatch(OrderActionCreators.annullerDiscountStart());
  }

  const isHaveAtLeastOneOldPrice = getIsHaveAtLeastOneOldPrice()
  const correctTotalPrice = (getFullCouponSpecialDiscount(basket, basketData, discount, promotionDiscount) + livraison)|| 0
  const isApprovedDiscount = getIsApprovedDiscount(basket, discount)

  const checkFooterLine = !isStoreDeliveryMethod && (correctTotalPrice - oldPrice) || '';

  return (
    <div className="peymant-content-item peymant-content-item-value">
      <div className="peymant-content-item-value-wrapper">
        <div
          className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
          <h4>{totalQuantity} {totalQuantity < 2 ? i18next.t('product.basket.23') : i18next.t('product.basket.12')}</h4>
          {isStoreDeliveryMethod ? (
            <h2 className="price"> {replaceComma(isHaveAtLeastOneOldPrice ? oldPrice : price) + getCurrency()}</h2>
          ) : (
            <h2 className="price"> {replaceComma(isHaveAtLeastOneOldPrice ? oldPrice : price)}{getCurrency()}</h2>
          )}
        </div>
        {isHaveAtLeastOneOldPrice && <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
          <h4>{i18next.t('payment.20')}</h4>
          <h2 className="price">{replaceComma(price) + getCurrency()}</h2>
        </div>}
        {promotionDiscount && discountedSum > 0 ?
          <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
            <h4>{promotionDiscount.ruleNameMessage}</h4>
            <h2 className="price">-{replaceComma(discountedSum)}
              {promotionDiscount.ruleType ? '€' : '%'}
            </h2>
          </div> : ""
        }
        {discount && discount.value && !discount.isProductsInBarcode && isApprovedDiscount &&
          <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
            <div className="validateDiscountWrapper">
              <h4> {discount ? i18next.t('product.basket.21') + " " + `${discount.discountLabel}`.toUpperCase() : ""}</h4>
              <span onClick={removeValidateDiscount} className="validateDiscount-trashicon">
                <Icon className='darkred' name="trash alternate"></Icon>
              </span>
            </div>
            <h2 className="price">-
              {
                discount && discount.couponType === "PERCENT" && replaceComma(discount.value).split(",")[1] === "00"
                  ? replaceComma(discount.value).split(",")[0]
                  : discount
                    ? replaceComma(discount.value)
                    : ""
              }
              {discount.couponType === "PERCENT" ? "%" : "€"}
            </h2>
          </div>
        }
        {!isStoreDeliveryMethod ? (
          <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
            <h4>
              {livraison ? i18next.t('product.basket.18') : i18next.t('product.basket.17')}
            </h4>
            <h2 className="price"> {replaceComma(livraison) + getCurrency()}</h2>
          </div>
        ) : ""}
        {correctTotalPrice - oldPrice ? (
          <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
            <h4>{i18next.t('product.basket.27')}</h4>
            <h2 className="price"> {replaceComma(correctTotalPrice - oldPrice) + getCurrency()}</h2>
          </div>
        ) : null}
      </div>
      <div className={`peymant-content-item-value-footer dflex justify-content-between ${checkFooterLine && 'p-20'}`}>
        <h4>
          {
            STORE_DEPOT === "1"
              ? i18next.t('product.basket.15')
              : basketStore.storeDelivery === "STO"
                ? i18next.t('product.basket.31')
                : i18next.t('product.basket.15')
          }
        </h4>
        <div>
          <h4 className="peymant-content-all-price">{replaceComma(correctTotalPrice) + getCurrency()}</h4>
          <h4 className="comprise">{i18next.t('product.basket.16')}</h4>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any): Partial<Props> => ({
  discount: getDiscount(state),
  deliveryMethod: getDeliveryMethod(state),
  brandDetail: getBrandDetail(state),
  promotionDiscount: getPromotionDiscount(state),
  basket: getBasket(state),
});

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
};

export const DiscountPriceBlock = connect(mapStateToProps, mapDispatchToProps)(Price);