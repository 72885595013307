import { createActions } from 'reduxsauce';


export const { Types: OrderTypes, Creators: OrderActionCreators } = createActions({
    orderStart: [null],
    orderSuccess: ['data'],
    orderFailure: ['error'],
    createOrderStart: ['data', "callback"],
    createOrderSuccess: ['data'],
    createOrderFailure: ['error'],
    promotionDiscountStart: ['discountedSum', 'promotionData'],
    promotionDiscountSuccess: ['data'],
    annullerPromotionDiscountStart: null,
    validateDiscountStart: ['data', 'webClientId', 'callback'],
    validateDiscountSuccess: ['data', 'typeOfRequest', 'isApprovedDiscount'],
    validatePrivateDiscountSuccess: ['data'],
    validateDiscountFailure: ['error'],
    updateOrderStart: ["data"],
    updateOrderSuccess: ["data"],
    updateOrderFailure: ["error"],
    getBasketStart: ['callback'],
    selectedDeliveryMethodStart: ["method"],
    storeLocationStart: ['data'],
    resetStoreLocationStart: [null],
    cleareCreatedOrderStart: null,
    cleareValidateInitialStart: null,
    annullerDiscountStart: null,
    annullerPrivateDiscountSuccess: ['data'],
    annullerDiscountSuccess: null,
    setDiscountData: ['data'],
    resetErrorMessage: null,
});
