import React, {Component} from 'react';
import {Redirect, Route, Switch} from "react-router";
import {MetroSpinner} from "react-spinners-kit";

import ErrorBoundaryRoute from "../../shared/error/error-boundary-route";
import Header from './header/container/header';
import Footer from './footer/container/footer';

//modules
import Home from '../../modules/home/container/home';
import Collection from '../../modules/collection/container';
import Timeline from '../../modules/timeline/container';
import OrEtArgent from '../../modules/orEtArgent/container';
import Product from "../../modules/product/container/product";
import Blog from "../../modules/blog/container/blog";
import BlogPost from "../../modules/blog/container/component/blogPost";
import Menu from "./header/container/menu/container/menu";
import Search from "../../modules/search/container/search";
import Products from '../../modules/products/container/index';
import Connection from '../../modules/connection/container/index';
import MentionLegales from '../../modules/mentionLegales/container';
import PlanDuSite from '../../modules/planDuSite/container';
import Component503 from '../../modules/503/503';
import Component404 from '../../modules/404/404';
import MySpace from '../../modules/monEspace/container';
import Validation from "../../modules/validation/container/validation";
import ValidationPaypal from "../../modules/validation/container/validationPaypal";
import ContactUs from "../../modules/services/contactUs/container/contactUs";
import Professional from "../../modules/services/professional/container";
import Catalogue from "../../modules/services/catalogue/container";
import Motdepasse from "../../modules/motdepasse/container/motdepasse";
import Nore from "../../modules/notre/container/index";
import Retour from "../../modules/retour/container/index";
import Acceped from "./../../modules/accepted/container/index";
import Magasin from "./../../modules/magasin/content/index";
import ValidationDiscount from "./../../modules/validation/container/validationDiscount";
import Shopper from "./../../modules/magasin/content/component/shopper";
import Campaign from "./../../modules/campaign/container/index";
import Wishlist from './../../modules/Wishlist/continer/Wishlist';
import PaymentConfirmation from '../../modules/adyen/paymentConfirmation/index';
//variables
import {CATALOGUE_TYPE, MENU_TYPE} from "../../utils/constants/variables";
import BlackWindow from "../../modules/common/blackWindow";
import {getLangPrefix} from "../../helpers/helpers";
import Basket from '../../modules/basketRefactor/container/basket';

interface State {
    activeMenuItem: number,
    routeList?: any,
    isMobile: boolean,
    styleNavbar?: any,
    homeItem: object,
    hoverMenu: boolean,
    gon: boolean
}

class MainLayout extends Component {
    state: State = {
        activeMenuItem: -1,
        isMobile: false,
        routeList: [],
        styleNavbar: false,
        homeItem: {},
        hoverMenu: false,
        gon: true
    };

    handleMenuHover = (index, isMobile: boolean = false) => {
        if (this.state.activeMenuItem !== index) {
            this.setState({activeMenuItem: index, isMobile, gon: false});
        }
    };

    handleMenuLeave = () => {
        this.setState({activeMenuItem: -1, gon: false});
    };
    getRouteList = (routeList = []) => {
        let homeItem: any = routeList.find((item: any) => item.key === "home");
        homeItem = homeItem ? homeItem : {};
        this.setState({routeList, homeItem})
    };

    componentDidUpdate(prevProps) {
        if (this.props["location"].pathname !== prevProps.location.pathname) {
            this.setState({gon: true})
        }
    }

    getComponentByRoute = (urlKey, altUrl1 = "") => {
        let component;
        const key = urlKey.includes("footer_") && altUrl1 ? altUrl1.toLowerCase() : urlKey;
        switch (key.toLowerCase()) {
            case 'creation_de_compte':
                component = Connection;
                break;
            case 'ma_commande':
                component = Basket;
                break;
            case 'paiement':
                component = Validation;
                break;
            case 'contact':
                component = ContactUs;
                break;
            case 'catalogue':
                component = Catalogue;
                break;
            case 'espace-professionnel':
                component = Professional;
                break;
            case 'mot_de_passe':
                component = Motdepasse;
                break;
            case 'plan-du-site':
                component = PlanDuSite;
                break;
            case 'retour':
                component = Retour;
                break;
            case 'mon_compte':
            case 'mes_commandes':
            case 'mes_adresses':
            case 'mes_retours':
            case 'mes_informations':
            case 'mes_tickets':
                component = MySpace;
                break;
            case "boutique" :
            case "negozi" :
                component = Magasin;
                break;
            case "shopper" :
                component = Shopper;
                break;
            case "campaign" :
                component= Campaign;
                break;
            case "wishlist" :
                component = Wishlist;
                break;
            case "404":
                component = Component404;
                break;
            default:
                component = Products;
        }
        return component;
    };

    getComponentByGridId = (gridId = 0, urlKey = "", altUrl1 = "") => {
        let component;
        switch (gridId) {
            case 1:
                component = Home;
                break;
            case 2:
                component = MentionLegales;
                break;
            case 3:
                component = OrEtArgent;
                break;
            case 4:
                component = Collection;
                break;
            case 5:
                component = Products;
                break;
            case 6:
                component = Timeline;
                break;
            case 7:
                component = Nore;
                break;
             case 8:
                component = Blog;
                break;
            default:
                component = this.getComponentByRoute(urlKey, altUrl1);
        }
        return component;
    };

    navStyles = (style) => {
        this.setState({
            styleNavbar: style
        })
    };

    render() {
        const {activeMenuItem, routeList, styleNavbar, homeItem, gon} = this.state;
        const LANGUAGE_PREFIX = getLangPrefix();
        const homeMenu = routeList.length ? routeList.find(item => (item.key && item.key.toUpperCase() === "HOME") || item.gridTypeId === 1) : undefined;
        const promotion = homeMenu ? homeMenu.promotion : "";

        return (
            <div>
                {
                    activeMenuItem > 0 ? <BlackWindow type="menu"/> : ""
                }

                <Header navStyles={this.navStyles} routeList={routeList}/>

                <Menu
                    handleMenuHover={this.handleMenuHover}
                    activeMenuItem={this.state.activeMenuItem}
                    handleMenuLeave={this.handleMenuLeave}
                    handleReceiveMenu={this.getRouteList}
                    styleOptions={styleNavbar}
                />
                <div onMouseOut={this.state.activeMenuItem !== -1 ? this.handleMenuLeave : undefined}>
                    <Switch>
                        <Switch>
                            {routeList.length ?
                                <Switch>
                                    
                                    {
                                        routeList.map((url, i) => {
                                            let component = this.getComponentByGridId(url.gridTypeId, url.key, url.altUrl1);
                                                return <ErrorBoundaryRoute exact
                                                                           path={`${LANGUAGE_PREFIX}${url.canonicalUrl}`}
                                                                           component={component} key={url.menuId}
                                                                           menuItem={url}
                                                                           gon={gon}

                                                />;
                                            }
                                        )
                                    }
                                    {
                                        routeList.map((url, i) => {
                                            if (url.gridTypeId === 8 || url.key === "magazine") {
                                                return <ErrorBoundaryRoute exact
                                                                           path={`${LANGUAGE_PREFIX}${url.canonicalUrl}/:postId`}
                                                                           component={BlogPost} key={url.menuId}
                                                                           gon={gon}
                                                                           menuItem={url}/>;
                                            }
                                            if (url.key !== 'nouveaux_precieux' && (url.menuType === MENU_TYPE || url.menuType === CATALOGUE_TYPE)) {
                                                    return <ErrorBoundaryRoute exact
                                                                               path={`${LANGUAGE_PREFIX}${url.canonicalUrl}/:productId`}
                                                                               component={Product} key={url.menuId}
                                                                               gon={gon}
                                                                               menuItem={url}/>;
                                                }
                                            }
                                        )}

                                    {
                                        routeList.map((url, i) => {
                                            let component = this.getComponentByRoute(url.key, url.altUrl1);
                                            if (url.menuType === MENU_TYPE || url.menuType === CATALOGUE_TYPE) {
                                                return <ErrorBoundaryRoute exact
                                                                           path={`${LANGUAGE_PREFIX}${url.canonicalUrl}/filtres/:data+`}
                                                                           gon={gon}
                                                                           component={component} key={url.menuId}
                                                                           menuItem={url}/>;
                                            }
                                        })
                                    }
                                    {
                                        routeList.map((url, i) => {
                                            if (url.altUrl1) {
                                                let component = this.getComponentByRoute(url.key, url.altUrl1);
                                                return <ErrorBoundaryRoute exact path={url.altUrl1}
                                                                           gon={gon}
                                                                           canonicalUrl={`${LANGUAGE_PREFIX}${url.canonicalUrl}`}
                                                                           component={component} key={url.menuId}
                                                                           menuItem={url}/>;
                                            }
                                        })
                                    }
                                    {
                                        routeList.map((url, i) => {
                                            if (url.altUrl2) {
                                                let component = this.getComponentByRoute(url.key, url.altUrl1);
                                                return <ErrorBoundaryRoute exact path={url.altUrl2}
                                                                           gon={gon}
                                                                           canonicalUrl={`${LANGUAGE_PREFIX}${url.canonicalUrl}`}
                                                                           component={component} key={url.menuId}
                                                                           menuItem={url}/>;
                                            }
                                        })
                                    }
                                    <ErrorBoundaryRoute exact path="/" component={Home} gon={gon} menuItem={homeItem}/>
                                    <ErrorBoundaryRoute path={`${LANGUAGE_PREFIX}/search`} gon={gon}
                                                        component={Search}/>
                                    <ErrorBoundaryRoute exact path={`${LANGUAGE_PREFIX}/accepted`} gon={gon} component={PaymentConfirmation}/>
                                    <ErrorBoundaryRoute exact path={`${LANGUAGE_PREFIX}/failed`} gon={gon} component={PaymentConfirmation}/>
                                    <Route path={`${LANGUAGE_PREFIX}/validate`}
                                           render={(props) => <ValidationDiscount {...props} /> }/>
                                    <Route path={`${LANGUAGE_PREFIX}/accepted-paypal`}
                                           render={(props) => <ValidationPaypal {...props} />}/>
                                    <Route path={`${LANGUAGE_PREFIX}/failed-paypal`}
                                           render={(props) => <ValidationPaypal {...props} />}/>
                                    <Route path={`${LANGUAGE_PREFIX}/reset/finish/:resetKey`}
                                           render={(props) => <Motdepasse  {...props} validateResetPasswordKey={true}/>}/>
                                    <Route path="/reset/finish/:resetKey"
                                           render={(props) => <Motdepasse  {...props} validateResetPasswordKey={true}/>}/>
                                    <ErrorBoundaryRoute path={`${LANGUAGE_PREFIX}/503`} component={Component503}/>
                                    <ErrorBoundaryRoute path={`${LANGUAGE_PREFIX}/404`} component={Component404}/>
                                    <Redirect from="*" to={`${LANGUAGE_PREFIX}/404`}/>
                                </Switch>
                                :
                                ""
                            }
                        </Switch>
                        <ErrorBoundaryRoute exact path="/" component={Home} menuItem={homeItem}/>
                        <ErrorBoundaryRoute path={`/404`} component={Component404}/>
                        <Redirect from="*" to={`/404`}/>
                    </Switch>
                </div>
                <Footer promotion={promotion}/>
            </div>
        );
    }
}

export default MainLayout;
