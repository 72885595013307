import {BlogTypes, BlogActionCreators} from "./actions";
import {takeLatest, put, call} from "redux-saga/effects";
import axios from 'axios';
import {CONTENT_URL, INSTAGRAM_DATA_URL, PRODUCTS_URL} from "../../../utils/constants/service";
import {discountHeader, getApiPrefix} from "../../../helpers/helpers"
import {BRAND_ID, COUNTRY, DEFAULT_CURRENCY, ALT_BRAND_ID} from "../../../utils/constants/variables";


export function* getBlogData(params) {
    try {
        const headers = {headers: {language: getApiPrefix()}};
        const data = yield call(() => {
            return axios.get(`${CONTENT_URL}${params.id}`, headers)
                .then(res => res.data)
        });
        yield put(BlogActionCreators.blogSuccess(data));
    } catch (e) {
        yield put(BlogActionCreators.blogFailure(e.message));
    }
}

export function* getInstagramData(params) {
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };
        const data = yield call(() => {
            return axios.get(`${INSTAGRAM_DATA_URL}${BRAND_ID}?count=${params.count}`, headers)
                .then(res => res.data)
        });
        yield put(BlogActionCreators.blogInstagramSuccess(data));
    } catch (e) {
        yield put(BlogActionCreators.blogInstagramFailure(e.message));
    }
}

export function* blogFavoriteProducts(params) {
    try {
        const data = yield call(() => {
            return axios.post(
                `${PRODUCTS_URL}${params.id}`,
                {
                    brand: ALT_BRAND_ID || BRAND_ID,
                    country: COUNTRY,
                    currency:DEFAULT_CURRENCY,
                    language:getApiPrefix()
                },
                discountHeader()
            )
                .then(res => res.data)
        });


        yield put(BlogActionCreators.blogFavoriteProductsSuccess(data));
    } catch (e) {
        yield put(BlogActionCreators.blogFavoriteProductsFailure(e.message));
    }
}

export function* blogSaga() {
    yield takeLatest(BlogTypes.BLOG_START, getBlogData);
    yield takeLatest(BlogTypes.BLOG_INSTAGRAM_START, getInstagramData);
    yield takeLatest(BlogTypes.BLOG_FAVORITE_PRODUCTS_START, blogFavoriteProducts);

}

export default blogSaga;
