import React from 'react';
import { DELIVERY_PRICE } from "./../../../../../utils/constants/variables";
import i18next from "i18next";
import security from '../../../../../assets/img/security.png';
import service from '../../../../../assets/img/service.png';
import creditCard from '../../../../../assets/img/credit-card.png';
import box from '../../../../../assets/img/box.png';
import okImg from '../../../../../assets/img/ok.png';
import { getCurrency, replaceComma } from "../../../../../helpers/helpers";

const About = () => {
    return (
        <section className="about">
            <div className="home-container">
                <div className="d-around  about-item-content">
                    <div className="about-item">
                        <img src={security} alt="security" />
                        <h4>{i18next.t('home.about.1')}</h4>
                    </div>
                    <div className="about-item">
                        <img src={box} alt="box" />
                        <h4>{
                            DELIVERY_PRICE > 0
                                ? i18next.t('product.basket.45', {
                                    price: replaceComma(DELIVERY_PRICE),
                                    currency: getCurrency()
                                })
                                : i18next.t('product.product.9')
                        }</h4>
                    </div>
                    <div className="about-item">
                        <img src={service} alt="security" />
                        <h4>{i18next.t('home.about.5')}</h4>
                    </div>
                    <div className="about-item">
                        <img src={creditCard} alt="creditCard" />
                        <h4>{i18next.t('home.about.4')}</h4>
                    </div>
                    <div className="about-item">
                        <img src={okImg} alt="okimg" />
                        <h4>{i18next.t('home.about.3')}</h4>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default About;
