import React from "react";
import Swiper from 'react-id-swiper';
import { Modal } from 'semantic-ui-react';

import { BASE_API_GATEWAY } from "../../utils/constants/service";

import './fullPageSlider.scss'

interface Props {
    onClose: () => void;
    show: boolean;
    data: string[];
}

const FullPageSlider: React.FC<Props> = ({
    onClose,
    show,
    data
}) => {
    const params = {
        spaceBetween: 20,
        direction: 'horizontal',
        loop: true,
        loopFillGroupWithBlank: true,
        speed: 600,
        setWrapperSize: true,
        resizeReInit: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    };
    return (
        <Modal
            onClose={onClose}
            open={show}
            className="full-page-slider-modal"
        >
            <div className="full-page-slider-container">
                <div className="close-slider-btn d-end">
                    <span onClick={onClose}>&#10006;</span>
                </div>
                <div className="single-slider-container">
                    <Swiper {...params}>
                        {data.map((item, i) => {
                            return (
                                <div key={i} className="slide single-slider">
                                    <img src={`${BASE_API_GATEWAY}${item}`} alt="" />
                                </div>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
        </Modal>
    )
}

export default FullPageSlider;