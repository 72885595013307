import {createReducer} from 'reduxsauce';
import {WichListTypes} from './actions';

export interface IPaymentState {
    wichList: any,
    error: string,
    status: string,
    loading: boolean,
}
const initialWichList = localStorage.getItem('wishlist');

export const wichList: IPaymentState = {
    wichList:  initialWichList ? JSON.parse(initialWichList) : [],
    error: "",
    status: "",
    loading: false,
};

export const updateWichList = (state, action) => {
    const { data } = action;

    return {
        ...state,
        wichList: data
    };
};

export const handlers = {
    [WichListTypes.WICH_LIST_UPDATE]: updateWichList
};

export default createReducer(wichList, handlers);
