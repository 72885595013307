import { UserTypes, UserActionCreators } from "./actions";
import { takeLatest, put, call, select } from "redux-saga/effects";
import { USER_AUTHENTICATE_URL, WEB_CLIENT, WEB_CLIENT_ADDRESS, fetchIpstackData } from "../../../utils/constants/service";
import axios from 'axios';
import { BRAND_ID, DISCOUNT_CODE, PRIVATE_DISCOUNT } from "../../../utils/constants/variables";
import { decryptCryptr, getShippingCity, detectmob, getOperationSystem, getBasketData } from "../../../helpers/helpers";
import { getBrandDetail } from "../../../layouts/main/header/redux/selectors";
import { TrackLogin } from "../../../helpers/ga4Tracker";
import { MesAddressActionCreators } from "../../monEspace/container/components/MesAddress/redux/actions";
import { getBasket } from "../../addToCart/redux/selectors";
import { getDiscount } from "../../basket/redux/selectors";
import { OrderActionCreators } from "../../basket/redux/actions";


export function* login(userData) {
    const { detect } = require('detect-browser');
    const { callback = () => { } } = userData;
    const { email, password } = userData.user;
    const browser = detect();

    try {
        if (!(email && password)) {
            throw new Error()
        }
        const { customerCode } = yield select(getBrandDetail);
        const awinCampaign = localStorage.getItem("awinCampaign");
        const awinAwc = localStorage.getItem("awinAwc");

        const body = {
            customerCode,
            username: email.toLowerCase(),
            password: password,
            brandId: BRAND_ID,
            rememberMe: true,
            tokenValidityInMilliseconds: 0,
            browserId: decryptCryptr("browserId"),
            browserType: detectmob() ? browser.name + "-mobile" : browser.name,
            operatingSystem: getOperationSystem(),
            country: localStorage.getItem("country"),
            ...(awinCampaign && { awinCampaign }),
            ...(awinAwc && { awinAwc })
        };

        const user = yield call(() => {
            return axios.post(`${USER_AUTHENTICATE_URL}`, body)
                .then(res => res.data)
        });

        yield put(UserActionCreators.loginSuccess(user));
        TrackLogin()
        const headers = {
            headers: {
                Authorization: `Bearer ${user.id_token}`,
            }
        };

        const address = yield call(() => {
            return axios.get(WEB_CLIENT_ADDRESS, headers)
                .then(res => res.data)
        });

        if (Array.isArray(address)) {
            const { zip = '', countryCode = '' } = address.find(item => item.addressType === "SHIP" && item.isDefault === true) || {};

            if (countryCode) {
                localStorage.country = countryCode;
            }

            if (zip) {
                getShippingCity(zip);
            }
        }

        yield put(MesAddressActionCreators.mesAddressSuccess(address));

        callback();
    } catch (e) {
        yield put(UserActionCreators.loginFailure(e.message));
    }

}

export function* getUserInfo() {
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };
        const discountCode = localStorage.getItem(DISCOUNT_CODE);
        const privateCoupon = localStorage.getItem(PRIVATE_DISCOUNT);
        const discount = yield select(getDiscount);

        const data = yield call(() => {
            return axios.get(WEB_CLIENT, headers)
                .then(res => res.data)
        });

        const discountClient = discountCode || data && data.discountCodes && data.discountCodes[0];

        yield put(UserActionCreators.userInfoSuccess(data));

        if (!privateCoupon && discountClient && discountClient !== (discount && discount.discountCode) && !window.location.pathname.includes("validate")) {
            yield put(OrderActionCreators.validateDiscountStart(discountClient, data.webClientId))
        }
    } catch (e) {
        yield put(UserActionCreators.userInfoFailure(e.message));
    }
}

export function* userSaga() {
    yield takeLatest(UserTypes.LOGIN_START, login);
    yield takeLatest(UserTypes.USER_INFO_START, getUserInfo);
}

export default userSaga;
