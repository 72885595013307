import { CreationTypes, CreationActionCreators } from "./actions";
import { takeLatest, put, call, select } from "redux-saga/effects";
import axios from 'axios';
import { ACCOUNT_REGISTER_URL, NEW_MAILJET_SUBSCRIBE_URL, ZG_CUSTOMER_CREATE, fetchIpstackData } from "../../../utils/constants/service";
import { getBrandDetail } from "../../../layouts/main/header/redux/selectors";

export function* creation(params) {
    try {
        const { customerCode } = yield select(getBrandDetail);
        const headers = {
            headers: {
            }
        };

        const body = {
            customerCode,
            password: params.form.password,
            firstName: params.form.username,
            lastName: params.form.userlastname,
            email: params.form.email,
            login: params.form.email,
            langKey: params.form.language,
            gender: params.form.gender,
            birthdate: params.form.birthdate,
            webClientRequest: {
                brandId: params.form.brandId,
                preferredStoreId: params.form.preferredStoreId,
                multisiteOptin: params.form.connection_3 ? 'Y' : 'N',
                dataOptin: params.form.connection_2 ? 'Y' : 'N',
                newsOptin: params.form.connection_1 ? 'Y' : 'N',
                country: localStorage.getItem("country")
            }
        };
        yield call(() => {
            return axios.post(`${ACCOUNT_REGISTER_URL}`, body, headers)
                .then(res => res.data)
        });
        // if (params.form.connection_1) {
        //     yield put(CreationActionCreators.subscriptionStart({ webSiteUrl: params.form.webSiteUrl, email: params.form.email, storeCode: params.form.storeCode }));
        // }

        yield put(CreationActionCreators.creationSuccess({ created: true, email: params.form.email }));
        localStorage.setItem('access', 'cookieApproval')
        yield put(CreationActionCreators.loginStart({ email: params.form.email, password: params.form.password },
            () => {
                if (params.form.connection_1) {
                    const authHeaders = {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                            'Content-Type': 'application/json'
                        }
                    };
                    axios.post(`${ZG_CUSTOMER_CREATE}`, {}, authHeaders).then(res => res.data);
                }
            }
        ));
    } catch (e) {
        yield put(CreationActionCreators.creationFailure(e.message));
    }
}

export function* subscription(params) {

    const { email, storeCode, webSiteUrl } = params.data
    try {
        const headers = {
            headers: {
            }
        };
        const body = {
            email,
            storeCode,
            webSiteUrl
        }

        const data = yield call(() => {
            return axios.post(`${NEW_MAILJET_SUBSCRIBE_URL}`, body, headers)
                .then(res => res.data)
        });
        yield put(CreationActionCreators.subscriptionSuccess(data));
    } catch (e) {
        yield put(CreationActionCreators.subscriptionFailure(e.message));
    }
}

export function* creationSaga() {
    yield takeLatest(CreationTypes.CREATION_START, creation);
    yield takeLatest(CreationTypes.SUBSCRIPTION_START, subscription);
}

export default creationSaga;
