import React from "react"

import "./confirmPopup.scss"
import BlackWindow from "../blackWindow";

interface Props {
    handleConfirm?: () => void,
    handleCancel: any,
    title: string,
    text: string,
    footer?: boolean
}

const ConfirmPopup = ({ title, text, footer = true, handleConfirm, handleCancel }: Props) => {

    return (
        <>
            <div className="confirmpopup">
                <div className="confirmpopup-content">
                    {title ? <p>{title}</p> : ""
                    }
                    <div className="confirmpopup-content-text-box" dangerouslySetInnerHTML={{ __html: text }} />
                    {
                        footer ?
                            <div className="confirmpopup-buttons">
                                <button onClick={handleConfirm}>Yes</button>
                                <button onClick={handleCancel}>No</button>
                            </div> :
                            <div className="confirmpopup-buttons">
                                <button onClick={handleCancel}>OK</button>
                            </div>
                    }

                </div>
            </div>
            <BlackWindow type="page" handleClick={footer ? handleCancel : null} />
        </>
    );
}

export default ConfirmPopup