import { createReducer } from 'reduxsauce';
import { UserTypes } from './actions';
import { isLoggedin } from "../../../helpers/helpers";
import { PRIVATE_DISCOUNT } from '../../../utils/constants/variables';

export interface IAccountState {
    authenticated: boolean,
    user: any,
    userInfo?: any,
    loginFailed?: string
}

export const account: IAccountState = {
    authenticated: false,
    user: {},
    userInfo: {},
    loginFailed: "",
};

export const login = (state) => {
    return {...state, loginFailed: ""};
};

export const logout = (state) => {
    localStorage.removeItem('token');
    localStorage.removeItem(PRIVATE_DISCOUNT);
    return {...state, authenticated: false};
};

export const tokenValidateStart = (state) => {
    return {
        ...state,
        authenticated: isLoggedin()
    };
};

export const loginSuccess = (state, action) => {
    const {user} = action;

    localStorage.setItem('token', user.id_token);
    return {...state, authenticated: true, user: action.data, loginFailed: "SUCCESS"};
};

export const loginFailure = (state, action) => {
    return {...state, error: action.error, user: {}, loginFailed: "FAILED"};
};

export const userInfo = (state) => {
    return {...state};
};

export const userInfoSuccess = (state, action) => {
    return {...state, userInfo: action.data};
};

export const userInfoFailure = (state, action) => {
    return {...state, error: action.error, userInfo: {}};
};

export const handlers = {
    [UserTypes.LOGIN_START]: login,
    [UserTypes.LOGIN_SUCCESS]: loginSuccess,
    [UserTypes.LOGIN_FAILURE]: loginFailure,
    [UserTypes.LOGOUT_START]: logout,
    [UserTypes.TOKEN_VALIDATE_START]: tokenValidateStart,
    [UserTypes.USER_INFO_START]: userInfo,
    [UserTypes.USER_INFO_SUCCESS]: userInfoSuccess,
    [UserTypes.USER_INFO_FAILURE]: userInfoFailure,
};

export default createReducer(account, handlers);