import { createActions } from 'reduxsauce';

export const { Types: CreationTypes, Creators: CreationActionCreators } = createActions({
    creationStart: ['form'],
    creationSuccess: ['data'],
    creationFailure: ['error'],
    clickPopUpStart: [],
    subscriptionStart: ['data'],
    subscriptionSuccess: ['data'],
    subscriptionFailure: ['error'],
    loginStart: ['user', 'callback'],
});
