import {createReducer} from 'reduxsauce';

export interface ISpaceState {
    connection: object
}

export const getSpace: ISpaceState = {
    connection: {}
};

export const monEspace = (state) => {
    return {...state};
};

export default createReducer(getSpace, monEspace);