import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import CurrentPage from "../../../../layouts/common/currentPage";
import Loading from "../../../product/container/components/loading";
import {BASE_API_GATEWAY} from "../../../../utils/constants/service";
import {CatalogueActionCreators} from "../redux/actions";
import {getBrandCatalogues} from "../redux/selectors";
//styles
import "./catalogue.scss";
import Swiper from "react-id-swiper";

interface Props {
    catalogues: any,
    menuItem?: any,
    canonicalUrl?: any,
    location?: any,
    history?: any,
}

class Catalogue extends React.Component<Props & typeof CatalogueActionCreators> {

    componentDidMount() {
        window.scroll(0, 0);
        const {canonicalUrl, location, history} = this.props;
        if (canonicalUrl && location.pathname !== canonicalUrl) {
            history.push(canonicalUrl);
        }
        this.props.catalogueStart()
    }


    render() {
        const {menuItem, catalogues} = this.props;

        const params = {
            direction:  "horizontal",
            slidesPerView: 2,
            speed: 600,
            loop:catalogues.length,
            setWrapperSize: true,
            resizeReInit: true,
            autoplay: {delay: 5000},
            breakpoints: {
                382: {
                    slidesPerView: 1,
                },
                680: {
                    slidesPerView: 2,
                },
                980: {
                    slidesPerView: 2,
                },
                1020: {
                    slidesPerView: 2,
                },
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        };

        return (
            <div className="catalogue">
                {Object.keys(menuItem).length &&
                <CurrentPage selectedItem={menuItem}/>
                }
                {
                    catalogues.length > 2 ?  <div className="flex-box slider container">
                    <Swiper {...params} >
                                {
                                    catalogues.length ?
                                        catalogues.map(item => {
                                            return (
                                                <div key={item.code} className="catalogue-item slider">
                                                    <div className='img-wrapper slider'>
                                                        <a href={`${BASE_API_GATEWAY}/${item.catalogueUrl}`} target="_blank" >
                                                            <img src={`${BASE_API_GATEWAY}/${item.photoUrl}`} title="Collection" alt="PHOTO"/>
                                                        </a>
                                                    </div>
                                                    <h4 className="title">{`${item.name} ${item.seasonName}`}</h4>
                                                </div>
                                            )
                                        })
                                        :
                                        <Loading />
                                }
                    </Swiper>
                </div>
                        :
                            <div className="flex-box container">
                                {
                                    catalogues.length ?
                                        catalogues.map(item => {
                                            return (
                                                <div key={item.code} className="catalogue-item">
                                                    <div className='img-wrapper'>
                                                        <a href={`${BASE_API_GATEWAY}/${item.catalogueUrl}`} target="_blank" >
                                                            <img src={`${BASE_API_GATEWAY}/${item.photoUrl}`} title="descampsImg" alt="PHOTO"/>
                                                        </a>
                                                    </div>
                                                    <h4 className="title">{`${item.name} ${item.seasonName}`}</h4>
                                                </div>
                                            )
                                        })
                                        :
                                        <Loading />
                                }
                            </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any): Props => ({
    catalogues: getBrandCatalogues(state),
});

const mapDispatchToProps = (dispatch) : typeof CatalogueActionCreators => {
    return bindActionCreators(CatalogueActionCreators, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(Catalogue)

