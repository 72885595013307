import {createReducer} from 'reduxsauce';
import {CatalogueTypes} from "./actions";

export interface ICatalogue {
    catalogues: object,
    error: string,
}

export const Catalogue: ICatalogue = {
    catalogues: [],
    error: "",
};


export const catalogueStart = (state) => {
    return {...state};
};

export const catalogueSuccess = (state, action) => {
    return {
        ...state, catalogues: action.data
    };
};

export const catalogueFailure = (state, action) => {
    return {...state, error: action.error, catalogues: []};
};

export const handlers = {
    [CatalogueTypes.CATALOGUE_START]:catalogueStart,
    [CatalogueTypes.CATALOGUE_SUCCESS]: catalogueSuccess,
    [CatalogueTypes.CATALOGUE_FAILURE]: catalogueFailure,
};

export default createReducer(Catalogue, handlers);