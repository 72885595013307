import React from "react";
import i18next from "i18next";
import { Dropdown, Icon } from 'semantic-ui-react';
import { connect } from "react-redux";
import Colorbar from "./colorbar";
import Photo from "../../../common/product/photo";
import Price from "../../../common/product/price";
import AddToCart from './../../../addToCart/container/addToCart';
import LinkFullUrl from "../../../../shared/linkFullUrl/linkFullUrl";
import { Productoptions, getConvertedItemDataForGA4 } from "../../../../helpers/helpers";
import { STORE_DELIVERY } from "../../../../utils/constants/variables";
import { TrackItemSelect } from "../../../../helpers/ga4Tracker";
import { bindActionCreators } from "redux";
import { ProductActionCreators } from "../../redux/actions";

interface Props {
    index?: number,
    data?: any,
    handleChangeColor?: any,
    handleChangeQuantity?: any,
    handleChangeSize?: any,
    handleChangeSelected?: any,
    mobile?: boolean,
    selected?: boolean,
    reset?: boolean,
    quantity?: any,
    selectedProducts?: any,
    handleAddedToCart?: any,
    alt?: any,
    toggleStoreLocator?: () => void,
    handleZindex?: () => void,
    menuItem?: any,
    selectColor?: any,
}

interface State {
    options: any,
    sizeOptions: any,
    isColorToggled: boolean,
    selectedSize: any,
    quantity?: any,
    path: string
}
class ComplectItem extends React.Component<Props, State> {
    state: State = {
        options: [],
        isColorToggled: false,
        selectedSize: "",
        quantity: "0",
        sizeOptions: [],
        path: ""
    };
    componentDidMount(): void {
        this.setSizeInfo();
        this.setPath();
    }

    componentDidUpdate(prevProps): void {
        if (this.props.reset && !prevProps.reset) {
            this.setSizeInfo();
        }
    }

    setPath = () => {
        const [path] = window.location.pathname.match(/.+\//g) || [""]
        this.setState({ path })
    }

    setSizeInfo = () => {
        const sizes = this.getSizes();
        const sizeOptions = sizes.sort((a, b) => a.productId - b.productId).map(item => ({ value: item.productReference, text: item.size }))
        if (sizeOptions.length) {
            sizeOptions.unshift(
                {
                    key: "",
                    value: "",
                    text: i18next.t("product.product.17")
                }
            )
        }
        this.setState(
            {
                selectedSize: sizes[0] ? sizes[0].productReference : "",
                quantity: "0",
                sizeOptions
            }
        )
    }

    getSizes = () => {
        const { data: { sizes = [] } = {} } = this.props;
        return sizes.filter(size => size.webInventory > 0 || STORE_DELIVERY === "1" && !size.webInventory);
    }

    handleChangeQuantity = (name, quantity) => {
        this.setState({ quantity });
        this.props.handleChangeQuantity(this.props.data.productId, quantity, this.state.selectedSize);
        if (this.props.data.sizes && this.props.data.sizes) {
            const productNow = this.props.data.sizes.filter(elem => elem.productReference == this.state.selectedSize)[0]
            if (productNow && Object.keys(productNow).length) {
                const data = getConvertedItemDataForGA4(this.props.menuItem, this.props.data, quantity, productNow);
                const value = data.price;
                const valueT = value * data.quantity;
                TrackItemSelect(data, valueT)
            }
        }
    };

    handleChangeSize = (name, selectedSize) => {
        this.setState({ selectedSize });
        this.props.handleChangeSize(this.props.data.productId, selectedSize, this.state.quantity)

        if (this.props.data.sizes && this.props.data.sizes) {
            const productNow = this.props.data.sizes.filter(elem => elem.productReference == selectedSize)[0]
            if (productNow && Object.keys(productNow).length) {
                const data = getConvertedItemDataForGA4(this.props.menuItem, this.props.data, this.state.quantity > 0 ? this.state.quantity : 1, productNow);
                const value = data.price;
                const valueT = value * data.quantity;
                TrackItemSelect(data, valueT)
            }
        }
    };

    handleColorToggle = (e) => {
        e.stopPropagation();
        this.setState({ isColorToggled: !this.state.isColorToggled });
    };

    callSizeEvent = (item) => {
        // this.props.selectColor(true)
    }

    handleClickColor = () => {
        this.props.selectColor(true)
    };

    render() {
        const { data, data: { productId } } = this.props;
        const { isColorToggled, selectedSize, quantity, sizeOptions, path } = this.state;
        const selectedProduct = Array.isArray(data.sizes) ? data.sizes.find(elem => elem.productReference == selectedSize) || {} : {};
        const qtyOptions = [
            { key: 0, value: "0", text: i18next.t("product.product.5") },
            ...Productoptions(selectedProduct.webInventory || STORE_DELIVERY === "1" ? undefined : 0)
        ]

        return (
            <div className={`pure-complect-item-content ${selectedSize && Number(quantity) ? "active" : ''}`}>
                <div className="pure-complect-item-content-img">
                    {
                        <LinkFullUrl onClick={() => this.callSizeEvent(data)} to={path + (data && data.canonicalUrl)}>
                            <Photo
                                src={data && data.photo}
                                discountDetail={data.discountDetail}
                                thumbnail={data && data.thumbnail}
                                onSpecial={data.onSpecial}
                                alt={data.name}
                                outlet={data.outlet}
                            />
                        </LinkFullUrl>
                    }
                </div>

                <div className="pure-complect-item-wrapper">
                    <div className="pure-complect-item-wrapper--header">
                        <div className="pure-complect-item-wrapper-title-box">
                            <h4 className="title">{data.name}</h4>
                            <LinkFullUrl onClick={() => this.callSizeEvent(data)} to={path + data.canonicalUrl}>{i18next.t('product.parure.2')}</LinkFullUrl>
                        </div>
                        <div className="pure-complect-item-wrapper-links">
                            {
                                sizeOptions.length ?
                                    <>
                                        <Dropdown
                                            className="mainSelect"
                                            selection
                                            value={selectedSize}
                                            options={sizeOptions.sort((a, b) => a.text - b.text)}
                                            onChange={(e, { value }) => selectedSize === value ? null : this.handleChangeSize(productId ? productId.toString() : "", value)}
                                            icon={<Icon name='angle down' />}
                                        />
                                        <Dropdown
                                            className="mainSelect"
                                            selection
                                            value={quantity}
                                            options={qtyOptions}
                                            onChange={(e, { value }) => this.handleChangeQuantity(productId ? productId.toString() : "", value)}
                                            icon={<Icon name='angle down' />}
                                        />
                                    </>
                                    :
                                    <div className="product-no-size">
                                        <h4 className="no-size-title">
                                            {
                                                STORE_DELIVERY === "1" ?
                                                    i18next.t('catalogue.product.8')
                                                    :
                                                    i18next.t('product.product.18')
                                            }
                                        </h4>
                                    </div>

                            }
                            <a href="#" rel="noopener">{i18next.t('product.product.4')}</a>
                        </div>
                    </div>

                    <div className="pure-complect-color-box">
                        {data.colors && data.colors.length > 1 ?
                            <>
                                <div className="pure-complect-color-button dflex align-items-center"
                                    onClick={this.handleColorToggle}>
                                    <p>{i18next.t('product.parure.3')}</p>
                                    <Icon name="angle down" className={`${isColorToggled ? "active" : ''}`} />
                                </div>
                                {isColorToggled &&
                                    <Colorbar
                                        data={data.colors}
                                        handleClickColor={this.handleClickColor}
                                    />
                                }
                            </>
                            :
                            ""
                        }
                    </div>
                    <div className="pure-complect-item-wrapper-price">
                        {quantity < 1 ? <h6 className="title">{i18next.t('product.product.7')}</h6> : ""}
                        <Price
                            startP={selectedSize === "" ? data.startingPrice : selectedProduct.startingPrice}
                            discount={selectedSize === "" ? data.discountDetail : selectedProduct.discountDetail}
                            newP={selectedSize === "" ? data.listPriceAfterDiscount : selectedProduct.listPriceAfterDiscount}
                            quantity={quantity < 1 ? 1 : quantity}
                        />
                    </div>
                </div>
                <div className="complect-item-btn">
                    <AddToCart
                        buttonText={i18next.t('product.product.13')}
                        data={this.props.selectedProducts}
                        isComplect={true}
                        handleAddedToCard={this.props.handleAddedToCart}
                        isEnable={quantity && selectedSize}
                        toggleStoreLocator={this.props.toggleStoreLocator}
                        handleZindex={this.props.handleZindex}
                        multiple={false}
                        menuItem={this.props.menuItem}
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(ProductActionCreators, dispatch)
};

export default connect(null, mapDispatchToProps)(ComplectItem);