import React from "react";
import "./blackWindow.scss";

interface Props {
    type?: string,
    handleClick?: any
}

const BlackWindow = ({ type, handleClick }: Props) => {
    let classname = ''
    switch (type) {
        case "menu":
            classname = "black-window-menu";
            break;
        case "SearchingBox":
            classname = "white-window-menu";
            break;
        default:
            classname = "black-window";
    }

    return <div className={classname} onClick={handleClick}></div>;
}

export default BlackWindow
