import {OrEtArgentActionCreators, OrEtArgentTypes} from "./actions";
import {call, put, takeLatest} from "redux-saga/effects";
import axios from "axios";
import {PRODUCTS_URL} from "../../../utils/constants/service";
import {discountHeader} from "../../../helpers/helpers";

export function* orEtArgent(param){
    const { selectedItem } = param;
    try {
        const data = yield call(() => {
             return axios.post(`${PRODUCTS_URL}${selectedItem.menuId}`,{}, discountHeader())
                 .then(res => res.data)
        });


        yield put(OrEtArgentActionCreators.orEtArgentSuccess(data));
    } catch (e) {
        yield put(OrEtArgentActionCreators.orEtArgentFailure(e.message));
    }
}

export function* orEtArgentSaga(){
    yield takeLatest(OrEtArgentTypes.OR_ET_ARGENT_START, orEtArgent);
}

export default orEtArgentSaga;
