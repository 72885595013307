import React, { Component } from 'react';
import './503.scss';
import i18next from "i18next";

const Component503 = () => {
    return (
        <div className="not-found-container-503">
            <div className="not-found-code">

            </div>
            <div className="not-found-inner">
                <div>
                    <p className="introuvable">
                        {i18next.t('Error.3')}
                    </p>
                    <p className="rien-sous">
                        {i18next.t('Error.4')}
                    </p>
                </div>
            </div>
        </div>
    )
}


export default Component503;