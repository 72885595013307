import {createActions} from 'reduxsauce';

export const {Types: ValidationTypes, Creators: ValidationActionCreators} = createActions ({
    validationStart: ['id'],
    validationSuccess: ['data'],
    validationFailure: ['error'],
    validationPaymentAcceptedStart: ['textlibre'],
    validationPaymentAcceptedSuccess: ['paymentData'],
    validationPaymentAcceptedFailure: ['error'],
    validationPaymentFailedStart: ['textlibre'],
    validationPaymentFailedSuccess: ['paymentData'],
    validationPaymentFailedFailure: ['error'],
    updateOrderStart: ['data'],
    userPaymentPaypalStart: ['body'],
    userPaymentPaypalSuccess: ['data'],
    userPaymentPaypalFailure: ['error'],
    validateDiscountStart: ['data','price',"typeOfRequest"],
});
