import {createReducer} from 'reduxsauce';
import {DownloadTypes} from "./actions";

export interface IDownloadState {
    download: boolean,
    error: string

}
export const DownloadR: IDownloadState = {
    download: true,
    error: ""
};

export const Download = (state) => {
    return {...state};
};

export const DownloadSuccess = (state, action) => {
  return{...state, download: action.data}
};

export const DownloadFailure = (state, action) => {
    return {...state, error: action.error, download: false};
};

export const handlers = {
    [DownloadTypes.DOWNLOAD_START]: Download,
    [DownloadTypes.DOWNLOAD_SUCCESS]: DownloadSuccess,
    [DownloadTypes.DOWNLOAD_FAILURE]: DownloadFailure,
};

export default createReducer(DownloadR,handlers);