import React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";

interface Interface extends RouteComponentProps<any> {
    location: any
}

class ScrollToTop extends React.Component<Interface> {

    componentDidMount(): void {
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return null;
    }
}

export default withRouter(ScrollToTop);