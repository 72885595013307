import {UserAccountTypes, UserAccountActionCreators} from "./actions";
import { takeLatest, put, call } from "redux-saga/effects";
import { USER_ACTIVATION_URL } from "../../../utils/constants/service";
import axios from 'axios';

export function* activate(accountData) {

    try {

        const headers = {
            headers: {
            }
        };
        const user = yield call(() => {
            return axios.get(`${USER_ACTIVATION_URL}` + accountData.activationKey, headers)
                .then(res => res.data)
        });
        yield put(UserAccountActionCreators.activateSuccess(user));
    } catch (e) {
        yield put(UserAccountActionCreators.activateFailure(e.message));
    }
}

export function* userAccountSaga() {
    yield takeLatest(UserAccountTypes.ACTIVATE_START, activate);
}

export default userAccountSaga;
