import {retourType,RetourActionCreators} from "./actions";
import {takeLatest,put,call} from "redux-saga/effects";
import {BASE_API_MESSAGINGSERVICE,BASE_API_GATEWAY} from "../../../utils/constants/service";
import {BRAND_ID} from "../../../utils/constants/variables";
import {getApiPrefix} from "../../../helpers/helpers"

import axios from "axios";

export function* retour(){
    try {
        const data = yield call(() => {
            return axios.get(`${BASE_API_MESSAGINGSERVICE}external/tickets/form?brandId=${BRAND_ID}&language=${getApiPrefix()}`)
                .then(res => res.data)
        });


        yield put(RetourActionCreators.retourReasonSuccess(data));
    } catch (e) {
        yield put(RetourActionCreators.retourReasonFailure(e.message));
    }
}

export function* retourTicket(params){
    const headers = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
        }};
    try {
        const data = yield call(() => {
            return axios.post(`${BASE_API_GATEWAY}/api/returns`,JSON.stringify(params.data), headers)
                .then(res => res.data)
        });

        yield put(RetourActionCreators.retourTicketSuccess(data));
    } catch (e) {
        yield put(RetourActionCreators.retourTicketFailure(e.message));
    }
}


export function* retourSaga(){
    yield takeLatest(retourType.RETOUR_REASON_START, retour);
    yield takeLatest(retourType.RETOUR_TICKET_START, retourTicket);
}

export default retourSaga;