import React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {BASE_URL} from "../../utils/constants/service";


interface Props extends RouteComponentProps<any>{
    to?:any,
    onClick?: any,
    key?:any,
    className?:any,
}

class LinkFullUrl extends  React.Component<Props >  {
    historyPush = (e) => {
        e.preventDefault();
        this.props.history.push(this.props.to);
        this.props && this.props.onClick && this.props.onClick();
    };
    render() {
        return <a href={BASE_URL+(this.props.to === typeof "object" ? this.props.to.pathname : this.props.to)} onClick={this.historyPush} className={this.props.className && this.props.className}>{this.props.children}</a>
    }
}
export default withRouter(LinkFullUrl);
