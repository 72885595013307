import React from "react";
import {connect} from "react-redux";
import {Accordion} from 'semantic-ui-react';
import i18next from "i18next";
import {getMenu} from "../../../../layouts/main/header/redux/selectors";
import {BASE_API_GATEWAY} from "../../../../utils/constants/service";
import {PAGE_TYPE} from "../../../../utils/constants/variables";
import {MentionLegalesActionCreators} from "../../../mentionLegales/redux/actions";
import {getMentionLegalesContent, getMentionLegalesLoading} from "../../../mentionLegales/redux/selectors";
import BlackWindow from "../../../common/blackWindow";
import closeIcon from "./../../../../assets/img/close.svg";
import {getItemMenu} from "../../../../helpers/helpers";
import Loading from "./loading";

interface Props {
    toggleStoreLocator?: any,
    menuList?: any,
    data: any,
    activeIndex?: any,
    isLoading: boolean
}

interface State {
    showStoreLocator: boolean,
    activeIndex?: any,

}


class SizeGuide extends React.Component<Props & typeof MentionLegalesActionCreators> {
    state: State = {
        showStoreLocator: false,
        activeIndex: -1,
    };

    componentDidMount() {
        const menuItem =  getItemMenu(PAGE_TYPE, "guide_des_tailles", this.props.menuList);
        if(menuItem && menuItem.gridId) {
            this.props.dispatch(MentionLegalesActionCreators.mentionLegalesStart(menuItem.gridId))
        }
    }

    handleClick = (e, titleProps) => {
        const {index} = titleProps;
        const {activeIndex} = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({activeIndex: newIndex})
    };

    render() {
        const {data, isLoading} = this.props;
        const {activeIndex} = this.state;
        return (
            <div className="storeLocator">
                <BlackWindow type="page" handleClick={this.props.toggleStoreLocator}/>
                <div className="storeLocator-content container">
                    <div className="storeLocator-content-head">
                        <h4 className="storeLocator-content-head-title">{i18next.t('product.product.4')}</h4>
                        <img src={closeIcon} onClick={this.props.toggleStoreLocator}  alt="PHOTO"/>
                    </div>
                    <div className="storeLocator-wrapper">
                        <div className="container">
                            <div className="mentions-page">
                                {
                                    isLoading ? <Loading/> : <div className="">
                                        {
                                            data && data.length ? data.sort((a, b) => (a.widget - b.widget)).map((item, index) => {
                                                return <Accordion key={index}>
                                                    <Accordion.Title active={activeIndex === index}
                                                                    onClick={(e) => this.handleClick(e, {index: index})}>{item.name}</Accordion.Title>
                                                    <Accordion.Content active={activeIndex === index}>
                                                        {
                                                            item.type && item.type.toUpperCase() === "PHOTO" ?
                                                                <img
                                                                    key={index}
                                                                    src={BASE_API_GATEWAY + item.value}
                                                                    alt={item.altText || "PHOTO"}
                                                                />
                                                                :
                                                                <div dangerouslySetInnerHTML={{__html: item.value}}></div>
                                                        }
                                                    </Accordion.Content>
                                                </Accordion>
                                            }) : ""
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state: any): Props => ({
    data: getMentionLegalesContent(state),
    menuList: getMenu(state),
    isLoading: getMentionLegalesLoading(state)
});

export default connect(mapStateToProps)(SizeGuide)