import React, { Component } from 'react';
import { connect } from "react-redux"
import i18next from "i18next";
import "./recapCommande.scss";
import { bindActionCreators } from "redux";
import { DownloadActionCreators } from "../redux/actions"
import { getCurrency, getDateTimestamp, getRouteByMenuKey, replaceComma } from "../../../helpers/helpers";
import { BASE_API_GATEWAY } from "../../../utils/constants/service";
import { getDownloadData, getError } from "../redux/selectors";
import { Link } from "react-router-dom";
import { getMenu } from "../../../layouts/main/header/redux/selectors";

interface Props {
    order?: any,
    download?: any,
    error?: any,
    menuList?: any
}

interface State {
    show: boolean
}

class RecapCommande extends Component<Props & typeof DownloadActionCreators, State> {

    state: State = {
        show: false
    };

    componentDidMount = () => {
        window.scroll(0, 0)
    };

    componentDidUpdate(prevProps) {
        if (prevProps.download) {
            this.setState({ show: true })
        }
    }

    handleClick = () => {
        window.scroll(0, 0);
        this.props.downloadStart(this.props.order.orderId);
    };


    render() {
        const { order, menuList } = this.props;
        const { orderLines, billingAddress, shippingAddress, orderInvoice } = order;
        const invoiceId = orderInvoice || 16705;
        const livraisonPrice = Array.isArray(order.orderLines) && order.orderLines.length && order.orderLines.find(item => item.itemTypeCode === "O") ? order.orderLines.find(item => item.itemTypeCode === "O").listPrice : 0;

        return (
            <div className="recap-commande">
                <div className="small-container">
                    <div className="recap-commande-wrapper">
                        <div className="recamp-commande-info">
                            <div className="recamp-commande-info-date">
                                <h4 className="recamp-commande-info-date-title">
                                    {i18next.t('commandes.details.1')} n°{order.orderId}
                                </h4>
                                <p className="recamp-commande-info-date-text">
                                    {i18next.t('commandes.details.2')} :&nbsp;<span className="color-black">{getDateTimestamp(order.orderDate)}</span>
                                </p>
                                <p className="recamp-commande-info-date-text">
                                    {i18next.t('commandes.details.12')} :&nbsp;<span className="color-black">{order.orderStatus && order.orderStatus.label}</span>
                                </p>
                                <p className="recamp-commande-info-date-expendle">
                                    {i18next.t('commandes.details.3')} :&nbsp;<span className="color-black">{getDateTimestamp(order.shipmentDate)}</span>
                                </p>
                                <p className="recamp-commande-info-date-expendle">
                                    {i18next.t('commandes.details.13')} :&nbsp;
                                    {
                                        order.carrierCode && order.carrierCode.toUpperCase() === "CHRONOPOST" ?
                                            <a
                                                href={`https://www.chronopost.fr/tracking-no-cms/suivi-page?listeNumerosLT=${order.packageNumber}`}
                                                target="_blank"
                                                rel="noopener"
                                                className="color-black"
                                            >
                                                {order.packageNumber}
                                            </a>
                                            : order.carrierCode && order.carrierCode.toUpperCase() === "TNT GLOBAL EXPR" ?
                                                <a
                                                    href={`https://www.tnt.it/tracking/Tracking.do`}
                                                    target="_blank"
                                                    rel="noopener"
                                                    className='color-black'
                                                >
                                                    {order.packageNumber}
                                                </a>
                                                : <span>{order.packageNumber}</span>
                                    }
                                </p>
                                <p className="recamp-commande-info-date-expendle">
                                    {i18next.t('commandes.details.14')} :&nbsp;
                                    {
                                        order.carrierCode && order.carrierCode.toUpperCase() === "CHRONOPOST" ?
                                            <a
                                                href={`https://www.chronopost.fr/tracking-no-cms/suivi-page?listeNumerosLT=${order.packageNumber}`}
                                                target="_blank"
                                                rel="noopener"
                                                className='color-black'
                                            >
                                                {order.carrierCode}
                                            </a>
                                            : order.carrierCode && order.carrierCode.toUpperCase() === "TNT GLOBAL EXPR" ?
                                                <a
                                                    href={`https://www.tnt.it/tracking/Tracking.do`}
                                                    target="_blank"
                                                    rel="noopener"
                                                    className='color-black'
                                                >
                                                    {order.carrierCode}
                                                </a>
                                                : <span>
                                                    {order.carrierCode}
                                                </span>
                                    }
                                </p>
                                <p className="recamp-commande-info-date-expendle">
                                    {i18next.t('commandes.details.15')} :&nbsp;
                                    <span className="color-black">{order && order.carrierStatus} </span>
                                </p>
                            </div>
                            <div className="recamp-commande-info-livrasion">
                                <h4 className="recamp-commande-info-adresse-title">
                                    {i18next.t('commandes.details.4')} :
                                </h4>
                                {shippingAddress &&
                                    <>
                                        <p className="recamp-commande-info-adresse-text">{shippingAddress.name}</p>
                                        <p className="recamp-commande-info-adresse-text">{shippingAddress.address1}</p>
                                        <p className="recamp-commande-info-adresse-text">{shippingAddress.address2}</p>
                                        <p className="recamp-commande-info-adresse-text">{shippingAddress.city}</p>
                                        <p className="recamp-commande-info-adresse-text">{shippingAddress.zip}</p>
                                        <p className="recamp-commande-info-adresse-text country">{shippingAddress.country}</p>
                                    </>
                                }
                            </div>
                            <div className="recamp-commande-info-facturation">
                                <h4 className="recamp-commande-info-adresse-title">
                                    {i18next.t('commandes.details.5')} :
                                </h4>
                                {billingAddress &&
                                    <>
                                        <p className="recamp-commande-info-adresse-text">{billingAddress.name}</p>
                                        <p className="recamp-commande-info-adresse-text">{billingAddress.address1}</p>
                                        <p className="recamp-commande-info-adresse-text">{billingAddress.address2}</p>
                                        <p className="recamp-commande-info-adresse-text">{billingAddress.city}</p>
                                        <p className="recamp-commande-info-adresse-text">{billingAddress.zip}</p>
                                        <p className="recamp-commande-info-adresse-text country">{billingAddress.country}</p>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="reacmp-commande-price">
                            <div className="reacmp-commande-price-info">
                                <ul className="reacmp-commande-price-info-wrapper">
                                    <li>
                                        <span>{order.totalQuantity} {i18next.t('commandes.details.6')}</span>
                                        <p className="reacmp-commande-price-info-wrapper-price">{replaceComma(order.totalSales - livraisonPrice)}{getCurrency(order.currency)}</p>
                                    </li>
                                    {livraisonPrice ?
                                        <li>
                                            <span>{i18next.t('commandes.details.7')}</span>
                                            <p className="reacmp-commande-price-info-wrapper-price">{replaceComma(livraisonPrice)}{getCurrency(order.currency)}</p>
                                        </li>
                                        : ""
                                    }
                                </ul>
                            </div>
                            <div className="reacmp-commande-price-telecharger">
                                <div className="reacmp-commande-price-telecharger-wrapper">
                                    <div className="reacmp-commande-price-telecharger-wrapper-box">
                                        <p className="reacmp-commande-price-telecharger-wrapper-box-text">{i18next.t('commandes.details.8')}
                                            :</p>
                                        <p className="reacmp-commande-price-telecharger-wrapper-box-price">{replaceComma(order.totalSales)}{getCurrency(order.currency)}</p>
                                    </div>
                                    <p className="reacmp-commande-price-telecharger-wrapper-comprise-text">
                                        * {i18next.t('commandes.details.9')}</p>
                                    {/*<a href={`${PRODUCT_SERVICE_API_URL}files/download/${invoiceId}`} target="_blank" download >*/}
                                    <button
                                        className="reacmp-commande-price-telecharger-btn button-style hoverButtonStyle"
                                        onClick={this.handleClick}
                                        disabled={true}
                                    >
                                        {i18next.t('commandes.details.10')}
                                    </button>
                                    {
                                        order.orderStatus.code !== "DEP" ?
                                            <button
                                                className="reacmp-commande-price-telecharger-btn button-style disabled"
                                                disabled={true}
                                            >{i18next.t('commandes.3')}</button>
                                            :
                                            <Link
                                                to={{
                                                    pathname: getRouteByMenuKey(menuList, "retour").route,
                                                    state: { element: this.props.order }
                                                }}
                                                className="reacmp-commande-price-telecharger-btn button-style link hoverButtonStyle"
                                            >
                                                {i18next.t('commandes.3')}
                                            </Link>
                                    }


                                    {/*</a>*/}
                                    {this.state.show ? <p className="download-Error">{i18next.t('download.1')}</p> : ""}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="recap-commande-contenu">
                        <div className="recamp-commande-contenu-head">
                            {i18next.t('commandes.details.11')}
                        </div>
                        <div className="recamp-commande-contenu-wrapper">
                            {
                                orderLines && orderLines.length && orderLines.filter(item => item.itemTypeCode !== "O").map((item, i) => {
                                    return (
                                        <div className="recamp-commande-contenu-item" key={i}>
                                            <div className="recamp-commande-contenu-item-img">
                                                <img src={`${BASE_API_GATEWAY}/${item.productPhotoUrl}`}
                                                    alt="contenu image" />
                                            </div>
                                            <div className="recamp-commande-contenu-item-text-box">
                                                <h4 className="recamp-commande-contenu-item-title">{item.productNameLevel1}</h4>
                                                <p className="recamp-commande-contenu-item-text">{item.size}</p>
                                                <p className="recamp-commande-contenu-item-text">Quantité
                                                    : {item.orderQty}</p>
                                                <p className="recamp-commande-contenu-item-price">{replaceComma(item.listPrice)}{getCurrency(item.currency)}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    download: getDownloadData(state),
    error: getError(state),
    menuList: getMenu(state),
});
const mapDispatchToProps = (dispatch): typeof DownloadActionCreators => {
    return bindActionCreators(DownloadActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(RecapCommande);
