import React from "react";

import { BASE_API_GATEWAY } from "../../../../../../../../utils/constants/service";
import i18next from "i18next";
import { getLangPrefix } from "../../../../../../../../helpers/helpers";
import LinkFullUrl from "../../../../../../../../shared/linkFullUrl/linkFullUrl";

interface Props {
    menuItem: any,
    handleClick: any
}

export default class Styles extends React.Component<Props> {
    render() {
        const { menuItem } = this.props;
        const LANGUAGE_PREFIX = getLangPrefix();

        return (
            <div className="styles-tendances">
                {
                    menuItem.children.length &&
                    menuItem.children.map(item => (
                        item.key === "les_intemporels_descamps" ?
                            <div className="tendances" key={item.menuId}>
                                <div className='content'>
                                    <div>
                                        <h4 className='title'>
                                            {item.label}
                                        </h4>
                                        {
                                            item.children && item.children.map((child, i) => (
                                                item.children.length - 1 !== i && item.children[item.children.length - 1].visuals && item.children[item.children.length - 1].visuals[0] ?
                                                    <LinkFullUrl key={"l" + i} className="link-text" to={`${LANGUAGE_PREFIX}${child && child.canonicalUrl}`} >{child && child.label}</LinkFullUrl> : ""
                                            ))
                                        }
                                    </div>
                                    <div className="tendances-content">
                                        <div className="tendances-box-item">
                                            {Array.isArray(item.children) && item.children[item.children.length - 1].visuals && item.children[item.children.length - 1].visuals[0] ?
                                                <>
                                                    <img src={`${BASE_API_GATEWAY}${item.children[item.children.length - 1].visuals[0].photo}`}
                                                        alt="fotilia image" />
                                                    <div className="tendances-box-item-text">
                                                        <h4>{item.children[item.children.length - 1].label}</h4>
                                                        <LinkFullUrl
                                                            to={item.children[item.children.length - 1].visuals[0].menuHref ? `${LANGUAGE_PREFIX}${item.children[item.children.length - 1].visuals[0].menuHref}` : `#`}
                                                            onClick={this.props.handleClick} >{item.children[item.children.length - 1].visuals[0].name}</LinkFullUrl>
                                                    </div>
                                                </> : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="styles" key={item.menuId}>
                                <div className="content">
                                    <h4><LinkFullUrl className="link-hover" to={`${LANGUAGE_PREFIX}${item.canonicalUrl}`} onClick={this.props.handleClick} >{item.label}</LinkFullUrl></h4>
                                    <div className="styles-box-content">
                                        {item.children && item.children.length && item.children.map((item) => (
                                            <div className="styles-box-item" key={item.menuId}>
                                                {item.visuals && item.visuals[0] ?
                                                    <LinkFullUrl
                                                        to={item.visuals[0].menuHref ? `${LANGUAGE_PREFIX}${item.visuals[0].menuHref}` : `#`}
                                                        onClick={this.props.handleClick}
                                                    >
                                                        <img src={`${BASE_API_GATEWAY}${item && item.visuals[0].photo}`} alt="fotilia image"/>
                                                        <div className="styles-box-item-text">
                                                            <h4>{item && item.label}</h4>
                                                            <p className="link">
                                                                {i18next.t('home.collection.discover')}
                                                            </p>
                                                        </div>
                                                    </LinkFullUrl> : ""
                                                }
                                            </div>
                                        ))
                                    }
                                    </div>
                                </div>
                            </div>
                    ))
                }
            </div>
        );
    }
}
