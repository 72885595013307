import { createReducer } from 'reduxsauce';
import { SubscribeTypes } from './actions';

export interface ISubscribeState {
    subscribe: any,
    error: string,
    status: string,
}

export const subscribes: ISubscribeState = {
    subscribe: {},
    error: "",
    status: ""
};

export const subscribe = (state) => {
    return { ...state, status: "" };
};

export const subscribeSuccess = (state, action) => {

    return { ...state, subscribe: action.data, status: "success" };
};

export const subscribeFailure = (state, action) => {
    return { ...state, error: action.error, subscribe: {}, status: "failed" };
};

export const handlers = {
    [SubscribeTypes.SUBSCRIBE_START]: subscribe,
    [SubscribeTypes.SUBSCRIBE_SUCCESS]: subscribeSuccess,
    [SubscribeTypes.SUBSCRIBE_FAILURE]: subscribeFailure
};

export default createReducer(subscribes, handlers);