import {createActions} from 'reduxsauce';

export const {Types: BlogTypes, Creators: BlogActionCreators} = createActions({
    blogStart: ['id'],
    blogSuccess: ['data'],
    blogFailure: ['error'],
    blogInstagramStart: ['count'],
    blogInstagramSuccess: ['data'],
    blogInstagramFailure: ['error'],
    recommendedProductsStart: [null],
    blogFavoriteProductsStart: ['id'],
    blogFavoriteProductsSuccess: ['data'],
    blogFavoriteProductsFailure: ['error'],
});
