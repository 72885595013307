import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18next from "i18next";

import { getMenu } from "../../../../../monEspace/redux/selectors";
import PageMetaData from "../../../../../common/pageMetaData/pageMetaData";
import RetournerUnArticleMess from "../../retournerUnArticleMess/container";
//styles
import "./retournerUnArticle.scss";
import { BASE_API_GATEWAY } from "../../../../../../utils/constants/service";

interface Props {
    menuList: any,
    menuItem?: any,
    handleUpdateChild?: any,
    data?: any,
}

interface State {
    found?: any,
    checked?: any,
    showReturns: boolean,
    countOfCheked: number,
    chekedID: any[],
}


class RetournerUnArticle extends Component<Props> {
    state: State = {
        checked: new Set(),
        found: "",
        showReturns: false,
        countOfCheked: 0,
        chekedID: [],
    };


    changeCheckbox = (e, index) => {
        const { checked } = this.state;
        if (!checked.has(index)) {
            checked.add(index);
            this.state.chekedID.push({ productId: +e.target.value.split(",")[0], quantity: +e.target.value.split(",")[1] });
            this.setState({ countOfCheked: this.state.countOfCheked += 1, chekedID: this.state.chekedID })
        } else {
            checked.delete(index);
            this.setState({ countOfCheked: this.state.countOfCheked > 0 ? this.state.countOfCheked -= 1 : 0 });
            const indexID = this.state.chekedID.indexOf(e.target.value);
            this.state.chekedID.splice(indexID, 1);

        }
        this.setState({ checked })
    };

    toggleReturns = () => {
        this.setState({ showReturns: !this.state.showReturns })
    };

    render() {
        const { checked, showReturns, countOfCheked, chekedID } = this.state;
        const { menuItem, data } = this.props;
        const isReturnHided = !showReturns;
        const orederLineItems = data && Array.isArray(data.orderLines) && data.orderLines.filter(item => item.itemTypeCode !== "O") || []
        const orderStatus = data.orderStatus
        return (
            <>
                {menuItem && Object.keys(menuItem).length && <PageMetaData pageKey="ma_commande" />}
                {isReturnHided ?
                    <div className="choosing-page-wrapper">
                        <div className="choosing-section">
                            <div className="heading-box">
                                <h3 className="title">{i18next.t("mes-retours.4")}</h3>
                            </div>
                            <div className="flex-box">
                                {
                                    orederLineItems.map((item, index) => {
                                        return <div className={`choosing-box${checked.has(index) ? ' checked' : ''}`}
                                            key={index}>
                                            <div className="checkbox-wrapper">
                                                <div className="checkbox">
                                                    <input type="checkbox" id={`${index}`} onChange={(e) => {
                                                        this.changeCheckbox(e, index)
                                                    }} value={`${item.productId},${item.orderQty}`}></input>
                                                    <label htmlFor={`${index}`}></label>
                                                </div>
                                            </div>
                                            <div className="img-wrapper">
                                                <img
                                                    src={`${BASE_API_GATEWAY}/${item && item.productPhotoUrl}`}
                                                    alt="image">
                                                </img>
                                            </div>
                                            <div className="info-wrapper">
                                                <h4 className="title">{item.productNameLevel1}</h4>
                                                <h4 className="size">{item.size}</h4>
                                                <p className="quantity">{item.orderQty}</p>
                                            </div>
                                        </div>
                                    })
                                }

                            </div>
                            <p className="info-text">
                                <span>{countOfCheked} </span>
                                {i18next.t("mes-retours.5")}
                            </p>
                            <button className={`button-style main-btn ${!chekedID.length ? "disabled" : ""}`}
                                disabled={!chekedID.length}
                                onClick={this.toggleReturns}>{i18next.t('mes-retours.6')}</button>
                        </div>
                    </div>
                    :
                    <RetournerUnArticleMess orderStatus={orderStatus} orderLineItems={orederLineItems} products={chekedID} orderId={data.orderId} />
                }
            </>
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    menuList: getMenu(state),
});


export default connect(mapStateToProps)(RetournerUnArticle);
