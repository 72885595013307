import {createReducer} from 'reduxsauce';
import {ValidationTypes} from './actions';

export interface IValidationState {
    data: any,
    paypalData?: any,
    paymentData?: any
}

export const validationContent: IValidationState = {
    data: {},
    paypalData: {},
    paymentData: {}
};

export const getValidationData = (state) => {
    return {...state};
};

export const validationSuccess = (state, action) => {
    return {...state, data: action.data};
};

export const validationFailure = (state, action) => {
    return { ...state, error: action.error, data: {} };

};


export const getValidationPaymentAccepted = (state) => {
    return { ...state };
};

export const validationPaymentAcceptedSuccess = (state, action) => {
    return {
        ...state, paymentData: {
            moneticoCallbackStatus: "accepted", status: action.paymentData.status,
            message: action.paymentData.message,
            paymentConfirmDTO: action.paymentData.paymentConfirmDTO
        }
    };
};

export const validationPaymentAcceptedFailure = (state, action) => {
    return { ...state, error: action.error, paymentData: { moneticoCallbackStatus: "accepted", status: "ERROR", message: action.paymentData.message} };

};



export const getValidationPaymentFailed = (state) => {
    return { ...state };
};

export const validationPaymentFailedSuccess = (state, action) => {
    return {
        ...state, paymentData: {
            moneticoCallbackStatus: "accepted", status: action.paymentData.status,
            message: action.paymentData.message,
            paymentConfirmDTO: action.paymentData.paymentConfirmDTO} };
};

export const validationPaymentFailedFailure = (state, action) => {
    return { ...state, error: action.error, paymentData: { moneticoCallbackStatus: "failed", status: "ERROR", message: action.paymentData.message} };

};


export const userPaymentPaypalStart = (state) => {
    return {...state, paypalData: {}};
};

export const userPaymentPaypalSuccess = (state, action) => {
    return {...state, paypalData: action.data};
};

export const userPaymentPaypalFailure = (state, action) => {
    return { ...state, error: action.error, paypalData: {} };

};

export const handlers = {
    [ValidationTypes.VALIDATION_START]: getValidationData,
    [ValidationTypes.VALIDATION_SUCCESS]: validationSuccess,
    [ValidationTypes.VALIDATION_FAILURE]: validationFailure,
    [ValidationTypes.VALIDATION_PAYMENT_ACCEPTED_START]: getValidationPaymentAccepted,
    [ValidationTypes.VALIDATION_PAYMENT_ACCEPTED_SUCCESS]: validationPaymentAcceptedSuccess,
    [ValidationTypes.VALIDATION_PAYMENT_ACCEPTED_FAILURE]: validationPaymentAcceptedFailure,
    [ValidationTypes.VALIDATION_PAYMENT_FAILED_START]: getValidationPaymentFailed,
    [ValidationTypes.VALIDATION_PAYMENT_FAILED_SUCCESS]: validationPaymentFailedSuccess,
    [ValidationTypes.VALIDATION_PAYMENT_FAILED_FAILURE]: validationPaymentFailedFailure,
    [ValidationTypes.USER_PAYMENT_PAYPAL_START]: userPaymentPaypalStart,
    [ValidationTypes.USER_PAYMENT_PAYPAL_SUCCESS]: userPaymentPaypalSuccess,
    [ValidationTypes.USER_PAYMENT_PAYPAL_FAILURE]: userPaymentPaypalFailure,
};

export default createReducer(validationContent,handlers);
