import React from "react";
import i18next from "i18next";
import { HIDE_DISCOUNT } from "../../../utils/constants/variables";
import "./discount.scss";

interface Props {
  product: any;
  banner: {
    PROMOTION: string;
    ON_SALE: string;
  };
  isPhoto?: boolean;
}

const DiscountDetail = ({ product, banner, isPhoto = false }: Props) => {
  if (product.discountDetail) {
    return (
      <div className={isPhoto ? "isSale active" : "discount-box"}>
        <p className={`text ${product.outlet ? "text-outlet" : ""}`}>
          {product.outlet
            ? i18next.t("product.product.outlet")
            : banner.PROMOTION}
        </p>
        {HIDE_DISCOUNT === "0" ? (
          <p
            className={`amount ${
              product.discountDetail === "-20%"
                ? "greenBg"
                : product.discountDetail === "-30%"
                ? "yellowBg"
                : product.discountDetail === "-40%"
                ? "redBg"
                : ""
            }`}
          >
            {product.discountDetail.slice(0, -1)}
            <span className="small">{product.discountDetail.slice(-1)}</span>
          </p>
        ) : (
          <p
            className={`vHidden amount ${
              product.discountDetail === "-20%"
                ? "greenBg"
                : product.discountDetail === "-30%"
                ? "yellowBg"
                : product.discountDetail === "-40%"
                ? "redBg"
                : ""
            }`}
          >
            {product.discountDetail.slice(0, -1)}
            <span className="small">{product.discountDetail.slice(-1)}</span>
          </p>
        )}
      </div>
    );
  }

  if (product.onSpecial == "Y") {
    if (isPhoto) {
      return (
        <div className="isSale active">
          <div className="discount-box">
            <p className="text on-special-text text-nouveaute">
              {banner.ON_SALE}
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className="discount-box">
        <p className="text on-special-text">{banner.ON_SALE}</p>
      </div>
    );
  }
  return null;
};

export default DiscountDetail;
