import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import CurrentPage from "../../../../layouts/common/currentPage";
import {ShopperActionCreators} from "../../redux/actions";
import {getshopperSendResponse,getShopperStatus} from "../../redux/selectors";
import Input from "../../../common/input/Input";
import {Dropdown} from 'semantic-ui-react';
import i18next from "i18next";
import {
    emailIsValid,
    getApiPrefix,
    getLang,
    getDateOfShopper,
    getLangPrefix,
    getOpeningHours, formatOpeningHours
} from "../../../../helpers/helpers";
import {BRAND_ID, COUNTRY, RECAPTCHA_SITE_KEY, APPOINTMENT_TIMES} from "../../../../utils/constants/variables";
import {Link} from "react-router-dom";
import {ReCaptcha} from "react-recaptcha-google";
import {getContactUsData} from "../../../services/contactUs/redux/selectors";
import Swiper from "react-id-swiper";
import "./../magasin.scss";

interface Props {
    menuItem?: any,
    shopperStatus?: string,
    canonicalUrl?: string,
    location?: any,
    history?: any,
    contactUsData?: any,
    shopperData: any,
}

interface State {
    form: any,
    validation: any,
    options: any,
    selectedDay: any,
    selectedTime: any,
}

class Shopper extends React.Component<Props & typeof ShopperActionCreators, State> {
    state: State = {
        form: {
            name: "",
            email: "",
            phone: "",
            info: "",
            message: "",
            recaptcha: "",
            date: "",
        },
        validation: {
            name: {required: true, invalid: false},
            email: {required: true, invalid: false},
            info: {required: true, invalid: false},
            recaptcha: {required: true, invalid: false},
            phone: {required: true, invalid: false},
        },
        options: [],
        selectedDay: NaN,
        selectedTime: NaN,
    };
    private recaptchaRef: any = false;
    private invalidRef: any =  React.createRef<HTMLDivElement>();

    componentDidMount() {
        if (this.recaptchaRef) {
            this.recaptchaRef.reset();
        }
        this.props.contactUsStart()
        this.props.resetShopperStatus()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.contactUsData && (this.props.contactUsData.ticketSubjectList !== prevProps.contactUsData.ticketSubjectList)) {
            const options: any = [];
            this.props.contactUsData.ticketSubjectList && this.props.contactUsData.ticketSubjectList.map(item => {
                if (item.code === "SHO") {
                    options.push({value: item.code, text: item.subject})
                }
            });
            if(options.length){
                this.setState((prevState: State) => ({
                    form: {
                        ...prevState.form,
                        info: options[0].value
                    },options
                }))
            }
        }

        if(prevProps.shopperStatus !== this.props.shopperStatus){
            if(this.props.shopperStatus === "success"){
                window.scrollTo(0, 0);
            }
        }
    }


    handleChange = (e: any) => {
        const {name, value} = e.target;
        this.setState((prevState: State) => ({
            form: {
                ...prevState.form,
                [name]: value
            }
        }))
    };

    handleChangeSelect = (name, value) => {
        this.setState((prevState: State) => ({
            form: {
                ...prevState.form,
                [name]: value
            }
        }))
    };

    handleLoadRecaptcha() {
        if (this.recaptchaRef) {
            this.recaptchaRef.reset();
        }
    }

    handleVerifyRecaptcha = (recaptchaToken) => {
        if (recaptchaToken) {
            this.setState((prevState: State) => ({
                form: {
                    ...prevState.form,
                    recaptcha: recaptchaToken
                }
            }))
        }
    };

    handleSubmit = () => {
        let hasInvalidElement = false;
        const {validation, form, selectedTime} = this.state;
        const {location} = this.props;
        const validEmail = emailIsValid(form.email);
        const storeId = location && location.state && location.state.store && location.state.store.storeId;
        const appointmentDate = i18next.t(`days.${new Date(form.date).getDay()}`) + " " +
            new Date(form.date).getDate() + " " + i18next.t(`months.${new Date(form.date).getMonth()}`) + " " + APPOINTMENT_TIMES[selectedTime]
        for (let key in validation) {
            if (validation[key].required && !form[key]) {
                hasInvalidElement = true;
                validation[key].invalid = true;
            } else {
                validation[key].invalid = false;
            }
        }

        if (!validEmail) {
            validation.email.invalid = true;
            hasInvalidElement = true
        }

        if (!hasInvalidElement) {
            const data = {
                brandId: BRAND_ID,
                contactName: form.name,
                contactPhone: form.phone,
                contactEmail: form.email,
                ticketSubjectCode: form.info,
                ticketMessage: form.message,
                ticketMessageFrom: "C",
                language: getApiPrefix(),
                country: COUNTRY,
                appointmentDate,
                storeId
            };
            this.props.shopperSendStart(data)
        }else {
            window.scrollTo(0, this.invalidRef.offsetTop);
        }

        this.setState({validation})
    };

    handleSelectDate = (date) => {
        this.setState((prevState: State) => ({
            form: {
                ...prevState.form,
                date: date
            },
            selectedDay: new Date(date).getDate()
        }))
    }

    render() {
        const {form, validation, options, selectedDay, selectedTime} = this.state;
        const {menuItem, shopperStatus, location, history,canonicalUrl} = this.props;
        const store = location && location.state && location.state.store && location.state.store;
        const appointmentDate = i18next.t(`days.${new Date(form.date).getDay()}`) + " " +
            new Date(form.date).getDate() + " " + i18next.t(`months.${new Date(form.date).getMonth()}`) + " " + APPOINTMENT_TIMES[selectedTime]
        if (!(store && store.storeId)) {
            history.push(`${getLangPrefix()}/`)
        }
        const dateOfShopper = getDateOfShopper();
        const disabled = !(selectedDay >=0 && selectedTime >=0)
        const params = {
            direction: "horizontal",
            grabCursor: true,

            breakpoints: {
                382: {
                    slidesPerView: 2,
                },
                680: {
                    slidesPerView: 3,
                },
                980: {
                    slidesPerView: 3,
                },
                1020: {
                    slidesPerView: 5,
                },
            },
            speed: 600,
            loop: false,
            autoplay: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            wrapperClass: "swiper-wrapper",
            containerClass: "swiper-container",
            setWrapperSize: true,
            resizeReInit: true,
        }
        return (
            <section>
                {!shopperStatus &&
                <div className="contact-us magasin--shopper">
                    {menuItem &&
                    <CurrentPage selectedItem={menuItem}/>
                    }
                    <div className="container">
                        <div className="contact-us-block">
                            <div className="flexbox-wrapper">
                                <div>
                                    <div className="input-box required" ref={validation.name.invalid && this.invalidRef }>
                                        <Input invalid={validation.name.invalid} id="nom" name="name" value={form.name}
                                               text={i18next.t("contactUs.1")} handleChange={this.handleChange}/>
                                    </div>
                                    <div className="input-box required" ref={validation.email.invalid && this.invalidRef}>
                                        <Input invalid={validation.email.invalid} id="email" name="email"
                                               value={form.email} text={i18next.t("contactUs.2")}
                                               handleChange={this.handleChange}/>
                                    </div>
                                    <div className="input-box required" ref={validation.phone.invalid && this.invalidRef}>
                                        <Input invalid={validation.phone.invalid} id="tel" name="phone"
                                               value={form.phone}
                                               text={i18next.t("contactUs.3")} handleChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div>
                                    <div className="input-box required"  ref={validation.info.invalid && this.invalidRef}>
                                        <span
                                            className={`label-text ${validation.info.invalid ? "invalid" : ""}`}>{i18next.t("contactUs.4")}</span>
                                        <Dropdown
                                            icon="angle down"
                                            compact
                                            selection
                                            options={options}
                                            value={form.info}
                                            name={`info`}
                                            onChange={this.handleChangeSelect}
                                            className={"mainSelector"}
                                        />
                                    </div>
                                    <div className="input-box  default-input">
                                        <label htmlFor="infoText">{i18next.t("contactUs.5")}</label>
                                        <textarea id="infoText" name="message" value={form.message}
                                                  onChange={this.handleChange}></textarea>
                                    </div>

                                </div>
                            </div>
                            <div className="magasin--shopper--infoBox">
                                {
                                    store && Object.keys(store).length &&
                                    <div className="location-adres-item magasin--shopper--infoBox--shope" >

                                        <h4>{store.name}</h4>
                                        <div className="location-adres-text-content">
                                            <div>
                                                <p>{store.address}</p>
                                                <p>{store.zip}</p>
                                                <p>{store.city}</p>
                                                <p>Tél: {store.phone}</p>
                                            </div>
                                            <div>
                                                {
                                                    getOpeningHours(store.openingHours) &&
                                                    getOpeningHours(store.openingHours).length ?
                                                        getOpeningHours(store.openingHours).map((hours, i) => {
                                                            return (
                                                                <div className='TimeBox' key={i}>
                                                                    <div>{` ${i18next.t(`weekDays.${i}`)}:`}</div>
                                                                    <div>{`${formatOpeningHours(hours)}`}</div>
                                                                </div>
                                                            )
                                                        }) : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="magasin--shopper--infoBox--date">
                                    <div className="magasin--shopper--slider">
                                        {
                                            dateOfShopper && dateOfShopper.length ?
                                                <>
                                                    <h4 className="magasin--shopper--slider--label" ref={!(selectedDay >= 0) && this.invalidRef}>
                                                        {i18next.t("magasin.2")}
                                                    </h4>
                                                    <Swiper {...params}>
                                                        {
                                                            dateOfShopper.map((item, index) => (
                                                                <div key={index}
                                                                     className={`magasin--shopper--slider--item ${selectedDay === item.getDate() ? "active" : ""} ${!item.getDay() ? "disable" :""}`}
                                                                     onClick={() => {item.getDay() ?this.handleSelectDate(item) :""}}>
                                                                    <p className="magasin--shopper--slider--item--day">{i18next.t(`days.${item.getDay()}`)}</p>
                                                                    <p className="magasin--shopper--slider--item--date">{item.getDate()}</p>
                                                                    <p className="magasin--shopper--slider--item--month">{i18next.t(`months.${item.getMonth()}`)}</p>
                                                                </div>
                                                            ))
                                                        }
                                                    </Swiper>
                                                </> : ""
                                        }
                                    </div>
                                    <div className="magasin--shopper--slider">
                                        {
                                            APPOINTMENT_TIMES && APPOINTMENT_TIMES.length ?
                                                <>
                                                    <h4 className="magasin--shopper--slider--label"  ref={!(selectedTime >= 0) && this.invalidRef}>
                                                        {i18next.t("magasin.3")}
                                                    </h4>
                                                    <Swiper {...params}>
                                                        {
                                                            APPOINTMENT_TIMES.map((item, index) => (
                                                                <div key={index}
                                                                     className={`magasin--shopper--slider--item ${selectedTime === index ? "active" : ""}`}
                                                                     onClick={() => {
                                                                         this.setState({selectedTime: index})
                                                                     }}>
                                                                    <p className="magasin--shopper--slider--item--time">{item}</p>
                                                                </div>
                                                            ))
                                                        }
                                                    </Swiper>
                                                </> : ""
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="captcha-wrapper">
                                <ReCaptcha
                                    ref={(el) => {
                                        this.recaptchaRef = el;
                                    }}
                                    render="explicit"
                                    hl={getLang()}
                                    sitekey={RECAPTCHA_SITE_KEY}
                                    onloadCallback={this.handleLoadRecaptcha}
                                    verifyCallback={this.handleVerifyRecaptcha}
                                />
                                <div
                                    className={validation.recaptcha.invalid ? "invalid" : "d-none"}>{i18next.t("validation.required", {field: i18next.t("fields.recaptcha")})}</div>
                            </div>
                            <button className={`main-btn ${disabled ? "disabled" : ""} `}
                                    disabled={disabled}
                                    onClick={this.handleSubmit}>{i18next.t("magasin.1")}</button>
                        </div>

                    </div>
                </div>
                }
                {shopperStatus === "success" &&
                <div className="contact-us-return">
                    {menuItem &&
                    <CurrentPage selectedItem={menuItem} showDescription={false}/>
                    }
                    <div className="container">
                        <div className="magasin--shopper--wrapper">
                            <h3 className="title">{i18next.t("magasin.4")}</h3>
                            <h5 className="sub-title">{appointmentDate}</h5>
                            {
                                store && Object.keys(store).length &&
                                <div className="store" >
                                    <h3 className="sub-title">{store.name}</h3>
                                    <p className="text">{store.address}</p>
                                    <p className="text">{store.zip}</p>
                                    <p className="text">Tél: {store.phone}</p>
                                </div>
                            }
                            <p className="text">{i18next.t("magasin.5")}</p>
                            <Link className="link" to={"/"}> {i18next.t("contactUs.return.4")} </Link>
                        </div>
                    </div>
                </div>
                }
            </section>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    return {
        contactUsData: getContactUsData(state),
        shopperData: getshopperSendResponse(state),
        shopperStatus: getShopperStatus(state),
    }
};
const mapDispatchToProps = (dispatch): typeof ShopperActionCreators => {
    return bindActionCreators(ShopperActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Shopper);
