export const getOrder = state => state.orderR.order;
export const getCreateOrder = state => state.orderR.createOrder;
export const getUpdateOrder = state => state.orderR.updateOrder;
export const getDiscount = state => state.orderR.discount;
export const getPrivateDiscount = state => state.orderR.privateDiscount;
export const getPromotionDiscount = state => state.orderR.promotionDiscount;
export const getDiscountError = state => state.orderR.validateDiscountError
export const getPrivateDiscountStatus = state => state.orderR.privateDiscountStatus;
export const getStatus = state => state.orderR.status;
export const getorderStatus = state => state.orderR.orderStatus;
export const getUpdateStatus = state => state.orderR.updateStatus;
export const getError = state => state.orderR.error;
export const getUser = state => state.account.userInfo;
export const getDeliveryMethod = state => state.orderR.deliveryMethod;
export const getUnconditionalDiscount = state => state.orderR.unConditionalDiscount
