import React from "react";
import {connect} from "react-redux";

import CurrentPage from "../../../../layouts/common/currentPage";
import {getMenu} from "../../../../layouts/main/header/redux/selectors";
import {getData} from "../../../../helpers/helpers";
//styles
import "./professional.scss";
import {HomeActionCreators} from "../../../home/redux/actions";
import {getHome} from "../../../home/redux/selectors";
import {BASE_API_GATEWAY} from "../../../../utils/constants/service";

interface Props {
    menuList: any,
    data?: any
}

interface State {
}

class Professional extends React.Component<Props & typeof HomeActionCreators> {
    state: State = {};

    componentDidMount() {
        this.props.canonicalUrl && this.props.location.pathname !== this.props.canonicalUrl ? this.props.history.push(this.props.canonicalUrl) : "";
        const {menuItem} = this.props;
        if (menuItem && menuItem.gridId) {
            this.props.dispatch(HomeActionCreators.homeStart(menuItem.gridId))

        }
    }

    setFound = (menuList) => {
        const found = menuList.length ? menuList.find(item => item.key === "espace_professionnel") : {};

    };

    render() {
        const {data, menuItem} = this.props;
        return (
            <div className="professional">
                {menuItem && Object.keys(menuItem).length &&
                <CurrentPage selectedItem={menuItem}/>
                }
                {data && data.length ? data.sort((a, b) => +a.widget - +b.widget).map((item, index) => (
                        <div className='container professional-content' key={index}>
                            {item.type.toUpperCase() === "PHOTO" ? <img src={`${BASE_API_GATEWAY}${getData(item.name, "image", data)}`}  alt="PHOTO"/> :
                                <div dangerouslySetInnerHTML={{__html: item.value}}></div>}
                        </div>
                    ))
                    : ""
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any): Props => ({
    data: getHome(state),
    menuList: getMenu(state),
});
export default connect(mapStateToProps)(Professional)