import React, {useState,useEffect} from 'react'
import {BRAND} from "../../../../utils/constants/variables";
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    PinterestIcon,
    EmailShareButton,
    PinterestShareButton,
    EmailIcon
} from 'react-share'
import {Icon} from "semantic-ui-react";
import i18next from "i18next";


 const SocialBtn = (props) => {

    const url = window.location.protocol + "//" + window.location.host + window.location.pathname;
    const [show, SetShow] = useState(false);

    return (
        <div className="position_rel">
            {
                show &&
              <div className="shareBox">
                  <Icon className="closeShareBox" name='close' onClick={()=>{SetShow(false)}} />
                    <FacebookShareButton
                        url={url}
                        hashtag={`${"#" + BRAND + "Collection"}`}
                        {...props}
                    >
                        <FacebookIcon size={40} round={true} logofillcolor="white" {...props}/>
                    </FacebookShareButton>

                    <WhatsappShareButton
                        url={url}
                        hashtag={`${"#" + BRAND + "Collection"}`}
                        {...props}
                    >
                        <WhatsappIcon size={40} round={true} logofillcolor="white" {...props}/>
                    </WhatsappShareButton>

                    <PinterestShareButton
                        url={url}
                        media={props.urls}
                        description={`${BRAND + "" + "Collection"}`}
                        {...props}
                    >
                        <PinterestIcon size={40} round={true} logofillcolor="white" {...props}/>
                    </PinterestShareButton>

                    <EmailShareButton
                        onClick={()=>window.open(url,"_blank")}
                        openShareDialogOnClick={true}
                        url={url}
                        hashtag={`${"#" + BRAND + "Collection"}`}
                        {...props}
                    >
                        <EmailIcon size={40} round={true} logofillcolor="white" {...props}/>
                    </EmailShareButton>
                </div>
            }

            <button className="share-btn" onClick={() => SetShow(true)}>
                     <Icon name='share' link size='small'/>{i18next.t('product.product.22')}
            </button>

        </div>
    )
};

export default SocialBtn;
