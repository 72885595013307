import React, {useState, useMemo, useEffect, useRef} from 'react'
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import {getDataBySectionAndWidget} from "../../../../helpers/helpers";
import Banner from "./banner";
import './slider.scss';
import ReactPlayer from 'react-player';
import VideoPlayButton from './videoPlayButton';


const Slider = ({data, menuItem}) => {
    if (data.length <= 1) {
        return null
    }
    const [swiper, setSwiper] = useState<any>(null);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<any>(0);
    const [viemoIndex, setViemoIndex] = useState<any>(null);
    const [viemoPlay, setViemoPlay] = useState<boolean>(false);
    const [volume, setVolume] = useState<number>(0)
    const {promotion} = menuItem;

    const onResize = () => {
        setIsMobile(window.innerWidth < 920);
    }

    useEffect(() => {
        let unmounted = false;
        if(!unmounted) {
            setIsMobile(window.innerWidth < 920);
        }
        window.addEventListener("resize", onResize);
        return () => {
            unmounted = true;
        }
    }, []);

    const getPhotoOrVideo = (widget, promotion) => {
        let name = '';
        if(promotion === "Y"){
            if(isMobile){
                name = 'Banner-Promo-Mobile'
            }else{
                name = 'Banner-Promo'
            }
        }else if(promotion === "N"){
            if(isMobile){
                name = 'Banner-Mobile'
            }else{
                name = 'Banner'
            }
        }
        // const name = promotion === "Y" ? 'Banner-Promo-Mobile' : "Banner"
        const photo = getDataBySectionAndWidget(1, widget, data, "PHOTO", name)
        return photo.value
            ? photo
            : getDataBySectionAndWidget(1, widget, data, "VIDEO", name)
    }


    const sec1 = useMemo(() => getPhotoOrVideo(1, promotion), [promotion, isMobile]);
    const sec4 = useMemo(() => getPhotoOrVideo(4, promotion), [promotion, isMobile]);
    const sec7 = useMemo(() => getPhotoOrVideo(7, promotion), [promotion, isMobile]);

    const homeSlideTopMargin = useMemo(() => {
        if (window.innerWidth < 500) {
            if(((window.innerWidth - 360) - 140) < -50) {
                return -50
            }
            return (window.innerWidth - 360) - 140
        }
        return 0;
    }, [window.innerWidth]);

    if (!(sec1.value || sec4.value || sec7.value)) {
        return null
    }

    const slides = [sec1]
    if (sec4.value) {
        slides.push(sec4)
    }
    if (sec7.value) {
        slides.push(sec7)

    }
    const onPlay = () => {
        if (swiper !== null) {
            swiper.autoplay.stop();
        }
    }
    const onPause = () => {
        if (swiper !== null) {
            swiper.autoplay.start();
        }
    }
    const onEnd = () => {
        if (swiper !== null) {
            swiper.autoplay.start();
        }
    }
    const videoController = () => {
        if (isPlaying) {
            onPause()
            setIsPlaying(false)
        } else {
            onPlay();
            setIsPlaying(true)
        }
    }
    const params = {
        slidesPerView: 1,
        speed: 600,
        loop: true,
        lazy: true,
        autoplay: window.innerWidth < 768 ? "" : {delay: 5000},
        disableOnInteraction: false,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        getSwiper: (swiper) => {
            setSwiper(swiper)
        },
    }
    const sliderEvents = (e) => {
        if (swiper && swiper.activeIndex) {
            const slides = swiper.slides
            setViemoIndex(Array.from(slides[swiper.activeIndex].classList).findIndex(item => item === "viemo"))
            setActiveIndex(swiper.activeIndex)
        }
        if (e.target.getAttribute("class") === "swiper-button-next" || e.target.getAttribute("class") === "swiper-button-prev") {
            setIsPlaying(false);
            setViemoPlay(false)

        }
    }
    const getVideoType = (path) => {
        if (path.search(/vimeo/i) > -1) {
            return "vimeo"
        }
        if (path.search(/youtube/i) > -1) {
            return "youtube"
        }
    }
    const playViemo = () => {
        setViemoPlay(true)
        onPlay()
        setIsPlaying(true)

    }
    const mobileViemo = () => {
        setViemoPlay(!viemoPlay)
        if (isPlaying) {
            onPause()
            setIsPlaying(false)
        } else {
            onPlay();
            setIsPlaying(true)
        }
    }

    const handleClick = (e: any) => {
        e.stopPropagation()
        setVolume(volume ? 0 : 1)
    }

    return (
        <div className="container full-screen">
            <div
                className="home-slider"
                // style={{transform: `translateY(${homeSlideTopMargin}px`}}
                aria-hidden="true"
                onClick={(e) => sliderEvents(e)}>
                {
                    slides && slides.length <= 1 ?
                        <Banner photo={slides[0]} data={data} promotion={menuItem.promotion}/>
                        :
                        <Swiper {...params}>
                            {
                                slides.map((item, index) => {
                                    return (
                                        item.type === "VIDEO" && getVideoType(item.value) === "youtube" ?
                                            <div key={index} className="home-slider-video"
                                                 onClick={videoController}>
                                                <iframe
                                                    src={`${item.value}?rel=0&autoplay=${isPlaying ? 1 : 0}&controls=0&showinfo=0&autohide=0`}
                                                    allow='autoplay' frameBorder="0"/>
                                            </div>
                                            : item.type === "VIDEO" && getVideoType(item.value) === "vimeo" ?

                                            <div className="home-slider-video" key={index} onClick={mobileViemo}
                                                onMouseEnter={playViemo}>
                                                <ReactPlayer
                                                    url={item.value}
                                                    playing={viemoPlay}
                                                    controls={false}
                                                    width='100%'
                                                    height='100%'
                                                    loop={true}
                                                    volume={volume}
                                                />
                                                <VideoPlayButton volume={volume} handleClick={handleClick} />
                                            </div>
                                            :
                                            <div key={index} className="home-slider-banner">
                                                <Banner key={item.widget} photo={item} data={data}
                                                        promotion={menuItem.promotion}/>
                                            </div>
                                    )

                                })
                            }
                        </Swiper>
                }

            </div>
        </div>
    )
}

export default Slider;
