import {combineReducers} from "redux";
import account from "../modules/login/redux/reducers";
import useraccount from "../modules/account/redux/reducers";
import product from "../modules/product/redux/reducers";
import productsR from "../modules/products/redux/reducers";
import addToCart from "../modules/addToCart/redux/reducers";
import menuR from "../layouts/main/header/redux/reducers";
import collection from "../modules/collection/redux/reducers";
import timeline from "../modules/timeline/redux/reducers";
import orEtArgent from "../modules/orEtArgent/redux/reducers";
import homeContent from "../modules/home/redux/reducers";
import validationContent from "../modules/validation/redux/reducers";
import connections from "../modules/connection/redux/reducers";
import creations from "../modules/creation/redux/reducers";
import mentionLegales from "../modules/mentionLegales/redux/reducers";
import subscribes from "../layouts/main/footer/redux/reducers";
import locationSearch from "../modules/locationSearchInput/redux/reducers";
import monEspace from "../modules/monEspace/redux/reducers";
import mesInformation from "../modules/monEspace/container/components/mesInformations/redux/reducers";
import mesCommandes from "../modules/monEspace/container/components/mesCommandes/redux/reducers";
import mesAddress from "../modules/monEspace/container/components/MesAddress/redux/reducers";
import contactUs from "../modules/services/contactUs/redux/reducers";
import orderR from "../modules/basket/redux/reducers";
import SearchingR from "../modules/search/redux/reducers";
import paymentR from "../modules/payment/redux/reducers";
import motdepasse from "../modules/motdepasse/redux/reducers";
import RetourReason from "../modules/retour/redux/reducers"
import DownloadR from "../modules/recapCommande/redux/reducers"
import locationStore from "../modules/location/redux/reducers";
import Catalogue from "../modules/services/catalogue/redux/reducers";
import blogContent from "../modules/blog/redux/reducers"
import shopperContent from "../modules/magasin/redux/reducers";
import wichList from '../modules/Wishlist/redux/reducers';

const rootReducer = combineReducers({
    account,
    useraccount,
    locationStore,
    blogContent,
    product,
    productsR,
    addToCart,
    menuR,
    collection,
    timeline,
    orEtArgent,
    homeContent,
    validationContent,
    connections,
    creations,
    mentionLegales,
    subscribes,
    locationSearch,
    mesInformation,
    mesCommandes,
    mesAddress,
    contactUs,
    orderR,
    SearchingR,
    paymentR,
    motdepasse,
    monEspace,
    RetourReason,
    DownloadR,
    Catalogue,
    shopperContent,
    wichList
});

export default rootReducer;
