import {createReducer} from 'reduxsauce';
import {UserAccountTypes} from './actions';

export interface IUserAccountState {
    activated: boolean,
    user: any
}

export const useraccount: IUserAccountState = {
    activated: false,
    user: {}
};

export const activate = (state) => {
    return {...state};
};


export const activateSuccess = (state, action) => {
    return {...state, activated: true, user: action.user};
};

export const activateFailure = (state, action) => {
    return {...state, error: action.error, user: {}};
};

export const handlers = {
    [UserAccountTypes.ACTIVATE_START]: activate,
    [UserAccountTypes.ACTIVATE_SUCCESS]:activateSuccess,
    [UserAccountTypes.ACTIVATE_FAILURE]: activateFailure
};

export default createReducer(useraccount, handlers);