import React from 'react';
import {Map, GoogleApiWrapper, Marker, InfoWindow} from 'google-maps-react';
import {GOOGLE_MAPS_KEY} from "../../../utils/constants/variables";
import IMG from "../img/mark.png"
import {Paper} from '@material-ui/core';
import {Typography} from '@material-ui/core';
import LogoLoader from "../../../layouts/main/logoLoader";


const mapStyles = {
    width: '100%',
    height: '100%',
};

class MapComponent extends React.Component {
    constructor(props) {
        super(props);
        this.productsRef = React.createRef();

        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            locationStoreId:null
        };
    };

    locationStore = (store) => {
        this.props.locationStoreProps(store);
        this.props.setLocation(store.storeId)
        this.setState({
            locationStoreId:store.storeId
        });
        this.onMarkerClick(store)
    };
    onMarkerClick = (props, marker) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };

    onMapClick = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    displayMarkers = () => {
        return this.props.locationSearch && this.props.locationSearch.length && this.props.locationSearch.map((store, index) => {
            return <Marker key={index} id={index}
                           position={{
                               lat: store.latitude,
                               lng: store.longitude
                           }}
                           icon={{
                               url: IMG,
                               anchor: new this.props.google.maps.Point(32, 32),
                               scaledSize: new this.props.google.maps.Size(64, 64)
                           }}
                           onClick={() => this.locationStore(store)}/>
        })
    };

    render() {
        if (this.props.loading) {
            return <LogoLoader/>
        }

        if(!this.props.locationSearch.length) return null;

        const {latitude, longitude} = this.props.locationSearch[0];

        return (<Map
                    google={this.props.google}
                    zoom={12}
                    style={mapStyles}
                    onClick={() => this.onMapClick()}
                    center={{
                        lat: latitude,
                        lng: longitude
                    }}
                    initialCenter={{
                        lat: latitude,
                        lng: longitude
                    }}
                >
                    {this.displayMarkers()}
                    <InfoWindow
                        ref={this.productsRef}
                        google={this.props.google}
                        marker={this.state.activeMarker}
                        position={{
                            lat: this.state.selectedPlace.latitude || latitude,
                            lng: this.state.selectedPlace.longitude || longitude
                        }}
                        visible={this.state.showingInfoWindow}
                    >
                        <Paper>
                            <Typography
                                className="typo black"
                                component='h4'
                            >
                                {this.state.selectedPlace ? this.state.selectedPlace.name : ""}
                            </Typography>
                            <Typography
                                className= "typo"
                                component='p'
                            >
                                {this.state.selectedPlace ? this.state.selectedPlace.address : ""}<br/>
                                {this.state.selectedPlace ? this.state.selectedPlace.zip : ""} <br/>
                                {this.state.selectedPlace ? this.state.selectedPlace.city : ""} <br/>
                                Tél: <a className="main-link"
                                        href={`tel:${this.state.selectedPlace ? this.state.selectedPlace.phone : ""}`}>
                                {this.state.selectedPlace ? this.state.selectedPlace.phone : ""}
                            </a>
                            </Typography>
                        </Paper>
                    </InfoWindow>
                </Map>)
    }
}

export default GoogleApiWrapper({
    apiKey: GOOGLE_MAPS_KEY
})(MapComponent);
