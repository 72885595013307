export const getMenu = state => state.menuR.menu;
export const getError = state => state.menuR.error;
export const getLoginPopup = state => state.creations.loginPopup;
export const getUser = state => state.account.userInfo;
export const getLoginError = state => state.account.loginFailed;
export const getLanguage = state => state.menuR.language;
export const getCookieContent = state => state.menuR.cookieContent;
export const getMenuLoading = state => state.menuR.isLoading;
export const getStores = state => state.menuR.stores;
export const getBrandDetail = state => state.menuR.brandDetail;
export const getProductClusterBarcodes = state => state.menuR.clusterBarcodes;
export const getProductClusterPackBarcodes = state => state.menuR.clusterPackBarcodes;
export const getMenuItem = state => state.menuR.menuItem
export const getNotFoundData = state => state.menuR.notFoundData
export const getNotFoundBanner = state => state.menuR.notFoundBanner
export const getNotFoundProduct = state => state.menuR.notFoundProduct
