import { getIsPartOfDiscountCluster, getLocalStorageObject } from "./helpers";
import {
  VALIDATE_DISCCOUNT,
  STORAGE_BASKET_INDEX,
} from "../utils/constants/variables";

export const getDiscountByCluster = (
  discount = getLocalStorageObject(VALIDATE_DISCCOUNT),
  totalPriceAfterPromotion,
  totalPrice
) => {
  const { value, productCluster = [] } = discount;
  const basket = JSON.parse(localStorage.getItem(STORAGE_BASKET_INDEX)) || {};
  const isProductCluster = productCluster.length;
  const promotionDiscount = totalPrice - totalPriceAfterPromotion;
  let totalDiscount = 0;
  for (let productId in basket) {
    if (!basket[productId] || !basket[productId].sizes) continue;

    const sizes = basket[productId].sizes;

    totalDiscount += sizes.reduce((acc, item) => {
      const shouldDiscount = isProductCluster ? productCluster.some((cluster) => cluster.barcode === item.productReference) : true;
      if (shouldDiscount) {
        let currentPrice = item.quantity * (item.listPriceAfterDiscount || item.startingPrice);
        if (promotionDiscount) {
          const currentPricePercentInTotal = currentPrice * 100 / totalPrice;
          currentPrice -= currentPricePercentInTotal * promotionDiscount / 100;
        }

        acc += currentPrice * +value / 100;
      }
      return acc;
    }, 0);

  }

  return totalDiscount;
};

export const getBasketData = (
  basket = localStorage.getItem(STORAGE_BASKET_INDEX) || {}
) => {
  let price = 0;
  let quantity = 0;
  let oldPrice = 0;

  for (let productId in basket) {
    if (!basket[productId] || !basket[productId].sizes) continue;

    const sizes = basket[productId].sizes;

    const currentSizeData = sizes.reduce((acc, product) => {
      acc.sizesQuantity += product.quantity;
      acc.sizesPrice += product.quantity * (product.listPriceAfterDiscount || product.startingPrice);
      acc.sizesOldPrice += product.quantity * (product.oldPrice || product.startingPrice);

      return acc;
    }, { sizesPrice: 0, sizesQuantity: 0, sizesOldPrice: 0 });

    price += currentSizeData.sizesPrice;
    quantity += currentSizeData.sizesQuantity;
    oldPrice += currentSizeData.sizesOldPrice;
  }

  return {
    price,
    quantity,
    oldPrice,
  }
};

// export const getCouponSpecialDiscount = (itemPrice, basketData) => {
//   const percentInTotal = itemPrice * 100 / basketData.price;
//   return percentInTotal * basketData.totalCouponDiscount / 100;
// }

export const getCouponSpecialDiscount = (item = {}, basketData, discount, promotionDiscount = {}, basket, packCategoryIds) => {
  if (!basketData || !item || !Object.keys(item).length) return 0;
  const itemCurrentPrice = item.listPriceAfterDiscount || item.startingPrice
  const isPack = (promotionDiscount && promotionDiscount.ruleType === 'PACK')
  let isApprovedPack = false;
  if (isPack) {
    const data = checkIsHaveClustersAtLeastTwoProducts(basket, packCategoryIds)
    if (data.isApproved) isApprovedPack = true;
  }
  const itemPrice = ((isPack && isApprovedPack) ? 1 : item.quantity) * itemCurrentPrice;
  if (!discount && item.specialDiscount) {
    if (isPack) {
      if (isApprovedPack) return itemPrice
      return item.specialDiscount
    }
    return item.specialDiscount
  }

  const hasPromotion = promotionDiscount && promotionDiscount.value;
  let E = 1;
  const getCalculatedValue = (price, value, total) => {
    const percentInTotal = (price * 100) / total;
    const discountPriceForThisSize = (value * percentInTotal) / 100;
    return discountPriceForThisSize
  }
  const getCorrectValue = (quantity, promotion, d, e) => {
    if (quantity < 1) return 0;
    let isPercent = true;
    if (discount && discount.couponType !== "PERCENT") isPercent = false
    if (!promotion || !promotion.length || isPack) return isPercent ? e * d : e - getCalculatedValue(itemPrice, (+discount.value || 0), basketData.price)
    if (quantity === 1) {
      const itemNow = itemCurrentPrice * (item.specialDiscount ? ((100 - (promotionDiscount && promotionDiscount.value[0])) / 100) : 1)
      return isPercent ? itemNow * d : itemNow - getCalculatedValue(itemPrice, +discount.value, basketData.price)
    }

    let totalDiscount = 0;
    for (let i = quantity; i > 0; i--) {
      const currentDiscount = promotionDiscount && promotionDiscount.value[i] ? (100 - promotionDiscount.value[i]) / 100 : 1
      const its = itemCurrentPrice * currentDiscount;
      totalDiscount += isPercent ? its * d : its - getCalculatedValue(itemPrice, +discount.value, basketData.price);
    }
    return totalDiscount;
  }
  if (item.specialDiscount && hasPromotion && promotionDiscount.ruleType) {
    switch (promotionDiscount.ruleType) {
      case 'QUANTITY': E = itemPrice * ((100 - promotionDiscount.value) / 100); break;
      case 'PERCENTAGE': E = itemCurrentPrice; break;
      case 'PACK': E = itemPrice * ((100 - promotionDiscount.value[1]) / 100); break;
      default: E = itemPrice - item.specialDiscount; break;
    }
  } else E = itemPrice;
  const isProductCluster = getIsPartOfDiscountCluster(item, discount)
  const CD = (100 - (discount && discount.value ? discount.value : 0 )) / 100
  const D = item.specialDiscount ? discount.productCluster ? isProductCluster ? CD : 1 : CD : CD
  const S = itemPrice - getCorrectValue(item.quantity, hasPromotion, D, E);
  return parseFloat(S.toFixed(3)) || 0;
}

export const discountBasketByCoupon = (discount, basketData) => {
  const basket = getLocalStorageObject(STORAGE_BASKET_INDEX);
  const discountValue = parseFloat(discount.value);

  for (let productId in basket) {
    if (!basket[productId] || !basket[productId].sizes) continue;

    const sizes = basket[productId].sizes;

    sizes.forEach((size) => {
      const productPrice = size.listPriceAfterDiscount || size.startingPrice;

      if (discount.couponType === "PERCENT") {
        size.couponSpecialDiscount = ((productPrice * discountValue) / 100) * size.quantity;
      }

      if (discount.couponType === "FIXED") {
        const priceWithQty = productPrice * size.quantity;
        const percentInTotal = (priceWithQty * 100) / basketData.price;
        const discountPriceForThisSize = (discountValue * percentInTotal) / 100;

        size.couponSpecialDiscount = discountPriceForThisSize;
      }
    });
  }
  return basket;
}

export const removeCouponDiscountFromBasket = () => {
  const basket = getLocalStorageObject(STORAGE_BASKET_INDEX);

  for (let productId in basket) {
    if (!basket[productId] || !basket[productId].sizes) continue;

    const sizes = basket[productId].sizes;

    sizes.forEach((size) => {
      delete size.couponSpecialDiscount;
    });
  }

  return basket;
}

export const checkIsHaveClustersAtLeastTwoProducts = (basket = {}, packCategoryIds = {}) => {
	const categoriIds = Object.keys(packCategoryIds);
  const obj = {
    count: 0,
    quantity: 0
  }
  Object.keys(basket).map((it) => {
    const sizes = basket[it].sizes;
    if (!sizes || !sizes.length) return false;
    for (let i = 0; i < categoriIds.length; i++) {
      sizes.forEach((size) => {
        if (size.quantity > 1) {
          obj.quantity++
        } else if (packCategoryIds[categoriIds[i]].includes(size.productReference)) {
          obj.count++
        }
      })
    }
  })
  return {
    ...obj,
    isApproved: obj.count > 3
  };
}