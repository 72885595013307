import { createActions } from 'reduxsauce';

export const { Types: MenuTypes, Creators: MenuActionCreators } = createActions({
    menuStart: null,
    menuSuccess: ['data'],
    menuFailure: ['error'],
    notFoundSuccess: ['data'],
    loginStart: ['user'],
    logoutStart: ['user'],
    tokenValidateStart: ['user'],
    searchingStart: ['data'],
    clickPopUpStart: [],
    userInfoStart: [null],
    languageStart: ["lang"],
    cookieContentStart: ["id"],
    cookieContentSuccess: ["data"],
    cookieContentFailure: ["error"],
    getStoresStart: [null],
    getStoresSuccess: ["data"],
    getStoresFailure: [null],
    brandDetailStart: null,
    brandDetailSuccess: ['data'],
    brandDetailFailure: ['error'],
    getBarcodesByProductClusterIdStart: ["clusterId"],
    getBarcodesByProductClusterIdSuccess: ["data"],
    getPackBarcodesByProductClusterIdSuccess: ["data"],
    getBarcodesByProductClusterIdFailure: ["error"],
    setMenuItem: ['data']
});