import {createActions} from 'reduxsauce';

export const {Types: LocationSearchTypes, Creators: LocationSearchActionCreators} = createActions ({
    locationSearchStart: ['location', 'filter'],
    locationSearchSuccess: ['data'],
    locationSearchFailure: ['error'],
    clickAndCollectStoresStart: ['info', 'callback'],
    clickAndCollectStoresSuccess: ['data'],
    clickAndCollectStoresFailure: ['error'],
    locationSearchDestroyedStart: [null],
});
