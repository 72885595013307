import React from "react";
import { connect } from "react-redux";
import { UserAccountActionCreators } from "../redux/actions";
import { getUser, isActivated } from "../redux/selectors";
import { bindActionCreators } from "redux";
import { IUserAccountState } from "../redux/reducers";

interface Props extends IUserAccountState {
}

interface State {
    user: {
        email: string
    },
    activationSent: boolean;
}

class UserAccount extends React.Component<Props & typeof UserAccountActionCreators, State> {

    state: State = {
        user: {
            email: ""
        },
        activationSent: false
    };

    componentDidMount(): void {
    }

    render(): React.ReactNode {
        const { activate, match: { params } } = this.props;
        const { activationKey } = params;

        if (activate && !this.state.activationSent) {
            this.props.activateStart(activationKey);
            this.state.activationSent = true;
        }

        return (
            <div>
                {
                    this.props.activated == "undefined" &&
                    <div><h5>Please wait while we activate your account</h5></div>
                }
                {
                    this.props.activated &&
                    <div><h5>Account Activated</h5></div>
                }
                {
                    !this.props.activated &&
                    <div><h5>Not able to activate account.</h5></div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    activated: isActivated(state),
    user: getUser(state)
});

const mapDispatchToProps = (dispatch): typeof UserAccountActionCreators => {
    return bindActionCreators(UserAccountActionCreators, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(UserAccount);

