import { ShopperTypes, ShopperActionCreators } from "./actions";
import { redirectPage } from "../../../helpers/helpers";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import { CONTACTUS_SEND_URL } from "../../../utils/constants/service";
import { BRAND_ID } from "../../../utils/constants/variables";
import { select } from "redux-saga/effects";
import { getBrandDetail } from "../../../layouts/main/header/redux/selectors";

export function* sendShopperRequest(params) {
    const { body } = params;
    try {
        const { customerCode } = yield select(getBrandDetail);
        const headers = {
            headers: {
                brand: BRAND_ID,
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            }
        };
        const data = yield call(() => {
            return axios.post(`${CONTACTUS_SEND_URL}`, { customerCode, ...body }, headers)
                .then(res => res.data)
        });
        yield put(ShopperActionCreators.shopperSendSuccess(data));
    } catch (e) {
        yield put(ShopperActionCreators.shopperSendFailure(e.message));
        if (e.response.status === 503 && params.id === 21) {
            redirectPage()
        }
    }
}

export function* ShopperSaga() {
    yield takeLatest(ShopperTypes.SHOPPER_SEND_START, sendShopperRequest);
}

export default ShopperSaga;
