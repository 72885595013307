import React, { useEffect, useRef, useState } from "react";
import i18next from "i18next";
import ResponseMessage from "../../../responseMessage/container";
import { connect } from 'react-redux'
import { OrderActionCreators } from "../../../basket/redux/actions";
import { getDiscount, getDiscountError, getPrivateDiscountStatus, getPromotionDiscount } from "../../../basket/redux/selectors";
import { getCurrency, isFloat, makeThousandFormat, replaceComma } from "../../../../helpers/helpers";
import { BRAND_ID, DISCOUNT_CODE, REDEEM_POINTS, initialMessage } from "../../../../utils/constants/variables";
import { getUserInfo } from "../../../login/redux/selectors";
import { getBrandDetail } from "../../../../layouts/main/header/redux/selectors";
import { PaymentActionCreators } from "../../../payment/redux/actions";
import { getBasket } from "../../../addToCart/redux/selectors";

interface Props {
  basketData: Record<string, any>;
  authenticated: boolean,
  discount: any,
  promotionDiscount: any;
  status: string,
  error: any,
  validation?: boolean,
  brandDetail: any,
  webClient: any;
  unConditionalDiscount: any;
  discountError: string;
  privateDiscountStatus: string;
  dispatch: (...args) => void;
  basket: any
}

const DiscountPayment: React.FC<Props> = ({
  basketData,
  discountError,
  discount,
  webClient,
  promotionDiscount,
  privateDiscountStatus,
  brandDetail,
  dispatch,
  basket
}) => {
  const [discountCode, setDiscountCode] = useState<string>("");
  const [messageOptions, setMessageOptions] = useState<Record<string, any>>({ ...initialMessage });

  const refStore = useRef<Record<string, any>>({})
  const treshold = brandDetail.conversionThreshold || Infinity;
  const mmultiple = brandDetail.conversionMultiple || 0;
  const storeClientLoyalty = webClient.storeClientLoyalty || {};
  const loyaltyPointsCalculated = makeThousandFormat(Math.trunc(storeClientLoyalty.loyaltyPoints / mmultiple) * mmultiple)

  // useEffect(() => {
  //   if (!basketData.totalPrice) return () => {};
  //   checkCouponDiscountAndApply(); // if code exist in localstorage apply it
  // }, [basketData, webClient])

  useEffect(() => {
    if (privateDiscountStatus === "success") {
      setDiscountCode("");
      showMessage("success", i18next.t('product.basket.25'));
    }
  }, [privateDiscountStatus])

  useEffect(() => {
    if (discount !== null) {
      if (["FIXED", "PERCENT"].includes(discount.couponType)) {
        const val = isFloat(+discount.value) ? replaceComma(discount.value) : +discount.value;
        const successText = `${i18next.t("product.basket.19")} - ${discount.couponType === "PERCENT" ? `${val}%` : `${val}€`}`;
        showMessage("success", successText);
      }
    }

    setDiscountCode("");
  }, [discount])

  useEffect(() => {
    if (discountError) {
      showMessage("failed", discountError);
      dispatch(OrderActionCreators.resetErrorMessage());
    }
  }, [discountError])

  const checkCouponDiscountAndApply = () => {
    const { price, discountedSum } = basketData;
    const { webClientId } = webClient;
    const code = localStorage.getItem(DISCOUNT_CODE);

    if (!discount && price && webClientId && discountedSum === 0 && code && !refStore.current.isValidCalled) {
      refStore.current.isValidCalled = true;
      dispatch(OrderActionCreators.validateDiscountStart(
        code,
        price,
        "submitBtn"
      ));
    }
  }

  const showMessage = (status: string, text: string) => {
    setMessageOptions({
      status: status,
      text: text,
      isVisible: true
    });

    setTimeout(() => {
      setMessageOptions({ ...initialMessage });
    }, 7000);
  }

  const handleSubmit = () => {
    if (!discountCode) {
      showMessage("failed", i18next.t("product.basket.46"));

      return;
    }

    dispatch(OrderActionCreators.validateDiscountStart(discountCode));
  };

  return (
    <>
      <div className={`peymant-content-item peymant-content-item-code desktopSize768 `}>
        <h4>{i18next.t('product.basket.10')}</h4>
        <input
          type="text"
          placeholder={i18next.t("product.basket.21")}
          className="input-style"
          value={discountCode}
          onChange={(e) => setDiscountCode(e.target.value)}
        />
        {messageOptions.isVisible && (
          <ResponseMessage
            status={messageOptions.status}
            errorText={messageOptions.text}
            successText={messageOptions.text}
          />
        )}
        <button
          className="hoverButtonStyle button-style dflex align-items-center justify-content-center"
          onClick={handleSubmit}
        >
          {i18next.t('product.basket.11')}
        </button>
      </div>
      {REDEEM_POINTS === '1' && storeClientLoyalty.loyaltyPoints >= treshold && webClient.brandId === BRAND_ID && (
        <div className="peymant-content-item peymant-content-item-value">
          <div className="peymant-content-item-value-wrapper">
            <div
              className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between"
            >
              <h4>
                {i18next.t('mesInformations.23')}:&nbsp;
                <span className="price">
                  {storeClientLoyalty.loyaltyPoints} {`(${replaceComma(storeClientLoyalty.loyaltyValue)}${getCurrency()})`}
                </span>
              </h4>
            </div>
            <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between">
              <h4>
                {i18next.t('product.basket.42')}:&nbsp;
                <span className="price">{loyaltyPointsCalculated || ''}</span>
              </h4>
            </div>
            <div className="peymant-content-item-value-wrapper-item dflex align-items-center justify-content-between close">
              {basketData.price < storeClientLoyalty.loyaltyValue ? (
                <h4>
                  {i18next.t('product.basket.44')}: 
                  <span className="price">
                    {replaceComma(storeClientLoyalty.loyaltyValue)}{getCurrency(storeClientLoyalty.currency)}
                  </span>
                </h4>
              ) : (
                <h4>
                  {i18next.t('product.basket.43')}: 
                  <span className="price">{mmultiple}</span>
                </h4>
              )}
            </div>
            <div className="d-center">
              <button
                className={`valider-button hoverButtonStyle ${basketData.price < storeClientLoyalty.loyaltyValue ? 'disabled' : ''}`}
                onClick={() => dispatch(PaymentActionCreators.createClientDiscountStart({}))}
                disabled={basketData.price < storeClientLoyalty.loyaltyValue}
              >
                {i18next.t('product.basket.11')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: any): Partial<Props> => ({
  discountError: getDiscountError(state),
  privateDiscountStatus: getPrivateDiscountStatus(state),
  discount: getDiscount(state),
  webClient: getUserInfo(state),
  brandDetail: getBrandDetail(state),
  promotionDiscount: getPromotionDiscount(state),
  basket: getBasket(state),
});

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
};

export const DiscountPaymentBlock = connect(mapStateToProps, mapDispatchToProps)(DiscountPayment);