import { SubscribeTypes, SubscribeActionCreators } from "./actions";
import { takeLatest, put, call } from "redux-saga/effects";
import axios from 'axios';
import { NEW_MAILJET_SUBSCRIBE_URL } from "../../../../utils/constants/service";

export function* subscribeSend(params) {

    const { body } = params
    try {
        const headers = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const data = yield call(() => {
            return axios.post(`  ${NEW_MAILJET_SUBSCRIBE_URL}`, JSON.stringify(body), headers)
                .then(res => res.data)
        });
        if (data.result === "OK") {
            localStorage.setItem('noPop', "saved")
        }
        yield put(SubscribeActionCreators.subscribeSuccess(data));
    } catch (e) {
        yield put(SubscribeActionCreators.subscribeFailure(e.message));
    }
}

export function* subscribeSaga() {
    yield takeLatest(SubscribeTypes.SUBSCRIBE_START, subscribeSend);
}

export default subscribeSaga;
