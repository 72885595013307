import React, {PureComponent} from 'react';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {Accordion} from 'semantic-ui-react';
import {getMentionLegalesContent, getMentionLegalesLoading, getMenu} from "../redux/selectors";
import {getRecommendedProductsData} from "../../home/redux/selectors";
import RecommendedProducts from "../../home/container/components/recommendedProducts";
import {MentionLegalesActionCreators} from '../redux/actions';
import PageMetaData from "../../common/pageMetaData/pageMetaData";
import CurrentPage from "../../../layouts/common/currentPage";
import {BASE_API_GATEWAY} from "../../../utils/constants/service";
import './mentionLegales.scss';
import Loading from "../../product/container/components/loading";


interface Props {
    canonicalUrl?: any,
    history?: any,
    location?: any,
    menuList: any,
    data: any,
    recommendedProducts: any,
    isLoading: boolean
}

interface State {
    activeIndex: number,
    found: any
}

class MentionLegales extends PureComponent<Props & typeof MentionLegalesActionCreators> {
    state: State = {activeIndex: -1, found: {}};

    handleClick = (e, titleProps) => {
        const {index} = titleProps;
        const {activeIndex} = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({activeIndex: newIndex})
    };

    componentDidMount() {
        const {menuItem} = this.props;
        if(menuItem && menuItem.gridId){
            this.props.mentionLegalesStart(menuItem.gridId)
        }

        if(menuItem && menuItem.key === "guide_des_tailles"){
            this.props.recommendedProductsStart();
        }

    }

    componentWillReceiveProps(nextProps: Readonly<any>): void {
        const {data} = nextProps;
        if (data && data.length) {
            const idx = data.findIndex((item: any) => item.name === 'Cookie');
            if (idx !== -1) this.setState({
                activeIndex: idx,
            })
        }
    }

    render() {
        const {data, menuItem, recommendedProducts, isLoading} = this.props;
        const {activeIndex} = this.state;
        return (
            <div>
                {
                    Object.entries(menuItem).length ?
                        <CurrentPage selectedItem={menuItem} showPageMetaData={false}/> : ""
                }
                {
                    Object.entries(menuItem).length && menuItem.key && data && data.length ?
                        <PageMetaData pageKey={menuItem.key} dataFAQ={menuItem.key === "faq" ? data : ""}/> : ""
                }
                {
                    isLoading ? <Loading/>
                    :
                    <div className="container">
                        <div className="mentions-page">
                            <div>
                                {
                                    data && data.length ?
                                        data.sort((a, b) => (a.widget - b.widget))
                                            .map((item, index) => {
                                                return <Accordion key={index}>
                                                    <Accordion.Title active={activeIndex === index}
                                                                     onClick={(e) => this.handleClick(e, {index: index})}>{item.name}</Accordion.Title>
                                                    <Accordion.Content active={activeIndex === index}>
                                                        {
                                                            item.type && item.type.toUpperCase() === "PHOTO" ?
                                                                <img
                                                                    key={index}
                                                                    src={BASE_API_GATEWAY + item.value}
                                                                    alt={item.altText || "PHOTO"}
                                                                />
                                                                :
                                                                <div
                                                                    dangerouslySetInnerHTML={{__html: item.value}}></div>

                                                        }
                                                    </Accordion.Content>
                                                </Accordion>
                                            }) : ""
                                }
                            </div>
                        </div>
                        {
                            recommendedProducts.length && menuItem && menuItem.key === "guide_des_tailles" ?
                                <RecommendedProducts recommendedProducts={recommendedProducts} sliderType="home"/>
                                :
                                null
                        }
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    data: getMentionLegalesContent(state),
    menuList: getMenu(state),
    recommendedProducts: getRecommendedProductsData(state),
    isLoading: getMentionLegalesLoading(state)
});

const mapDispatchToProps = (dispatch): typeof MentionLegalesActionCreators => {
    return bindActionCreators(MentionLegalesActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MentionLegales);