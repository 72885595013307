import {ContactUsTypes, ContactUsActionCreators} from "./actions";
import {takeLatest, put, call, select} from "redux-saga/effects";
import axios from 'axios';
import {CONTACTUS_URL, CONTACTUS_SEND_URL, ZENDESK_API} from "../../../../utils/constants/service";
import {getApiPrefix} from  "../../../../helpers/helpers"
import {BRAND_ID} from "../../../../utils/constants/variables";
import { getContactUsData } from "./selectors";
import { getBrandDetail } from "../../../../layouts/main/header/redux/selectors";

export function* contactUsGet() {
    try {
        const data = yield call(() => {
            return axios.get(`${CONTACTUS_URL}form?brandId=${BRAND_ID}&language=${getApiPrefix()}`)
            .then(res => res.data)
        });

        yield put(ContactUsActionCreators.contactUsSuccess(data));
    } catch (e) {
        yield put(ContactUsActionCreators.contactUsFailure(e.message));
    }
}

export function* contactUsSend(params) {
    const headers = {
        headers: {
            brand: BRAND_ID,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
        }};
    try {
        const { ticketSubjectList = []} = yield select(getContactUsData) || {};
        const { customerCode, zendesk = false } = yield select(getBrandDetail);
        const { data, status } = yield call(() => {
            return axios.post(`${CONTACTUS_SEND_URL}`, JSON.stringify({customerCode, ...params.data}), headers)
                .then(res => res)
        });

        if(status === 200 && zendesk) {
            const { subject = '' } = ticketSubjectList.find(ticketSubject => ticketSubject.code === params.data.ticketSubjectCode) || {};
            const body = {
                comment: params.data.ticketMessage,
                email: params.data.contactEmail,
                name: params.data.contactName,
                subject: subject,
                brandId: BRAND_ID
            }

            yield call(() => {
                return axios.post(`${ZENDESK_API}`, JSON.stringify(body), headers)
                .then(res => res.data)
            });
        }
        yield put(ContactUsActionCreators.contactUsSendSuccess(data));
    } catch (e) {
        yield put(ContactUsActionCreators.contactUsSendFailure(e.message));
    }
}

export function* contactUs() {
    yield takeLatest(ContactUsTypes.CONTACT_US_START, contactUsGet);
    yield takeLatest(ContactUsTypes.CONTACT_US_SEND_START, contactUsSend);
}

export default contactUs;