import React from "react";

import Styles from "./styles";
import Common from "./common";

import {SUB_MENU_STYLES} from "../../../../../../../../utils/constants/variables";

interface Props {
    menuItem: any,
    handleClick: any
}
class SubMenu extends React.Component<Props> {

    render() {
        const {menuItem} = this.props;
        return (
            <div className={'navbar-menu-opened-wrapper'}>
                { menuItem &&
                <div className={`navbar-menu-opened-content ${menuItem.key ? "active" : ""} ${menuItem.key}`}>
                    <div className="container">
                        {
                            menuItem.key == SUB_MENU_STYLES ?
                                <Styles menuItem={menuItem} handleClick={this.props.handleClick}/>
                                :
                                <Common menuItem={menuItem} handleClick={this.props.handleClick}/>
                        }
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default SubMenu;