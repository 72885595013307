import React from 'react';
import CurrentPage from "../../../../layouts/common/currentPage";
import {
    countTimeBlog, getCurrency,
    getDataBySectionAndWidget,
    getLangPrefix,
    replaceComma,
    stripHTMLreg,
    getProductCategoryName
} from "../../../../helpers/helpers";
import {getItemPost, getInstagramData, getBlogData,getFavoriteProducts} from "../../redux/selectors";
import {BlogActionCreators} from "../../redux/actions";
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {BASE_API_GATEWAY} from "../../../../utils/constants/service";
import parse from "html-react-parser";
import InstagramSection from './instagramSection'
import {Helmet} from 'react-helmet';
import {HIDE_DISCOUNT, INSTAGRAM} from "../../../../utils/constants/variables";
import BlogSlider from "./blogSlider";
import RecommendedProducts from "../../../home/container/components/recommendedProducts";
import i18next from "i18next";
import Swiper from "react-id-swiper";
import {Link} from "react-router-dom";
import LinkFullUrl from "../../../../shared/linkFullUrl/linkFullUrl";
import {LazyImage} from "react-lazy-images";
import {getBanner} from "../../../home/redux/selectors";
import FavoriteProducts from "./favoriteProducts";

interface Props {
    match?: any,
    menuItem?: any,
    data: any,
    products: any,
    blogStart?: any,
    instagramData: any,
    blogInstagramStart?: any,
    articles?: any,
    dataArticles?: any,
    blogFavoriteProductsStart?:any,
    banner:any
}


class BlogPost extends React.Component <Props> {

    componentDidMount() {
        const {menuItem, blogStart, data, blogInstagramStart, blogFavoriteProductsStart,products} = this.props;
        if (INSTAGRAM) {
            blogInstagramStart(5);
        }
        if (data && !data.length) {
            blogStart(menuItem.gridId);
        }
        if (!(products && products.length)) {
            blogFavoriteProductsStart(menuItem.menuId);
        }
    }

    getData = (key, type = "text") => {
        let result;
        const itemByKey = this.props.data.length && key ? this.props.data.find(item => item.name && item.name.toLowerCase() === key.toLowerCase()) : "";

        switch (type) {
            case "text":
                result = itemByKey ? parse(itemByKey.value.replace(stripHTMLreg, '')) : "";
                break;
            case "image":
                result = itemByKey ? (BASE_API_GATEWAY + itemByKey && itemByKey.value) : "";
                break;
            case "menu-href":
                result = itemByKey ? itemByKey && itemByKey.menuHref : "/#";
                break;
            default:
                result = "";
        }
        return result;
    };

    render() {
        const {match: {params: {postId = ""}}, menuItem, data, instagramData, dataArticles, products, banner} = this.props;
        const blogData = data && data.length ? data.filter(item => item.section === Number(postId) && item.widget > 1) : []
        return (
            <div className="blog-post-content">
                <div className="div-grey-color">
                    <CurrentPage selectedItem={menuItem} showDescription={false} postData={data && data}/>
                    <Helmet>
                        <meta name="title"
                              content={getDataBySectionAndWidget(Number(postId), 1, data, "TEXT", "Title").value
                                  ? getDataBySectionAndWidget(Number(postId), 1, data, "TEXT", "Title").value.replace(stripHTMLreg, "") : ""}/>
                    </Helmet>
                    <section className="product-info-box no-border">
                        <aside className="main-box container">
                            <div className="info-box">
                                <h2 className="title"
                                    dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(Number(postId), 1, data, "TEXT", "Title").value}}/>

                                <h4 className="text"
                                    dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(Number(postId), 1, data, "TEXT", "Subtitle").value}}/>
                            </div>
                        </aside>
                    </section>
                </div>
                <section className="container notreContent">
                    <div className="notreContent--header">
                        {
                            blogData.length ?  blogData.map((item) => (
                                <React.Fragment key={item.widget}>
                                    <div className="blog-img">
                                        {
                                            getDataBySectionAndWidget(Number(postId), item.widget, data, "PHOTO").value ?
                                                <img src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(Number(postId),  item.widget, data, "PHOTO").value}`}/>
                                                : ""
                                        }
                                        {
                                            getDataBySectionAndWidget(Number(postId), item.widget, data, "PHOTO").name ?
                                                <p className="blog-img--name"
                                                   dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(Number(postId), item.widget, data, "PHOTO").name}}/>:""
                                        }

                                    </div>
                                    <div className="notreContent--header--textBox">
                                        <p className="notreContent--header--textBox--description blog-desc"
                                           dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(Number(postId), item.widget, data, "TEXT").value}}/>
                                    </div>
                                </React.Fragment>
                                )) :""
                        }
                    </div>
                </section>
                <section className={"container"}>
                    {
                        products && products.catalogue && products.catalogue.content ?
                            <div className="blogSlider">
                                <h4 className="recommendedSlidercontent--title ">{i18next.t('blog.3')}</h4>
                                <FavoriteProducts products={products.catalogue.content} banner={banner}/>
                            </div> : ""
                    }
                </section>
                <BlogSlider data={dataArticles} postId={Number(postId)}/>

            </div>
        )
    }
}

const mapStateToProps = (state, props): Props => {
    const {match: {params: {postId = ""}}} = props;
    return {
        dataArticles: getBlogData(state),
        data: getItemPost(state, postId),
        instagramData: getInstagramData(state),
        articles: getBlogData(state),
        products : getFavoriteProducts(state),
        banner: getBanner(state),
    }
};

const mapDispatchToProps = (dispatch): typeof BlogActionCreators => {
    return bindActionCreators(BlogActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogPost);
