import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import {Link} from "react-router-dom";
import {getDataBySectionAndWidget, getLangPrefix} from "../../../../helpers/helpers";
import {BASE_API_GATEWAY} from "../../../../utils/constants/service";
import ImgBlob from "./imgBlob";
import {LazyImage} from "react-lazy-images";
import back from "../../../../assets/img/grey-background.webp"
const {detect} = require('detect-browser');

interface Props {
    data: any,
    getData: any,
}


class Spirit extends React.Component<Props> {


    render() {
        const browser = detect();
        const {data} = this.props;
        const LANGUAGE_PREFIX = getLangPrefix();
        return (
            <section className="spirit">
                <div className="home-container">
                    <div className="dflex">
                        <div className="spirit-content-mobile">
                            <h1>
                                <p dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(3, 1, data).value}}/>
                            </h1>
                            <h2><p dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(3, 2, data).value}}/>
                            </h2>
                            <div className="spirit-content-mobile-wrapper">
                                <div className="spirit-content-text">
                                    {
                                        <p dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(3, 3, data).value}}/>}
                                </div>
                                {getDataBySectionAndWidget(3, 4, data) &&
                                <AwesomeSlider>
                                    <div>
                                        <Link
                                            to={`${LANGUAGE_PREFIX}${getDataBySectionAndWidget(3, 4, data).menuHref}`}>
                                            {
                                                <p dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(3, 4, data).value}}/>}
                                        </Link>
                                    </div>
                                    <div>
                                        <Link
                                            to={`${LANGUAGE_PREFIX}${getDataBySectionAndWidget(3, 5, data).menuHref}`}>
                                            {
                                                <p dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(3, 5, data).value}}/>}
                                        </Link>
                                    </div>
                                    <div>
                                        <Link
                                            to={`${LANGUAGE_PREFIX}${getDataBySectionAndWidget(3, 6, data).menuHref || "#"}`}>
                                            {
                                                <p dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(3, 6, data).value}}/>}
                                        </Link>
                                    </div>
                                </AwesomeSlider>
                                }
                            </div>
                        </div>
                        <div className="spirit-content ">
                            <div>
                                <h1>
                                    <p dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(3, 1, data).value}}/>
                                </h1>
                                <h2><p dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(3, 2, data).value}}/>
                                </h2>
                                <div className="spirit-content-text">
                                    {
                                        <p dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(3, 3, data).value}}/>}
                                </div>
                            </div>
                            <div className="d-between spirit-content-links">
                                <Link to={`${LANGUAGE_PREFIX}${getDataBySectionAndWidget(3, 4, data).menuHref}`|| "#"}>
                                    {
                                        <p dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(3, 4, data).value}}/>}
                                </Link>
                                <Link to={`${LANGUAGE_PREFIX}${getDataBySectionAndWidget(3, 5, data).menuHref}`|| "#"}>
                                    {
                                        <p dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(3, 5, data).value}}/>}
                                </Link>
                                <Link to={`${LANGUAGE_PREFIX}${getDataBySectionAndWidget(3, 6, data).menuHref}`|| "#"}
                                      aria-label="Intermdiate JavaScript">
                                    {
                                        <p dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(3, 6, data).value}}/>
                                    }
                                </Link>
                            </div>

                        </div>
                        <div className="spirit-img">
                            <Link to={`${LANGUAGE_PREFIX}${getDataBySectionAndWidget(3, 7, data).menuHref}`|| "#"}>
                                {
                                    getDataBySectionAndWidget(3, 7, data) && getDataBySectionAndWidget(3, 7, data).value ?
                                        <ImgBlob src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(3, 7, data).value && getDataBySectionAndWidget(3, 7, data).value}`}
                                                 alt={getDataBySectionAndWidget(3, 7, data).altText || "livelo image"}/>:""
                                }

                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Spirit;

