import {createActions} from 'reduxsauce';

export const {Types: StoreLocationTypes, Creators: StoreLocationActionCreators} = createActions ({
    storeLocationStart: ['data'],
    getBasketStart: [null],
    addtocartStart: ["name", "key", "value", "setAsQuantity"],
    removeBasketStart: ["name", "key", "prSize"],
    selectedDeliveryMethodStart: ["method"],
    locationSearchDestroyedStart: [null],
    locationSearchStart: ['location', 'filter'],
    recommendedProductsStart: [null],
    resetStoreLocationStart: [null],
});
