import {MentionLegalesTypes, MentionLegalesActionCreators} from "./actions";
import {takeLatest, put, call} from "redux-saga/effects";
import axios from 'axios';
import {CONTENT_URL} from "../../../utils/constants/service";
import {getApiPrefix} from "../../../helpers/helpers"


export function* getData(params) {
    try {
        const headers = {headers: {language: getApiPrefix()}};
        const data = yield call(() => {
            return axios.get(`${CONTENT_URL}${params.gridId}`, headers)
                .then(res => res.data)
        });
        yield put(MentionLegalesActionCreators.mentionLegalesSuccess(data));
    } catch (e) {
        yield put(MentionLegalesActionCreators.mentionLegalesFailure(e.message));
    }
}


export function* mentionLegalesSaga() {
    yield takeLatest(MentionLegalesTypes.MENTION_LEGALES_START, getData);
}

export default mentionLegalesSaga;