import {createActions} from 'reduxsauce';

export const {Types: HomeTypes, Creators: HomeActionCreators} = createActions({
    homeStart: ['id'],
    homeSuccess: ['data'],
    homeFailure: ['error'],
    homeInstagramStart: ['count'],
    homeInstagramSuccess: ['data'],
    homeInstagramFailure: ['error'],
    recommendedProductsStart: [null],
    recommendedProductsSuccess: ['data'],
    recommendedProductsFailure: ['error'],
    validateDiscountStart: ['data','price','typeOfRequest'],
    bannerStart:[null],
    bannerSuccess: ['data'],
    bannerError:['error'],
    subscribeStart: ['body'],
});
