import {createReducer} from 'reduxsauce';
import {MentionLegalesTypes} from "./actions";

export interface IState {
    data: object,
    isLoading: boolean,
    error: string
}

export const mentionLegales: IState = {
    data: [],
    isLoading: false,
    error: ""
};

export const mentionLegalesStart = (state) => {
    return {...state, isLoading: true};
};

export const mentionLegalesSuccess = (state, action) => {
    return {...state, data: action.data, isLoading: false};
};

export const mentionLegalesFailure = (state, action) => {
    return {...state, error: action.error, data: [], isLoading: false};
};

export const handlers = {
    [MentionLegalesTypes.MENTION_LEGALES_START]: mentionLegalesStart,
    [MentionLegalesTypes.MENTION_LEGALES_SUCCESS]: mentionLegalesSuccess,
    [MentionLegalesTypes.MENTION_LEGALES_FAILURE]: mentionLegalesFailure,
}

export default createReducer(mentionLegales, handlers);