import {createReducer} from 'reduxsauce';

export interface IProductsState {
    collection: object
}

export const getCollection: IProductsState = {
    collection: [
        {
            id: "1",
            name: "Longwu Women's Loose Casual Front Tie Short Sleeve Bandage Party Dress",
            image: 'https://images-na.ssl-images-amazon.com/images/I/41JP5CAVnsL._AC_US218_.jpg',
            price: '32'
        },
        {
            id: "8",
            name: "For Love & Lemons Women's Temecula Mini Dress",
            image: 'https://images-na.ssl-images-amazon.com/images/I/51XvR5AbyRL._AC_US218_.jpg',
            price: '63'
        },
        {
            id: "3",
            name: "Self Portrait Women's Abstract Triangle Lace Dress",
            image: 'https://images-na.ssl-images-amazon.com/images/I/41d7ZrXbefL._AC_US218_.jpg',
            price: '405'
        },
    ]
};

export const collection = (state) => {
    return {...state};
};

export default createReducer(getCollection, collection);