import React from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import i18next from "i18next";

import { MesAddressActionCreators } from "../../../monEspace/container/components/MesAddress/redux/actions";

import { OrderActionCreators } from "../../redux/actions";
import {
    getStatus,
    getError,
    getDiscount,
    getUnconditionalDiscount,
    getDiscountError,
} from "../../redux/selectors";
import { compareBaskets, getBasketData, getBasketQuantity, getDiscountList, getPromotionValue, isFloat, isProductsInBarcode, replaceComma } from "../../../../helpers/helpers";
import { StoreLocationActionCreators } from "../../../location/redux/actions";
import { getBasket } from "../../../addToCart/redux/selectors";
import ResponseMessage from "../../../responseMessage/container";
import { getUserInfo, isAuthenticated } from "../../../login/redux/selectors";
import { PROMOTION_DISCOUNT, PROMOTION_RULES, VALIDATE_DISCCOUNT } from "../../../../utils/constants/variables";
import { getBrandDetail } from "../../../../layouts/main/header/redux/selectors";

interface Props {
    basket?: any,
    authenticated: boolean,
    discount: any,
    status: string,
    error: any,
    validation?: boolean,
    classN?: string,
    brandDetail: any,
    webClient: any;
    unConditionalDiscount: any;
    discountError: any;
}

interface State {
    discountCode: string,
    visible: boolean,
    content: string,
    errorText: string,
    successText: string,
    status: string
}

class DiscountPayment extends React.Component<Props & typeof MesAddressActionCreators & typeof OrderActionCreators> {
    state: State = {
        discountCode: "",
        visible: false,
        content: "",
        errorText: "",
        successText: "",
        status: ""
    };

    componentDidMount(): void {
        const isProductsInBarcodeStatus = isProductsInBarcode();
        const { basket, discount } = this.props;
        const { price } = getBasketData(basket);
        const validateDiscount = discount;

        if (!isProductsInBarcodeStatus) {
            localStorage.removeItem(PROMOTION_DISCOUNT);
            this.props.getBasketStart();
        }

        if (validateDiscount && Object.keys(validateDiscount) && !isProductsInBarcodeStatus) {
            this.props.validateDiscountStart(validateDiscount.discountCode, price);
        }
        // this.discountPromotion();

    }

    componentDidUpdate(prevProps, prevState) {
        const promo = localStorage.getItem(PROMOTION_DISCOUNT);
        const promotionDiscount = promo && JSON.parse(promo);
        const local = localStorage.getItem(VALIDATE_DISCCOUNT);
        const validateDiscount = local && JSON.parse(local);
        if (prevProps.unConditionalDiscount !== this.props.unConditionalDiscount && this.state.discountCode) {
            if (this.props.unConditionalDiscount && this.props.unConditionalDiscount.valid === false) {
                let errorText = this.props.unConditionalDiscount.authenticated ? i18next.t("product.basket.20") : i18next.t("product.basket.24");
                status = "failed";
                this.setState({ visible: true, errorText, status })
                setTimeout(() => {
                    this.setState({ visible: false, status: "" })
                }, 7000)
            }

        }
        if (this.props.discount && prevProps.discount && (this.props.discount.valid !== prevProps.discount.valid)) {
            if (!this.props.discount.valid) {
                localStorage.removeItem(VALIDATE_DISCCOUNT);
                this.props.getBasketStart();
            }
        }

        if (compareBaskets(prevProps.basket, this.props.basket)) {
            if (!validateDiscount ||
                validateDiscount &&
                validateDiscount.couponType && !["FIXED", "PERCENT"].includes(validateDiscount.couponType)
            ) {
                // this.discountPromotion();
            }
        }

        if (!prevProps.status && this.props.status) {
            const { error = "", discount = {}, webClient = {} } = this.props
            const { discountCodes = [] } = webClient;
            let errorText = error;
            let successText = "";
            let status = this.props.status;
            if (!discount.valid) {
                errorText = discount.authenticated ? i18next.t("product.basket.20") : i18next.t("product.basket.24");
                status = "failed";
                successText = i18next.t('product.basket.20')
                this.setState({ visible: true, errorText, successText, status })
            } else {
                if (discount.couponType === "PRIVATE_SALE") {
                    successText = i18next.t('product.basket.25')
                    this.setState({ visible: true, errorText, successText, status })
                } else if (!promotionDiscount && ["FIXED", "PERCENT"].includes(discount.couponType) && !discountCodes.includes(discount.discountCode)) {
                    const val = isFloat(+discount.value) ? replaceComma(discount.value) : +discount.value
                    successText = `${i18next.t("product.basket.19")} - ${discount.couponType === "PERCENT" ? `${val}%` : `${val}€`}`
                    this.setState({ visible: true, errorText, successText, status })
                }
            }

            setTimeout(() => {
                this.setState({ visible: false, status: "" })
            }, 7000)
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.authenticated !== this.props.authenticated && this.state.content !== "panier") {
            nextProps.authenticated ? this.setContnet("livraison") : this.setContnet("identification")
        }
    };

    componentWillUnmount = () => {
        this.props.cleareValidateInitialStart();
    }

    getDiscountValue = (data) => {
        const { basket } = this.props;
        const { totalPrice } = getBasketData(basket);

        const quantity = data.promotion !== undefined ? getBasketQuantity(basket, data.promotion) : getBasketQuantity(basket);

        if (quantity === 0) {
            return false;
        }

        return getPromotionValue(data);
    }

    discountPromotion = () => {
        const { brandDetail, basket } = this.props;
        const promo = localStorage.getItem(PROMOTION_DISCOUNT);
        const promoDiscount = promo && JSON.parse(promo);

        if (brandDetail.promotion && PROMOTION_RULES === '1') {


            const isTypeExist = (brandDetail && brandDetail.promotion || []).find((item) => ["AMOUNT", "VALUE", "QUANTITY", "PERCENTAGE", "PACK"].includes(item.ruleType));
            const discount = isTypeExist ? this.getDiscountValue(isTypeExist) : false;

            if (discount !== 0 && !discount && promoDiscount) {

                localStorage.removeItem(PROMOTION_DISCOUNT);
                this.props.getBasketStart();

                return;
            }

            if (discount || discount === 0) {
                const data = {
                    ruleType: isTypeExist.ruleType,
                    value: discount,
                    basketQuantity: getBasketQuantity(basket, isTypeExist.promotion),
                    ...(isTypeExist.threshold !== undefined) && { threshold: isTypeExist.threshold },
                    ...(isTypeExist.currency !== undefined) && { currency: isTypeExist.currency },
                    ...(isTypeExist.promotion !== undefined) && { promotion: isTypeExist.promotion },
                    ...(isTypeExist.productCategoryId_1 !== undefined) && { productCategoryId_1: isTypeExist.productCategoryId_1 },
                    ...(isTypeExist.productCategoryId_2 !== undefined) && { productCategoryId_2: isTypeExist.productCategoryId_2 },
                };

                this.props.promotionDiscountStart(data);
                this.props.getBasketStart();
            }
        }
    }

    setContnet = (content) => {
        localStorage.getItem("token") ? null : window.scrollTo(0, 0);
        this.setState({ content });
        window.scrollTo(0, 0);
    };
    handleChange = (e) => {
        this.setState({ discountCode: e.target.value })
    };
    handleSubmit = () => {
        if (this.state.discountCode) {
            const { basket } = this.props;
            const { price } = getBasketData(basket);
            this.props.validateDiscountStart(
                this.state.discountCode,
                price,
                "submitBtn"
            );
        } else {
            let errorText = i18next.t("product.basket.46")
            status = "failed";
            this.setState({ visible: true, errorText, status })
            setTimeout(() => {
                this.setState({ visible: false, status: "" })
            }, 7000)
        }
    };

    render() {
        const { discountCode, visible, errorText, status, successText } = this.state;
        const { classN, discount, discountError } = this.props;
        const promotionDiscount = localStorage.getItem(PROMOTION_DISCOUNT);
        const isInvalid = (discount && discount.couponType !== undefined && ['FIXED', 'PERCENT'].includes(discount.couponType) && promotionDiscount && isProductsInBarcode());

        return (
            <div className={`peymant-content-item peymant-content-item-code ${classN ? classN : ""} `}>
                <h4>{i18next.t('product.basket.10')}</h4>
                <input type="text" placeholder={i18next.t("product.basket.21")} className="input-style"
                    value={discountCode} onChange={(e) => this.handleChange(e)} />

                {
                    visible && status &&
                    <ResponseMessage
                        status={status}
                        errorText={errorText}
                        successText={successText}
                    />
                }

                <button
                    className="hoverButtonStyle button-style dflex align-items-center justify-content-center"
                    onClick={this.handleSubmit}>
                    {i18next.t('product.basket.11')}
                </button>
                {discountError &&
                    <p style={{ textAlign: "center", color: "red", marginTop: "10px" }}>{i18next.t(discountError)}</p>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    return {
        basket: getBasket(state),
        authenticated: isAuthenticated(state),
        discount: getDiscount(state),
        status: getStatus(state),
        error: getError(state),
        brandDetail: getBrandDetail(state),
        webClient: getUserInfo(state),
        unConditionalDiscount: getUnconditionalDiscount(state),
        discountError: getDiscountError(state)
    }
};
const mapDispatchToProps = (dispatch): typeof MesAddressActionCreators => {
    return bindActionCreators({ ...MesAddressActionCreators, ...OrderActionCreators, ...StoreLocationActionCreators }, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(DiscountPayment);
