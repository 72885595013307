import React, { useState } from "react";
import Location from "../../modules/location/container/location";
import './storesList.scss'

interface Props {
    stores: any[];
    setStores: (stores) => void;
}

const StoreList = ({ stores, setStores }: Props) => {
    const [showLocation, setShowLocation] = useState(false);

    const toValidName = (name) => {
        return name === 'AD' ? 'ANDORRA' : name;
    }

    const handleShowLocation = () => {
        setShowLocation(!showLocation);
    };

    const showStores = (stores) => {
        setStores(stores);
        setShowLocation(true);
    }

    const filterStores = (stores) => {
        return stores.reduce((storeSet, store) => {
            if (!storeSet.find(item => store.name === item.name)) {
                storeSet.push(store);
            }
            return storeSet;
        }, []);
    }

    return (
        <div className="stores-container">
            {
                stores.map((store, storeIndex) => (
                    <div className="store-item" key={storeIndex}>
                        <p className="country-name">{toValidName(store.country)}</p>
                        <div className='city-container'>
                            <div className="city-group">
                                {filterStores(store.cities).map((city, cityIndex) => (
                                    <div
                                        className="city-item"
                                        onClick={() => showStores(city.stores)}
                                        key={cityIndex}
                                    >
                                        <span className='city-name'>{toValidName(city.name)}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))
            }
            {showLocation && <Location handleShow={handleShowLocation} />}
        </div>
    );
}

export default StoreList