import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom';
import i18next from "i18next";
import { ProductsActionCreators } from "../redux/actions";
import { IProductsState } from "../redux/reducers";
import { BASE_API_GATEWAY } from "../../../utils/constants/service";
import { dataLayerClick, getCurrency, getLangPrefix, isPhotoUrlEndsWithNull } from "../../../helpers/helpers";
import { MetroSpinner } from "react-spinners-kit";
import { replaceComma } from "./../../../helpers/helpers";
import { LazyImage } from "react-lazy-images";
import { connect } from "react-redux";
//styles
import "./products.scss";
import { getBanner, getBanners } from "../../home/redux/selectors";
import { HIDE_DISCOUNT, PRODUCT_TYPES } from "../../../utils/constants/variables";
import LinkFullUrl from "../../../shared/linkFullUrl/linkFullUrl";
import DiscountDetail from "../../common/product/discountDetail";
import HeardIcon from "./heardIcon";
import PermanentSale from "../../common/product/permanentSale";
import { getNotFoundProduct } from "../../../layouts/main/header/redux/selectors";

interface Props extends IProductsState {
    loading: boolean,
    filters: {},
    banner?: any,
    banners?: any,
    wishlist?: boolean,
    noFilter?: boolean,
    menuItem?: any,
    notFoundProduct?: string
}


class ProductList extends React.Component<Props & typeof ProductsActionCreators> {

    state = {
        activeIndex: -1,
        width: window.innerWidth,
        loading: this.props.loading,
    };

    handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;
        this.setState({ activeIndex: newIndex })
    };

    componentDidMount(): void {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => { this.setState({ width: window.innerWidth }) };


    getProductCategoryName = (item) => {
        const found = item.find(element => element.searchFilter === 'Collection');
        const categoryName = found ? found.values[0].value : '';
        return categoryName;
    };

    render(): React.ReactNode {
        const { width } = this.state;
        const { list, handleClick, menuItem = {}, loading, resetFiltre, banner, banners, wishlist, noFilter, notFoundProduct } = this.props;
        const path = this.props.location.pathname.includes("filtres") ? this.props.location.pathname.split("/filtres")[0] : this.props.location.pathname;
        let isMobile = width < 920;
        const visuals = menuItem && menuItem.visuals ? menuItem.visuals[0] : '';
        const LANGUAGE_PREFIX = getLangPrefix();
        const { productType } = menuItem;
        const foundItem = banners && banners.find(b => b.labelType === "ON_SALE");
        return (
            <Fragment>
                <MetroSpinner
                    size={50}
                    color="#686769"
                    loading={wishlist ? list && list.content ? false : loading : loading}
                />
                <div className="product-content">
                    {
                        list && list.content && list.content.length ? (
                            list.content.map((product, index) => {
                                const photoThumbnail = isPhotoUrlEndsWithNull(`${BASE_API_GATEWAY}${product.thumbnail}`) || `${BASE_API_GATEWAY}${notFoundProduct}`;
                                const photoSrc = isPhotoUrlEndsWithNull(`${BASE_API_GATEWAY}${product && product.photo}`) || `${BASE_API_GATEWAY}${notFoundProduct}`;
                                return (productType !== PRODUCT_TYPES.c && productType !== PRODUCT_TYPES.sc) ? (
                                    <div
                                        key={product.productId}
                                        className={`
                                                advertisingBox ${index === 6 && visuals && visuals.type === "CATALOGUE"
                                                ? "currentAdvertising"
                                                : ""} ${productType === PRODUCT_TYPES.s
                                                    ? "fourColums"
                                                    : ""} ${product.onDisplay === "Y" && !isMobile ? 'opened' : ""}`}
                                    >
                                        <LinkFullUrl
                                            className={
                                                `product-item small
                                                    ${index === 6 &&
                                                    visuals &&
                                                    visuals.type === "CATALOGUE" ?
                                                    "product-content-sm-product" :
                                                    ""
                                                }
                                                    ${product.onDisplay === "Y" &&
                                                !isMobile && 'opened'
                                                }`
                                            }
                                            to={wishlist ? `${getLangPrefix()}${product.productUrl}` : `${path}/${product.canonicalUrl}`}
                                            onClick={!wishlist ? () => dataLayerClick(product, menuItem.label && menuItem.label) : () => { }}
                                        >
                                            <div className="product-item-img">
                                                <HeardIcon product={product} menuItem={this.props.menuItem} />

                                                <LazyImage
                                                    src={photoSrc}
                                                    alt="product-image"
                                                    placeholder={({ imageProps, ref }) => (
                                                        <img
                                                            ref={ref}
                                                            src={photoThumbnail}
                                                            alt={imageProps.alt}
                                                        />
                                                    )}
                                                    actual={({ imageProps }) => (
                                                        <img {...imageProps} alt={product.name} />
                                                    )}
                                                />
                                                {
                                                    product.listPriceAfterDiscount <= product.startingPrice &&
                                                    <DiscountDetail
                                                        product={product}
                                                        banner={banner}
                                                    />
                                                }
                                                {foundItem && foundItem.labelText && product.permanent && <PermanentSale text={foundItem.labelText} />}
                                                <div className="product-item-img-text">
                                                    <h2 className="product-title">{product.name}</h2>
                                                    <p className="product-categorye-name">
                                                        {
                                                            product.attributes &&
                                                            product.attributes.length &&
                                                            this.getProductCategoryName(product.attributes)
                                                        }
                                                    </p>
                                                    <p className="product-price">
                                                        {i18next.t('catalogue.product.3')}&nbsp;
                                                        {
                                                            (product.listPriceAfterDiscount) ?
                                                                <span>
                                                                    {HIDE_DISCOUNT === "0" ?
                                                                        <span className='old-price'> {replaceComma(product.startingPrice)}</span> :
                                                                        ""
                                                                    }
                                                                    <span className="new-price">
                                                                        {replaceComma(product.listPriceAfterDiscount)}
                                                                        {getCurrency(product.currency)}
                                                                    </span>
                                                                </span> :
                                                                <span className="new-price">
                                                                    {replaceComma(product.startingPrice)}
                                                                    {getCurrency(product.currency)}
                                                                </span>
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="info-box">
                                                <div className="front">
                                                    <h2 className="product-title">{product.name}</h2>
                                                    <p className="product-categorye-name">
                                                        {
                                                            product.attributes &&
                                                            product.attributes.length &&
                                                            this.getProductCategoryName(product.attributes)
                                                        }
                                                    </p>
                                                    <div className="info-box-lg-content">
                                                        <p className="text">
                                                            {
                                                                product.attributes &&
                                                                product.attributes.length &&
                                                                this.getProductCategoryName(product.attributes)
                                                            }
                                                        </p>
                                                        <p className="text multiline-ellipsis"
                                                            dangerouslySetInnerHTML={{ __html: (product.description && product.description.substring(0, 92)) + "..." }}>
                                                        </p>
                                                    </div>
                                                    <p className="product-price">{i18next.t('catalogue.product.3')}&nbsp;
                                                        {
                                                            (product.listPriceAfterDiscount) ?
                                                                <span>
                                                                    {
                                                                        HIDE_DISCOUNT === "0" &&
                                                                            product.listPriceAfterDiscount < product.startingPrice ?
                                                                            <span className='old-price'>
                                                                                {replaceComma(product.startingPrice)}
                                                                            </span> :
                                                                            ""
                                                                    }
                                                                    <span className="new-price">
                                                                        {replaceComma(product.listPriceAfterDiscount)}
                                                                        {getCurrency(product.currency)}
                                                                    </span>
                                                                </span> :
                                                                <span className="new-price">
                                                                    {replaceComma(product.startingPrice)}
                                                                    {getCurrency(product.currency)}
                                                                </span>
                                                        }
                                                    </p>
                                                    <div className="info-box-lg-content">
                                                        <p className="title-brd">{i18next.t("catalogue.product.4")}</p>
                                                        <span className="title-brd-image"></span>
                                                    </div>
                                                </div>
                                                <div className="back">
                                                    <p className="btt">{i18next.t("catalogue.product.5")}</p>
                                                </div>
                                            </div>
                                        </LinkFullUrl>
                                        {
                                            index === 6 &&
                                            visuals &&
                                            visuals.type === "CATALOGUE" &&
                                            (
                                                <div className={`product-item small product-content-sm-product`}>
                                                    <LinkFullUrl
                                                        to={`${LANGUAGE_PREFIX}${visuals && visuals.menuHref}`}
                                                        className=" advertising"
                                                    >
                                                        <img src={`${BASE_API_GATEWAY}${visuals && visuals.photo}`} alt="PHOTO" />
                                                    </LinkFullUrl>
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : (
                                    <Fragment key={product.productId}>
                                        <div
                                            className={
                                                `advertisingBox
                                                    ${productType === PRODUCT_TYPES.s ?
                                                    "fourColums" :
                                                    ""
                                                }
                                                    ${product.onDisplay == "Y" &&
                                                    !isMobile ?
                                                    `opened` :
                                                    ""
                                                }`
                                            }
                                        >
                                            <LinkFullUrl
                                                className={
                                                    `product-item
                                                        ${product.onDisplay == "Y" &&
                                                    !isMobile &&
                                                    `opened`
                                                    }`
                                                }
                                                to={wishlist ? `${getLangPrefix()}${product.productUrl}` : `${path}/${product.canonicalUrl}`}
                                                onClick={!wishlist ? () => dataLayerClick(product, menuItem.label && menuItem.label) : () => { }}
                                            >
                                                <div className="product-item-img">
                                                    <HeardIcon product={product} menuItem={this.props.menuItem} />
                                                    <LazyImage
                                                        src={`${BASE_API_GATEWAY}${product && product.photo}`}
                                                        alt="product-image"
                                                        placeholder={({ imageProps, ref }) => (
                                                            <img
                                                                ref={ref}
                                                                src={`${BASE_API_GATEWAY}${product.thumbnail}`}
                                                                alt={imageProps.alt}
                                                            />
                                                        )}
                                                        actual={({ imageProps }) => (
                                                            <img {...imageProps} alt={product.name} />
                                                        )}
                                                    />
                                                    {
                                                        product.listPriceAfterDiscount <= product.startingPrice &&
                                                        <DiscountDetail
                                                            product={product}
                                                            banner={banner}
                                                        />
                                                    }
                                                    <div className="product-item-img-text">
                                                        <h2 className="product-title">{product.name}</h2>
                                                        <p className="product-categorye-name">
                                                            {
                                                                product.attributes &&
                                                                product.attributes.length &&
                                                                this.getProductCategoryName(product.attributes)
                                                            }
                                                        </p>
                                                        <p className="product-price">
                                                            <span className='price-text'>{i18next.t('catalogue.product.3')}&nbsp;</span>
                                                            {
                                                                (product.listPriceAfterDiscount) ?
                                                                    <span>
                                                                        {
                                                                            HIDE_DISCOUNT === "0" &&
                                                                                product.listPriceAfterDiscount < product.startingPrice ?
                                                                                <span className='old-price'>
                                                                                    {replaceComma(product.startingPrice)}
                                                                                </span> :
                                                                                ""
                                                                        }
                                                                        <span className="new-price">
                                                                            {replaceComma(product.listPriceAfterDiscount)}
                                                                            {getCurrency(product.currency)}
                                                                        </span>
                                                                    </span> :
                                                                    <span>
                                                                        {replaceComma(product.startingPrice)}
                                                                        {getCurrency(product.currency)}
                                                                    </span>
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="info-box">
                                                    <div className="front">
                                                        <h2 className="product-title">{product.name}</h2>
                                                        <p className="product-categorye-name">
                                                            {
                                                                product.attributes &&
                                                                product.attributes.length &&
                                                                this.getProductCategoryName(product.attributes)
                                                            }
                                                        </p>
                                                        <div className="info-box-lg-content">
                                                            <p className="text">
                                                                {
                                                                    product.attributes &&
                                                                    product.attributes['General Material'] &&
                                                                    product.attributes['General Material'].length &&
                                                                    product.attributes['General Material'][0]
                                                                }
                                                            </p>
                                                            <p
                                                                className="text multiline-ellipsis"
                                                                dangerouslySetInnerHTML={{ __html: (product.description && product.description.substring(0, 92)) + "..." }}
                                                            ></p>
                                                        </div>
                                                        <p className="product-price">
                                                            <span className='price-text'>
                                                                {i18next.t('catalogue.product.3')}&nbsp;
                                                            </span>
                                                            {
                                                                (product.listPriceAfterDiscount) ?
                                                                    <span>
                                                                        {
                                                                            HIDE_DISCOUNT === "0" ?
                                                                                <span className='old-price'>
                                                                                    {replaceComma(product.startingPrice)}
                                                                                </span> :
                                                                                ""
                                                                        }
                                                                        <span className="new-price">
                                                                            {replaceComma(product.listPriceAfterDiscount)}
                                                                            {getCurrency(product.currency)}
                                                                        </span>
                                                                    </span> :
                                                                    <span className="new-price">
                                                                        {replaceComma(product.startingPrice)}
                                                                        {getCurrency(product.currency)}
                                                                    </span>
                                                            }
                                                        </p>
                                                        <div className="info-box-lg-content">
                                                            <p className="title-brd">{i18next.t("catalogue.product.4")}</p>
                                                            <span className="title-brd-image"></span>
                                                        </div>
                                                    </div>
                                                    <div className="back">
                                                        <p className="btt">{i18next.t("catalogue.product.5")}</p>
                                                    </div>
                                                </div>
                                            </LinkFullUrl>
                                        </div>
                                        {
                                            index === 4 &&
                                            visuals &&
                                            visuals.type === "CATALOGUE" &&
                                            (
                                                <div className={`advertisingBox`}>
                                                    <div className={`product-item product-style-two `}>
                                                        <LinkFullUrl
                                                            to={`${LANGUAGE_PREFIX}${visuals && visuals.menuHref}`}
                                                            className="advertising"
                                                        >
                                                            <img src={`${BASE_API_GATEWAY}${visuals && visuals.photo}`} alt="PHOTO" />
                                                        </LinkFullUrl>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </Fragment>
                                )
                            }
                            )
                        ) : resetFiltre ?
                            <div className="errorBox">
                                <h1 className="errorBox--Message">
                                    Aucun produit pour cette sélection
                                </h1>
                            </div> :
                            null
                    }
                    {
                        list &&
                            list.content &&
                            list.content.length &&
                            !noFilter ?
                            <div className="filter-content">
                                <button
                                    className='filter-btn'
                                    onClick={handleClick}
                                >
                                    {i18next.t("product.filter.title")}
                                </button>
                            </div> :
                            null
                    }
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    banner: getBanner(state),
    banners: getBanners(state),
    notFoundProduct: getNotFoundProduct(state)
});

export default connect(mapStateToProps)(withRouter(ProductList));
