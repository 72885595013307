import {createActions} from "reduxsauce";

export const {Types: retourType, Creators: RetourActionCreators} = createActions ({
    retourReasonStart: ["data"],
    retourReasonSuccess:["data"],
    retourReasonFailure: ["data"],
    retourTicketStart:["data"],
    retourTicketSuccess:["data"],
    retourTicketFailure:["data"],
    storeLocationStart: ['data'],
});