import React, { Component } from 'react';
import { connect } from "react-redux";

import CurrentPage from '../../../layouts/common/currentPage';
import { HomeActionCreators } from "../../home/redux/actions";
import { BASE_API_GATEWAY } from "../../../utils/constants/service";
//styles
import "./notre.scss";
import { getMenu } from "../../../layouts/main/header/redux/selectors";
import { getHome } from "../../home/redux/selectors";
import { closeRedonner, getDataBySectionAndWidget, getLangPrefix, toggleRedonner } from "../../../helpers/helpers";
import ImgBlob from "../../home/container/components/imgBlob";

interface Props {
    canonicalUrl?: string,
    history?: any,
    location?: any,
    menuList?: any,
    menuItem?: any,
    data?: any,

}

interface State {
    url: any,
    found: any,
    defaulteImgLink: string,
}

class Nore extends Component<Props & typeof HomeActionCreators, State> {
    state: State = {
        url: '/#',
        found: {},
        defaulteImgLink: "/productservice/api/files/download/32818",
    };

    componentDidMount() {
        const { menuItem } = this.props;

        if (menuItem && menuItem.gridId) {
            this.props.dispatch(HomeActionCreators.homeStart(menuItem.gridId))
        }
    }

    toMenuHref(item) {
        if (item.menuHref) {
            this.props.history.push(`${getLangPrefix()}${item.menuHref}`)
        }
    }

    getRedonnerGrid() {
        const { data } = this.props;
        return data.find((grid) => grid.type === "TEXT" && grid.name && grid.name.toLowerCase() === "redonner");
    }

    handleOpenRedonner() {
        toggleRedonner();
    }

    componentWillUnmount() {
        closeRedonner();
    }

    render() {
        const { menuItem, data } = this.props;
        const redonnerGrid = this.getRedonnerGrid();
        const photo = data && data.filter(item => item.type === "PHOTO" && item.section !== 1 || (item.type === "TEXT" && `${item.name}`.toLowerCase() === "redonner"));
        const baseWidget = getDataBySectionAndWidget(1, 1, data, "PHOTO");
        return (
            <>
                <CurrentPage selectedItem={menuItem} />
                <section className="container notreContent">
                    <div className="notreContent--header">
                        <div className="notreContent--header--imgBox" onClick={() => this.toMenuHref(baseWidget)}>
                            {
                                baseWidget.value ? <ImgBlob
                                    src={`${BASE_API_GATEWAY}${baseWidget && baseWidget.value}`}
                                    alt={baseWidget.altText || "img"}
                                    ElemclassName={baseWidget ? 'c-pointer' : ''}
                                /> : ''
                            }
                        </div>
                        <div className="notreContent--header--textBox">
                            <h2 className="notreContent--header--textBox--title"><p
                                dangerouslySetInnerHTML={{ __html: data ? getDataBySectionAndWidget(1, 2, data, "TEXT").value : '' }} />
                            </h2>
                            <div className="notreContent--header--textBox--description">
                                <p dangerouslySetInnerHTML={{ __html: data ? getDataBySectionAndWidget(1, 3, data, "TEXT").value : '' }} />
                            </div>
                        </div>
                    </div>
                    {
                        photo && photo.length ? photo.map((item, index) => (
                            <React.Fragment key={index}>
                                {`${item.name}`.toLowerCase() === "redonner" ? (
                                    <div className="notreContent--boxes d-center">
                                        <button
                                            className='redonner-button hoverButtonStyleGreen'
                                            dangerouslySetInnerHTML={{ __html: redonnerGrid && redonnerGrid.value || '' }}
                                            onClick={this.handleOpenRedonner}

                                        />
                                    </div>
                                ) : (
                                    <div className={`notreContent--boxes ${index % 2 !== 0 && "revers"}`}>
                                        <div className="notreContent--boxes--half">
                                            <div
                                                className="notreContent--boxes--half--imgBox"
                                                onClick={() => this.toMenuHref(item)}
                                            >
                                                {
                                                    data && Object.keys(data).length ? <ImgBlob
                                                        src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(item.section, 1, data, "PHOTO").value && getDataBySectionAndWidget(item.section, 1, data, "PHOTO").value}`}
                                                        alt={getDataBySectionAndWidget(item.section, 1, data, "PHOTO").altText || "img"}
                                                        ElemclassName={item.menuHref ? 'c-pointer' : ''} /> : ''
                                                }

                                            </div>
                                        </div>
                                        <div className="notreContent--boxes--half">
                                            <div className="notreContent--boxes--half--textBox">
                                                {
                                                    <>
                                                        <h2 className="notreContent--boxes--half--textBox--title"><p
                                                            dangerouslySetInnerHTML={{ __html: data ? getDataBySectionAndWidget(item.section, 2, data, "TEXT").value : '' }} />
                                                        </h2>
                                                        <div className="notreContent--boxes--half--textBox--description">
                                                            <p dangerouslySetInnerHTML={{ __html: data ? getDataBySectionAndWidget(item.section, 3, data, "TEXT").value : '' }} />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        ))
                            : ""

                    }
                </section>
            </>
        )
    }
}

const mapStateToProps = (State: any): Props => ({
    menuList: getMenu(State),
    data: getHome(State),
});

export default connect(mapStateToProps)(Nore);