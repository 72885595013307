import React, { CSSProperties, useEffect, useRef, useState } from "react";
import './promotionBanner.scss'
import { getBanners } from "../../../modules/home/redux/selectors";
import {connect} from "react-redux"
import { getMenu } from "../header/redux/selectors";
import { getLangPrefix, getMenuItemByMenuIdElem } from "../../../helpers/helpers";
import { withRouter } from "react-router-dom";
interface BannerCSS extends CSSProperties {
    "--banerStart": string
}

interface BannersCSS extends CSSProperties {
    "--bannersDelay": string,
    "--slideUp": string,
    "--dynamicHeight": string
}

interface Props {
    banners?: Record<string, any>[],
    menuList?: any
}

const PromotionBanner = ({ promotionEnd, banners, banner, history, menuList }) => {
    const calculateTimeLeft = () => {
        const now = new Date().getTime() + new Date().getTimezoneOffset() * 60000;
        const utc = new Date(promotionEnd);
        const difference = +utc - +now;
        let timeLeft: any = {};
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24) < 10 ? '0' + Math.floor((difference / (1000 * 60 * 60)) % 24) : Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60) < 10 ? '0' + Math.floor((difference / 1000 / 60) % 60) : Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60) < 10 ? '0' + Math.floor((difference / 1000) % 60) : Math.floor((difference / 1000) % 60)
            };
        }
        return timeLeft;
    };
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const bannersRef = useRef<HTMLDivElement | null>(null);
    const [height, setHeight] = useState<number>(0)
    // useEffect(() => {
    //     let timer = setTimeout(() => {
    //         setTimeLeft(calculateTimeLeft());
    //     }, 1000);

    //     return () => {
    //         clearTimeout(timer)
    //     }
    // });

    useEffect(() => {
        if (bannersRef.current && bannersRef.current.clientHeight > 0) {
            setHeight(bannersRef.current.clientHeight)
        }
    }, [banner])

    const items = [
        ...banner.BANNER1 ? [{
            labelType: 'BANNER1',
            value: banner.BANNER1
        }] : [],
        ...banner.BANNER2 ? [{
            labelType: 'BANNER2',
            value: banner.BANNER2
        }] : [],
        ...banner.BANNER3 ? [{
            labelType: 'BANNER3',
            value: banner.BANNER3
        }] : [],
    ]

    if (!banner) {
        return null;
    }

    const bannersStyle: BannersCSS = {
        "--bannersDelay": `${(items.length * 4) + 2.5}s`,
        "--slideUp": `slide-up-${items.length}`,
        "--dynamicHeight": `${height > 0 ? `${height}px` : '100%'}`
    };

    const navigateBanner = (item: Record<string, any>) => {
        if (!item || !item.labelType) return;
        const foundItem = banners.find(b => b.labelType === item.labelType);
        if (!foundItem || !foundItem.labelMenuId) return
        const menuFoundItem = getMenuItemByMenuIdElem(menuList, foundItem.labelMenuId)
        if (!menuFoundItem) return;
        history.push(`${getLangPrefix()}${menuFoundItem.canonicalUrl}`);
    }

    const foundItem = banners && items && items.length === 1 ? banners.find(b => b.labelType === items[0].labelType) : null

    return (
        <>
            <div className="promotion" ref={bannersRef}>
                {/* {
                        banner.BANNER3 && Object.keys(timeLeft).length > 0 ?

                            <div className="promotionTimer">
                                {
                                    timeLeft.days ?
                                        <div>
                                            <span>{`${i18next.t("Timer.1")}`} {timeLeft.days}  {timeLeft.days > 1 ? `${i18next.t("Timer.2")}` : `${i18next.t("Timer.3")}`}  </span>
                                        </div>
                                        :
                                        <div>
                                            <h2 className="promotion-baner-2">{banner.BANNER3}</h2>
                                            <span className="promotion-banner-timer promotion-baner-2 ">{`${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`}</span>
                                        </div>
                                }
                            </div>
                            : ""
                    } */}
                <div className="promotion-banners" style={{
                    height: `${height > 0 ? `${height}px` : '100%'}`,
                    maxHeight: `${height > 0 ? `${height}px` : '100%'}`,
                }}>
                    <div className="promotion-banners-marquee" style={{
                        ...bannersStyle,
                        opacity: items.length > 1 ? 0 : 1
                    }}>
                        {items.length > 1
                            ? items.map((item, idx) => {
                                const style: BannerCSS = { "--banerStart": `${idx * 4}s` };
                                const foundItemC = banners.find(b => b.labelType === item.labelType);
                                return <div key={idx} onClick={() => navigateBanner(item)} style={{
                                    ...style,
                                    cursor: `${foundItemC && foundItemC.labelMenuId ? 'pointer' : 'default'}`,
                                    height: `${height > 0 ? `${height}px` : '100%'}`,
                                    minHeight: `${height > 0 ? `${height}px` : '100%'}`,
                                    maxHeight: `${height > 0 ? `${height}px` : '100%'}`,
                                }} className="promotion-baner">{item.value}</div>
                            })
                            : items[0] ? <div
                                style={{
                                    cursor: `${foundItem && foundItem.labelMenuId ? 'pointer' : 'default'}`,
                                }}
                                className="promotion-baner"
                                onClick={() => navigateBanner(items[0])}>{items[0].value}</div> : null
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: any): Props => ({
    banners: getBanners(state),
    menuList: getMenu(state),
});

export default connect(mapStateToProps, null)(withRouter(PromotionBanner as any));