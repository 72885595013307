import React, { Component } from 'react';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom'
import { connect } from "react-redux";

import CurrentPage from '../../../layouts/common/currentPage';
import { checkNoExistUrl, getCurrency, getItemMenu, getLangPrefix, replaceComma } from "../../../helpers/helpers";
import { getBanner, getHome } from "../../home/redux/selectors";
import { getLanguage, getMenu } from "../../../layouts/main/header/redux/selectors";
import { getOrEtArgent } from "./../redux/selectors";
import { getDataBySectionAndWidget } from "./../../../helpers/helpers";
import { HomeActionCreators } from "../../home/redux/actions";
import { OrEtArgentActionCreators } from "../redux/actions";
import { BASE_API_GATEWAY } from "../../../utils/constants/service";
import i18next from "i18next";
//styles
import "./orEtArgent.scss";
import ImgBlob from "../../home/container/components/imgBlob";
import { HIDE_DISCOUNT, CATALOGUE_TYPE } from "../../../utils/constants/variables";
import DiscountDetail from '../../common/product/discountDetail';

interface Props {
    canonicalUrl?: string,
    history?: any,
    location?: any,
    menuList?: any,
    menuItem?: any,
    data?: any,
    oretargetList?: any,
    language?: string,
    banner?: any
}

interface State {
    url: any,
    found: any,
}

class OrEtArgent extends Component<Props & typeof HomeActionCreators, typeof OrEtArgentActionCreators, State> {
    state: State = {
        url: '/#',
        found: {},
    };

    componentDidMount() {
        const { location, menuItem, canonicalUrl } = this.props;
        if (canonicalUrl && location.pathname !== canonicalUrl) {
            this.props.history.push(canonicalUrl)
        }
        if (menuItem && menuItem.gridId) {
            this.getCanonicalUrl(menuItem.key);
            this.props.dispatch(HomeActionCreators.homeStart(menuItem.gridId))
        }
        this.props.dispatch(OrEtArgentActionCreators.orEtArgentStart(menuItem));
    }

    componentDidUpdate(prevProps, prevState) {
        const { menuItem, language } = this.props;
        if (prevProps.menuItem && menuItem && (prevProps.menuItem.gridId !== menuItem.gridId || language !== prevProps.language)) {
            this.props.dispatch(HomeActionCreators.homeStart(menuItem.gridId))
            this.props.dispatch(OrEtArgentActionCreators.orEtArgentStart(menuItem));
        }
    }

    getCanonicalUrl = (key) => {
        const { menuList } = this.props;
        const item = getItemMenu(CATALOGUE_TYPE, key, menuList);
        if (item && typeof item === 'object') {
            if (item.menuType.toUpperCase() === CATALOGUE_TYPE) {
                this.setState({ url: item.canonicalUrl })
            } else {
                this.setState({ url: `${this.props.location.pathname.substr(3)}/catalogue` })
            }
        }
    };
    getProductCategoryName = (item) => {
        const found = item.find(element => element.searchFilter === 'Collection');
        const categoryName = found ? found.values[0].value : '';
        return categoryName;
    };

    render() {
        const { url } = this.state;
        const { data, menuItem, oretargetList, banner } = this.props;
        let text = data.filter(i => i.type === "TEXT");
        const productList = oretargetList && Object.keys(oretargetList).length && oretargetList.catalogue && oretargetList.catalogue.content && oretargetList.catalogue.content.length && oretargetList.catalogue.content.filter(item => item.productType === "S")
            .sort((a, b) => a.ranking - b.ranking);
        const photoS1W1 = getDataBySectionAndWidget(1, 1, data, "PHOTO");

        return (
            <div>
                <CurrentPage selectedItem={menuItem} />
                <section className="">
                    <div className="container">
                        <div className="page-inner-images">
                            <div className="coverBox">
                                {
                                    <div className="large-img-box">
                                        {
                                            photoS1W1.value ?
                                                <ImgBlob src={`${BASE_API_GATEWAY}${photoS1W1.value}`}
                                                    alt={photoS1W1.altText || "PHOTO"} /> : ""
                                        }
                                    </div>

                                }
                                <div className="title">
                                    {
                                        <p dangerouslySetInnerHTML={{ __html: getDataBySectionAndWidget(1, 2, data, "TEXT").value }} />
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="collection-text">
                            {

                                <p dangerouslySetInnerHTML={{ __html: getDataBySectionAndWidget(1, 3, data, "TEXT").value }} />

                            }
                        </div>
                    </div>
                </section>

                <section className="oret-products ">
                    <div className="oret-products--header container">
                        <div className="oret-products--header--title">
                            <p dangerouslySetInnerHTML={{ __html: getDataBySectionAndWidget(2, 1, data, "TEXT").value }} />
                        </div>
                    </div>
                    <div className="container">
                        {
                            text && text.length ? text.map((item, index) => {
                                return item.section === "2" ?
                                    <p key={index} dangerouslySetInnerHTML={{ __html: item.value }} /> : ""
                            }) : ""
                        }

                        <div className="oret-products-content">
                            {
                                <div className="oret-products-big-img" >
                                    <Link
                                        to={`${getLangPrefix()}${getDataBySectionAndWidget(2, 2, data, "PHOTO").menuHref}`} className={checkNoExistUrl(`${getLangPrefix()}${getDataBySectionAndWidget(2, 3, data, "PHOTO").menuHref}`) ? 'non-clickable' : ''}>
                                        <ImgBlob
                                            src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(2, 2, data, "PHOTO").value}`}
                                            alt="oret products content image" />
                                    </Link>
                                </div>
                            }


                            {
                                oretargetList && Object.keys(oretargetList).length ?
                                    <div className="oret-product-item-content">
                                        {
                                            productList && productList.slice(0, 4).map((item) => (
                                                <div className={`product-item product-style-two oret-product-item `} key={item.productId}>
                                                    <Link
                                                        to={`/${item.language.slice(0, 2)}${item.menuCanonicalUrl}/${item.canonicalUrl}`}>
                                                        <div className="product-item-img oret-product-item-img">
                                                            <ImgBlob src={`${BASE_API_GATEWAY}${item && item.photo}`}
                                                                alt="Product image" />

                                                            <DiscountDetail product={item} banner={banner} />

                                                            <div className="product-item-img-text oret-product-item--img-text">
                                                                <h2 className="product-title">{item.name}</h2>
                                                                <p className="product-categorye-name">
                                                                    {
                                                                        item.attributes &&
                                                                        item.attributes.length &&
                                                                        this.getProductCategoryName(item.attributes)
                                                                    }
                                                                </p>
                                                                <p className="product-price">
                                                                    <span
                                                                        className='price-text'>{i18next.t('catalogue.product.3')} </span>
                                                                    {
                                                                        (item.listPriceAfterDiscount) ?
                                                                            <span>
                                                                                {
                                                                                    HIDE_DISCOUNT === "0" ?
                                                                                        <span
                                                                                            className='old-price'> {replaceComma(item.startingPrice)}</span> : ""
                                                                                }
                                                                                <span className='new-price'>{replaceComma(item.listPriceAfterDiscount)} {getCurrency(item.currency)}</span></span> :
                                                                            <span className="new-price">{replaceComma(item.startingPrice)} {getCurrency(item.currency)}</span>
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="info-box">
                                                            <div className="front">
                                                                <h2 className="oret-product-item-title">{item.name}</h2>
                                                                <p className="product-categorye-name">
                                                                    {
                                                                        item.attributes &&
                                                                        item.attributes.length &&
                                                                        this.getProductCategoryName(item.attributes)
                                                                    }
                                                                </p>
                                                                <div className="info-box-lg-content">
                                                                    <p className="text">
                                                                        {
                                                                            item.attributes &&
                                                                            item.attributes['General Material'] &&
                                                                            item.attributes['General Material'].length &&
                                                                            item.attributes['General Material'][0]
                                                                        }
                                                                    </p>
                                                                    <p className="text multiline-ellipsis"
                                                                        dangerouslySetInnerHTML={{ __html: item.description }}>
                                                                    </p>
                                                                </div>
                                                                <p className="product-price">
                                                                    <span
                                                                        className='price-text'>{i18next.t('catalogue.product.3')} </span>
                                                                    {
                                                                        (item.listPriceAfterDiscount) ?
                                                                            <span>
                                                                                {
                                                                                    HIDE_DISCOUNT === "0" ?
                                                                                        <span
                                                                                            className='old-price oret-product-item--old-price'> {replaceComma(item.startingPrice)}</span> : ""
                                                                                }
                                                                                <span className='new-price oret-product-item--new-price'> {replaceComma(item.listPriceAfterDiscount)} {getCurrency(item.currency)}</span></span> :
                                                                            <span className="new-price oret-product-item--new-price"> {replaceComma(item.startingPrice)} {getCurrency(item.currency)}</span>
                                                                    }
                                                                </p>
                                                                <div className="info-box-lg-content">
                                                                    <p className="title-brd">{i18next.t("catalogue.product.4")}</p>
                                                                    <span className="title-brd-image"></span>
                                                                </div>
                                                            </div>
                                                            <div className="back">
                                                                <p className="link">{i18next.t('home.collection.discover')}</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))
                                        }
                                    </div> : ""
                            }


                        </div>

                        <p dangerouslySetInnerHTML={{ __html: getDataBySectionAndWidget(2, 2, data, "TEXT").value }} />
                        <div className="oret-products-content">
                            {
                                <div className="oret-products-big-img" >
                                    <Link
                                        to={`${getLangPrefix()}${getDataBySectionAndWidget(2, 3, data, "PHOTO").menuHref}`} className={checkNoExistUrl(`${getLangPrefix()}${getDataBySectionAndWidget(2, 3, data, "PHOTO").menuHref}`) ? 'non-clickable' : ''}>
                                        <ImgBlob
                                            src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(2, 3, data, "PHOTO").value}`}
                                            alt="oret products content image" />
                                    </Link>
                                </div>

                            }


                            {
                                oretargetList && Object.keys(oretargetList).length ?
                                    <div className="oret-product-item-content">
                                        {
                                            productList && productList.slice(4, 8).map(item => (
                                                <div className={`product-item product-style-two oret-product-item `} key={item.productId}>
                                                    <Link
                                                        to={`/${item.language.slice(0, 2)}${item.menuCanonicalUrl}/${item.canonicalUrl}`}>
                                                        <div className="product-item-img oret-product-item-img">
                                                            <ImgBlob src={`${BASE_API_GATEWAY}${item && item.photo}`}
                                                                alt="Product image" />

                                                            <DiscountDetail product={item} banner={banner} />

                                                            <div className="product-item-img-text oret-product-item--img-text">
                                                                <h2 className="product-title">{item.name}</h2>
                                                                <p className="product-categorye-name">
                                                                    {
                                                                        item.attributes &&
                                                                        item.attributes.length &&
                                                                        this.getProductCategoryName(item.attributes)
                                                                    }
                                                                </p>
                                                                <p className="product-price">
                                                                    <span
                                                                        className='price-text'>{i18next.t('catalogue.product.3')} </span>
                                                                    {
                                                                        (item.listPriceAfterDiscount) ?
                                                                            <span>
                                                                                {
                                                                                    HIDE_DISCOUNT === "0" ?
                                                                                        <span
                                                                                            className='old-price'> {replaceComma(item.startingPrice)}</span> : ""
                                                                                }
                                                                                <span className='new-price'>{replaceComma(item.listPriceAfterDiscount)} {getCurrency(item.currency)}</span></span> :
                                                                            <span className="new-price">{replaceComma(item.startingPrice)} {getCurrency(item.currency)}</span>
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="info-box">
                                                            <div className="front">
                                                                <h2 className="oret-product-item-title">{item.name}</h2>
                                                                <p className="product-categorye-name">
                                                                    {
                                                                        item.attributes &&
                                                                        item.attributes.length &&
                                                                        this.getProductCategoryName(item.attributes)
                                                                    }
                                                                </p>
                                                                <div className="info-box-lg-content">
                                                                    <p className="text">
                                                                        {
                                                                            item.attributes &&
                                                                            item.attributes['General Material'] &&
                                                                            item.attributes['General Material'].length &&
                                                                            item.attributes['General Material'][0]
                                                                        }
                                                                    </p>
                                                                    <p className="text multiline-ellipsis"
                                                                        dangerouslySetInnerHTML={{ __html: item.description }}>
                                                                    </p>
                                                                </div>
                                                                <p className="product-price">
                                                                    <span
                                                                        className='price-text'>{i18next.t('catalogue.product.3')} </span>
                                                                    {
                                                                        (item.listPriceAfterDiscount) ?
                                                                            <span>
                                                                                {
                                                                                    HIDE_DISCOUNT === "0" ?
                                                                                        <span
                                                                                            className='old-price oret-product-item--old-price'> {replaceComma(item.startingPrice)}</span> : ""
                                                                                }
                                                                                <span className='new-price oret-product-item--new-price'> {replaceComma(item.listPriceAfterDiscount)} {getCurrency(item.currency)}</span></span> :
                                                                            <span className="new-price oret-product-item--new-price"> {replaceComma(item.startingPrice)} {getCurrency(item.currency)}</span>
                                                                    }
                                                                </p>
                                                                <div className="info-box-lg-content">
                                                                    <p className="title-brd">{i18next.t("catalogue.product.4")}</p>
                                                                    <span className="title-brd-image"></span>
                                                                </div>
                                                            </div>
                                                            <div className="back">
                                                                <p className="link">{i18next.t('home.collection.discover')}</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))
                                        }
                                    </div> : ""
                            }


                        </div>

                        <p className="oret-text" dangerouslySetInnerHTML={{ __html: getDataBySectionAndWidget(2, 3, data, "TEXT").value }} />

                        <Link
                            to={`${getLangPrefix()}${url}`}
                            className="main-btn hoverButtonStyle"
                        >
                            {i18next.t('orEtArgent.2')}
                        </Link>
                    </div>


                </section>
            </div>
        )
    }
}

const mapStateToProps = (collectionState: any): Props => ({
    data: getHome(collectionState),
    menuList: getMenu(collectionState),
    oretargetList: getOrEtArgent(collectionState),
    language: getLanguage(collectionState),
    banner: getBanner(collectionState),
});

export default connect(mapStateToProps)(withRouter(OrEtArgent));
