import React, { Fragment, useEffect, useState } from "react";
import ProductList from "../../products/container/productList";
import { getProducts } from "../../products/redux/selectors";
import { connect } from "react-redux";
import { useStorageEffect } from "../../../shared/hooks/useLocalstorageEffect";
import i18next from "i18next";

import './index.scss';
import { WichListActionCreators } from "../redux/actions";
import { bindActionCreators } from "redux";
import CommonCurrentPage from "../../../layouts/common/commonCurrentPage";

const listenStorage = useStorageEffect("localStorage");

const Wishlist = (props) => {
    const { wichListUpdate, menuItem } = props;
    const [wichList, setWichList] = useState([]);

    useEffect(() => {
        const wichListInStorage = localStorage.getItem('wishlist');
        if (wichListInStorage) {
            setWichList(JSON.parse(wichListInStorage));
        };

        listenStorage('wishlist', (event) => {
            const { value } = event;
            setWichList(value);
        });
    }, []);

    useEffect(() => {
        wichListUpdate(wichList);
    }, [wichList]);

    if (!wichList.length) {
        return <h1 className="notFavoriteProductText">{i18next.t('product.product.25')}</h1>
    };


    return (
        <Fragment>
            <CommonCurrentPage
                navTabs={[]}
                selectedMenuItem={{ label: menuItem.title }}
                connectItem={{ description: menuItem.description }}
                showDescription={'wishlist'}
                productData={null}
                showPageMetaData={null}
            />
            <section className="product-section housse-container flex-column product-section-wishlist">
                <div className="product-content-outer">
                    <div className="infinite-scroll-component ">
                        <ProductList
                            list={{ content: wichList }}
                            wishlist={true}
                            noFilter={true}
                        />
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    products: getProducts(state),
});
const mapDispatchToProps = (dispatch): typeof WichListActionCreators => {
    return bindActionCreators(WichListActionCreators, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
