import {createReducer} from 'reduxsauce';
import {ShopperTypes} from "./actions";

export interface IShopperState {
    shopperSendResponse: any,
    status: string,
}

export const shopperContent: IShopperState = {
    shopperSendResponse:{},
    status:""

};
export const shopperSendStart = (state) => {
    return {...state,contactUsSendResponse: {}, status: ""};
};

export const shopperSendSuccess = (state, action) => {
    return {...state, shopperSendResponse: action.data, status: "success"};
};

export const shopperSendFailure = (state, action) => {
    return {...state, error: action.error, shopperSendResponse: {}, status: "filed"};
};

export const resetShopperStatus = (state) => {
    return {...state,contactUsSendResponse: {}, status: ""};
};



export const handlers = {
    [ShopperTypes.SHOPPER_SEND_START]: shopperSendStart,
    [ShopperTypes.SHOPPER_SEND_SUCCESS]: shopperSendSuccess,
    [ShopperTypes.SHOPPER_SEND_FAILURE]: shopperSendFailure,
    [ShopperTypes.RESET_SHOPPER_STATUS]: resetShopperStatus,
};

export default createReducer(shopperContent, handlers);
