import React, { useState } from "react"
import hard from '../../../assets/img/heard.png';
import hardDark from '../../../assets/img/heard-dark.png';
import { WichListActionCreators } from "../../Wishlist/redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { TrackAddToWishlist } from "../../../helpers/ga4Tracker";
import { getConvertedItemDataForGA4 } from "../../../helpers/helpers";

const HeardIcon = (props) => {
    const { product, wichListUpdate, menuItem = {} } = props;
    const storageWishlist = localStorage.getItem('wishlist');
    const wishlist = storageWishlist ? JSON.parse(storageWishlist) : [];
    const findCheckedProduct = wishlist.find(item => item.productId === product.productId);

    const [heard, setHeard] = useState<'heard' | 'hardDark'>(findCheckedProduct ? 'hardDark' : 'heard');

    const getProductUrl = (product) => {
        const path = window.location.pathname.replace(/^\/[a-z]{2}/, '');
        const isFullPath = path.includes(product.canonicalUrl)
        return isFullPath ? path : `${path}/${product.canonicalUrl}`;
    }

    const clickHeard = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (product && (product.brandId || product.brand) && product.canonicalUrl) {
            const storageWishlist = localStorage.getItem('wishlist');
            let wishlist = storageWishlist ? JSON.parse(storageWishlist) : [];
            const { productId } = product;

            if (heard === 'heard') {
                product.productUrl = getProductUrl(product);
                wishlist.push(product);
                localStorage.setItem('wishlist', JSON.stringify(wishlist));
                setHeard('hardDark');
                if (wishlist && wishlist.length) {
                    const dataT = getConvertedItemDataForGA4(menuItem, product, 1);
                    const value = dataT.price
                    TrackAddToWishlist(dataT, value)
                } else {
                    TrackAddToWishlist({} as any, 0)
                }
            } else {
                wishlist = wishlist.filter(wishlistItem => {
                    return wishlistItem.productId !== productId;
                });

                if (wishlist.length) {
                    localStorage.setItem('wishlist', JSON.stringify(wishlist));
                } else {
                    localStorage.setItem('wishlist', JSON.stringify(wishlist));
                    localStorage.removeItem('wishlist');
                };
                setHeard('heard');
            };

            wichListUpdate(wishlist);
        };
    };

    return (
        <img
            src={heard === 'heard' ? hard : hardDark}
            className="product-item-img-hard heard-icon"
            onClick={clickHeard}
        />
    );
};


const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch): typeof WichListActionCreators => {
    return bindActionCreators(WichListActionCreators, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(HeardIcon);
