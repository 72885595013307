import {createReducer} from 'reduxsauce';
import {SearchingTypes} from './actions';

export interface ISearchingState {
    searching: any,
    searchingInput: any,
    error: string,
    loading: boolean,
    loadMoreData: boolean,
    criteria?:any,
}

export const SearchingR: ISearchingState = {
    searching: {},
    searchingInput: {},
    error: "",
    loading: false,
    loadMoreData: false,
    criteria: "",
};

export const emptySearchResult = (state) => {
    return {...state, searchingInput: {}, loading: false };
}

export const searching = (state) => {
    return {...state, loading: true };
};

export const searchingSuccess = (state, action) => {
    if (action.data.size > 5) {
        const loadMoreData = state.searching.products
                ? action.data.data.products && (action.data.data.products.totalElements >  state.searching.products.content.length)
                : true;

        if (action.data.criteria == state.criteria ) {
            action.data.data.products.content = [...state.searching.products.content, ...action.data.data.products.content];
            state.criteria=action.data.criteria ;
        } else {
            state.searching.products = {};
            state.criteria=action.data.criteria ;
        }
        return {...state, searching: action.data.data, loading: false, loadMoreData};
    }
    else {
        return {...state, searchingInput: action.data.data, loading: false, loadMoreData: false};
    }

};

export const searchingFailure = (state, action) => {
    return {...state, error: action.error, searching: {}, loading: false, loadMoreData: false};
};



export const handlers = {
    [SearchingTypes.SEARCHING_START]: searching,
    [SearchingTypes.SEARCHING_SUCCESS]: searchingSuccess,
    [SearchingTypes.SEARCHING_FAILURE]: searchingFailure,
    [SearchingTypes.EMPTY_SEARCHING_START]: emptySearchResult,
};

export default createReducer(SearchingR, handlers);