import React from 'react';
import { connect } from 'react-redux';
import { getMenu } from "../../../layouts/main/header/redux/selectors";
import './planDuSite.scss';

import CurrentPage from "../../../layouts/common/currentPage";
import { getRouteByMenuKey } from "../../../helpers/helpers";
import { Link } from "react-router-dom";


interface Props {
    menuList: any,
    menuItem?: any,
}


const PlanDuSite = ({ menuList, menuItem }: Props) => {

    const getMenuList = (items) => {
        return (
            items.length && items.map(item => {
                const url = getRouteByMenuKey(items, item.key).route;
                return (
                    <li key={item.menuId} className="item-nav">
                        {url ?
                            <Link to={url} >
                                {item.label}
                            </Link>
                            :
                            <span>{item && item.label}</span>
                        }
                        {item.children && Array.isArray(item.children) ?
                            <ul className="children">{getMenuList(item.children)}</ul> : ""}
                    </li>
                )
            })
        )
    };

    const categories = menuList && Array.isArray(menuList) ? menuList.filter(item => item.menuType && item.menuType.toUpperCase() === "MENU") : [];
    const pages = menuList && Array.isArray(menuList) ? menuList.filter(item => item.menuType && item.menuType.toUpperCase() === "PAGE") : [];
    const catalogue = menuList && Array.isArray(menuList) ? menuList.filter(item => item.menuType && item.menuType.toUpperCase() === "CATALOGUE") : [];
    return (
        <div>
            {
                Object.entries(menuItem).length ?
                    <CurrentPage selectedItem={menuItem} showPageMetaData={false} /> : ""
            }
            <div className="container">
                <div className="plan-du-site-page">
                    <div className="site-menu">
                        <h4>categories</h4>
                        <ul className="parent">
                            {getMenuList(categories)}
                        </ul>
                    </div>
                    <div className="site-menu">
                        <h4>pages</h4>
                        <ul className="parent">
                            {getMenuList(pages)}
                            {getMenuList(catalogue)}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state: any): Props => ({
    menuList: getMenu(state),
});

export default connect(mapStateToProps)(PlanDuSite);
