import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import i18next from "i18next";
import { Form, TextArea, Button, Select } from 'semantic-ui-react'
import "./RetournerUnArticleMess.scss";
import { BRAND_ID, STORE_DELIVERY, COUNTRY, STORE_DEPOT, ORDER_STATUS, STORE_RETURN } from "./../../../../../../utils/constants/variables"
import MesRetoursTwo from "../../mesRetoursTwo/container";
import { getRetour, getTicketRetour } from "../../../../redux/selectors";
import { RetourActionCreators } from "../../../../redux/actions";
import { getApiPrefix } from "../../../../../../helpers/helpers"
import ChooseNearestStore from "../../../../../location/container/chooseNearestStore";
import { getUserStore } from "../../../../../location/redux/selectors";

interface Props {
    content: {},
    ticket: {},
    products?: object,
    orderId?: any,
    orderLineItems?: any[];
    userStore: any;
    orderStatus?: any
}

interface State {
    found?: any,
    showReturns: boolean,
    textValue: string,
    selectorValue: any,
    storeId: number
}

class RetournerUnArticleMess extends Component<Props & typeof RetourActionCreators> {
    state: State = {
        showReturns: false,
        textValue: "",
        selectorValue: 0,
        storeId: 0
    };

    componentDidMount() {
        this.props.retourReasonStart();
        this.props.storeLocationStart({});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.ticket && prevProps.ticket && this.props.ticket.id !== prevProps.ticket.id) {
            this.setState({ showReturns: true })
        }
        if (STORE_DELIVERY === "1" && this.props.userStore.storeId && this.props.userStore.storeId !== prevProps.userStore.storeId) {
            this.setState({ storeId: this.props.userStore.storeId })
        }
    }

    handleSubmit = () => {
        const { orderId, products, orderLineItems } = this.props;
        const { textValue, selectorValue, storeId } = this.state;
        const webOrderLineItems = products.length
            ? products
            : orderLineItems.map((item) => ({ productId: item.productId, quantity: item.orderQty }))

        const data = {
            "brandId": BRAND_ID,
            "orderId": orderId,
            "ticketMessage": textValue,
            "country": COUNTRY,
            "languageId": getApiPrefix(),
            "ticketSubjectCode": "RET",
            "ticketMessageFrom": "C",
            "returnReasonId": selectorValue,
            "storeId": storeId,
            "webOrderLineItems": webOrderLineItems
        };
        this.props.retourTicketStart(data);
    };

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value })
    };

    render() {
        const { orderStatus } = this.props;
        const { showReturns, textValue, selectorValue } = this.state;
        const { content } = this.props;
        let options: object[] = [];
        content && content.content.returnReasonList && Object.keys(content.content.returnReasonList).length && content.content.returnReasonList.map(item => {
            options.push({ key: item.id, text: item.reason, value: item.id })
        });

        return (
            <>
                {!showReturns ?
                    <div className="return-page-wrapper">
                        <div className="container dflex">
                            <div className="return-page-form">
                                <Form className="page-content-form" onSubmit={this.handleSubmit}>
                                    <Form.Group widths='equal'>
                                        <Form.Field
                                            control={Select}
                                            options={options}
                                            label={i18next.t('mesRetours.7')}
                                            placeholder={i18next.t('mesRetours.8')}
                                            className='input-box'
                                            value={selectorValue}
                                            name="selectorValue"
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Field
                                        control={TextArea}
                                        label={i18next.t('mesRetours.9')}
                                        placeholder={i18next.t('mesRetours.10')}
                                        className='input-box'
                                        value={textValue}
                                        name="textValue"
                                        onChange={this.handleChange}
                                    />
                                    <Form.Field
                                        control={Button}
                                        content={i18next.t('mesRetours.11')}
                                        className="btn-wrapper"
                                        disabled={textValue === "" || selectorValue === 0}
                                    />
                                </Form>
                            </div>
                            {
                                STORE_RETURN === '1' && orderStatus.code === ORDER_STATUS.WIT ?
                                    STORE_DEPOT === "1" ?
                                        "" :
                                        STORE_DELIVERY === "1" ?
                                            <div className='return-page-locator'>
                                                <p className='return-page-locator-label'>{i18next.t('mesRetours.12')}</p>
                                                <ChooseNearestStore
                                                    from='retour'
                                                    classN='return-page-nearest-store'
                                                    text={i18next.t('mesRetours.13')}
                                                    isReturn={true}
                                                    textBtn={i18next.t('mesRetours.15')}
                                                />
                                            </div> :
                                            "" :
                                    ""
                            }
                        </div>
                    </div>
                    :
                    <MesRetoursTwo />
                }
            </>
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    content: getRetour(state),
    ticket: getTicketRetour(state),
    userStore: getUserStore(state)
});

const mapDispatchToProps = (dispatch): typeof RetourActionCreators => {
    return bindActionCreators(RetourActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(RetournerUnArticleMess);
