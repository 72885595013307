import React, { PureComponent } from 'react';
import CurrentPage from '../../../layouts/common/currentPage';
import { connect } from "react-redux";
import { getDataBySectionAndWidget, getLangPrefix } from "../../../helpers/helpers";
import { Link } from "react-router-dom";
import i18next from "i18next";
import "./collection.scss"
import { HomeActionCreators } from "../../home/redux/actions";
import { getHome } from "../../home/redux/selectors";
import { BASE_API_GATEWAY } from "../../../utils/constants/service";

interface Props {
    canonicalUrl?: any,
    history?: any,
    location?: any,
    menuItem?: any,
    data?: any,
}

class Collection extends PureComponent<Props & typeof HomeActionCreators> {
    componentDidMount() {
        const { canonicalUrl, location, history, menuItem } = this.props;
        if (canonicalUrl && location.pathname !== canonicalUrl) {
            history.push(canonicalUrl);
        }
        if (menuItem && menuItem.gridId) {
            this.props.dispatch(HomeActionCreators.homeStart(menuItem.gridId))
        }
    }

    render() {
        const { data, menuItem } = this.props;
        const LANGUAGE_PREFIX = getLangPrefix();
        const photoS1W1 = getDataBySectionAndWidget(1, 1, data, "PHOTO");
        return (

            <div>
                <CurrentPage selectedItem={menuItem} />
                {data && data.length > 0 &&
                    <>

                        <section className="">
                            <div className="container">
                                <div className="page-inner-images">
                                    <div className="coverBox">
                                        <div className="large-img-box">
                                            {
                                                photoS1W1 && photoS1W1.value ?
                                                    <img
                                                        src={`${BASE_API_GATEWAY}${photoS1W1 && photoS1W1.value}`}
                                                        alt={photoS1W1.altText || "PHOTO"} /> : ""
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="history-section container">
                            <p className="text" dangerouslySetInnerHTML={{ __html: getDataBySectionAndWidget(2, 1, data, "TEXT").value }} />
                        </section>
                        <section className="descamps-section container">
                            <aside className="descamps-row">
                                <div className='descamps-col'>
                                    <div className="img-box">
                                        <img
                                            src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(3, 1, data, "PHOTO") && getDataBySectionAndWidget(3, 1, data, "PHOTO").value}`}
                                            alt="PHOTO" />
                                    </div>
                                    <div className="info-box">
                                        <h3 className="title"
                                            dangerouslySetInnerHTML={{ __html: `${getDataBySectionAndWidget(4, 1, data, "TEXT") && getDataBySectionAndWidget(4, 1, data, "TEXT").value}` }} />
                                        <Link
                                            to={`${LANGUAGE_PREFIX}${getDataBySectionAndWidget(3, 1, data, "PHOTO") && getDataBySectionAndWidget(3, 1, data, "PHOTO").menuHref}`}
                                            className="link"
                                        >
                                            {i18next.t('home.collection.discover')}
                                        </Link>
                                    </div>
                                </div>
                                <div className='descamps-col'>
                                    <div className="img-box">
                                        <img
                                            src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(3, 2, data, "PHOTO") && getDataBySectionAndWidget(3, 2, data, "PHOTO").value}`}
                                            alt="PHOTO" />
                                    </div>
                                    <div className="info-box">
                                        <h3 className="title"
                                            dangerouslySetInnerHTML={{ __html: `${getDataBySectionAndWidget(4, 2, data, "TEXT") && getDataBySectionAndWidget(4, 2, data, "TEXT").value}` }} />
                                        <Link
                                            to={`${LANGUAGE_PREFIX}${getDataBySectionAndWidget(3, 2, data, "PHOTO") && getDataBySectionAndWidget(3, 2, data, "PHOTO").menuHref}`}
                                            className="link"
                                        >
                                            {i18next.t('home.collection.discover')}
                                        </Link>
                                    </div>
                                </div>
                                <div className='descamps-col'>
                                    <div className="img-box">
                                        <img
                                            src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(3, 3, data, "PHOTO") && getDataBySectionAndWidget(3, 3, data, "PHOTO").value}`}
                                            alt="PHOTO" />
                                    </div>
                                    <div className="info-box">
                                        <h3 className="title"
                                            dangerouslySetInnerHTML={{ __html: `${getDataBySectionAndWidget(4, 3, data, "TEXT") && getDataBySectionAndWidget(4, 3, data, "TEXT").value}` }} />
                                        <Link
                                            to={`${LANGUAGE_PREFIX}${getDataBySectionAndWidget(3, 3, data, "PHOTO") && getDataBySectionAndWidget(3, 3, data, "PHOTO").menuHref}`}
                                            className="link"
                                        >
                                            {i18next.t('home.collection.discover')}
                                        </Link>
                                    </div>
                                </div>
                            </aside>
                        </section>
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = (collectionState: any): Props => ({
    data: getHome(collectionState),
});

export default connect(mapStateToProps)(Collection);
