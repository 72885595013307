import {createReducer} from 'reduxsauce';
import {MesInformationTypes} from './actions';

export interface IMesInformationState {
    webClient: any,
    brand: any,
    mesInformationSend: any,
    error: string,
    status: string,
    changePass: string
}

export const mesInformation: IMesInformationState = {
    webClient: {},
    brand: [],
    mesInformationSend: {},
    error: "",
    status: "",
    changePass: "",

};

export const getMesInformation = (state) => {
    return {...state};
};

export const mesInformationSuccess = (state, action) => {
    const webClient = action.data ? action.data["webClient"] : {};
    const brand = action.data ? action.data["brand"] : [];
    return {...state, webClient, brand, status: "", mesInformationSend: {}};
};

export const mesInformationFailure = (state, action) => {
    return {...state, error: action.error, brand: [], webClient: {}, status: "", mesInformationSend: {}};
};

export const getMesInformationPost = (state) => {
    return {...state};
};

export const mesInformationPostSuccess = (state, action) => {
    return {...state, mesInformationSend: action.data, webClient: action.data, status: "success"};
};

export const mesInformationPostFailure = (state, action) => {
    return {...state, error: action.error, status: "failed" };
};

export const changePass = (state) => {
    return {...state, changePass: ""};
};

export const changePassSuccess = (state, action) => {
    return {...state, changePass: "success"};
};

export const changePassFailure = (state, action) => {
    return {...state, error: action.error, changePass: "failed"};
};



export const handlers = {
    [MesInformationTypes.MES_INFORMATION_START]: getMesInformation,
    [MesInformationTypes.MES_INFORMATION_SUCCESS]: mesInformationSuccess,
    [MesInformationTypes.MES_INFORMATION_FAILURE]: mesInformationFailure,
    [MesInformationTypes.MES_INFORMATION_POST_START]: getMesInformationPost,
    [MesInformationTypes.MES_INFORMATION_POST_SUCCESS]: mesInformationPostSuccess,
    [MesInformationTypes.MES_INFORMATION_POST_FAILURE]: mesInformationPostFailure,
    [MesInformationTypes.MES_INFORMATION_CHANGE_PASS_START]: changePass,
    [MesInformationTypes.MES_INFORMATION_CHANGE_PASS_SUCCESS]: changePassSuccess,
    [MesInformationTypes.MES_INFORMATION_CHANGE_PASS_FAILURE]: changePassFailure
};

export default createReducer(mesInformation, handlers);