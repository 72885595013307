import {TimelineTypes, TimelineActionCreators} from "./actions";
import {takeLatest, put, call} from "redux-saga/effects";
import {getApiPrefix} from "../../../helpers/helpers";
import axios from "axios";
import {CONTENT_URL} from "../../../utils/constants/service";

export function* timeline(params){
    try{
        const headers = {headers: {language: getApiPrefix()}};
        const data = yield call(() => {
            return axios.get(`${CONTENT_URL}${params.id}`, headers)
                .then(res => res.data)
        });
       yield put(TimelineActionCreators.timelineSuccess(data));
    }catch(e){
       yield put(TimelineActionCreators.timelineFailure(e.message));
    }
}

export function* timelineSaga(){
    yield takeLatest(TimelineTypes.TIMELINE_START, timeline);
}

export default timelineSaga;