import {ProductTypes, ProductActionCreators} from "./actions";
import {takeLatest, put, call} from "redux-saga/effects";
import axios from 'axios';
import {
    PRODUCT_CANONICAL_URL,
    PRODUCT_CARE_URL,
    PRODUCT_COMPOSITION_URL
} from "../../../utils/constants/service";
import {ALT_BRAND_ID, BRAND_ID} from "../../../utils/constants/variables";
import {discountHeader, getApiPrefix} from "../../../helpers/helpers"


export function* product(params) {
    try {
        const { productId, brandId = null } = params.data;

        const product = yield call(() => {
            return axios.get(`${PRODUCT_CANONICAL_URL}${productId}`, discountHeader(brandId))
                .then(res => res.data )
        });
        yield put(ProductActionCreators.productSuccess(product));

    } catch (e) {
        yield put(ProductActionCreators.productFailure(e.message));
    }
}

export function* productComposite(params) {
    try {
        const data = yield call(() => {
            return axios.get(`${PRODUCT_CANONICAL_URL}${params.id}`, discountHeader())
                .then(res => res.data )
        });
        yield put(ProductActionCreators.productCompositeSuccess(data));

    } catch (e) {
        yield put(ProductActionCreators.productCompositeFailure(e.message));
    }
}

export function* care(params) {
    try {
        const headers = {headers: {brand: ALT_BRAND_ID ||BRAND_ID, currency: 'EUR', language: getApiPrefix()}};
        const care = yield call(() => {
            return axios.get(`${PRODUCT_CARE_URL}${params.productId}`, headers)
                .then(res =>  res.data)
        });
        yield put(ProductActionCreators.careSuccess(care));
    } catch (e) {
        yield put(ProductActionCreators.careFailure(e.message));
    }
}

export function* composition(params) {
    try {
        const headers = {headers: {brand: BRAND_ID, currency: 'EUR', language: getApiPrefix()}};
            const data = yield call(() => {
                return axios.get(`${PRODUCT_COMPOSITION_URL}${params.productId}`, headers)
                    .then(async(res) => {
                        return res.data;
                    })
            });

        yield put(ProductActionCreators.compositionSuccess(data));
    } catch (e) {
        yield put(ProductActionCreators.compositionFailure(e.message));
    }
}

export function* productSaga() {
    yield takeLatest(ProductTypes.PRODUCT_START, product);
    yield takeLatest(ProductTypes.PRODUCT_COMPOSITE_START, productComposite);
}

export function* productCareSaga() {
    yield takeLatest(ProductTypes.CARE_START, care);
}

export function* productCompositionSaga() {
    yield takeLatest(ProductTypes.COMPOSITION_START, composition);
}

export default productSaga;
