import { AddToCartTypes, AddToCartActionCreators } from "./actions";
import { takeLatest, put, call, select } from "redux-saga/effects";
import {
    addToLocalStorage,
    getLocalStorageObject,
    getPackDiscountPrice,
    getProductPack,
    removeFromStorage,
    setStoreToBasket,
    getBasketData,
    getPromotionValue
} from "../../../helpers/helpers";
import { PRODUCT_ITEM_TYPES, STORAGE_BASKET_INDEX } from "../../../utils/constants/variables";
import { getBrandDetail } from "../../../layouts/main/header/redux/selectors";
import { OrderActionCreators } from "../../basket/redux/actions";
import { getDiscount } from "../../basket/redux/selectors";
import { createPromotionData, getPromotionDiscountValue } from "../../../helpers/promotionDiscountHelper";

export function* addToCart(params) {
    try {
        const discount = yield select(getDiscount);
        const { name, key, value, setAsQuantity = false } = params;
        const { pack, size } = value
        const brandDetails = yield select(getBrandDetail);
        const promotion = brandDetails && brandDetails.promotion && brandDetails.promotion[0] || {};

        if (value.productCategory && value.productCategory.toLowerCase() === "gift card") {
            value.size.itemType = PRODUCT_ITEM_TYPES.GFC
        }
        const data = yield call(() => {
            const added = addToLocalStorage(name, key, value, setAsQuantity);
            if (Array.isArray(pack) && pack.length) {
                pack.map(item => {
                    let sizes = {
                        currency: size.currency,
                        oldPrice: item.startingPrice,
                        productId: item.productId,
                        productReference: size.productReference,
                        quantity: size.quantity,
                        size: item.size,
                        startingPrice: item.startingPrice,
                        taxRate: item.taxRate,
                    }
                    let packValue = {
                        canonicalMenu: item.canonicalMenu,
                        canonicallUrl: item.canonicalUrl,
                        collection: item.collection,
                        description: item.description,
                        discountDetail: item.discountDetail,
                        name: item.name,
                        photo: item.photo,
                        productId: item.productId,
                        size: sizes,
                        quantity: item.quantity,
                        startingPrice: item.startingPrice,
                        taxRate: item.taxRate,
                        packParent: key
                    }
                    addToLocalStorage("basket", item.parentProductId, packValue);
                })
            }

            if (setAsQuantity) {
                const productPack = getProductPack(name, key);
                Array.isArray(productPack) && productPack.length && productPack.forEach(item => {
                    const newQuantity = item.quantity;
                    addToLocalStorage(name, item.productId, { size: { quantity: newQuantity, productId: item.prSize } }, true)
                })
            }

            let newBasket = getLocalStorageObject(name);

            return { added, basket: newBasket };
        });

        const basketData = getBasketData(data.basket);
        const isCumulative = discount && discount.cumulative;

        if (promotion && (!discount || isCumulative)) {
            const promotionValue = getPromotionValue(promotion, basketData.totalPrice, basketData.totalQuantity);
            const promotionData = createPromotionData(promotion, data.basket, promotionValue);
            const discountedSum = getPromotionDiscountValue(promotionData, data.basket) || 0;

            if (discountedSum > 0) {
                if (!isCumulative) yield put(OrderActionCreators.annullerDiscountSuccess());
                yield put(OrderActionCreators.promotionDiscountSuccess(promotionData))
            } else {
                yield put(OrderActionCreators.annullerPromotionDiscountStart())
            }
        }

        if (discount && discount.couponType === "FIXED" && +discount.value >= basketData.totalPrice) {
            yield put(OrderActionCreators.annullerDiscountSuccess());
        }

        yield put(AddToCartActionCreators.addtocartSuccess(data));
    } catch (e) {
        console.log(e)
        yield put(AddToCartActionCreators.addtocartFailure(e.message));
    }
}


export function* basket(params) {
    try {
        const data = yield call(() => {
            return getLocalStorageObject(STORAGE_BASKET_INDEX)
        });
        yield put(AddToCartActionCreators.getBasketSuccess(data));
        if (params.callback) params.callback();
    } catch (e) {
        yield put(AddToCartActionCreators.addtocartFailure(e.message));
    }
}

export function* removeBasket(params) {
    const { name, key, prSize } = params;
    const brandDetails = yield select(getBrandDetail);
    try {
        const data = yield call(() => {
            const packElems = getProductPack(name, key);
            if (Array.isArray(packElems) && packElems.length) {
                packElems.forEach(item => {
                    const packParentElem = getLocalStorageObject(name)[item.productId]
                    if (packParentElem) {
                        const { parentElementQuantity, childElementQuantity } = getPackDiscountPrice(name, packParentElem, item.productId)

                        let currentQuantity = 0;
                        getLocalStorageObject(name)[key].sizes && getLocalStorageObject(name)[key].sizes.forEach(item => {
                            if (item.productId === prSize) {
                                currentQuantity = item.quantity
                            }
                        })

                        if (currentQuantity >= childElementQuantity) {
                            removeFromStorage(name, item.productId, item.prSize);
                        } else {
                            addToLocalStorage(name, item.productId, { size: { quantity: childElementQuantity - currentQuantity, productId: item.prSize } }, true)
                        }
                    }
                })
            }
            removeFromStorage(name, key, prSize);

            return getLocalStorageObject(name);
        });
        try {
            const promotion = brandDetails && brandDetails.promotion && brandDetails.promotion[0] || {};
            const basketData = getBasketData(data);
            const promotionValue = getPromotionValue(promotion, basketData.totalPrice, basketData.totalQuantity);
            if (promotionValue) {
                const promotionData = createPromotionData(promotion, data, promotionValue);
                const discountedSum = getPromotionDiscountValue(promotionData, data) || 0;
                if (discountedSum <= 0) yield put(OrderActionCreators.annullerPromotionDiscountStart())
            } else yield put(OrderActionCreators.annullerPromotionDiscountStart())
        } catch (error) {
            yield put(OrderActionCreators.annullerPromotionDiscountStart())
        }
        yield put(AddToCartActionCreators.removeBasketSuccess(data));
    } catch (e) {
        yield put(AddToCartActionCreators.addtocartFailure(e.message));
    }
}

export function* updateBasketStore(params) {
    const { store } = params;
    const basket = setStoreToBasket(store);

    yield put(AddToCartActionCreators.updateBasketStoreSuccess(basket));
}

export function* addToCartSaga() {
    yield takeLatest(AddToCartTypes.ADDTOCART_START, addToCart);
    yield takeLatest(AddToCartTypes.GET_BASKET_START, basket);
    yield takeLatest(AddToCartTypes.REMOVE_BASKET_START, removeBasket);
    yield takeLatest(AddToCartTypes.UPDATE_BASKET_STORE_START, updateBasketStore);
}

export default addToCartSaga;
