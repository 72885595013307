import React from "react";

import "../../creation/container/creation.scss";
import './input.scss'


interface Props {
    name?: any,
    placeholder?: string,
    value?: any,
    id: any;
    text?: string,
    invalid?: boolean,
    handleChange: any,
    type?: any,
    important?: any,
    disabled?: boolean,
    icon?: any,
    onKeyDown?: any
    className?: string

}
const Input = ({ name, placeholder, id, text, value, invalid = false, type, important = false, disabled = false, icon = null, onKeyDown = () => { }, handleChange, className }: Props) => {
    return (
        <div className={`default-input ${className || ""}`}>
            <label htmlFor={id} className={invalid ? "invalid" : ""}>{text}{important ? '*' : null}</label>
            <div className="iconBox">
                <input
                    type={type ? type : "text"}
                    id={id}
                    disabled={disabled}
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={onKeyDown}
                />
                {icon}
            </div>
        </div>
    );
}


export default Input