import {LocationSearchTypes, LocationSearchActionCreators} from "./actions";
import {takeLatest, put, call} from "redux-saga/effects";
import axios from 'axios';
import { fetchIpstackData, fetchClickAnCollectStores, LOCATIONSEARCH_URL} from "../../../utils/constants/service";
import {ALT_BRAND_ID, BRAND_ID, COUNTRY, COUNTRY_LIST, DEFAULT_LOCATION} from "../../../utils/constants/variables";
import {getApiPrefix} from "../../../helpers/helpers";


export function* getLocationSearch(params) {
    const {location, filter} = params;
    try {
        let country_code = localStorage.country
        const shippingCity = localStorage.shippingCity;
        const approvedVariables = !!((localStorage.country && localStorage.shippingCity) && !location)

        if (!country_code) {
            const country = yield call(fetchIpstackData);
            country_code = country.country_code;
            if(country_code){
                localStorage.country = country_code;
            }
        }
        // const country =  COUNTRY_LIST.includes(country_code) ? country_code : COUNTRY;
        const headers = {headers: {
            country: approvedVariables ? country_code : COUNTRY,
            brand: ALT_BRAND_ID || BRAND_ID,
            language: getApiPrefix(),
        }};

        const data = yield call(() => {
            return axios.get(`${LOCATIONSEARCH_URL}${approvedVariables ? (shippingCity || DEFAULT_LOCATION) : location || DEFAULT_LOCATION}?brand=${ALT_BRAND_ID || BRAND_ID}&language=${getApiPrefix()}&country=${approvedVariables ? country_code : COUNTRY}`, headers)
            .then(res => res.data)
        });
        if (filter) {
            const filtered = data.filter(it => it.storeType !== 'E')
            return yield put(LocationSearchActionCreators.locationSearchSuccess(filtered));
        }

        yield put(LocationSearchActionCreators.locationSearchSuccess(data));
    } catch (e) {
        yield put(LocationSearchActionCreators.locationSearchFailure(e.message));
    }
}

export function* getClickAndCollectStores(params) {
    const { info, callback = () => {} } = params;
    const {location,body,queryParams} = info;
    try {
        const data = yield call(() => fetchClickAnCollectStores(body, location, queryParams));
        yield put(LocationSearchActionCreators.clickAndCollectStoresSuccess(data));
        callback(data);
    } catch (e) {
        yield put(LocationSearchActionCreators.clickAndCollectStoresFailure(e.message));
    }
}

export function* locationSearchSaga() {
    yield takeLatest(LocationSearchTypes.LOCATION_SEARCH_START, getLocationSearch);
    yield takeLatest(LocationSearchTypes.CLICK_AND_COLLECT_STORES_START, getClickAndCollectStores);
}

export default locationSearchSaga;
