import React from "react"
import {replaceComma} from "../../../helpers/helpers";
import {HIDE_DISCOUNT} from "../../../utils/constants/variables";

export default function Price({startP, oldP = 0, discount, newP, quantity = 1, currency = "€", isMultiple = true}) {
    return (discount ? <>
                {HIDE_DISCOUNT === "0" ? <span className="old-price"> {replaceComma(Number(oldP || startP) * (isMultiple ? quantity : 1))}</span> : ""}
                <span className="new-price">{replaceComma(Number(newP) * (isMultiple ? quantity : 1))}{currency}</span>
            </> : <span className="new-price">{replaceComma(Number(startP) * (isMultiple ? quantity : 1))}{currency}</span>
    );

}
