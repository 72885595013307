import {createReducer} from 'reduxsauce';
import {MotdePasseTypes} from './actions';

export interface IMotdePasseState {
    userData: any,
    error: string
}

export const motdepasse: IMotdePasseState = {
    userData: {
        resetPassword: true,
        changePassword: false,
        passwordChanged: false
    },
    error: ""
};

export const resetPassword = (state) => {
    return { ...state };
};

export const resetPasswordSuccess = (state, action) => {
    return { ...state,  userData: action.userData };
};

export const resetPasswordFailure = (state, action) => {
    return { ...state, error: action.error, userData: { ...state.userData, resetPassword: true} };
};

export const handlers = {
    [MotdePasseTypes.MOTDE_PASSE_RESET_PASSWORD_START]: resetPassword,
    [MotdePasseTypes.MOTDE_PASSE_RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
    [MotdePasseTypes.MOTDE_PASSE_RESET_PASSWORD_FAILURE]: resetPasswordFailure,
    
};

export default createReducer(motdepasse, handlers);