import { CollectionActionCreators } from "./actions";
import { put } from "redux-saga/effects";

export function* collection() {
    try {
        //const user = yield call(Api)
        yield put(CollectionActionCreators.collectionSuccess({ collectionList: 'product list' }));
    } catch (e) {
        yield put(CollectionActionCreators.collectionFailure(e.message));
    }
}

export function* collectionSaga() {
    // yield takeLatest(ProductsTypes.GET_PRODUCT_LIST, products);
}

export default collectionSaga;