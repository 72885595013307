import React from 'react'
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css'
import {
    getCurrency,
    getLangPrefix,
    getProductCategoryName,
    replaceComma
} from "../../../../helpers/helpers";
import {BASE_API_GATEWAY} from "../../../../utils/constants/service";
import i18next from "i18next";
import LinkFullUrl from "../../../../shared/linkFullUrl/linkFullUrl";
import {LazyImage} from "react-lazy-images";
import {HIDE_DISCOUNT} from "../../../../utils/constants/variables";
import DiscountDetail from '../../../common/product/discountDetail';


const FavoriteProducts = (props) => {

    const params = {
        direction:  "horizontal",
        slidesPerView: 3,
        speed: 600,
        setWrapperSize: true,
        resizeReInit: true,
        autoplay: {delay: 5000},
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            382: {
                slidesPerView: 1,
            },
            680: {
                slidesPerView: 3,
            },
            980: {
                slidesPerView:4,
            },
        },
    },
        {products, banner} = props;

    return (
        <div>
            { products && products.length ?
                <Swiper {...params}>
                    {products && products.map((item, i) => (
                        <div
                            className="product-item  product-content-sm-product    recommendedSlidercontent--item "
                            key={i}>
                            <LinkFullUrl to={`${getLangPrefix()}${item.canonicalUrl}`}>
                                <div className="product-item-img">
                                    <LazyImage
                                        src={`${BASE_API_GATEWAY}${ item && item.photo}`}
                                        alt="product-image"
                                        placeholder={({imageProps, ref}) => (
                                            <img
                                                ref={ref}
                                                src={`${BASE_API_GATEWAY}${item && item.thumbnail}`}
                                                alt={imageProps.alt}

                                            />
                                        )}
                                        actual={({imageProps}) => (
                                            <img {...imageProps}  />
                                        )}
                                    />

                                    <DiscountDetail product={item} banner={banner} />

                                    <div className="product-item-img-text">
                                        <h2 className="product-title">{item.name}</h2>
                                        <p className="product-categorye-name">
                                            {
                                                item.attributes &&
                                                item.attributes.length &&
                                                getProductCategoryName(item.attributes)
                                            }
                                        </p>
                                        <p className="product-price">
                                                        <span
                                                            className='price-text'>{i18next.t('catalogue.product.3')} </span>
                                            {
                                                (item.listPriceAfterDiscount) ?
                                                    <span>{replaceComma(item.listPriceAfterDiscount)} {getCurrency(item.currency)}</span> :
                                                    <span>{replaceComma(item.startingPrice)} {getCurrency(item.currency)}</span>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="info-box">
                                    <div className="front">
                                        <h2 className="product-title">{item.name}</h2>
                                        <p className="product-categorye-name">
                                            {
                                                item.attributes &&
                                                item.attributes.length &&
                                                getProductCategoryName(item.attributes)
                                            }
                                        </p>
                                        <div className="info-box-lg-content">
                                            <p className="text">
                                                {
                                                    item.attributes &&
                                                    item.attributes['General Material'] &&
                                                    item.attributes['General Material'].length &&
                                                    item.attributes['General Material'][0]
                                                }
                                            </p>
                                            <p className="text multiline-ellipsis"
                                               dangerouslySetInnerHTML={{__html: item.description}}>
                                            </p>
                                        </div>
                                        <p className="product-price">
                                                        <span
                                                            className='price-text'>{i18next.t('catalogue.product.3')} </span>
                                            {
                                                (item.listPriceAfterDiscount) ?
                                                    <span>
                                                                             {
                                                                                 HIDE_DISCOUNT === "0" ?
                                                                                     <span
                                                                                         className='old-price'> {replaceComma(item.startingPrice)}</span> : ""
                                                                             }
                                                        <span className="new-price">{replaceComma(item.listPriceAfterDiscount)} {getCurrency(item.currency)}</span></span> :
                                                    <span className="new-price">{replaceComma(item.startingPrice)} {getCurrency(item.currency)}</span>
                                            }
                                        </p>
                                        <div className="info-box-lg-content">
                                            <p className="title-brd">{i18next.t("catalogue.product.4")}</p>
                                            <span className="title-brd-image"></span>
                                        </div>
                                    </div>
                                    <div className="back">
                                        <p className="btt">{i18next.t('home.collection.discover')}</p>
                                    </div>
                                </div>
                            </LinkFullUrl>
                        </div>
                    ))}
                </Swiper>: ""}
        </div>
    )
};


export default FavoriteProducts
