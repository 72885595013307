import {ValidationTypes, ValidationActionCreators} from "./actions";
import {takeLatest, put, call} from "redux-saga/effects";
import axios from 'axios';
import { CONTENT_URL , CREATE_USER_PAYMENT_PAYPAL} from "../../../utils/constants/service";
import { PAYMENT_ACCEPTED_URL } from "../../../utils/constants/service";
import { PAYMENT_FAILED_URL } from "../../../utils/constants/service";
import {getApiPrefix} from "../../../helpers/helpers"

export function* getValidationData(params) {
    try {
        const headers = {headers: {language: getApiPrefix()}};
        const data = yield call(() => {
            return axios.get(`${CONTENT_URL}${params.id}`, headers)
                .then(res => res.data);

        });
        yield put(ValidationActionCreators.validationSuccess(data));
    } catch (e) {
        yield put(ValidationActionCreators.validationFailure(e.message));
    }
}

export function* getValidationPaymentAccepted(params) {
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            }
        };
        const data = yield call(() => {
            return axios.get(`${PAYMENT_ACCEPTED_URL}${params.textlibre}`, headers)
                .then(res => res.data);

        });
        yield put(ValidationActionCreators.validationPaymentAcceptedSuccess(data));
    } catch (e) {
        yield put(ValidationActionCreators.validationPaymentAcceptedFailure(e.message));
    }
}


export function* getValidationPaymentFailed(params) {
    try {
        try {
            const headers = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'application/json'
                }
            };
            const data = yield call(() => {
                return axios.get(`${PAYMENT_FAILED_URL}${params.textlibre}`, headers)
                    .then(res => res.data);

            });
            yield put(ValidationActionCreators.validationPaymentFailedSuccess(data));
        } catch (e) {
            yield put(ValidationActionCreators.validationPaymentFailedFailure(e.message));
        }
    } catch (e) {
        yield put(ValidationActionCreators.validationPaymentFailedFailure(e.message));
    }
}

export function* getuserPaymentPaypalData(params) {
    const { body, callback } = params

    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            }
        };
        const data = yield call(() => {
            return axios.post(`${CREATE_USER_PAYMENT_PAYPAL}`, body , headers)
                .then(res => res.data);

        });
        yield put(ValidationActionCreators.userPaymentPaypalSuccess(data));
        callback && callback(data);
    } catch (e) {
        console.log(e)
        yield put(ValidationActionCreators.userPaymentPaypalFailure(e.message));
    }
}

export function* validationSaga() {
    yield takeLatest(ValidationTypes.VALIDATION_START, getValidationData);
    yield takeLatest(ValidationTypes.VALIDATION_PAYMENT_ACCEPTED_START, getValidationPaymentAccepted);
    yield takeLatest(ValidationTypes.VALIDATION_PAYMENT_FAILED_START, getValidationPaymentFailed);
    yield takeLatest(ValidationTypes.USER_PAYMENT_PAYPAL_START, getuserPaymentPaypalData);
}

export default validationSaga;
