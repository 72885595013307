/* eslint-disable */
import {
  getProductClusterBarcodes,
  getProductClusterPackBarcodes
} from "../layouts/main/header/redux/selectors";
import { PROMOTION_DISCOUNT } from "../utils/constants/variables";
import { fixedInHundredth, getBasketQuantity } from "./helpers";
import { getBrandDetail } from "../layouts/main/header/redux/selectors";
import { store } from '../index';

export const getPromotionDiscountValue = (promotionDiscount, basket) => {
  switch(promotionDiscount.ruleType) {
    case "AMOUNT":
    case "VALUE":
    case "QUANTITY":
      return discountByAmoutValueQuantity(promotionDiscount.ruleType, promotionDiscount.value, basket);
    case "PERCENTAGE":
      return discountByPercentage(promotionDiscount.value, basket, promotionDiscount.basketQuantity, promotionDiscount.promotion);
    case "PACK":
      return discountByPack(promotionDiscount.value, basket);
    default:
      return 0;
  }
}

const discountByAmoutValueQuantity = (
  ruleType,
  discount,
  basket
) => {
  const sizesList = getSizesList(basket);
  const reduxState = store.getState();
  const categoriIds = getProductClusterBarcodes(reduxState);
  const detail = getBrandDetail(reduxState);
  const promotionDiscountState = detail.promotion && detail.promotion.length && detail.promotion[0];
  const { clusterTotal, clusterQuantity } = getClusterTotals(basket, categoriIds);

  if (!promotionDiscountState) return 0;

  switch (ruleType) {
      case "AMOUNT":
          if (clusterTotal < promotionDiscountState.threshold) return 0;
          amountQuantityDiscount(sizesList, discount, categoriIds);
          replaceProductSizes(sizesList, basket);
          return (clusterTotal * discount) / 100;
      case "QUANTITY":
          if (clusterQuantity < promotionDiscountState.quantity) return 0;
          amountQuantityDiscount(sizesList, discount, categoriIds);
          replaceProductSizes(sizesList, basket);
          return (clusterTotal * discount) / 100;
      case "VALUE":
          if (clusterTotal < promotionDiscountState.threshold) return 0;
          valueDiscount(sizesList, clusterTotal, discount, categoriIds);
          replaceProductSizes(sizesList, basket);
          return discount;
      default:
          return undefined;
  }
};

const valueDiscount = (sizesList, clusterTotal, discountPercent, categoriIds) => {
  if (!discountPercent) return;

  let total = 0;
  sizesList.forEach((product) => {
      if (categoriIds.includes(product.productReference)) {
          const productPrice = product.listPriceAfterDiscount || product.startingPrice;
          const percent = (productPrice * product.quantity) * 100 / clusterTotal;
          const result = fixedInHundredth(discountPercent * percent / 100);
          total += result;
          product.specialDiscount = result;
      }
  });

  const difference = discountPercent - total;
  sizesList[0].specialDiscount += difference;
}

const amountQuantityDiscount = (
  sizesList,
  discountPercent,
  categoriIds
) => {
  for (let i = 0; i < sizesList.length; i++) {
      if (categoriIds.includes(sizesList[i].productReference)) {
          const productPrice = sizesList[i].listPriceAfterDiscount || sizesList[i].startingPrice;
          sizesList[i].specialDiscount = ((productPrice * discountPercent) / 100) * sizesList[i].quantity;
      }
  }
}

export const discountByPercentage = (
  discountPercent,
  basket,
  basketProductsQuantity,
  promotion
) => {
  /** discount is a number to sale product with
   * if basketProductsQuantity is 1 or 2, it receives only one discount
   * in case if basketProductsQuantity is 1, it receives (discount_1), if its 2 it receives (discount_2)
   * if basketProductsQuantity is 3, it receives an discount array with [discount_3, discount_2, discount_1]
   * if basketProductsQuantity > 3, it receives an discount array with [discount_3, discount_3, discount_3, discount_2, discount_1]
   **/

  const reduxState = store.getState();
  const categoriIds = getProductClusterBarcodes(reduxState);
  const detail = getBrandDetail(reduxState);
  const promotionDiscount = detail.promotion && detail.promotion.length && detail.promotion[0] || {};

  /**
   * sizesList here is sorted all lists of sizes of basket products order by less expensive
   */
  const sizesList = sortList(getSizesList(basket, promotion, true)).filter((size) => categoriIds.includes(size.productReference));
  /**
   * totalDiscount is how much products are discounted, their total discounted number
   */
  const totalDiscount = percentageDiscount(sizesList, promotionDiscount);

  replaceProductSizes(sizesList, basket);
  return totalDiscount;
};

export const percentageDiscount = (sizesList, promotionDiscount) => {
  const lastDiscountIndex = Object.keys(promotionDiscount).reduce((discountIndex, key) => {
      if (key.includes('discount')) {
          const [, number] = key.split("_");
          discountIndex = discountIndex < +number ? +number : discountIndex;
      }

      return discountIndex;
  }, 0)

  return sizesList.reduce((acc, size, index) => {
      let discount = promotionDiscount[`discount_${sizesList.length - index}`];
      discount = discount || discount === 0 ? discount : promotionDiscount[`discount_${lastDiscountIndex}`];
      const productPrice = size.listPriceAfterDiscount || size.startingPrice;
      const currentDiscount = (productPrice * discount) / 100;

      size.specialDiscount = size.specialDiscount
          ? size.specialDiscount + currentDiscount
          : currentDiscount

      return acc + currentDiscount;
  }, 0);
};

const discountByPack = (
  discountsList,
  basket
) => {
  let discountedSum = 0;
  const reduxState = store.getState();
  const packCategoriIds = getProductClusterPackBarcodes(reduxState);
  const categoriIds = Object.keys(packCategoriIds);

  if (!categoriIds.length) return 0;

  const pc1 = categoriIds[0] ? packCategoriIds[categoriIds[0]] : [];
  const pc2 = categoriIds[1] ? packCategoriIds[categoriIds[1]] : [];
  const sizesList = getSizesListByCategoryId(basket, pc1, pc2);
  const productId1List = sizesList.category1;
  const productId2List = sizesList.category2;

  if (!(packCategoriIds[2] || []).length ? (productId1List.length || productId2List.length) : productId1List.length && productId2List.length) {
      let lowestQuantity = getLowestQuantity([...productId1List, ...productId2List]);

      const discounted1 = packDiscount(productId1List, discountsList[0], lowestQuantity);
      const discounted2 = packDiscount(productId2List, discountsList[1], lowestQuantity, productId1List);
      discountedSum = discounted1 + discounted2;
      productId1List.forEach((item) => {
          const index = productId2List.findIndex((nextList) => nextList.productId === item.productId);

          if (index > -1) {
              item.specialDiscount = (item.specialDiscount || 0) + (productId2List[index].specialDiscount || 0);
              productId2List[index].specialDiscount = item.specialDiscount;
              !item.specialDiscount && delete item.specialDiscount;
              !productId2List[index].specialDiscount && delete productId2List[index].specialDiscount;
          }
      })

      replaceProductSizes([...productId1List, ...productId2List], basket);
  } else {
      !productId1List.length && !productId2List.length && localStorage.removeItem(PROMOTION_DISCOUNT);
      return false
  }

  return discountedSum;
};

const getSizesListByCategoryId = (basket, pId1, pId2) => {
  return Object.keys(basket).reduce((acc, productKey) => {
      const product = basket[productKey];
      if (!(product.sizes && product.sizes.length)) return acc;

      product.sizes.forEach((size) => {
          const sizeItem = {
              ...size,
              ...(size.parentId === undefined && {
                  parentId: Number(productKey),
              }),
          }

          if (pId1.includes(size.productReference)) {
              acc["category1"].push(sizeItem);
          }

          if (pId2.includes(size.productReference)) {
              acc["category2"].push(sizeItem);
          }
      })

      return acc;
  },
      {
          category1: [],
          category2: [],
      }
  );
};

const getLowestQuantity = (sizes) => {
  return sizes.reduce((acc, cur) => {
      if (cur.quantity < acc) acc = cur.quantity;
      return acc;
  }, +Infinity)
}

const packDiscount = (product, discount, lowestQty, firstItems) => {

  let discountedSum = 0;
  if (discount) {
    if (firstItems && firstItems.length > 1) {
      for (let i = 0; i < product.length; i++) {
        const realDiscount = (discount * (lowestQty * 100)) / 100;
        const productPrice = product[i].listPriceAfterDiscount || product[i].startingPrice;
        discountedSum += (productPrice * product[i].quantity * realDiscount) / 100;
        product[i].specialDiscount = product[i].quantity * ((productPrice * discount) / 100);
      }
    } else if (firstItems && firstItems[0].quantity > 1) {
      for (let i = 0; i < product.length; i++) {
        const realDiscount = (discount * (lowestQty * 100 / product[i].quantity)) / 100;
        const productPrice = product[i].listPriceAfterDiscount || product[i].startingPrice;
        discountedSum += (productPrice * product[i].quantity * realDiscount) / 100;
        product[i].specialDiscount = product[i].quantity * ((productPrice * discount) / 100);
      }
    } else {
      const lowestItemIdx = product.findIndex((lowest, it) => (it.startingPrice < lowest.startingPrice ? it : lowest), product[0])
      const lowestItem = product[lowestItemIdx];
      const realDiscount = (discount * (lowestQty * 100 / lowestItem.quantity)) / 100;
      const productPrice = lowestItem.listPriceAfterDiscount || lowestItem.startingPrice;
      discountedSum += (productPrice * lowestItem.quantity * realDiscount) / 100;
      product[lowestItemIdx].specialDiscount = (productPrice * discount) / 100
    }
  }
  return discountedSum;
}

export const getSizesList = (basket, promotion = false, withQuantity = false) => {
  /**Accepts basket which is actual basket from localStorage
   * promotion can be true or false
   * if promotion is false, every single object from product's sizes should be pushed in one array
   * if promotion is true, every single object from product's sizes which contains listPriceAfterDiscount field should be pushed in one array otherwise ignore
   * promotion by default is false
   **/
  return Object.keys(basket).reduce((acc, product) => {
      if (basket[product].sizes) {
          basket[product].sizes.forEach((item) => {
              /**
               * parentId is the id of product object in basket, they are being put in sizes objects as field
               * because after we may need to replace the actual sizes' object in products with our ones
               * parentId will help us to understand in which product should be replaced sizes object
               * parentId is just an identificator, parentId is being taken from product object keys
               **/

              const length = withQuantity ? item.quantity : 1;
              const currentSize = {
                  ...item,
                  ...(item.parentId === undefined && {
                      parentId: Number(product),
                  }),
              }

              if (promotion && item.listPriceAfterDiscount !== undefined) {
                  for (let i = 0; i < length; i++) acc.push(currentSize);
              }

              if (!promotion) {
                  for (let i = 0; i < length; i++) acc.push(currentSize);
              }
          });
      }

      return acc;
  }, []);
};

export const getClusterTotals = (basket, categoriIds = []) => {
  return Object.keys(basket).reduce((acc, key) => {
      if (!+key) return acc;
      const item = basket[key];
      const sizes = item.sizes || [];

      sizes.forEach((size) => {
          const isClusterItem = categoriIds.includes(size.productReference);
          const priceWithQty = (size.listPriceAfterDiscount || size.startingPrice) * size.quantity;
          acc.totalPrice += priceWithQty;
          acc.totalQuantity += size.quantity;

          if (isClusterItem) {
              acc.clusterTotal += priceWithQty;
              acc.clusterQuantity += size.quantity;
          }
      })

      return acc;
  }, { totalPrice: 0, clusterTotal: 0, totalQuantity: 0, clusterQuantity: 0 })
}

const replaceProductSizes = (sizes, basket) => {
  sizes.forEach((sizeItem) => {
      basket[sizeItem.parentId].sizes = basket[sizeItem.parentId].sizes.filter(basketSizeItem => basketSizeItem.productId !== sizeItem.productId);
      basket[sizeItem.parentId].sizes.push(sizeItem);
  });

  localStorage.setItem('basket', JSON.stringify(basket));
}

export const sortList = (sizes, orderFlow = "asc") => {
  // Sorting list from the cheapest to the most expensive
  return sizes.sort((a, b) => {
      const prevPrice = a.listPriceAfterDiscount || a.startingPrice;
      const price = b.listPriceAfterDiscount || b.startingPrice;
      return orderFlow === "asc" ? prevPrice - price : price - prevPrice;
  });
};

export const createPromotionData = (promotion = {}, basket, promotionValue) => {
  return {
    ruleType: promotion.ruleType,
    value: promotionValue,
    basketQuantity: getBasketQuantity(basket, promotion.promotion),
    ruleNameMessage: promotion.ruleName,
    ...(promotion.promotionRuleId) && { promotionRuleId: promotion.promotionRuleId },
    ...(promotion.threshold !== undefined) && { threshold: promotion.threshold },
    ...(promotion.currency !== undefined) && { currency: promotion.currency },
    ...(promotion.promotion !== undefined) && { promotion: promotion.promotion },
    ...(promotion.productCategoryId_1 !== undefined) && { productCategoryId_1: promotion.productCategoryId_1 },
    ...(promotion.productCategoryId_2 !== undefined) && { productCategoryId_2: promotion.productCategoryId_2 },
  };
}