import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./mesaddress.scss";
import { getMesAddress, getError, getStatus, getUser } from "../redux/selectors";
import { MesAddressActionCreators } from "../redux/actions";
import { bindActionCreators } from "redux";
import { Icon } from 'semantic-ui-react';
import { AddressPopup } from '../../../../../basketRefactor/container/components/addressPopup';
import i18next from "i18next";
import ConfirmPopup from "../../../../../common/confirmPopup/confirmPopup";

interface Props {
    address: any,
    error: any,
    status: any,
    user: any
}

interface State {
    showAddressPopup: boolean,
    showConfirmPopup: boolean,
    maxAddressCount: number,
    typeBilling: string,
    typeShipping: string,
    billingAddress: any,
    shippingAddress: any,
    addressItem: any,
    addressType: string,
    addressId: any,
    isDefault: boolean,
}

class MesAddress extends Component<Props & typeof MesAddressActionCreators, State> {

    state: State = {
        showAddressPopup: false,
        maxAddressCount: 3,
        typeBilling: "BILL",
        typeShipping: "SHIP",
        showConfirmPopup: false,
        addressItem: {},
        addressId: "",
        addressType: "",
        billingAddress: [],
        shippingAddress: [],
        isDefault: false
    };

    componentDidMount = () => {
        this.props.mesAddressStart();
        const shippingAddress = this.props.address.filter(item => item.addressType === "SHIP");
        const billingAddress = this.props.address.filter(item => item.addressType === "BILL");
        this.setState({ billingAddress, shippingAddress })
    };
    componentWillReceiveProps = (nextProps) => {
        if (this.props.address && nextProps.address && nextProps.address.length !== this.props.address.length) {
            const shippingAddress = nextProps.address.filter(item => item.addressType === "SHIP");
            const billingAddress = nextProps.address.filter(item => item.addressType === "BILL");
            this.setState({ billingAddress, shippingAddress })
        }
    };
    toggleAddressPopup = (id: any = "", type: any = "", e) => {
        e.stopPropagation();
        let item: any = {};
        if (type === this.state.typeBilling) {
            item = this.state.billingAddress.find(obj => obj.clientAddressId === id);
        } else {
            item = this.state.shippingAddress.find(obj => obj.clientAddressId === id);
        }
        if (!item) {
            item = { addressType: type };
        }
        this.setState({ showAddressPopup: !this.state.showAddressPopup, addressItem: item, addressType: type })
    };
    handleUpsertAddressItem = (data: any = {}) => {
        let addresses = data.addressType === this.state.typeBilling ? this.state.billingAddress : this.state.shippingAddress;
        if (!data.clientAddressId && !data.webClientId) {
            const name = data.name ? data.name.split(" ") : "";
            const firstName = name[0];
            name.shift();
            const lastName = name.join(" ");
            const body = {
                "address1": data.address1 || "",
                "address2": data.address2 || "",
                "addressType": data.addressType || "",
                "city": data.city || "",
                "clientAddressId": 0,
                "firstName": firstName,
                "lastName": lastName,
                "country": data.country || "",
                "email": this.props.user.email || "",
                "isDefault": true,
                "name": data.name || "",
                "phone": data.phone || "",
                "zip": data.zip || ""
            };
            this.props.mesAddressPostStart(body);
        } else {
            const objIndex = addresses.findIndex(obj => obj.clientAddressId == data.clientAddressId);
            //REMOVE AFTER LASTNAME AND FIRSTNME VALUE
            const seperated = data.name.split(" ");
            data.firstName = seperated[0];
            seperated.shift();
            data.lastName = seperated.join(" ");
            //end remove
            this.props.mesAddressPostStart(data);
            addresses[objIndex] = data;
        }
        this.setState({ showAddressPopup: false })
    };
    toggleRemovePopup = (id: any = "", type: string = "", e) => {
        if (e) {
            e.stopPropagation();
        }
        this.setState({ showConfirmPopup: !this.state.showConfirmPopup, addressId: id, addressType: type })
    };
    handleRemoveAddress = () => {
        const { addressId, addressType } = this.state;
        this.props.mesAddressRemoveStart({ addressType, clientAddressId: addressId });
        this.setState({ showConfirmPopup: !this.state.showConfirmPopup, addressId: "", addressType: "" })

        let addresses = addressType === this.state.typeBilling ? this.state.billingAddress : this.state.shippingAddress;
        const firstAddress = addresses && addresses[0];
        if (firstAddress) {
            this.setDefault(firstAddress.clientAddressId, addressType)
        }
    };
    setDefault = (addressId, addressType) => {
        let addresses = addressType === this.state.typeBilling ? this.state.billingAddress : this.state.shippingAddress;
        const objIndex = addresses.findIndex(obj => obj.clientAddressId == addressId);
        const defaultIndex = addresses.findIndex(obj => obj.isDefault === true);
        addresses[objIndex].isDefault = true;
        //REMOVE AFTER LASTNAME AND FIRSTNME VALUE
        const seperated = addresses[objIndex].name.split(" ");
        addresses[objIndex].firstName = seperated[0];
        seperated.shift();
        addresses[objIndex].lastName = seperated.join(" ");
        //end remove
        this.props.mesAddressPostStart(addresses[objIndex]);

        addresses[defaultIndex] ? addresses[defaultIndex].isDefault = false : "";

        addressType === this.state.typeBilling ? this.setState({ billingAddress: addresses }) : this.setState({ shippingAddress: addresses })
    };

    render() {
        const { billingAddress, shippingAddress, showAddressPopup, typeBilling, typeShipping, showConfirmPopup, addressItem } = this.state;
        return (
            <div className="mes-address">
                <div className="grayBox">
                    <div className="mes-address-section">
                        <h5 className="section-title">{i18next.t("space.address.1")}</h5>
                        <div className="flex-box">
                            {Boolean(shippingAddress && shippingAddress.length) &&
                                shippingAddress.map((item, i) => (
                                    <div className={`item ${item.isDefault ? "item-default" : "item-choose-address"}`}
                                        key={i}>
                                        <div className='item-content'>
                                            <div className="item-content-flex-box">
                                                <div className='info-box'>
                                                    <p className="user-name">{`${item.name}`}</p>
                                                    <p className="address">{`${item.address1}`}</p>
                                                    <p className="city">{`${item.zip} ${item.city}`}</p>
                                                    <p className="country">{item.country}</p>
                                                    <p className="phone">{item.phone}</p>
                                                </div>
                                                <div className='del-btn c-pointer'
                                                    onClick={(e) => this.toggleRemovePopup(item.clientAddressId, typeShipping, e)}>
                                                    <Icon name="trash alternate"></Icon>
                                                </div>
                                            </div>
                                            <span className="main-link"
                                                onClick={(e) => this.toggleAddressPopup(item.clientAddressId, typeShipping, e)}>{i18next.t("space.address.3")}</span>
                                        </div>
                                        {item.isDefault ?
                                            <button className="main-btn">{i18next.t("space.address.7")}</button>
                                            :
                                            <span className="main-link"
                                                onClick={() => this.setDefault(item.clientAddressId, typeShipping)}>{i18next.t("space.address.5")}</span>
                                        }
                                    </div>
                                ))}
                            <div className='item item-add-address'>
                                <h5 className='title'>
                                    {i18next.t("space.address.6")}
                                </h5>
                                <div className='add-btn' onClick={(e) => this.toggleAddressPopup("", typeShipping, e)}>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="mes-address-section-white">
                    <div className="mes-address-section">
                        <h5 className="section-title">{i18next.t("space.address.2")}</h5>
                        <div className="flex-box">
                            {Boolean(billingAddress && billingAddress.length) && billingAddress.map((item, i) => (
                                <div className={`item ${item.isDefault ? "item-default" : "item-choose-address"}`}
                                    key={i}>
                                    <div className='item-content'>
                                        <div className="item-content-flex-box">
                                            <div className='info-box'>
                                                <p className="user-name">{item.name}</p>
                                                <p className="address">{`${item.address1} ${item.address2 ? item.address2 : ""}`}</p>
                                                <p className="zip">{`${item.zip} ${item.city}`}</p>
                                                <p className="country">{item.country}</p>
                                                <p className="phone">{item.phone}</p>
                                            </div>
                                            <div className='del-btn c-pointer'
                                                onClick={(e) => this.toggleRemovePopup(item.clientAddressId, typeBilling, e)}>
                                                <Icon name="trash alternate"></Icon>
                                            </div>
                                        </div>
                                        <span className="main-link"
                                            onClick={(e) => this.toggleAddressPopup(item.clientAddressId, typeBilling, e)}>{i18next.t("space.address.3")}</span>
                                    </div>
                                    {item.isDefault ?
                                        <button className="main-btn">{i18next.t("space.address.4")}</button>
                                        :
                                        <span className="main-link"
                                            onClick={() => this.setDefault(item.clientAddressId, typeBilling)}>{i18next.t("space.address.4")}</span>
                                    }
                                </div>
                            ))}

                            <div className='item item-add-address'>
                                <h5 className='title'>
                                    {i18next.t("space.address.6")}
                                </h5>
                                <div className='add-btn' onClick={(e) => this.toggleAddressPopup("", typeBilling, e)}>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showAddressPopup &&
                    <AddressPopup handleToggleAddressPopup={this.toggleAddressPopup}
                        handleUpsertAddressItem={this.handleUpsertAddressItem}
                        item={addressItem} />
                }
                {showConfirmPopup &&
                    <ConfirmPopup handleCancel={this.toggleRemovePopup}
                        handleConfirm={this.handleRemoveAddress}
                        text={i18next.t('product.basket.address.12')}
                        title={i18next.t('product.basket.address.11')} />
                }
            </div>
        )
    }
}

const mapStateToProps = (state: any): Props => {
    return {
        address: getMesAddress(state),
        error: getError(state),
        status: getStatus(state),
        user: getUser(state)
    }
};
const mapDispatchToProps = (dispatch): typeof MesAddressActionCreators => {
    return bindActionCreators(MesAddressActionCreators, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(MesAddress);