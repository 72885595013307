import {all, spawn} from "redux-saga/effects";
import userSaga from "../modules/login/redux/sagas";
import userAccountSaga from "../modules/account/redux/sagas";
import productSaga, {productCareSaga, productCompositionSaga} from "../modules/product/redux/sagas";
import productsSaga from "../modules/products/redux/sagas";
import addToCartSaga from "../modules/addToCart/redux/sagas";
import collectionSaga from "../modules/collection/redux/sagas";
import timelineSaga from "../modules/timeline/redux/sagas";
import orEtArgentSaga from "../modules/orEtArgent/redux/sagas";
import menuSaga from "../layouts/main/header/redux/sagas";
import homeSaga from "../modules/home/redux/sagas";
import connectionSaga from "../modules/connection/redux/sagas";
import creationSaga from "../modules/creation/redux/sagas";
import validationSaga from "../modules/validation/redux/sagas";
import mentionLegalesSaga from "../modules/mentionLegales/redux/sagas";
import subscribeSaga from "../layouts/main/footer/redux/sagas";
import locationSearchSaga from "../modules/locationSearchInput/redux/sagas";
import spaceSaga from "../modules/monEspace/redux/sagas";
import mesInformationSaga from "../modules/monEspace/container/components/mesInformations/redux/sagas";
import mesCommandesSaga from "../modules/monEspace/container/components/mesCommandes/redux/sagas";
import contactUs from "../modules/services/contactUs/redux/sagas";
import mesAddressSaga from "../modules/monEspace/container/components/MesAddress/redux/sagas";
import orderSaga from "../modules/basket/redux/sagas";
import SearchingSaga from "../modules/search/redux/sagas";
import paymentSaga from "../modules/payment/redux/sagas";
import motdePasseSaga from "../modules/motdepasse/redux/sagas";
import retourSaga from "../modules/retour/redux/sagas";
import DownloadSaga from "../modules/recapCommande/redux/sagas";
import catalogueSaga from "../modules/services/catalogue/redux/sagas";
import blogSaga from "../modules/blog/redux/sagas";
import ShopperSaga from "../modules/magasin/redux/sagas";
export default function* rootSaga() {
    yield all([
        spawn(userSaga),
        spawn(userAccountSaga),
        spawn(productSaga),
        spawn(productCareSaga),
        spawn(productCompositionSaga),
        spawn(productsSaga),
        spawn(addToCartSaga),
        spawn(menuSaga),
        spawn(timelineSaga),
        spawn(orEtArgentSaga),
        spawn(collectionSaga),
        spawn(homeSaga),
        spawn(connectionSaga),
        spawn(creationSaga),
        spawn(mentionLegalesSaga),
        spawn(validationSaga),
        spawn(subscribeSaga),
        spawn(locationSearchSaga),
        spawn(mesInformationSaga),
        spawn(mesCommandesSaga),
        spawn(mesAddressSaga),
        spawn(contactUs),
        spawn(orderSaga),
        spawn(SearchingSaga),
        spawn(paymentSaga),
        spawn(motdePasseSaga),
        spawn(spaceSaga),
        spawn(retourSaga),
        spawn(DownloadSaga),
        spawn(catalogueSaga),
        spawn(blogSaga),
        spawn(ShopperSaga)
    ]);
}
