import {createReducer} from 'reduxsauce';
import {LocationSearchTypes} from './actions';

export interface ILocationSearchState {
    locationSearchData: any,
    error: string,
    loading?: boolean,
    searchLoading?: boolean,
}

export const locationSearch: ILocationSearchState = {
    locationSearchData: [],
    error: "",
    loading: false,
    searchLoading: false,
};

export const getLocationSearch = (state) => {
    return { ...state, searchLoading: false };
};

export const locationSearchSuccess = (state, action) => {
   const sorted = action.data.sort((item,element) => item.distance - element.distance);
    return {...state, locationSearchData: sorted, searchLoading: false};
};

export const locationSearchFailure = (state, action) => {
    return {...state, error: action.error, locationSearchData: [], searchLoading: false};
};

export const clickAndCollectStoresStart = (state) => {
    return {...state, loading: true};
};

export const clickAndCollectStoresSuccess = (state, action) => {
    const sorted = action.data.sort((item,element) => item.distance - element.distance);
    return {...state, locationSearchData: sorted, loading: false};
};

export const clickAndCollectStoresFailure = (state, action) => {
    return {...state, error: action.error, locationSearchData: [], loading: false};
};

export const locationSearchDestroyedStart = (state) => {
    return {...state, locationSearchData: []};
};

export const handlers = {
    [LocationSearchTypes.LOCATION_SEARCH_START]: getLocationSearch,
    [LocationSearchTypes.LOCATION_SEARCH_SUCCESS]: locationSearchSuccess,
    [LocationSearchTypes.LOCATION_SEARCH_FAILURE]: locationSearchFailure,
    [LocationSearchTypes.CLICK_AND_COLLECT_STORES_START]: clickAndCollectStoresStart,
    [LocationSearchTypes.CLICK_AND_COLLECT_STORES_SUCCESS]: clickAndCollectStoresSuccess,
    [LocationSearchTypes.CLICK_AND_COLLECT_STORES_FAILURE]: clickAndCollectStoresFailure,
    [LocationSearchTypes.LOCATION_SEARCH_DESTROYED_START]: locationSearchDestroyedStart,
};

export default createReducer(locationSearch, handlers);
