import React from "react";
import { connect } from "react-redux";
import InfiniteScroll from 'react-infinite-scroll-component';
import { bindActionCreators } from "redux";
import { IProductsState } from "../redux/reducers";
import { ProductsActionCreators } from "../redux/actions";
import { getProducts, loading, loadMoreData, getMenu } from "../redux/selectors";
import { MetroSpinner } from "react-spinners-kit";
import CurrentPage from '../../../layouts/common/currentPage';
import CategoryFilters from '../../../layouts/common/categoryFilters/container/categoryFilters';
import ProductList from './productList';
import { getConvertedItemDataForGA4, getItemMenu } from '../../../helpers/helpers';
import { BRAND_ID, CATALOGUE_TYPE, MENU_TYPE } from "../../../utils/constants/variables";
//styles
import "./products.scss";
import i18next from "i18next";
import { Icon } from "semantic-ui-react";
import { TrackViewItemList } from "../../../helpers/ga4Tracker";

interface Props extends IProductsState {
    canonicalUrl?: string,
    lists?: any
}

interface ProductState {
    products?: any;
    loading?: boolean;
    loadMoreData?: boolean;
    menuList?: any;
}

interface State {
    ready: boolean;
    pageSize: number;
    selectedItem: any;
    filterModalIsOpen: boolean;
    totalElements: number;
    parent: any;
    resetFiltre: boolean,
    resetFiltrePath: string,
    filters: any,
    width: any,
    isFiltersVisible: boolean;
    isSortVisible: boolean;
    productCatalogue: {
        content: any[]
    };
    isPromotionSelected: boolean;
    sortingOptions: {
        field: string;
        altField: string;
        flow: string;
    };
    firstProducts: boolean,
}

class Products extends React.Component<Props & typeof ProductsActionCreators, State, ProductState> {
    state: State;
    productsRef: React.RefObject<any>;
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            ready: false,
            pageSize: 0,
            selectedItem: {},
            filterModalIsOpen: false,
            totalElements: 0,
            parent: [],
            resetFiltre: false,
            resetFiltrePath: "",
            filters: {},
            isFiltersVisible: false,
            isSortVisible: false,
            productCatalogue: {
                content: []
            },
            isPromotionSelected: false,
            sortingOptions: {
                field: '',
                altField: '',
                flow: ''
            },
            firstProducts: false,
        };

        this.productsRef = React.createRef();
    }

    componentDidMount(): void {
        window.addEventListener('resize', this.handleResize);

        if (!this.props.lists) {
            if (this.props.canonicalUrl && this.props.location.pathname !== this.props.canonicalUrl) this.props.history.push(this.props.canonicalUrl);
            this.setSelectedItem(this.props);
        };
    }

    componentWillReceiveProps = async (nextProps) => {
        const { menuList } = this.props;
        const { pageSize } = this.state;

        if (menuList !== nextProps.menuList) {
            const { menuItem } = this.props;
            await this.fetchProducts(menuItem, pageSize);
        }

        if (nextProps.products && Object.keys(nextProps.products).length) {
            this.setState({ ready: true });
        }

    };
    componentDidUpdate(prevProps) {
        const { products } = this.props;

        if (products.filters !== prevProps.products.filters && !this.props.lists) {
            if (!this.props.products.filters) {
                const setPathName = this.props.location.pathname.substring(0, this.props.location.pathname.indexOf('filtres'));
                if (!this.props.lists) {
                    this.setState({ resetFiltre: true, resetFiltrePath: setPathName });
                } else {
                    this.setState({ resetFiltre: true });
                }
                window.location.assign(setPathName)
            }
        }

        if (
            products.catalogue
            && prevProps.products.catalogue
            && products.catalogue !== prevProps.products.catalogue
            && !this.props.lists
        ) {
            const { sortingOptions: { field, altField, flow } } = this.state;
            this.setState((current) => {
                const content = field
                    ? this.sortItems(field, altField, flow, products.catalogue.content)
                    : products.catalogue.content;
                return { ...current, productCatalogue: { content } }
            })
            this.setState({ productCatalogue: products.catalogue })
        }

        if (products.catalogue
            && products.catalogue.content
            && products.catalogue.content.length
            && !this.state.firstProducts
            && products.catalogue !== prevProps.products.catalogue
        ) {
            let dataCatalogue = null as any

            if (this.state.productCatalogue.content[0]) {
                const itemMenu = this.state.productCatalogue.content[0].menuId
                const newItemMenu = products.catalogue.content && products.catalogue.content[0].menuId
                if (itemMenu !== newItemMenu) dataCatalogue = products.catalogue.content
                else this.setState({
                    productCatalogue: {
                        content: []
                    }
                })
            } else {
                dataCatalogue = products.catalogue.content
                this.setState({ productCatalogue: products.catalogue })
            }
            this.setState({ firstProducts: true })
            if (dataCatalogue) {
                const items = [] as any
                dataCatalogue.map((item: any) => {
                    const dataT = getConvertedItemDataForGA4(this.props.menuItem, item, item.quantity && item.quantity > 0 ? item.quantity : 1);
                    items.push(dataT)
                })
                TrackViewItemList(items)
            }
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        return this.props.resetFiltre != nextState.resetFiltre;
    }
    fetchMoreData = async () => {
        const { pageSize, selectedItem } = this.state;
        const { loadMoreData, products } = this.props;
        const { catalogue } = products;

        if (catalogue && catalogue.content && catalogue.content.length) {
            if (catalogue.totalElements === catalogue.content.length) {
                return
            }
        }

        if (catalogue && catalogue.content && catalogue.pageable && ((pageSize + 1) * catalogue.size) >= catalogue.totalElements) {
            return
        }

        if (!loadMoreData) {
            return;
        }
        await this.fetchProducts(selectedItem, pageSize + 1);
        this.setState((current) => ({ ...current, pageSize: pageSize + 1 }))
    };

    fetchProducts = async (selectedItem, pageSize) => {
        const filters = JSON.stringify(this.state.filters);
        await this.props.productsStart(selectedItem, pageSize, filters, BRAND_ID);
    };

    setSelectedItem(props) {
        const { pageSize, filters } = this.state;
        const { menuItem } = props;
        let parent: any = [];
        if (menuItem.menuType === CATALOGUE_TYPE) {
            const parentEl = getItemMenu(MENU_TYPE, menuItem.key, this.props.menuList);
            parent = parentEl ? [parentEl] : [];
        }
        this.setState({ selectedItem: menuItem, parent }, () => {
            if (filters !== null) {
                this.fetchProducts(menuItem, pageSize);
            }
        });
    }



    toggleClick = () => {
        const { filterModalIsOpen } = this.state;
        if (!filterModalIsOpen) {
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        } else {
            document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
        }

        this.setState({
            filterModalIsOpen: !filterModalIsOpen
        });
    };

    updateProducts = async (filtersObj, load = true, type = "") => {
        const { products } = this.props;
        let requestObj: any = { filters: { ...filtersObj } }
        if (filtersObj.Color && filtersObj.Color.length) {
            requestObj = { filters: { ...filtersObj }, filterColor: true }
        } else {
            requestObj = { filters: { ...filtersObj }, filterColor: false }
        }
        const colors = products.filters && products.filters.find(item => item.searchFilter.toUpperCase() === "COLOR");
        if (colors && colors.values && colors.values.length) {
            let newObj: any = { ...requestObj };
            requestObj = { ...newObj, existingColors: colors.values }
        }
        this.setState({ pageSize: 0, filters: requestObj }, () => load ? this.fetchProducts(this.state.selectedItem, 0) : "");
    };
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    toggleFilters = () => {
        const { isFiltersVisible } = this.state;
        this.setState({ isFiltersVisible: !isFiltersVisible })
    }

    toggleSort = () => {
        const { isSortVisible } = this.state;
        this.setState({ isSortVisible: !isSortVisible })
    }

    sortItems = (
        by: string,
        altBy: string,
        type: string,
        list: any = []
    ) => {
        return list.sort((item, nextItem) => {
            const currentValue = (item[by] ? item[by] : item[altBy]) || 0;
            const nextValue = (nextItem[by] ? nextItem[by] : nextItem[altBy]) || 0;

            return type === 'asc'
                ? currentValue - nextValue
                : nextValue - currentValue
        });
    }

    handleSortAction = (
        by: string,
        altBy: string,
        type: string,
        list: any = []
    ) => {
        const content = this.sortItems(by, altBy, type, list);
        this.setState({
            productCatalogue: {
                content
            },
            isPromotionSelected: false,
            sortingOptions: {
                field: by,
                altField: altBy,
                flow: type
            }
        })
        this.toggleSort();
    }

    handleFilterAction = (type: string) => {
        const { products } = this.props;
        const list = products && products.catalogue && products.catalogue.content
        if (type === "discount") {
            const filteredData = list.filter((item) => item.listPriceAfterDiscount);
            if (filteredData.length) {
                this.setState({ productCatalogue: { content: filteredData }, isPromotionSelected: true })
            }
        }
        this.toggleSort();
    }

    handleResize = () => { this.setState({ width: window.innerWidth }) };

    render(): React.ReactNode {
        const { products, loading, menuList, loadMoreData, menuItem } = this.props;
        const { catalogue } = products;
        const { filterModalIsOpen, ready, parent, resetFiltre, width, isFiltersVisible, isSortVisible, productCatalogue } = this.state;
        let size = catalogue ?
            catalogue.content && catalogue.content.length && (catalogue.content.length <= catalogue.totalElements)
                ? catalogue.content.length : catalogue.totalElements
            : 0;
        return (
            <div>
                {menuItem && Object.keys(menuItem).length ?
                    <CurrentPage selectedItem={menuItem} selectedParent={parent} /> : ""
                }
                <section className={`product-section housse-container ${resetFiltre ? "centeredBox" : ""} flex-column`} ref={this.productsRef}>
                    {filterModalIsOpen && (<div className="mobile-filter">
                        <CategoryFilters
                            menuList={menuList ? menuList : []}
                            filters={products && products.filters ? products.filters : []}
                            toggle={filterModalIsOpen}
                            toggleClick={this.toggleClick}
                            updateFilters={this.updateProducts}
                        />
                    </div>)}
                    <div className="filters-container">
                        {(size || "") && <div className="filter-fixed">
                            <span
                                className="text-uppercase text-bold c-pointer"
                                onClick={this.toggleFilters}
                            >
                                {i18next.t('footer.contact.4')}
                                {isFiltersVisible ? (
                                    <Icon name="chevron up" className="ml_15" />
                                ) : (
                                    <Icon name="chevron down" className="ml_15" />
                                )}
                            </span>
                            <span
                                className="text-uppercase text-bold c-pointer"
                                onClick={this.toggleSort}
                            >
                                {i18next.t('footer.contact.5')}
                                {isSortVisible ? (
                                    <Icon name="chevron up" className="ml_15" />
                                ) : (
                                    <Icon name="chevron down" className="ml_15" />
                                )}
                            </span>
                            {isFiltersVisible && (
                                <div className="filters-body">
                                    <CategoryFilters
                                        menuList={menuList ? menuList : []}
                                        filters={products && products.filters ? products.filters : []}
                                        toggle={false}
                                        toggleClick={this.toggleClick}
                                        updateFilters={this.updateProducts}
                                        isPopupFilter={true}
                                    />
                                </div>
                            )}
                            {isSortVisible && (
                                <div className="sort-body">
                                    <div
                                        className="sort-item"
                                        onClick={this.handleSortAction.bind(
                                            this,
                                            'ranking',
                                            'ranking',
                                            'asc',
                                            catalogue.content
                                        )}
                                    >{i18next.t("product.filter.5")}</div>
                                    <div
                                        className="sort-item"
                                        onClick={this.handleSortAction.bind(
                                            this,
                                            'listPriceAfterDiscount',
                                            'startingPrice',
                                            'asc',
                                            catalogue.content
                                        )}
                                    >{i18next.t("product.filter.2")}: {i18next.t("product.filter.3")}</div>
                                    <div
                                        className="sort-item"
                                        onClick={this.handleSortAction.bind(
                                            this,
                                            'listPriceAfterDiscount',
                                            'startingPrice',
                                            'desc',
                                            catalogue.content
                                        )}
                                    >{i18next.t("product.filter.2")}: {i18next.t("product.filter.4")}</div>
                                    <div
                                        className="sort-item"
                                        onClick={this.handleFilterAction.bind(this, 'discount')}
                                    >{i18next.t("product.filter.6")}</div>
                                </div>
                            )}
                        </div>}
                    </div>
                    <div className={`product-content-outer  ${resetFiltre ? "centeredBox" : ""}`}>
                        <InfiniteScroll
                            dataLength={size}
                            next={this.fetchMoreData}
                            hasMore={ready && loadMoreData}
                            scrollThreshold={0.3}
                            loader={
                                <MetroSpinner
                                    size={50}
                                    color="#686769"
                                    loading={loading}
                                />
                            }
                        >
                            <ProductList
                                menuItem={menuItem}
                                list={this.props.lists || productCatalogue}
                                handleClick={this.toggleClick}
                                loading={loading}
                                resetFiltre={resetFiltre}
                                noFilter={false}
                            />
                        </InfiniteScroll>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (productState: any): ProductState => ({
    products: getProducts(productState),
    loading: loading(productState),
    loadMoreData: loadMoreData(productState),
    menuList: getMenu(productState),
});

const mapDispatchToProps = (dispatch): typeof ProductsActionCreators => {
    return bindActionCreators(ProductsActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
