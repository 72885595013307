import { createReducer } from 'reduxsauce';
import { ConnectionsTypes } from './actions';


export interface IConnectionState {
    connection: object,
    favoriteStore: object,
    error: string,
    reset: object,
    resetError: string,
    resetFinish: boolean
}

export const connections: IConnectionState = {
    connection: {},
    favoriteStore: {},
    error:'',
    reset: {},
    resetError:'',
    resetFinish: false
};

export const connection = (state) => {
    return {...state};
};


export const connectionSuccess = (state, action) => {
    return { ...state, connection: action.data };
};

export const connectionFailure = (state, action) => {
    return { ...state, error: action.error, connection: {} };
};

export const connectionPasswordResetStart = (state) => {
    return { ...state, resetError: "", reset: {} };
};

export const connectionPasswordResetSuccess = (state, action) => {
    return { ...state, reset: action.data };
};

export const connectionPasswordResetFailure = (state, action) => {
    return { ...state, resetError: action.error, reset: {} };
};

export const connectionPasswordResetFinishStart = (state) => {
    return { ...state, resetError: "" };
};

export const connectionPasswordResetFinishSuccess = (state, action) => {
    return { ...state, resetFinish: true };
};

export const connectionPasswordResetFinishFailure = (state, action) => {
    return { ...state, resetError: action.error, resetFinish: false };
};

export const favoriteStoreStart = (state) => {
    return { ...state };
};

export const favoriteStoreSuccess = (state, action) => {
    return { ...state, favoriteStore: action.data };
};

export const favoriteStoreFailure = (state, action) => {
    return { ...state, error: action.error, favoriteStore: {} };
};
export const resetConnection = (state) => {
    return { ...state, reset:{}, resetFinish: false};
};

export const handlers = {
    [ConnectionsTypes.CONNECTION_START]: connection,
    [ConnectionsTypes.CONNECTION_SUCCESS]: connectionSuccess,
    [ConnectionsTypes.CONNECTION_FAILURE]: connectionFailure,
    [ConnectionsTypes.CONNECTION_PASSWORD_RESET_START]: connectionPasswordResetStart,
    [ConnectionsTypes.CONNECTION_PASSWORD_RESET_SUCCESS]: connectionPasswordResetSuccess,
    [ConnectionsTypes.CONNECTION_PASSWORD_RESET_FAILURE]: connectionPasswordResetFailure,
    [ConnectionsTypes.CONNECTION_PASSWORD_RESET_FINISH_START]: connectionPasswordResetFinishStart,
    [ConnectionsTypes.CONNECTION_PASSWORD_RESET_FINISH_SUCCESS]: connectionPasswordResetFinishSuccess,
    [ConnectionsTypes.CONNECTION_PASSWORD_RESET_FINISH_FAILURE]: connectionPasswordResetFinishFailure,
    [ConnectionsTypes.FAVORITE_STORE_START]: favoriteStoreStart,
    [ConnectionsTypes.FAVORITE_STORE_SUCCESS]: favoriteStoreSuccess,
    [ConnectionsTypes.FAVORITE_STORE_FAILURE]: favoriteStoreFailure,
    [ConnectionsTypes.RESET_CONNECTION_START]: resetConnection,
};


export default createReducer(connections, handlers);