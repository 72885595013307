import {createActions} from 'reduxsauce';

export const {Types: MesInformationTypes, Creators: MesInformationActionCreators} = createActions ({
    mesInformationStart: ['callback'],
    mesInformationSuccess: ['data'],
    mesInformationFailure: ['error'],
    mesInformationPostStart: ['data'],
    mesInformationPostSuccess: ['data'],
    mesInformationPostFailure: ['error'],
    mesInformationChangePassStart: ['data'],
    mesInformationChangePassSuccess: ['data'],
    mesInformationChangePassFailure: ['error']
});