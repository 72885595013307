import React from "react"
import { useEffect, useState } from "react"
import AccordionDescription from "../../../products/container/descriptionAcordion"
import { regForBr, stripHTMLreg } from "../../../../helpers/helpers"

const MenuKeywordWrapper = ({ menuItem }) => {
    const [width, setWidth] = useState(window.innerWidth)
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth)
        }
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <>

            {
                menuItem && menuItem.keywords && !menuItem.isProductPage ?
                    <div className="products">
                        <div>
                            <section className="pour-products keyword">
                                <div className="home-container">
                                    <div>
                                        <div className="poure-products-category-content keyword">
                                            <h3 className="description-luxury-text-title" style={{ fontSize: "24px" }}>{menuItem.label.replace(stripHTMLreg, "")}</h3>
                                        </div>
                                        <div className="poure-products-category-content-text keyword">
                                            <h3 className="poure-products-category-text keyword" dangerouslySetInnerHTML={{ __html: menuItem ? menuItem.keywords.replace(regForBr, "") : "" }} style={{ fontSize: "17px" }}></h3>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    : ""
            }
            {
                width < 768 ?
                    <AccordionDescription content={menuItem && menuItem.keywords} label={menuItem && menuItem.label} /> : ""
            }
        </>
    )
}

export default MenuKeywordWrapper