import React from "react";
import { connect } from "react-redux";
import i18next from "i18next";
import Input from '../modules/common/input/Input'
import './soumetre.scss'
import { getMenu } from "../layouts/main/header/redux/selectors";
import BlackWindow from "../modules/common/blackWindow";

import closeIcon from "./../assets/img/close.svg"
import { ConnectionsActionCreators } from "../modules/connection/redux/actions";
import { getResetSuccess, getResetError, getResetFinish } from "../modules/connection/redux/selectors";
import { bindActionCreators } from "redux";
import { emailIsValid } from "../helpers/helpers";


interface Props extends State {
    handleBasketToggle?: any,
    menuList: any,
    openpop?: boolean,
    items?: any,
    total?: any,
    handleClick: any,
    email?: string,
    legacyUser?: boolean,
    handleClose: any,
    finish: any
}

interface State {
    items?: any,
    menuList?: any,
    total?: number,
    connection?: any,
    error?: any,
    finish?: any

}

class Soumattre extends React.Component<Props & typeof ConnectionsActionCreators> {
    state = {
        emailToReset: '',
        validEmail: false,
        showError: false,
    };

    componentDidUpdate(prevProps) {
        if (this.props.error && prevProps.error !== this.props.error) {
            this.setState({ showError: true })
        }
    }

    componentDidMount() {
        if (this.props.email) {
            this.setState({ emailToReset: this.props.email });
        }
    }

    handleBasketToggle = () => {
        this.props.handleClose()
    };

    handleResetConnection = () => {
        this.props.resetConnectionStart()
        this.handleBasketToggle()
    }

    handleResetPassword = () => {
        this.setState({ showError: true });
        this.props.connectionPasswordResetStart(this.state.emailToReset);
    };


    handleChangeEmail = (e) => {
        const emailToReset = e.target.value;
        const validEmail = emailIsValid(emailToReset);
        this.setState({ emailToReset, validEmail });
    };

    render(): React.ReactNode {
        const { menuList, connection, error } = this.props;
        const { validEmail, emailToReset, showError } = this.state;
        const legacyUser = this.props.email && this.props.email === emailToReset;
        return (
            <>
                <BlackWindow type="page" handleClick={this.handleBasketToggle} />

                <div className="basket-popup active">
                    <div className="close" onClick={this.handleBasketToggle}>
                        <img src={closeIcon} alt="PHOTO" />
                    </div>
                    <div className="basket-popup-content">
                        <div className="basket-popup-shop-title">
                            <div className="shoppingBox">
                                <h4 className="shop-title">{i18next.t('soumettre.1')}</h4>
                            </div>
                            <img src={closeIcon} onClick={this.handleBasketToggle.bind(this, false)} alt="PHOTO" />
                        </div>
                        <div className="product-content1">

                            {(!connection.userstatus || connection.userstatus === "RESET_PASSWORD_FAILED") ?
                                <>
                                    <p className="product-content-text">{i18next.t('soumettre.2')}</p>
                                    <Input
                                        important={true}
                                        text={i18next.t('soumettre.3')}
                                        name="emailToReset"
                                        value={emailToReset}
                                        type='email'
                                        id='1'
                                        disabled={legacyUser}
                                        handleChange={this.handleChangeEmail}
                                    />

                                    <button
                                        onClick={this.handleResetPassword}
                                        className={`button-style product-content-btn hoverButtonStyle ${!validEmail && !legacyUser && "disabled"}`}
                                        disabled={!validEmail && !legacyUser}>{i18next.t('soumettre.4')}
                                    </button>
                                </>
                                : ""
                            }

                            {
                                connection.userstatus === "RESET_PASSWORD_EMAIL_SENT" ?
                                    <>
                                        <p className="product-content-text">{i18next.t("forgotPassword.5")}</p>

                                        <button onClick={this.handleResetConnection} className="button-style product-content-btn hoverButtonStyle" >
                                            {i18next.t('soumettre.5')}
                                        </button>
                                    </>
                                    : ""
                            }

                            {
                                error && showError && connection.userstatus !== "RESET_PASSWORD_EMAIL_SENT" ?
                                    <div className="errorMessage2">{error}</div>
                                    : ""
                            }

                        </div>

                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any): State => ({
    menuList: getMenu(state),
    connection: getResetSuccess(state),
    error: getResetError(state),
    finish: getResetFinish(state)
});


const mapDispatchToProps = (dispatch): typeof ConnectionsActionCreators => {
    return bindActionCreators(ConnectionsActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Soumattre);
