import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import i18next from "i18next";
import {MenuActionCreators} from "./../../redux/actions";
import {getLanguage, getMenu} from "../../redux/selectors";

import {getLang} from "./../../../../../helpers/helpers";
// Icons
import fr_flag from "./../../../../../assets/img/Language/fr.png"
import de_flag from "./../../../../../assets/img/Language/de.png"
import en_flag from "./../../../../../assets/img/Language/en.png"
import it_flag from "./../../../../../assets/img/Language/it.png"
//style
import "./Languege.scss"


interface State {
    showLanguage?: boolean;
    setLanguage: string
}
interface Props {
    menu: any,
    language: string
}
class Language extends Component<Props & typeof MenuActionCreators> {
    state : State= {
        showLanguage : false,
        setLanguage : "fr",
    };

    componentDidMount(){
        this.setState({setLanguage: getLang()})
    }

    hendleShowLanguage = () => {
        this.setState({showLanguage: !this.state.showLanguage})
    }

    hendleChangeLanguage = (Lang) => {
        this.setState({setLanguage: Lang})
        i18next.changeLanguage(Lang);

        // const path = this.props.history.location.pathname
        // const url = path.replace(/\/[a-z]{2}\//, `/`)

        this.props.history.push(`/${Lang}`);

        this.props.languageStart(Lang);

        this.props.menuStart()
    }

    render(){
        const {showLanguage,setLanguage} = this.state

        return(
            <div className="language">
                <ul className={`language--list ${showLanguage ? " show ":" "}`}
                    onClick={this.hendleShowLanguage}>
                    {/*<li className={`language--list--item ${setLanguage==="fr"? "active":""}`}*/}
                    {/*    onClick={()=> setLanguage!=="fr" ? this.hendleChangeLanguage("fr"):""}>*/}
                    {/*    <img className="flagImg" src={fr_flag} alt="Fr"/>*/}
                    {/*</li>*/}
                    {/*<li className={`language--list--item ${setLanguage==="de"? "active":""}`}*/}
                    {/*    onClick={()=> setLanguage!=="de"? this.hendleChangeLanguage("de") : ""}>*/}
                    {/*    <img className="flagImg" src={de_flag} alt="De"/>*/}
                    {/*</li>*/}
                    {/*<li className={`language--list--item ${setLanguage==="en"? "active":""}`}*/}
                    {/*    onClick={()=> setLanguage!=="en"? this.hendleChangeLanguage("en") : ""}>*/}
                    {/*    <img className="flagImg" src={en_flag} alt="En"/>*/}
                    {/*</li>*/}
                    <li className={`language--list--item ${setLanguage==="it"? "active":""}`}
                        onClick={()=> setLanguage!=="it" ? this.hendleChangeLanguage("it"):""}>
                        <img className="flagImg" src={it_flag} alt="It"/>
                    </li>
                </ul>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    menu: getMenu(state),
    language: getLanguage(state),

});

const mapDispatchToProps = (dispatch): typeof MenuActionCreators => {
    return bindActionCreators({ ...MenuActionCreators}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Language));


