import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import i18next from "i18next";
import {
    getRouteByMenuKey,
    getDataBySectionAndWidget,
    dateStringFormat,
    addDays
} from "../../../helpers/helpers";
import { BASE_API_GATEWAY } from "../../../utils/constants/service";
import { getMenu, getUser } from "../../../layouts/main/header/redux/selectors";
import { getUpdateStatus } from "../../basket/redux/selectors";
import { getValidation } from "../../validation/redux/selectors";
import { IValidationState } from "../../validation/redux/reducers";
import { ValidationActionCreators } from "../../validation/redux/actions";
import { getUserStore } from "../../location/redux/selectors";
import { DISPATCH_TIME, STORE_DEPOT } from "../../../utils/constants/variables";

interface Props extends IValidationState {
    menuList: any,
    updateStatus: string,
    acceptedPage: any,
    user: any,
    location?: any,
    data: any,
    userStore: any,
    ID?: string,
    BaskstStore?: any,
}

class Acceped extends React.Component<Props & typeof ValidationActionCreators> {

    componentDidMount() {
        if (this.props.acceptedPage && this.props.acceptedPage.gridId) {
            this.props.validationStart(this.props.acceptedPage.gridId)
        }
    }

    render() {
        const { menuList, location, data, userStore, ID, BaskstStore } = this.props;
        const selectedStore = location && location.state && location.state.BaskstStore ? location.state.BaskstStore["StoreInfo"] : BaskstStore ? BaskstStore["StoreInfo"] : "";
        const storeDelivery = location && location.state && location.state.BaskstStore ? location.state.BaskstStore["storeDelivery"] : BaskstStore ? BaskstStore["storeDelivery"] : "";
        if ((!(location && location.state && location.state.id)) || !((userStore && userStore.storeCode) || (selectedStore && Object.keys(selectedStore).length))) {
            if (!ID) {
                return (<Redirect to={"/"} />)
            }
        }
        const store = selectedStore ? selectedStore : userStore;
        return (
            <div>


                <div className="small-container">

                    <div className="validation">
                        <h2>{i18next.t('validation.1')}</h2>
                        <h4 className="validation-title">{i18next.t('validation.2')}</h4>
                        <h4>{i18next.t('validation.3')}</h4>
                        <p>{i18next.t('validation.4')}:
                            <span className="bold-text">&nbsp;{location && location.state && location.state.id ? location.state.id : ID ? ID : ""}</span>
                        </p>
                        {
                            STORE_DEPOT === "1" ? <h4>{i18next.t('validation.18')}</h4> :
                                <p>{i18next.t('locator.8')}<span className="bold-text">
                                    {selectedStore ?
                                        storeDelivery === "STO" ? ` ${dateStringFormat("", addDays(new Date(), 1))} 14h` :
                                            storeDelivery === "DIS" ? ` ${dateStringFormat("", addDays(new Date(), DISPATCH_TIME))}`
                                                : userStore && userStore.hasBasket === false ?
                                                    ` ${dateStringFormat("threeDaysAfter")} 14h`
                                                    :
                                                    ` ${dateStringFormat("tomorrow")} 14h` : ""} </span></p>
                        }
                    </div>
                    <div className="validation storeDetals">
                        <p className="storeName">{store.name}</p>
                        <p>{store.address}</p>
                        <p>{store.zip}</p>
                        {store.phone ? <p>Tél: {store.phone}</p> : ""}

                        <Link to="/">{i18next.t('validation.6')}</Link>
                    </div>
                    <div className="validation-item-content">
                        <h4>{i18next.t('validation.7')}</h4>
                        <div className="dflex justify-content-between">
                            <Link className="validation-item--parent" to={getRouteByMenuKey(menuList, "notre_savoir_faire").route}>
                                <div className="validation-item">
                                    <img
                                        src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(1, 1, data, "PHOTO").value}`}
                                        alt="validation image" />
                                    <div className="validation-item-text">
                                        <p dangerouslySetInnerHTML={{ __html: getDataBySectionAndWidget(1, 2, data).value }}></p>
                                    </div>
                                </div>
                            </Link>

                            <Link className="validation-item--parent" to={getRouteByMenuKey(menuList, "nouveaux_precieux").route}>
                                <div className="validation-item">
                                    <img
                                        src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(2, 1, data, "PHOTO").value}`}
                                        alt="validation image" />
                                    <div className="validation-item-text">
                                        <p dangerouslySetInnerHTML={{ __html: getDataBySectionAndWidget(2, 2, data).value }}></p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    const acceptedPage = state.menuR.menu.length ? state.menuR.menu.find(item => item.key == "paiement") : {};
    return {
        data: getValidation(state),
        menuList: getMenu(state),
        acceptedPage: acceptedPage,
        updateStatus: getUpdateStatus(state),
        user: getUser(state),
        userStore: getUserStore(state),
    };
};

const mapDispatchToProps = (dispatch): typeof ValidationActionCreators => {
    return bindActionCreators(ValidationActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Acceped);
