import {SearchingTypes, SearchingCreators} from "./actions";
import {takeLatest, put, call, all} from "redux-saga/effects";
import axios from 'axios';
import {SEARCHING_URL } from "../../../utils/constants/service";
import {getApiPrefix} from "../../../helpers/helpers"
import {ALT_BRAND_ID, BRAND_ID, COUNTRY, PROMOTION_CLUSTER} from "../../../utils/constants/variables";



export function* getSearchingData(params) {
    try {
        const headers = {headers: {brand: BRAND_ID || ALT_BRAND_ID, language: getApiPrefix(),country:COUNTRY, promoCluster: PROMOTION_CLUSTER}};
        const data = yield call(() => {
            return axios.get( `${SEARCHING_URL}?criteria=${params.data.criteria}&page=${params.data.page}&size=${params.data.size}&sort=ranking%2Casc` , headers)
                .then(res => res.data)
        });
        yield put(SearchingCreators.searchingSuccess({data, size: params.data.size,criteria: params.data.criteria }));
    } catch (e) {
        yield put(SearchingCreators.searchingFailure(e.message));
    }
}



export function* SearchingSaga() {
    yield takeLatest(SearchingTypes.SEARCHING_START, getSearchingData);
}

export default SearchingSaga;

