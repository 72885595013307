import React from "react";
import {connect} from "react-redux";
import i18next from "i18next";

import "./location.scss";
import LocationSearchInput from "../../locationSearchInput/container/locationSearchInput";
import {getLocationSearch, getLocationSearchLoading} from "../../locationSearchInput/redux/selectors";
import {
    getOpeningHours,
    formatOpeningHours,
    getRouteByMenuKey
} from "../../../helpers/helpers";


import closeIcon from "../../../assets/img/close.svg"
import {getUserStore} from "../redux/selectors";
import {StoreLocationActionCreators} from "../redux/actions";
import {bindActionCreators} from "redux";
import MapComponent from "./map"
import {Link} from "react-router-dom";
import {getMenu} from "../../monEspace/redux/selectors";

interface Props {
    handleShow: any,
    locationSearchData: any,
    userStore: any,
    storeLocationStart: any,
    menuList: any,
    loading: boolean
}

interface State {
    storeId:any
}

class Location extends React.Component<Props & State> {

    constructor(props) {
        super(props);
        this.state ={
            storeId:null
        };
    }

    private refsArray: any = [];

    setLocation = (Id) => {
        this.setState({
            storeId:Id
        });
        this.refsArray[Id].scrollIntoView()
    };

    render() {
        const { locationSearchData, menuList, loading } = this.props;
        return (
            <div className="location">
                <div className="location-content">
                    <div className="location-content-head">
                        <h4>{i18next.t('locator.3')}</h4>
                        <img src={closeIcon} className="x" onClick={this.props.handleShow} alt="PHOTO"/>
                    </div>
                    <div className="location-map">
                        {/*<img src={IMG} alt="location map"/>*/}
                        <div className="location-map--item map">
                            <MapComponent
                                locationSearch={locationSearchData}
                                locationStoreProps={this.props.storeLocationStart}
                                setLocation={this.setLocation}
                                loading={loading}
                            />
                        </div>

                        <div className="location-map--item info">
                            <div className="location-wrapper-item infoArea">
                                <div className="location-addres-content">

                                    {
                                        locationSearchData.length === 0 ?
                                            <div className="location-adres-item">{i18next.t("locator.9")} </div> : ""
                                    }

                                    {
                                        locationSearchData && locationSearchData.length ? locationSearchData.map((item) => {
                                            return (
                                                <div className="location-adres-item"  ref={ref => {
                                                    this.refsArray[item.storeId] = ref;
                                                }}  key={item.storeId} >

                                                    <h4>{item.name}</h4>
                                                    <div className="location-adres-text-content" >
                                                        <div>
                                                            <p>{item.address}</p>
                                                            <p>{item.zip}</p>
                                                            <p>{item.city}</p>
                                                            <p >Tél: {item.phone}</p>
                                                        </div>
                                                        {
                                                            item.personalShopper && getRouteByMenuKey(menuList, "shopper").route ?
                                                                <Link  to={
                                                                    {
                                                                        pathname:`${getRouteByMenuKey(menuList, "shopper").route}`,
                                                                        state:{
                                                                            store: item,
                                                                        }
                                                                    }}
                                                                       className={`main-btn magasin--body--info--btn hoverButtonStyle`}
                                                                       onClick={this.props.handleShow}>
                                                                    {i18next.t("magasin.1")}
                                                                </Link> :""
                                                        }
                                                        <div>
                                                            {
                                                                getOpeningHours(item.openingHours) &&
                                                                getOpeningHours(item.openingHours).length ?
                                                                    getOpeningHours(item.openingHours).map((hours, i) => {
                                                                        return (
                                                                            <div className='TimeBox' key={i}>
                                                                                <div>{` ${i18next.t(`weekDays.${i}`)}:`}</div>
                                                                                <div>{`${formatOpeningHours(hours)}`}</div>
                                                                            </div>
                                                                        )
                                                                    }) : ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : ""
                                    }
                                </div>
                            </div>
                            <div className="location-wrapper-item submitBox ">
                                <div className="location-wrapper-item-search">
                                    <h4>{i18next.t('locator.6')}</h4>
                                    <div className="location-wrapper-item-search-content">
                                        <LocationSearchInput classN="location-wrapper-item-search-icon" showButton={true}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        locationSearchData: getLocationSearch(state),
        userStore: getUserStore(state),
        menuList: getMenu(state),
        loading: getLocationSearchLoading(state)
    }
};
const mapDispatchToProps = (dispatch): typeof StoreLocationActionCreators => {
    return bindActionCreators(StoreLocationActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Location);
