import React from "react"
import PromotionBanner from "./promotionBanner";
import {connect} from "react-redux"
import {getBanner, getBannerError, getBanners} from "../../../modules/home/redux/selectors";
import {bindActionCreators} from "redux";
import {HomeActionCreators} from "../../../modules/home/redux/actions";
import {getLanguage} from "../header/redux/selectors";

interface Props {
    promotionEnd?: any,
    banner: any,
    error: any,
    language: any
}

class PromotionBannerRender extends React.Component <Props & typeof HomeActionCreators> {

    componentDidMount(): void {
        this.props.bannerStart();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.language !== prevProps.language) {
            this.props.bannerStart();
        }
    }

    render() {
        const {promotionEnd, banner} = this.props;
        if(banner) {
            return <PromotionBanner promotionEnd={promotionEnd} banner={banner}/>
        }
        return null
    }
}

const mapStateToProps = (state: any): Props => ({
    banner: getBanner(state),
    error: getBannerError(state),
    language: getLanguage(state),
});
const mapDispatchToProps = (dispatch): typeof HomeActionCreators => {
    return bindActionCreators(HomeActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionBannerRender);
