import React from "react";
import i18next from "i18next";
import { connect } from "react-redux";
import Identification from "./components/identification";
import DeliveryPayment from "./components/deliveryPayment";
import PageMetaData from "../../common/pageMetaData/pageMetaData";
import { bindActionCreators } from "redux";
//styles
import {
    getBasketData, getbasketLineItems,
} from "../../../helpers/helpers";
import Validation from "../../validation/container/validation";
import { getMenu } from "../../../layouts/main/header/redux/selectors";
import { isAuthenticated } from "../../login/redux/selectors";
import { AddToCartActionCreators } from "../../addToCart/redux/actions";
import { getBasket } from "../../addToCart/redux/selectors";
import { OrderActionCreators } from "../redux/actions";
import { getDiscount, getOrder, getDeliveryMethod } from "../redux/selectors"
import { getLocationSearch } from "../../locationSearchInput/redux/selectors";
import ConfirmPopup from "../../common/confirmPopup/confirmPopup";
import StoreLocator from "../../location/container/storeLocator";

interface Props {
    location?: any,
    menuItem?: any,
    menuList?: any,
    basket?: any,
    authenticated: boolean,
    discount: any,
    orders: any,
    validation?: boolean,
    deliveryMethod: string,
    locationSearchData: any
}

interface State {
    content: string,
    showValidation: boolean,
    discountCode: string,
    showMessage: string,
    visible: boolean,
    productQTY: string,
    quantityQTY: string | number,
    showStoreLocator: boolean,
    showInfoPopUp: boolean,
}

class Basket extends React.Component<Props & typeof AddToCartActionCreators, typeof OrderActionCreators> {
    PaymentRef: React.RefObject<any>;
    payRef: React.RefObject<any>;
    state: State;

    constructor(props) {
        super(props);
        this.state = {
            content: "livraison",
            showValidation: false,
            discountCode: "",
            showMessage: "",
            visible: false,
            productQTY: "",
            quantityQTY: "",
            showStoreLocator: false,
            showInfoPopUp: false,
        };
        this.PaymentRef = React.createRef();
        this.payRef =  React.createRef<HTMLDivElement>();
    }

    componentDidMount(): void {
        this.scrollToRef(this.PaymentRef);
        const { basket, authenticated } = this.props;
        const storeDelivery = basket["store"] && basket["store"]["storeDelivery"];
        if (storeDelivery === "STO") {
            this.props.selectedDeliveryMethodStart("storeDelivery")
        } else {
            this.props.selectedDeliveryMethodStart("onlineDelivery")
        }

        authenticated ? this.setContnet('livraison') : this.setContnet('identification')
    }

    componentDidUpdate(prevProps, prevState) {
        const { basket } = this.props;
        if ((basket && !prevProps.basket) || (basket && prevProps.basket && Object.keys(basket).length !== Object.keys(prevProps.basket).length)) {
            const storeDelivery = basket["store"] && basket["store"]["storeDelivery"];
            if (storeDelivery === "STO") {
                this.props.selectedDeliveryMethodStart("storeDelivery")
            } else {
                this.props.selectedDeliveryMethodStart("onlineDelivery")
            }

        }
        // if (
        //         (
        //             this.props.locationSearchData &&
        //             prevProps.locationSearchData
        //         ) &&
        //         Object.keys(this.props.locationSearchData).length !== Object.keys(prevProps.locationSearchData).length
        // ) {
        //      const { quantityQTY , productQTY } = this.state;
        //      const ids = productQTY.split("/");
        //      const storeCode = basket["store"] &&
        //                        basket["store"]["StoreInfo"] &&
        //                        `${basket["store"]["StoreInfo"].storeCode}`;
        //      const validStore = this.props.locationSearchData.filter(item => item.shipment && item.hasBasket);
        //      const products   = getbasketLineItems();
        //      products.map(prod => prod.productId === Number(ids[1]) ? prod.quantity = quantityQTY : prod );
        //      console.log(validStore)
        //      if (
        //             Object.keys(this.props.locationSearchData).length &&
        //             productQTY &&
        //             quantityQTY &&
        //             validStore &&
        //             validStore.length
        //         ) {
        //          if(isExistProductInClickAndCollect(validStore[0].barcodeList,[...products])){
        //              this.props.addtocartStart(STORAGE_BASKET_INDEX, ids[0], {size: {quantity: quantityQTY, productId: ids[1]}}, true);
        //              this.props.locationSearchDestroyedStart();
        //              this.setState({ quantityQTY : "" , productQTY : "" });
        //          } else {
        //              this.setState({ showInfoPopUp: true , quantityQTY : "" , productQTY : "" });
        //              this.props.locationSearchDestroyedStart();
        //          }
        //      } else if(productQTY && quantityQTY){
        //          this.setState({ showInfoPopUp: true , quantityQTY : "" , productQTY : "" });
        //          this.props.locationSearchDestroyedStart();
        //      }
        // }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.authenticated !== this.props.authenticated) {
            nextProps.authenticated ? this.setContnet("livraison") : this.setContnet("identification")
        }
        if (nextProps.authenticated) {
            this.setState({ showMessage: "" });
        }
    };

    scrollToRef = (ref) => {
        if (!this.props.validation) {
            window.scrollTo(0, ref.current.offsetTop - 350);
        }
    }
    setContnet = (content) => {
        localStorage.getItem("token") ? null : window.scrollTo(0, 0);
        this.setState({ content });
        window.scrollTo(0, 0);
    };

    handleChangeQuantity = (basketName, parentID, size, setAsQuantity) => {
        const { basket } = this.props;
        const storeDelivery = basket["store"] && basket["store"]["StoreInfo"] && `${basket["store"]["StoreInfo"].address},${basket["store"]["StoreInfo"].city}`;
        if (storeDelivery) {
            const products = getbasketLineItems();
            this.setState({ quantityQTY: size.size.quantity, productQTY: `${parentID}/${size.size.productId}` });
            products.map(prod => prod.productId === Number(size.size.productId) ? prod.quantity = size.size.quantity : prod);
            this.props.clickAndCollectStoresStart({ location: storeDelivery, body: { basketLineItems: [...products] } });
        } else {
            this.props.addtocartStart(basketName, parentID, { ...size }, setAsQuantity);
        }
    }

    toggleStoreLocator = () => this.setState(prevState => { return { ...prevState, showStoreLocator: !prevState.showStoreLocator } });
    handleCloseInfoPopup = () => {
        this.setState({ showInfoPopUp: false, quantityQTY: "", productQTY: "" });
        this.toggleStoreLocator();
    };


    render() {
        const { content, showValidation, showInfoPopUp, showStoreLocator } = this.state;
        const { basket, discount, orders } = this.props;
        const { totalPrice } = getBasketData(basket);

        return (
            <>
                {!showValidation ?
                    <div className="basket" >
                        <PageMetaData pageKey={this.props.menuItem.key} />
                        <h2 className="basket-title">{i18next.t('product.basket.38')}</h2>

                        <div className="large-container" ref={this.PaymentRef}>
                            <div className="mob-bastek-tab-title">
                                {content === "identification" ? i18next.t('product.basket.5') : i18next.t('product.basket.6')}
                            </div>

                            <div className="product-content large-container">
                                {content === "identification" && <Identification />}
                                {content === "livraison" &&
                                    <div style={{ display: "flex" }}>
                                        <DeliveryPayment
                                            changeQuantity={this.handleChangeQuantity}
                                            removeItem={this.props.removeBasketStart}
                                            items={basket} orders={orders}
                                            scrollToRef={this.scrollToRef}
                                            payRef={this.payRef}
                                            totalPrice={totalPrice}
                                            discount={discount}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <Validation />
                }
                {showInfoPopUp &&
                    <ConfirmPopup
                        handleCancel={this.handleCloseInfoPopup} text={i18next.t('modal.2')} title={""}
                        footer={false} />
                }
                {showStoreLocator &&
                    <StoreLocator toggleStoreLocator={this.toggleStoreLocator} product={basket} isPayment={true} />
                }
            </>
        );
    }
}


const mapStateToProps = (state: any): Props => ({
    menuList: getMenu(state),
    basket: getBasket(state),
    authenticated: isAuthenticated(state),
    discount: getDiscount(state),
    orders: getOrder(state),
    deliveryMethod: getDeliveryMethod(state),
    locationSearchData: getLocationSearch(state),
});

const mapDispatchToProps = (dispatch): typeof AddToCartActionCreators => {
    return bindActionCreators({ ...OrderActionCreators, ...AddToCartActionCreators }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Basket);



