export class StorageItem {
    storageKey: string;
    value: (event: any) => void;
    constructor(
        key = '',
        value = (event) => {}
    ) {
        this.storageKey = key;
        this.value = value;
    }
}