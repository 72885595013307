import { MenuTypes, MenuActionCreators } from "./actions";
import { takeLatest, put, call } from "redux-saga/effects";
import axios from 'axios';
import { BRAND_DETAILS_URL, CONTENT_URL, GET_STORES_DATA, MENU_URL, getClusterBarcodesById, getNotFoundPageData } from "../../../../utils/constants/service";
import { getApiPrefix, getMenuItemByKey } from "../../../../helpers/helpers"
import { BRAND_ID, DISCOUNT_CODE, PRIVATE_DISCOUNT } from "../../../../utils/constants/variables";
import { OrderActionCreators } from "../../../../modules/basket/redux/actions";

export function* getMenuData() {
    try {
        const headers = { headers: { brand: BRAND_ID, language: getApiPrefix() } };
        const data = yield call(() => {
            return axios.get(`${MENU_URL}`, headers)
                .then(res => {
                    return res.data
                })
        });
        const basketMenuItem = getMenuItemByKey(data, "404")
        if (basketMenuItem && basketMenuItem.gridId) {
            const getImages = async (gridId) => await getNotFoundPageData(gridId);
            const currentImages = yield call(getImages, basketMenuItem.gridId);
            if (currentImages && currentImages.length) yield put(MenuActionCreators.notFoundSuccess(currentImages));
        }
        yield put(MenuActionCreators.menuSuccess(data));
    } catch (e) {
        yield put(MenuActionCreators.menuFailure(e.message));
    }
}

export function* getCookieContent(params) {
    try {
        const headers = { headers: { language: getApiPrefix() } };
        const data = yield call(() => {
            return axios.get(`${CONTENT_URL}${params.id}`, headers)
                .then(res => res.data)
        });
        yield put(MenuActionCreators.cookieContentSuccess(data));
    } catch (e) {
        yield put(MenuActionCreators.cookieContentFailure(e.message));
    }
}

export function* getStores() {
    try {
        const headers = { headers: { brand: BRAND_ID, language: getApiPrefix() } };
        const data = yield call(() => {
            return axios.get(`${GET_STORES_DATA}?brandId=${BRAND_ID}`, headers).then(res => res.data)
        })
        yield put(MenuActionCreators.getStoresSuccess(data));
    } catch (e) {
        yield put(MenuActionCreators.getStoresFailure());
    }
}

export function* getBrandDetail() {
    try {
        const coupon = localStorage.getItem(DISCOUNT_CODE);
        const privateCoupon = localStorage.getItem(PRIVATE_DISCOUNT);
        const data = yield call(() => {
            return axios.get(`${BRAND_DETAILS_URL}?brandId=${BRAND_ID}`).then(res => res.data)
        })

        if (data.promotion && data.promotion[0]) {
            const promotion = data.promotion[0];
            let id = promotion.productClusterId_1 || promotion.productClusterId;

            if (promotion.ruleType === "PACK") {
                id = Object.keys(promotion).reduce((acc: any, item) => {
                    if (item.includes("productClusterId")) {
                        const [_, index] = item.split("_");
                        acc.push({ key: index, value: promotion[item] });
                    }
                    return acc;
                }, [])
            }
            yield put(MenuActionCreators.getBarcodesByProductClusterIdStart(id))
        }
        data.lowerlanguage = data.language ? data.language.split("_")[0] : 'fr';
        yield put(MenuActionCreators.brandDetailSuccess(data));
        if (privateCoupon) yield put(OrderActionCreators.validateDiscountStart(privateCoupon))
    } catch (e) {
        yield put(MenuActionCreators.brandDetailFailure());
    }
}

export function* getClusterBarcodes(params) {

    try {
        const { clusterId } = params;

        if (Array.isArray(clusterId)) {
            const packBarcodes = {};
            for (let i = 0; i < clusterId.length; i++) {
                const id = clusterId[i];
                const result = yield call(() => getClusterBarcodesById(id.value));
                packBarcodes[id.key] = result;
            }

            yield put(MenuActionCreators.getPackBarcodesByProductClusterIdSuccess(packBarcodes));
        } else {
            const data = yield call(() => getClusterBarcodesById(clusterId));

            yield put(MenuActionCreators.getBarcodesByProductClusterIdSuccess(data));
        }

        yield put(OrderActionCreators.promotionDiscountStart())
    } catch (error) {
        yield put(MenuActionCreators.getBarcodesByProductClusterIdFailure(error));
    }
}

export function* menuSaga() {
    yield takeLatest(MenuTypes.MENU_START, getMenuData);
    yield takeLatest(MenuTypes.COOKIE_CONTENT_START, getCookieContent);
    yield takeLatest(MenuTypes.GET_STORES_START, getStores);
    yield takeLatest(MenuTypes.BRAND_DETAIL_START, getBrandDetail);
    yield takeLatest(MenuTypes.GET_BARCODES_BY_PRODUCT_CLUSTER_ID_START, getClusterBarcodes);
}

export default menuSaga;
