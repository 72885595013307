import {CatalogueActionCreators, CatalogueTypes} from "./actions";
import axios from "axios";
import {call, put, takeLatest} from "redux-saga/effects";
import {BRAND_CATALOGUES_URL} from "../../../../utils/constants/service";
import {BRAND_ID} from "../../../../utils/constants/variables";

export function* getCatalogue(){
    try {

        const data = yield call(() => {
             return axios.get(`${BRAND_CATALOGUES_URL}${BRAND_ID}`)
                 .then(res => res.data)
        });


        yield put(CatalogueActionCreators.catalogueSuccess(data));
    } catch (e) {
        yield put(CatalogueActionCreators.catalogueFailure(e.message));
    }
}

export function* catalogueSaga(){
    yield takeLatest(CatalogueTypes.CATALOGUE_START, getCatalogue);
}

export default catalogueSaga;
