import {createActions} from 'reduxsauce';

export const {Types: ConnectionsTypes, Creators: ConnectionsActionCreators} = createActions ({
    connectionStart: ['email'],
    connectionSuccess: ['data'],
    connectionFailure: ['error'],
    loginStart: ['user'],
    connectionPasswordResetStart: ['email'],
    connectionPasswordResetSuccess: ['data'],
    connectionPasswordResetFailure: ['error'],
    connectionPasswordResetFinishStart: ['data'],
    connectionPasswordResetFinishSuccess: ['data'],
    connectionPasswordResetFinishFailure: ['error'],
    favoriteStoreStart: [],
    favoriteStoreSuccess: ['data'],
    favoriteStoreFailure: ['error'],
    resetConnectionStart : null

});