import React from "react"
import {Link} from "react-router-dom";
import {BASE_API_GATEWAY} from "../../../../../../../utils/constants/service";
import {Icon} from "semantic-ui-react";
import i18next from "i18next";
import {getLangPrefix} from "../../../../../../../helpers/helpers";
import Accordion from "../../../../../../../modules/accordion";
import LinkFullUrl from "../../../../../../../shared/linkFullUrl/linkFullUrl";

interface Props {
    menuItem: any,
    handleClick: any,
    handleToggleMenu: any
}

export default class SubMenu extends React.Component<Props> {

    handleClick = (index) => {
        this.props.handleClick(index, true)
    };
    render () {
        const {menuItem} = this.props;
        let menuItemVisuals = (menuItem && menuItem.children) ? menuItem.children.filter((item) => item.visuals && item.visuals[0] && item.visuals[0].type === "MENU") : [];
        const menuItemTexts = (menuItem && menuItem.children) ? menuItem.children.filter((item) => !item.visuals || (item.visuals[0] && item.visuals[0].type === "CATALOGUE")) : [];
        const hasChildren = (menuItemTexts && menuItemTexts.length) ? menuItemTexts.filter((item) => item.children ) : [];
        const LANGUAGE_PREFIX = getLangPrefix();
        if(menuItemTexts.length){
            menuItemTexts.map(item => {
                if(item.children && item.children.length) {
                    const visuals = item.children.filter(item => item.visuals && item.visuals[0] && item.visuals[0].type === "MENU")
                    menuItemVisuals = [...menuItemVisuals, ...visuals];
                }
            })
        }

        return (
            <React.Fragment>
                <div className="mobile-menu-item-content-head dflex align-items-center justify-content-between">
                    <div className="dflex align-items-center justify-content-between" onClick={() => this.handleClick(-1)}>
                        <Icon name="angle left"/>
                        <p>{i18next.t('common.return')}</p>
                    </div>

                    <h4>{menuItem && menuItem.label}</h4>
                </div>
                <div className="linge-de-lit">
                    {menuItemTexts.length &&
                    !hasChildren ?
                        <div className="decoration">
                            <ul className="homewear-list">
                                {
                                    menuItemTexts.map( item => (
                                        <>
                                            <li key={item.menuId}>
                                                <LinkFullUrl to={`${LANGUAGE_PREFIX}${item.canonicalUrl}`} onClick={this.props.handleToggleMenu} >{item && item.label}</LinkFullUrl>
                                            </li>
                                        </>
                                    ))
                                }
                            </ul>
                        </div>
                        :
                        <Accordion items={menuItemTexts} handleToggleMenu={this.props.handleToggleMenu}/>
                        // menuItemTexts.map((item, i) => (
                        //     <div className="linge-de-lit-item" key={item.menuId}>
                        //         {
                        //             <div>
                        //                 {
                        //                     item.canonicalUrl ?
                        //                         <li className="linge-de-lit-item-title--link">
                        //                             <Link to={`${LANGUAGE_PREFIX}${item.canonicalUrl}`} onClick={this.props.handleToggleMenu} >{item.label}</Link>
                        //                         </li>
                        //                         :
                        //                         <h4 className="linge-de-lit-item-title">
                        //                             {item.label}
                        //                         </h4>
                        //                 }
                        //
                        //                 {item.children && item.children.length &&
                        //                 <ul>
                        //                     {
                        //                         item.children.map(el => (
                        //                             !el.visuals || (el.visuals[0] && el.visuals[0].type === "CATALOGUE") ?
                        //                                 <li key={el.menuId}>
                        //                                     <Icon name="angle right"/>
                        //                                     <Link to={`${LANGUAGE_PREFIX}${el.canonicalUrl}`}  onClick={this.props.handleToggleMenu} >{el && el.label}</Link>
                        //                                 </li>
                        //                                 : ""
                        //                         ))
                        //                     }
                        //
                        //                 </ul>
                        //                 }
                        //             </div>
                        //         }
                        //     </div>
                        // ))
                    }

                    {
                        //TODO update according to response
                        <div className="linge-de-lit-content">
                            {
                                menuItemVisuals.map((item) => (
                                    <div className="linge-de-lit-item" key={item.menuId}>
                                        <div className="linge-de-lit-item-img">
                                            <img src={`${BASE_API_GATEWAY}${item && item.visuals[0].photo}`}
                                                 alt="luxury img"/>
                                            <div className="linge-de-lit-item-img-content">
                                                <h4 className="linge-de-lit-item-title">
                                                    {item.label}
                                                </h4>
                                                {item.visuals && item.visuals[0] &&
                                                <div className="link">
                                                  <LinkFullUrl  to={item.visuals[0].menuHref ? `${LANGUAGE_PREFIX}${item.visuals[0].menuHref}` : "/"} onClick={this.props.handleToggleMenu}>{item.visuals[0].name}</LinkFullUrl>
                                                </div>
                                                  }
                                            </div>
                                        </div>

                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}
