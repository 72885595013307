import React from "react";
import { useEffect } from "react";
import { MenuActionCreators } from "../../layouts/main/header/redux/actions";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux'

const WrraperMenuItem = ({
    setMenuItem,
    menuItem,
    ...props
}) => {

    useEffect(() => {
        setMenuItem(menuItem)
    }, [menuItem])
    return <>
        {props.children}
    </>
}

// const mapStateToProps = (state) => ({
//     menu: getMenu(state),
//     language: getLanguage(state),

// });

const mapDispatchToProps = (dispatch): typeof MenuActionCreators => {
    return bindActionCreators({ ...MenuActionCreators }, dispatch)
};

export default connect(null, mapDispatchToProps)(WrraperMenuItem);