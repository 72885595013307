import {createReducer} from "reduxsauce";
import {retourType} from "./actions";

export interface retourReasonState {
    content: object,
    ticketData: {},
}

export const RetourReason: retourReasonState = {
    content: {},
    ticketData:{},
};

export const reasonStart = (state) => {
    return {...state};
};

export const getRetourReason = (state,action) => {
    return {...state,content: action.data};
};
export const reasonFailure = (state,action) => {
    return {...state, error: action.error, content: {}};
};

export const retourTicketStart = (state) => {
    return {...state};
};

export const retourTicketSuccess = (state, action) => {
    return {...state,ticketData: action.data};
};

export const retourTicketFailure = (state,action) => {
    return {...state, error: action.error, ticketData: {}};
};

export const handlers = {
    [retourType.RETOUR_REASON_START]: reasonStart,
    [retourType.RETOUR_REASON_SUCCESS]: getRetourReason,
    [retourType.RETOUR_REASON_FAILURE]: reasonFailure,
    [retourType.RETOUR_TICKET_START]: retourTicketStart,
    [retourType.RETOUR_TICKET_SUCCESS]: retourTicketSuccess,
    [retourType.RETOUR_TICKET_FAILURE]: retourTicketFailure,

};
export default createReducer(RetourReason, handlers);