import {createReducer} from 'reduxsauce';
import {MesCommandesTypes} from './actions';

export interface IMesCommandesState {
    orders: any,
    tickets: any,
    error: string,
    status: string,
    loading:boolean,
}

export const mesCommandes: IMesCommandesState = {
    orders: [],
    tickets: [],
    error: "",
    status: "",
    loading:false,
};

export const getMesCommandes = (state) => {
    return {...state, loading: true};
};

export const mesCommandesSuccess = (state, action) => {
    return {...state, orders: action.data, status: "",loading: false};
};

export const mesCommandesFailure = (state, action) => {
    return {...state, error: action.error, orders: [], status: "",loading: false};
};

export const getMesTickets = (state) => {
    return {...state, loading: true};
};

export const mesTicketsSuccess = (state, action) => {
    return {...state, tickets: action.data, status: "",loading: false};
};

export const mesTicketsFailure = (state, action) => {
    return {...state, error: action.error, tickets: [], status: "",loading: false};
};


export const handlers = {
    [MesCommandesTypes.MES_COMMANDES_START]: getMesCommandes,
    [MesCommandesTypes.MES_COMMANDES_SUCCESS]: mesCommandesSuccess,
    [MesCommandesTypes.MES_COMMANDES_FAILURE]: mesCommandesFailure,
    [MesCommandesTypes.MES_TICKETS_START]: getMesTickets,
    [MesCommandesTypes.MES_TICKETS_SUCCESS]: mesTicketsSuccess,
    [MesCommandesTypes.MES_TICKETS_FAILURE]: mesTicketsFailure,
};

export default createReducer(mesCommandes, handlers);