import React from "react";
import {connect} from "react-redux";
import {Button, Form, Grid, Header, Message, Segment} from "semantic-ui-react";
import {UserActionCreators} from "../redux/actions";
import {getUser, isAuthenticated} from "../redux/selectors";
import {bindActionCreators} from "redux";
import {IAccountState} from "../redux/reducers";
import {GoogleLogin} from "react-google-login";
import FacebookLogin from "react-facebook-login";
import {Redirect} from 'react-router-dom';

interface Props extends IAccountState {
}

interface State {
    user: {
        email: string,
        password: string
    };
}

class Login extends React.Component<Props & typeof UserActionCreators, State> {

    state: State = {
        user: {
            email: "",
            password: ""
        }
    };

    handleChange = (e, {name, value}) => {
        this.state.user[name] = value;
        this.setState(this.state);
    };

    handleSubmit = () => {
        this.props.loginStart(this.state.user)
    };

    componentDidMount(): void {
        //this.props.loginStart();
    }

    render(): React.ReactNode {

        if (this.props.authenticated) {
            return <Redirect to="/home"/>
        }
        const responseFacebook = (response: any) => {

            this.props.loginSuccess({
                userId: response.email,
                name: response.name,
                type: "Facebook"
            });
        };

        const responseGoogle = (response: any) => {

            this.props.loginSuccess({
                userId: response.profileObj.email,
                name: response.profileObj.name,
                type: "Google"
            });
        };
        const {user} = this.state;
        const {email, password} = user;
        return (
            <Grid centered columns={4}>
                <Grid.Column>
                    <Header as="h2" textAlign="center">
                        Login
                    </Header>
                    <Segment>
                        <Form size="tiny" onSubmit={this.handleSubmit}>
                            <Form.Input
                                fluid
                                icon="user"
                                iconPosition="left"
                                placeholder="Email address"
                                name="email"
                                value={email}
                                onChange={this.handleChange}
                            />
                            <Form.Input
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder="Password"
                                type="password"
                                name="password"
                                value={password}
                                onChange={this.handleChange}
                            />

                            <Button color="blue" fluid size="large" content='Login'/>
                        </Form>
                    </Segment>
                    <Message>
                        <Grid columns='equal'>
                            <Grid.Row>
                                <Grid.Column>
                                    <FacebookLogin
                                        appId="550363855456533"
                                        autoLoad={true}
                                        textButton="Login"
                                        fields="name,email,picture"
                                        callback={responseFacebook}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <GoogleLogin
                                        clientId="717824867140-j5of3pic2slss3ri43femopkatbf2g8v.apps.googleusercontent.com"
                                        buttonText="Login"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Message>
                    <Grid.Column key="1">
                        {this.props.user.userId}
                    </Grid.Column>
                    <Grid.Column key="2">
                        {this.props.user.name}
                    </Grid.Column>
                    <Grid.Column key="3">
                        {this.props.user.type}
                    </Grid.Column>
                </Grid.Column>
            </Grid>
        )
    }
}

const mapStateToProps = (state: any): Props => ({
    authenticated: isAuthenticated(state),
    user: getUser(state)
});

const mapDispatchToProps = (dispatch): typeof UserActionCreators => {
    return bindActionCreators(UserActionCreators, dispatch)
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);

