import React from 'react';
import { getLangPrefix } from '../../../../../helpers/helpers';
import LinkFullUrl from '../../../../../shared/linkFullUrl/linkFullUrl';

export default function FooterItemMenu({data, handleToUp = ()=>{}}) {
    return (
        data.map((menuItem: any) => menuItem.key.includes("_ext") ?
            <li key={menuItem.key} className='footerItemLink'>
                <a href={menuItem.canonicalUrl} target="_blank">
                    {menuItem.label}
                </a>
            </li>
            
        :
            <li onClick={handleToUp} key={menuItem.key} className='footerItemLink'>
                <LinkFullUrl to={`${getLangPrefix()}${menuItem.canonicalUrl}`}>
                    {menuItem.label}
                </LinkFullUrl>
            </li>
    ))
}