import React from 'react';
import i18next from "i18next";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Icon, Input, Form} from "semantic-ui-react";
import CurrentPage from '../../../layouts/common/currentPage';
import {IMotdePasseState} from "../redux/reducers";
import {MotdePasseActionCreators} from "../redux/actions";
import {getUserData, getError, getMenu} from "../redux/selectors";
import {getItemMenu, getLangPrefix} from "../../../helpers/helpers";
import './motdepasse.scss'


interface Props extends IMotdePasseState {
    menuItem?: any,
    menuList?: any,
    validateResetPasswordKey?: boolean,
}

class Motdepasse extends React.Component<Props & typeof MotdePasseActionCreators> {
    state = {
        pass2: '',
        pass3: '',
        showPass2: false,
        showPass3: false,
        showPasswordResetScreen: false,
        passwordResetKey: '',
        resetPasswordSuccess: false,
        error: false
    };

    componentDidMount() {
        if (this.props.validateResetPasswordKey) {
            const {match} = this.props;
            this.setState({passwordResetKey: match.params.resetKey})
        } else {
            if (this.props.location && this.props.location.state && this.props.location.state.resetPassword) {
                this.setState({showPasswordResetScreen: true});
            }
        }

    }

    componentDidUpdate(prevProps) {
        if(!prevProps.userData.passwordChanged && this.props.userData.passwordChanged && !this.props.userData.changePassword && !this.props.userData.resetPassword) {
            this.setState({resetPasswordSuccess: true})
            const item = getItemMenu('PAGE', "ma_commande", this.props.menuList)
            setTimeout(() => {
                this.props.history.push(getLangPrefix()+item.canonicalUrl);
            }, 1000)
        }
    }

    handleChange = (e, {name, value, checked}) => {
        if (checked !== undefined) {
            value = checked ? 1 : 0
        }
        this.setState({[name]: value, error: false})
    };

    handleResetPassword = () => {
        if (this.state.pass2 != "" && this.state.pass2 === this.state.pass3) {
            this.props.motdePasseActions.motdePasseResetPasswordStart(this.state.pass2, this.state.passwordResetKey);
        } else {
            this.setState({error: true});
        }
    };

    showPass = (name) => {
        this.setState({[name]: !this.state[name]})
    };

    render(): React.ReactNode {
        const {menuItem, menuList} = this.props;

        const {
            pass2,
            pass3,
            showPass2,
            showPass3,
            error
        } = this.state;
        return (
            <div className="mot-de-pass">
                <CurrentPage selectedItem={menuItem}/>

                {
                    this.props.validateResetPasswordKey && !this.props.userData.resetPassword && !this.props.userData.passwordChanged ?
                        <div className="container">
                            <div className="page-content">
                                <p>{i18next.t("mesInformations.16")}</p>
                            </div>
                        </div>
                    : ""
                }


                {this.state.resetPasswordSuccess ?
                    <div className="container">
                        <div className="success">
                            <p>{i18next.t("mesInformations.22")}</p>
                        </div>
                    </div> : ""
                }

                {
                    !this.props.userData.changePassword && this.props.userData.resetPassword ?
                    <div className="container">
                        <div className="page-content">
                            <h5 className="message">{i18next.t("mesInformations.21")}</h5>
                            <Form className="page-content-form" onSubmit={this.handleResetPassword}>
                                <Form.Field required className='input-box'>
                                    <label>{i18next.t("mesInformations.11")}</label>
                                    <Input
                                        icon={<Icon name={showPass2 ? "eye" : "eye slash"} link
                                                    onClick={() => this.showPass('showPass2')}/>}
                                        type={showPass2 ? 'text' : 'password'}
                                        name='pass2'
                                        value={pass2}
                                        onChange={this.handleChange}
                                        placeholder={i18next.t("mesInformations.14")}
                                    />
                                </Form.Field>
                                <Form.Field required className='input-box'>
                                    <label>{i18next.t("mesInformations.12")}</label>
                                    <Input
                                        icon={<Icon name={showPass3 ? "eye" : "eye slash"} link
                                                    onClick={() => this.showPass('showPass3')}/>}
                                        type={showPass3 ? 'text' : 'password'}
                                        name='pass3'
                                        value={pass3}
                                        onChange={this.handleChange}
                                        placeholder={i18next.t("mesInformations.14")}
                                    />
                                </Form.Field>
                                <Form.Field className="btn-wrapper forgot-password-btn" disabled={!(pass2 && pass3)} control={Button}>
                                    {i18next.t("mesInformations.8")}</Form.Field>
                                {
                                    error &&
                                    <p className="error">{i18next.t("mesInformations.20")}</p>
                                }
                                {this.props.error ? <p className="error">{i18next.t("forgotPassword.6")} </p> : ""}
                            </Form>
                        </div>
                    </div> : ""
                }
            </div>
        )
    };
}


const mapStateToProps = (state: any): Props => {

    return {
        error: getError(state),
        menuList: getMenu(state),
        userData: getUserData(state),
    };
};

const mapDispatchToProps = (dispatch): typeof MotdePasseActionCreators => {
    return {
        motdePasseActions: bindActionCreators(MotdePasseActionCreators, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Motdepasse);
