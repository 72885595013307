import {createReducer} from 'reduxsauce';
import {ContactUsTypes} from './actions';

export interface IContactUsState {
    contactUsData: any,
    contactUsSendResponse: any,
    error: string,
    status: string
}

export const contactUs: IContactUsState = {
    contactUsData: {},
    contactUsSendResponse: {},
    error: "",
    status: ""
};

export const contactUsStart = (state) => {
    return {...state};
};

export const contactUsSuccess = (state, action) => {
    return {...state, contactUsData: action.data, status: "", contactUsSendResponse: {}};
};

export const contactUsFailure = (state, action) => {
    return {...state, error: action.error, contactUsData: {}, status: "", contactUsSendResponse: {}};
};

export const contactUsSendStart = (state) => {
    return {...state};
};

export const contactUsSendSuccess = (state, action) => {
    return {...state, contactUsSendResponse: action.data, status: "success"};
};

export const contactUsSendFailure = (state, action) => {
    return {...state, error: action.error, contactUsSendResponse: {}, status: "filed"};
};

export const handlers = {
    [ContactUsTypes.CONTACT_US_START]: contactUsStart,
    [ContactUsTypes.CONTACT_US_SUCCESS]: contactUsSuccess,
    [ContactUsTypes.CONTACT_US_FAILURE]: contactUsFailure,
    [ContactUsTypes.CONTACT_US_SEND_START]: contactUsSendStart,
    [ContactUsTypes.CONTACT_US_SEND_SUCCESS]: contactUsSendSuccess,
    [ContactUsTypes.CONTACT_US_SEND_FAILURE]: contactUsSendFailure
};

export default createReducer(contactUs, handlers);