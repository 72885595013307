import React from "react"
import {Link} from "react-router-dom";
import {getProductFullUrl} from "../../../../helpers/helpers";

export default function Colorbar({data, backLink = "/", handleClickColor = (color?: any) => {}}) {
    
    return <div className="product-color-item-content">
        {data && data.map((item, i) => {
            if (item.productId && item.canonicalUrl && item.code) {
                return (
                    <Link to={{
                        pathname: getProductFullUrl(location, item.canonicalUrl),
                        state: {
                            backLink: backLink,
                        }
                    }} key={i}>
                        <div className="product-color-item" data-color={item.code.split("/")[0]}
                             style={{background: item.code.split("/")[0]}} onClick={() => handleClickColor(item)}>
                            <span>{item.name}</span>
                        </div>
                    </Link>
                )
            } else if (item.code) {
                return (
                    <div className="product-color-item" key={i} data-color={item.code.split("/")[0]}
                         style={{background: item.code.split("/")[0]}} onClick={() => handleClickColor(item)}>
                        <span>{item.name}</span>
                    </div>
                )
            } else {
                return ''
            }

        })
        }
    </div>;
}