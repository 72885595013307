import React, {Fragment} from "react"
import {MENU_TYPE} from "../../../../../../../utils/constants/variables";
import SubMenu from "./submenu";
import './mobile-menu.scss';
import {Icon} from 'semantic-ui-react'
import i18next from "i18next";
import Language from "../../../language";
import { withRouter, RouteComponentProps  } from "react-router";
import {getLangPrefix} from "../../../../../../../helpers/helpers";

interface Props extends RouteComponentProps {
    menu: any,
    activeMenuItem: number,
    handleActive: any,
    handleToggleMenu: any,
    history: any
}

class MenuMobile extends React.Component<Props> {

    handleActive = (index) => {
        this.props.handleActive(index, true)
    };

    handleMoveToShop = () => {
        const { menu, history, handleToggleMenu } = this.props;
        const boutique = menu.find((menuItem) => menuItem.altUrl1 === 'boutique');
        history.push(`${getLangPrefix()}${boutique.canonicalUrl}`);
        handleToggleMenu()
    };

    render () {
        const {menu, activeMenuItem} = this.props;

        return (
            <Fragment>
                <div className="mobile-menu dflex align-items-start">
                    <div className="mobile-menu-content">
                        <div className="mobile-menu-head dflex justify-content-between align-items-center">

                            <a href="#" rel="noopener">{i18next.t('common.findShop')}</a>
                        </div>

                        <div className="mobile-menu-body">
                                { activeMenuItem === -1 &&
                                <div className="mobile-menu-item">
                                    <ul className="" >
                                        { menu.length &&
                                        menu.map((item, i) => (
                                            item.menuType === MENU_TYPE &&
                                            <li
                                                key={item.menuId}
                                                className={`${i === activeMenuItem ? 'active dflex justify-content-between align-items-center' : 'dflex justify-content-between align-items-center'} ${item.colored ? "colored" : ""}`}
                                                onClick={this.handleActive.bind(this, i)}
                                            >
                                                {item && item.label}
                                            <Icon name="angle right"/>
                                            </li>
                                        ))
                                        }
                                        <li className='d-flex justify-content-between align-items-center mobile-menu-find-shop'>
                                            <p onClick={this.handleMoveToShop}>{i18next.t('common.findShop')}</p>
                                        </li>
                                    </ul>
                                </div>
                                }

                                { activeMenuItem !== -1 &&
                                <div className="mobile-menu-item-content">
                                    <SubMenu menuItem={menu[activeMenuItem]} handleToggleMenu={this.props.handleToggleMenu} handleClick={this.handleActive}/>
                                </div>
                                }

                        </div>
                    </div>

                    <div className="mobile-menu-close" onClick={this.props.handleToggleMenu}>
                        <span/>
                        <span/>
                    </div>

                </div>
            </Fragment>

        );
    }
}

export default withRouter(MenuMobile)