import {createActions} from 'reduxsauce';

export const {Types: UserTypes, Creators: UserActionCreators} = createActions ({
    loginStart: ['user', 'callback'],
    loginSuccess: ['user'],
    loginFailure: ['error'],
    logoutStart: ['user'],
    tokenValidateStart: ['user'],
    userInfoStart: [null],
    userInfoSuccess: ['data'],
    userInfoFailure: ['error'],
});