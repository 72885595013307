import React from "react"
import BlackWindow from "../blackWindow";
import closeIcon from "../ContentpopUp/img/close.svg";
import './sidePanel.scss'

interface Props {
    handleClose: () => void,
    className?: string,
    children: JSX.Element
}

export default function SidePanel ({ handleClose, children, className }: Props) {
    return (
        <>
            <BlackWindow type="page" handleClick={handleClose}/>
            <div className={`side-panel-popup ${className || ''}`}>
                <div className="close">
                    <img src={closeIcon}  alt="PHOTO" onClick={handleClose}/>
                </div>
                <div className="side-panel-popup-content">
                    {children}
                </div>
            </div>
        </>

    );
}
