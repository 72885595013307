import React, { useEffect, useState } from "react";
import i18next from "i18next";
import ConfirmPopup from "../../common/confirmPopup/confirmPopup";
import StoreLocator from "../../location/container/storeLocator";
import Identification from "../../basket/container/components/identification";
import { connect } from 'react-redux'
import { Dispatch } from "redux";
import { AddressBlock } from "./components/addressBlock";
import { getBasket } from "../../addToCart/redux/selectors";
import { MesAddressActionCreators } from "../../monEspace/container/components/MesAddress/redux/actions";
import { getBasketData, getBasketProductsBySizes } from "../../../helpers/helpers";
import { isAuthenticated } from "../../login/redux/selectors";
import { SummaryBasket } from "../../basketRefactor/container/components/summary";
import { getMesAddress } from "../../monEspace/container/components/MesAddress/redux/selectors";
import { DiscountPaymentBlock } from "./components/discountPayment";
import { DiscountPriceBlock } from "./components/priceBlock";
import { getDiscount, getPromotionDiscount } from "../../basket/redux/selectors";
import { getBrandDetail } from "../../../layouts/main/header/redux/selectors";
import { PaymentBlock } from "./components/paymentBlock";

import "./basket.scss"
import { AddressTypes } from "../../../utils/constants/variables";

interface Props {
  location?: any;
  menuItem?: any;
  menuList?: any;
  basket?: any;
  authenticated: boolean;
  discount: any;
  promotionDiscount: any;
  orders: any;
  validation?: boolean;
  address: any[];
  deliveryMethod: string;
  locationSearchData: any;
  dispatch: any;
  brandDetail: any;
}

const Basket: React.FC<Props> = ({
  basket,
  authenticated,
  address,
  dispatch,
  discount,
  brandDetail,
  promotionDiscount,
}) => {
  const [showInfoPopUp, setShowInfoPopUp] = useState<boolean>(false);
  const [showStoreLocator, setShowStoreLocator] = useState<boolean>(false);
  const [basketProductsBySizes, setBasketProductsBySizes] = useState<any[]>([]);
  const [basketData, setBasketData] = useState<Record<string, string | number | boolean>>({});
  const [shippingAddress, setShippingAddress] = useState<any[]>([]);
  const [billingAddress, setBillingAddress] = useState<any[]>([]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState<any>({});
  const [selectedBillingAddress, setSelectedBillingAddress] = useState<any>({});

  useEffect(() => {
    setAddressesData(address);
  }, [address])

  const setAddressesData = (address) => {
    const { shippingAddress, billingAddress, selectedShippingAddress, selectedBillingAddress } = (address || []).reduce((acc, address) => {
      if (address.addressType === AddressTypes.SHIP) {
        if (address.isDefault) acc.selectedShippingAddress = address;
        acc.shippingAddress.push(address);
      }
      if (address.addressType === AddressTypes.BILL) {
        if (address.isDefault) acc.selectedBillingAddress = address;
        acc.billingAddress.push(address);
      }

      return acc;
    }, { shippingAddress: [], billingAddress: [], selectedShippingAddress: {}, selectedBillingAddress: {} });

    setShippingAddress(shippingAddress);
    setBillingAddress(billingAddress);
    setSelectedShippingAddress(selectedShippingAddress);
    setSelectedBillingAddress(selectedBillingAddress);
  }

  const upsertSelectedAddressList = (item: any = 0, type: AddressTypes) => {
    const isShip = type === AddressTypes.SHIP;
    const currentAddress = isShip ? selectedShippingAddress : selectedBillingAddress;

    if (currentAddress.clientAddressId === item.clientAddressId) {
      return isShip ? setSelectedShippingAddress({}) : setSelectedBillingAddress({});
    };

    return isShip ? setSelectedShippingAddress(item) : setSelectedBillingAddress(item);
  };

  useEffect(() => {
    setBasketProductsBySizes(getBasketProductsBySizes(basket));
    setBasketData(getBasketData(basket, discount || {}));
  }, [basket, discount, promotionDiscount])

  useEffect(() => {
    dispatch(MesAddressActionCreators.mesAddressStart());
  }, [])

  if (!brandDetail.id) return null;

  return (
    <>
      <div className="basket" >
        <h2 className="basket-title">{i18next.t('product.basket.38')}</h2>
        <div className="product-content large-container">
          {authenticated ? (
            <div className="delivery-payment">
              <div className="delivery-payment-content">
                <div className="delivery-payment-wrapper">
                  <AddressBlock
                    basket={basket}
                    basketProductsBySizes={basketProductsBySizes}
                    address={address}
                    shippingAddress={shippingAddress}
                    billingAddress={billingAddress}
                    selectedBillingAddress={selectedBillingAddress}
                    selectedShippingAddress={selectedShippingAddress}
                    upsertSelectedAddressList={upsertSelectedAddressList}
                  />
                  <SummaryBasket
                    productBySize={basketProductsBySizes}
                    basketStore={basket && basket.store || {}}
                  />
                </div>
                <div className="delivery-payment-right-container">
                  <DiscountPaymentBlock
                    basketData={basketData}
                  />
                  <DiscountPriceBlock
                    basketData={basketData}
                    basketStore={basket && basket.store || {}}
                  />
                  <PaymentBlock
                    address={address}
                    basketProductsBySizes={basketProductsBySizes}
                    basketStore={basket && basket.store || {}}
                    basketData={basketData}
                    basket={basket}
                    shippingAddress={shippingAddress}
                    billingAddress={billingAddress}
                    selectedShippingAddress={selectedShippingAddress}
                    selectedBillingAddress={selectedBillingAddress}
                  />
                </div>
              </div>
            </div>
          ) : (
            <Identification />
          )}
        </div>
      </div>
      {showInfoPopUp && (
        <ConfirmPopup
          handleCancel={() => setShowInfoPopUp(false)}
          text={i18next.t('modal.2')}
          title={""}
          footer={false}
        />
      )}
      {showStoreLocator && (
        <StoreLocator
          toggleStoreLocator={() => setShowStoreLocator(false)}
          product={basket}
          isPayment={true}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    basket: getBasket(state),
    authenticated: isAuthenticated(state),
    address: getMesAddress(state),
    discount: getDiscount(state),
    promotionDiscount: getPromotionDiscount(state),
    brandDetail: getBrandDetail(state)
  }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Basket);