import React, {PureComponent} from 'react';
import {Link} from "react-router-dom";
import i18next from "i18next";
//styles
import './mesRetoursTwo.scss';

class MesRetoursTwo extends PureComponent {

    render() {
        return (
            <>
                <div className='futureBox'>
                    <p className='bold-text'>
                        {i18next.t("MesRetoursTwo.1")}
                    </p>
                    <div className='info-box'>
                        {i18next.t("MesRetoursTwo.2")}
                    </div>
                    <Link to="" className='main-link'> {i18next.t("MesRetoursTwo.3")}</Link>
                </div>
            </>
        )
    }
}

export default MesRetoursTwo;
