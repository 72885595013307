import React from "react"
import "././ContentpopUp/ContentPopup.scss"
import BlackWindow from "./blackWindow";
import closeIcon from "./ContentpopUp/img/close.svg";
import ImgBlob from "../home/container/components/imgBlob";
import {BASE_API_GATEWAY} from "../../utils/constants/service";

interface Props {
    handleCancel: () => void,
    title: any,
    text: string,
    classN?: string,
    photo: any,
}

export default class DiscountPopUp extends React.Component<Props> {
    render() {
        const {title, text,classN,photo} = this.props;
        return (
            <div>
                <div className="modal-container discount">
                    <img src={closeIcon} className="x" onClick={this.props.handleCancel} alt="close"/>

                    <div className="contentpopup">

                        <div className="modal-left discount">
                            <div className='modal-title'>{title}</div>
                            <div className={`modal-description ${classN ? classN :""}`}><p>{text}</p></div>

                        </div>
                        <div className="modal-right discount">
                            <ImgBlob
                                src={`${BASE_API_GATEWAY}${photo.value}`}
                                alt={ "photo"}/>
                        </div>
                    </div>
                </div>
                <BlackWindow type="page" handleClick={this.props.handleCancel}/>
            </div>

        );
    }
}
