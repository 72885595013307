import React, { Component } from "react";
import { connect } from "react-redux";
import { getBanner, getHome } from "../../home/redux/selectors";
import "./campaign.scss";
import { getApiPrefix, getDataBySectionAndWidget, getRouteByMenuKey } from "../../../helpers/helpers";
import { HomeActionCreators } from "../../home/redux/actions";
import { bindActionCreators } from "redux";
import { BASE_API_GATEWAY } from "../../../utils/constants/service";
import i18next from "i18next";
import Checkbox from "../../common/chckboxList/checkboxList";
import { Link } from "react-router-dom";
import ResponseMessage from "../../responseMessage/container";
import { getMenu } from "../../../layouts/main/header/redux/selectors";
import { getStatus } from "../../../layouts/main/footer/redux/selectors";
import { BRAND_ID } from "../../../utils/constants/variables";


interface Props {
    menuItem?: any,
    data: any,
    menuList: any,
    banner?: any,
    status?: string
}

interface State {
    email: string,
    accept: boolean,
    error: boolean,
    subscribed: boolean,
    visibleMessage: boolean,
}

class Campaign extends Component<Props & typeof HomeActionCreators, State> {
    state: State = {
        email: '',
        accept: false,
        error: false,
        subscribed: false,
        visibleMessage: true
    };

    componentDidMount() {
        const { menuItem } = this.props;
        this.props.bannerStart();
        window.scrollTo(0, 0);
        if (Object.keys(menuItem).length && menuItem.language === getApiPrefix()) {
            this.props.homeStart(menuItem.gridId)
        }
    }

    handleSubscribe = (e) => {
        const { email, accept } = this.state;
        const { banner } = this.props;
        let body = {
            email,
            "brandId": BRAND_ID
        }
        if (banner && banner.CAMPAIGN_MAILING_ID) {
            body["mailingID"] = banner.CAMPAIGN_MAILING_ID
        }
        if (accept) {
            this.props.subscribeStart(body);
            this.setState({ error: false })
        } else {
            this.setState({ error: true })
        }
        this.setState({ visibleMessage: true });
        e.preventDefault();
    };
    hendleChangeStateEmail = (e) => {
        this.setState({ email: e.target.value })
    };

    handleChange = (e) => {
        this.setState({ accept: e.target.checked, error: false })
    };


    render() {
        const { data, menuList, status } = this.props;
        const { email, error, visibleMessage, accept } = this.state;
        return (
            <section className="campaign">
                <div className="container">
                    <div className="campaign--wall">
                        <img className="campaign--wall--photo"
                            src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(1, 1, data, "PHOTO").value}`}
                            alt={""} />
                    </div>
                    <div className="campaign--subscribe">
                        <div className="campaign--subscribe--infoBox">
                            <p dangerouslySetInnerHTML={{ __html: getDataBySectionAndWidget(2, 1, data, "TEXT").value }} />
                        </div>
                        <div className="campaign--subscribe--form">
                            <form onSubmit={this.handleSubscribe}>
                                <div className="campaign--subscribe--form--group">
                                    <input
                                        id="email"
                                        type="email"
                                        placeholder={i18next.t('footer.news.2')}
                                        className="campaign--subscribe--form--group--input"
                                        value={email}
                                        onChange={this.hendleChangeStateEmail}
                                    />
                                    <button disabled={!accept}
                                        className={`campaign--subscribe--form--group--btn ${accept ? "" : "disabled"}`}
                                        type="submit">{i18next.t('footer.news.4')}</button>
                                </div>
                                <div className="campaign--subscribe--form--check">
                                    <Checkbox
                                        text={i18next.t('footer.news.3')}
                                        data={<Link to={getRouteByMenuKey(menuList, "donnees_personnelles").route}
                                            target="_blank">{i18next.t('footer.news.7')}</Link>}
                                        name="connection_2"
                                        checked={accept}
                                        handleChange={this.handleChange}
                                        elemStyle={error ? "error" : ""}
                                    />
                                </div>

                            </form>

                            {visibleMessage && status && accept ?
                                <ResponseMessage status={status} errorText={i18next.t('footer.news.6')}
                                    successText={`${this.state.email} ${i18next.t('footer.news.5')}`} /> : ""}

                        </div>
                    </div>
                    <div className="campaign--banners">
                        <div className="campaign--banners--item">
                            <img className="campaign--banners--item--photo" src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(3, 1, data, "PHOTO").value}`} alt={""} />
                        </div>
                        <div className="campaign--banners--item">
                            <img className="campaign--banners--item--photo" src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(3, 2, data, "PHOTO").value}`} alt={""} />
                        </div>
                    </div>
                    <div className="campaign--pharagraph">
                        <h4 dangerouslySetInnerHTML={{ __html: getDataBySectionAndWidget(4, 1, data, "TEXT").value }} />
                    </div>
                </div>
            </section>
        )
    }
}


const mapStateToProps = (state: any): Props => ({
    data: getHome(state),
    menuList: getMenu(state),
    status: getStatus(state),
    banner: getBanner(state),
});

const mapDispatchToProps = (dispatch): typeof HomeActionCreators => {
    return bindActionCreators(HomeActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
