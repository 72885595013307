import React, {Component} from 'react';
import {Message} from "semantic-ui-react";


interface Props {
    status: string,
    errorText: string,
    successText: string
}

class ResponseMessage extends Component<Props> {

    render() {
        const {status, errorText, successText} = this.props;
        return (
            <>
                {
                    status === "failed" &&
                    <Message
                        error
                        content={errorText}
                    />
                }
                {
                    status === "success" &&
                    <Message
                        success
                        content={successText}
                    />
                }
            </>
        )
    }
}

export default ResponseMessage;