import React from 'react';
import './logoLoader.scss';
import logo from '../../../assets/img/logo-1.png'

const LogoLoader = () => {
    return (
        <div className="logo-loader">
            <img width="300" src={logo} alt={'loader'}/>
        </div>
    );
};

export default LogoLoader;