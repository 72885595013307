import {DownloadTypes, DownloadActionCreators} from "./actions";
import {takeLatest, put, call} from "redux-saga/effects";
import axios from 'axios';
import {DOWNLOAD_ID} from "../../../utils/constants/service";

export function*  getDownloadData(params) {
    try {
        const FileSaver = require('file-saver');
        const data = yield call(() => {
            return axios({
                url: `${DOWNLOAD_ID}${params.orderId}`,
                method: 'GET',
                responseType: 'blob', // important
                headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}
            }).then((response) => {
                if(response.data.size) {
                    FileSaver.saveAs(new Blob([response.data]), `${params.orderId}.pdf`);
                    return true;
                }else {
                    return false
                }
            });
        });
        yield put(DownloadActionCreators.downloadSuccess(data));
    } catch (e) {
        yield put(DownloadActionCreators.downloadFailure(e.message));
    }
}

export function* DownloadSaga(){
    yield takeLatest(DownloadTypes.DOWNLOAD_START, getDownloadData);
}

export default DownloadSaga;