import TagManager from 'react-gtm-module';
import { getBrandDetail } from "../layouts/main/header/redux/selectors";
import { store } from "../index";
import { GTM_TAG } from '../utils/constants/variables';
import { resetDatalayer } from './helpers';

interface ItemType {
	item_id: string,
	item_name: string,
	affiliation: string,
	coupon: string,
	discount: number,
	index: number,
	item_brand: string,
	item_category?: string,
	item_category2?: string,
	item_category3?: string,
	item_category4?: string,
	item_category5?: string,
	item_list_id: string,
	item_list_name: string,
	item_variant: string,
	location_id: string,
	price: number,
	quantity: number,
	promotion_name?: string,
	shipping_tier: string,
}

const InitialParams = ({ event, value, items }) => {
	const reduxState = store.getState();
	const detail = getBrandDetail(reduxState);
	return {
		dataLayer: {
			event,
			ecommerce: {
				gtmId: GTM_TAG,
				currency: detail.defaultCurrency || '',
				value,
				items,
			}
		}
	};
};

const defaultItem = (item: ItemType) => {
	return {
		item_id: item.item_id,
		item_name: item.item_name,
		affiliation: item.affiliation,
		coupon: item.coupon,
		discount: item.discount,
		index: item.index,
		item_brand: item.item_brand,
		item_list_id: item.item_list_id,
		item_list_name: item.item_list_name,
		item_variant: item.item_variant,
		location_id: item.location_id,
		price: item.price,
		quantity: item.quantity,
		shipping_tier: item.shipping_tier,
		...(item.promotion_name && {promotion_name: item.promotion_name}),
		...(item.item_category && {item_category: item.item_category}),
		...(item.item_category2 && {item_category2: item.item_category2}),
		...(item.item_category3 && {item_category3: item.item_category3}),
		...(item.item_category4 && {item_category4: item.item_category4}),
		...(item.item_category5 && {item_category5: item.item_category5}),
	}
}

class GA4Tracker {

	TrackItemview = (item = {} as ItemType, value = 0) => {
		const items = [defaultItem(item)];
		const event = 'view_item'
		const dataLayerEvent = {
			...InitialParams({ event, value, items }),
		};
		resetDatalayer()
		TagManager.dataLayer(dataLayerEvent);
	};

	TrackItemSelect = (item = {} as ItemType, value = 0) => {
		const items = [defaultItem(item)];
		const event = 'select_item'
		const dataLayerEvent = {
			...InitialParams({ event, value, items }),
		};
		resetDatalayer()
		TagManager.dataLayer(dataLayerEvent);
	};

	TrackAddToCart = (item = {} as ItemType, value = 0) => {
		const items = [defaultItem(item)];
		const event = 'add_to_cart'
		const dataLayerEvent = {
			...InitialParams({ event, value, items }),
		};
		resetDatalayer()
		TagManager.dataLayer(dataLayerEvent);
	};

	TrackAddToCartComplect = (cartItems = [], value = 0) => {
		const items = cartItems.map((item: ItemType) => {
			return defaultItem(item);
		});
		const event = 'add_to_cart'
		const dataLayerEvent = {
			...InitialParams({ event, value, items }),
		};
		resetDatalayer()
		TagManager.dataLayer(dataLayerEvent);
	};

	TrackCartView = (cartItems = [], value = 0) => {
		const items = cartItems.map((item: ItemType) => {
			return defaultItem(item);
		});
		const event = 'view_cart'
		const dataLayerEvent = {
			event: 'view_cart',
			...InitialParams({ event, value, items }),
		};
		resetDatalayer()
		TagManager.dataLayer(dataLayerEvent);
	};

	TrackBeginCheckout = (cartItems = [], value = 0) => {
		const items = cartItems.map((item: ItemType) => {
			return defaultItem(item);
		});
		const event = 'begin_checkout'
		const dataLayerEvent = {
			...InitialParams({ event, value, items }),
		};
		resetDatalayer()
		TagManager.dataLayer(dataLayerEvent);
	};

	TrackPurchase = (cartItems = [], value = 0) => {
		const items = cartItems.map((item: ItemType) => {
			return defaultItem(item);
		});
		const event = 'purchase'
		const dataLayerEvent = {
			...InitialParams({ event, value, items }),
		};
		resetDatalayer()
		TagManager.dataLayer(dataLayerEvent);
	};

	TrackRemoveFromCart = (item = {} as ItemType, value = 0) => {
		const items = [defaultItem(item)];
		const event = 'remove_from_cart'
		const dataLayerEvent = {
			...InitialParams({ event, value, items }),
		};
		resetDatalayer()
		TagManager.dataLayer(dataLayerEvent);
	};

	TrackAddToWishlist = (item = {} as ItemType, value = 0) => {
		const items = [defaultItem(item)];
		const event = 'add_to_wishlist'
		const dataLayerEvent = {
			...InitialParams({ event, value, items }),
		};
		resetDatalayer()
		TagManager.dataLayer(dataLayerEvent);
	};

	TrackLogin = () => {
		const dataLayerEvent = {
			dataLayer: {
				event: 'login',
				ecommerce: {
					method: 'Standard',
				}
			}
		};
		resetDatalayer()
		TagManager.dataLayer(dataLayerEvent);
	};

	TrackSignup = () => {
		const dataLayerEvent = {
			dataLayer: {
				event: 'sign_up',
				ecommerce: {
					method: 'Standard',
				}
			}
		};
		resetDatalayer()
		TagManager.dataLayer(dataLayerEvent);
	};

	TrackSearch = (searchTerm: string) => {
		const dataLayerEvent = {
			dataLayer: {
				event: 'search',
				ecommerce: {
					search_term: searchTerm,
				}
			}
		};
		resetDatalayer()
		TagManager.dataLayer(dataLayerEvent);
	};

	TrackViewItemList = (viewItems: ItemType[]) => {
		const items = viewItems.map((item: ItemType) => {
			return defaultItem(item);
		});
		const dataLayerEvent = {
			dataLayer: {
				event: 'view_item_list',
				ecommerce: {
					items
				}
			}
		};
		resetDatalayer()
		TagManager.dataLayer(dataLayerEvent);
	};
}

export const {
	TrackItemview,
	TrackItemSelect,
	TrackAddToCart,
	TrackAddToCartComplect,
	TrackCartView,
	TrackBeginCheckout,
	TrackPurchase,
	TrackRemoveFromCart,
	TrackAddToWishlist,
	TrackLogin,
	TrackSignup,
	TrackSearch,
	TrackViewItemList
} = new GA4Tracker();