import React, { useState } from "react";
import i18next from "i18next";
import Photo from "../../../common/product/photo";
import Price from "../../../common/product/price";
import ConfirmPopup from "../../../common/confirmPopup/confirmPopup";
import { connect } from 'react-redux'
import { getMenu } from "../../../products/redux/selectors";
import { getLocationSearch } from "../../../locationSearchInput/redux/selectors";
import { Dropdown, Icon } from 'semantic-ui-react';
import { HIDE_DISCOUNT, PRODUCT_MAX_QUANTITY, STORAGE_BASKET_INDEX, STORE_DELIVERY } from "../../../../utils/constants/variables";
import { Productoptions, getConvertedItemDataForGA4, getMenuItemByMenuIdElem, getPackDiscountPrice, getShippingCity, getbasketLineItems, isExistProductInClickAndCollect, replaceComma, setStoreToBasket } from "../../../../helpers/helpers";
import { AddToCartActionCreators } from "../../../addToCart/redux/actions";
import { LocationSearchActionCreators } from "../../../locationSearchInput/redux/actions";
import { TrackAddToCart, TrackRemoveFromCart } from "../../../../helpers/ga4Tracker";
import { getNotFoundProduct } from "../../../../layouts/main/header/redux/selectors";
import notFound from '../../../../assets/img/404.svg'

interface Props {
  productBySize: any[];
  basketStore: Record<string, any>;
  menuList: any[];
  locationSearchData: any[];
  dispatch: (...args) => void;
  changeQuantity: (...args) => void;
  notFoundProduct?: any
}

const Summary: React.FC<Props> = ({
  productBySize,
  basketStore,
  menuList,
  dispatch,
  notFoundProduct
}) => {
  const [isInfoPopupVisible, setIsInfoPopupVisible] = useState<boolean>(false);

  const getQtyOptions = (sizeElement) => [...Productoptions(basketStore.StoreInfo ? PRODUCT_MAX_QUANTITY : sizeElement.webInventory)];

  /**
   * check is one of stores has enough stok for each item on basket with new changed qty
   * if exist change qty otherwise keep old qty and do nothing
  */
  const onClickAndCollectChanged = (item, quantity, stores): void => {
    const { isProductExist, validStore } = stores.reduce((acc, store) => {
      if (acc.isProductExist) return acc;
      if (!store.shipment || !store.hasBasket) return acc;

      const validProductsCount = store.barcodeList.reduce((count, barcode) => {
        const productSize = productBySize.find((product) => product.productId === barcode.productId);

        if (productSize === undefined) return count;

        const productQuantity = productSize.productId === item.productId ? quantity : productSize.quantity;

        if (productQuantity <= barcode.stock) return count + 1;

        return count
      }, 0);

      if (validProductsCount === productBySize.length) {
        return { isProductExist: true, validStore: store }
      }

      return acc;
    }, { isProductExist: false, validStore: null });

    dispatch(LocationSearchActionCreators.locationSearchDestroyedStart());
    if (!isProductExist) {
      setIsInfoPopupVisible(true);
      return;
    }

    setStoreToBasket(validStore);
    dispatch(AddToCartActionCreators.addtocartStart(
      STORAGE_BASKET_INDEX,
      item.parentId,
      { size: { quantity: quantity, productId: item.productId } },
      true
    ));

    try {
      const menuItem = getMenuItemByMenuIdElem(menuList, item.menuId);
      const isBigger = quantity > item.quantity;
      const quantityDifference = Math.abs(quantity - item.quantity);
      const dataT = getConvertedItemDataForGA4(menuItem, item, quantityDifference);
      const valueT = dataT.price * dataT.quantity;
      if (isBigger) TrackRemoveFromCart(dataT, valueT);
      else TrackAddToCart(dataT, valueT);
    } catch (error) {
      console.log(error);      
    }
  }

  const handleChangeQuantity = (item, newQuantity): void => {
    const storeDelivery = getShippingCity();
    const storeDeliveryType = basketStore.storeDelivery || '';
    const queryParams = storeDeliveryType === 'DIS' ? { shipment: true } : { consignment: true };
    const products = productBySize.map((size) => {
      const quantity = size.productId === item.productId ? newQuantity : size.quantity;

      return { productId: size.productId, quantity };
    });

    if (!storeDelivery || STORE_DELIVERY !== '1') return;

    dispatch(LocationSearchActionCreators.clickAndCollectStoresStart({
      location: storeDelivery,
      body: { basketLineItems: [...products] },
      queryParams
    }, onClickAndCollectChanged.bind(null, item, newQuantity)));
  }

  const setPackElem = (name, value) => {
    handleChangeQuantity(name, value)
  }

  const handleRemoveItem = (item) => {
    try {
      const basketMenuItem = getMenuItemByMenuIdElem(menuList, item.menuId)
      const dataT = getConvertedItemDataForGA4(basketMenuItem, item, item.quantity && item.quantity > 0 ? item.quantity : 1, item);
      const valueT = dataT.price * dataT.quantity;
      // eslint-disable-next-line no-undef
      TrackRemoveFromCart(dataT, valueT)
    } catch (error) {
      console.log(error)
    }
    dispatch(AddToCartActionCreators.removeBasketStart(STORAGE_BASKET_INDEX, item.parentId, item.productId));
  };

  if (!productBySize.length) return null;

  return (
    <div className="summary summary-container">
      <div className="summary-header dflex align-items-center">
        <h4 className="title">{i18next.t('product.basket.summary.1')}</h4>
        <h4 className="title">{i18next.t('product.basket.summary.2')}</h4>
        <h4 className="title">{i18next.t('product.basket.summary.3')}</h4>
        <h4 className="title">{i18next.t('product.basket.summary.4')}</h4>
      </div>
      {productBySize.map((item) => {
        const { parentElementQuantity, childElementQuantity, totalChildPrice, childElementStartingPrice } = getPackDiscountPrice("basket", item, item);
        const isPackElem = item.packParent && childElementQuantity === parentElementQuantity;

        return (
          <div className="summary-item dflex align-items-center click-and-collect" key={item.productId}>
            <div className="summary-item-about dflex align-items-stretch store-locator-basket">
              <div className="summary-item-img">
                <Photo
                  src={item.photo || notFoundProduct || notFound}
                  thumbnail={item.thumbnail}
                  discountDetail={""}
                  outlet={item.outlet}
                />
              </div>
              <div className="summary-item-about-content dflex">
                <div className="summarry-info-container">
                  <h4 className="title">
                    {item.name} {!item.webInventory && basketStore.storeDelivery === 'STO' ? ` (${i18next.t('catalogue.product.9')})` : ""}
                  </h4>
                  <div className="summary-item-about-text">
                    <p>{i18next.t("product.product.17")} : {item.size}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="dflex align-items-center summary-item-price">
              <div className="select-wrapper">
                <Dropdown
                  className="mainSelect mini"
                  value={item.quantity.toString()}
                  options={getQtyOptions(item).filter((elem) => !item.packParent || Number(elem.value) >= parentElementQuantity)}
                  compact
                  selection
                  name={`${item.parentId}/${item.productId}`}
                  onChange={(_, { value }) => handleChangeQuantity(item, +(value || 1))}
                  icon={<Icon name='angle down' />}
                />
              </div>
              <div className="summary-box">
                <Price
                  startP={item.startingPrice}
                  oldP={item.oldPrice}
                  discount={item.discountDetail}
                  newP={item.listPriceAfterDiscount}
                  quantity={item.quantity}
                  isMultiple={false}
                />
                <p className="summary-item-price-text">{i18next.t('product.basket.summary.5')}</p>
              </div>
              <div className="summary-box">
                {item.packParent ? (
                  <>
                    {
                      totalChildPrice !== childElementQuantity * childElementStartingPrice && HIDE_DISCOUNT === "0" ? (
                        <span
                          className="old-price"
                        >{replaceComma(childElementQuantity * childElementStartingPrice)}</span>
                      ) : null
                    }
                    <span className="new-price">{replaceComma(totalChildPrice)}</span>
                  </>
                ) : (
                  <Price
                    oldP={item.oldPrice}
                    startP={item.startingPrice}
                    discount={item.discountDetail}
                    newP={item.listPriceAfterDiscount}
                    quantity={item.quantity}
                  />
                )}
              </div>
              {isPackElem ? (
                <Icon name="trash alternate"
                  disabled={isPackElem}
                  onClick={() => childElementQuantity > parentElementQuantity
                    ? setPackElem(item, parentElementQuantity || 1)
                    : handleRemoveItem(item)
                  }
                />
              ) : (
                <Icon
                  name="trash alternate"
                  onClick={() => parentElementQuantity && childElementQuantity > parentElementQuantity
                    ? setPackElem(item, parentElementQuantity || 1)
                    : handleRemoveItem(item)
                  }
                />
              )}
              {isInfoPopupVisible && (
                <ConfirmPopup
                  handleCancel={() => setIsInfoPopupVisible(false)}
                  text={i18next.t('modal.2')}
                  title={""}
                  footer={false}
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  );
}

const mapStateToProps = (state: any): Partial<Props> => ({
  menuList: getMenu(state),
  locationSearchData: getLocationSearch(state),
  notFoundProduct: getNotFoundProduct(state),
});

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
};

export const SummaryBasket = connect(mapStateToProps, mapDispatchToProps)(Summary);
