import {createActions} from 'reduxsauce';

export const {Types: MesAddressTypes, Creators: MesAddressActionCreators} = createActions ({
   mesAddressStart: [null],
   mesAddressSuccess: ['data'],
   mesAddressFailure: ['error'],
   mesAddressPostStart: ['data'],
   mesAddressPostSuccess: ['data'],
   mesAddressPostFailure: ['error'],
   mesAddressRemoveStart: ['data'],
   mesAddressRemoveSuccess: ['data'],
   mesAddressRemoveFailure: ['error']
});