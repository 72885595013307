import { MesInformationTypes, MesInformationActionCreators } from "./actions";
import { takeLatest, put, call } from "redux-saga/effects";
import axios from 'axios';
import {
    WEB_CLIENT,
    STORES_BRAND_URL,
    USER_UPDATE_URL,
    PASSWORD_CHANGE_URL
} from "../../../../../../utils/constants/service";
import { ALT_BRAND_ID, BRAND_ID } from "../../../../../../utils/constants/variables";

export function* getMesInformation(params) {
    const { callback } = params;
    try {
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };
        const country = localStorage.getItem("country");
        const caountryPayload = country ? `&country=${country}` : "";

        const webClient = yield call(() => {
            return axios.get(WEB_CLIENT, headers)
                .then(res => res.data)
        });

        const brand = yield call(() => {
            return axios.get(`${STORES_BRAND_URL}?brandId=${ALT_BRAND_ID || BRAND_ID}${caountryPayload}`, headers)
                .then(res => res.data)
        });

        const data = [];
        data["webClient"] = webClient;
        data["brand"] = brand;

        yield put(MesInformationActionCreators.mesInformationSuccess(data));
        callback && callback(data);
    } catch (e) {
        yield put(MesInformationActionCreators.mesInformationFailure(e.message));
    }
}


export function* postMesInformation(params) {
    try {
        const headers = {
            headers: {
                brand: BRAND_ID,
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            }
        };

        const data = yield call(() => {
            return axios.post(`${USER_UPDATE_URL}`, JSON.stringify(params.data), headers)
                .then(res => res.data)
        });

        yield put(MesInformationActionCreators.mesInformationPostSuccess(data));
        yield put(MesInformationActionCreators.mesInformationStart());
    } catch (e) {
        yield put(MesInformationActionCreators.mesInformationPostFailure(e.message));
    }
}

export function* mesInformationChangePass(params) {
    try {
        const headers = {
            headers: {
                brand: BRAND_ID,
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            }
        };

        const data = yield call(() => {
            return axios.post(`${PASSWORD_CHANGE_URL}`, JSON.stringify(params.data), headers)
                .then(res => res.data)
        });

        yield put(MesInformationActionCreators.mesInformationChangePassSuccess(data));
    } catch (e) {
        yield put(MesInformationActionCreators.mesInformationChangePassFailure(e.message));
    }
}

export function* mesInformationSaga() {
    yield takeLatest(MesInformationTypes.MES_INFORMATION_START, getMesInformation);
    yield takeLatest(MesInformationTypes.MES_INFORMATION_POST_START, postMesInformation);
    yield takeLatest(MesInformationTypes.MES_INFORMATION_CHANGE_PASS_START, mesInformationChangePass);
}

export default mesInformationSaga;