import React from "react";
import i18next from "i18next";
import { Dropdown, Icon } from 'semantic-ui-react';
import { connect } from "react-redux";

import Photo from "../../../common/product/photo";
import Price from "../../../common/product/price";

import { HIDE_DISCOUNT, STORAGE_BASKET_INDEX, STORE_DELIVERY, PRODUCT_MAX_QUANTITY } from "../../../../utils/constants/variables";
import {
    getbasketLineItems,
    getConvertedItemDataForGA4,
    getMenuItemByMenuId,
    getMenuItemByMenuIdElem,
    getMenuItemByProductId,
    getPackDiscountPrice,
    getShippingCity,
    isExistProductInClickAndCollect,
    Productoptions,
    replaceComma,
} from "../../../../helpers/helpers";
import { getMenu } from "../../../products/redux/selectors";
import { AddToCartActionCreators } from "../../../addToCart/redux/actions";
import { getBasket } from "../../../addToCart/redux/selectors";
import { getLocationSearch } from "../../../locationSearchInput/redux/selectors";
import { bindActionCreators } from "redux";
import { OrderActionCreators } from "../../redux/actions";
import ConfirmPopup from "../../../common/confirmPopup/confirmPopup";
import { TrackRemoveFromCart, TrackAddToCart } from "../../../../helpers/ga4Tracker";

interface Props {
    isChanged?: false,
    items?: any,
    changeQuantity?: any,
    removeItem?: any,
    productQTY?: string,
    quantityQTY?: string | number,
    menuList?: any,
    basket?: any,
    locationSearchData?: any
}
interface State {
    isChanged: boolean,
    productQTY: string,
    quantityQTY: string | number,
    showInfoPopUp: boolean,
    currentQuantity: number,
}
class Summary extends React.Component<Props & typeof AddToCartActionCreators> {

    state: State = {
        isChanged: false,
        productQTY: "",
        quantityQTY: "",
        showInfoPopUp: false,
        currentQuantity: 0,
    }

    isStoresChanged = (oldStores, newStores) => {
        return oldStores.length !== newStores.length
            ? true
            : newStores.reduce((isChanged, store, index) => {
                if (!oldStores[index] || store.storeCode !== oldStores[index].storeCode) {
                    isChanged = true;
                }
                return isChanged;
            }, false)
    }

    changeQuantity = (name, value) => {
        const ids = name.split("/");
        this.setState({ isChanged: true });
        this.props.addtocartStart(STORAGE_BASKET_INDEX, ids[0], { size: { quantity: value, productId: ids[1] } }, true);
    }

    handleChangeQuantity = (name, value, currentQuantity = 0) => {
        const { basket } = this.props;
        const ids = name.split("/");
        const storeDelivery = getShippingCity();
        const storeDeliveryType = basket.store && basket.store.storeDelivery || '';

        this.props.changeQuantity(STORAGE_BASKET_INDEX, ids[0], { size: { quantity: value, productId: ids[1] } }, true);
        const products = getbasketLineItems();

        if (storeDelivery && STORE_DELIVERY === '1') {
            products.map(prod => prod.productId === Number(ids[1]) ? prod.quantity = Number(value) : prod);
            this.setState({ quantityQTY: value, productQTY: name });
            const queryParams = storeDeliveryType === 'DIS' ? { shipment: true } : { consignment: true };
            this.props.clickAndCollectStoresStart({
                location: storeDelivery,
                body: { basketLineItems: [...products] },
                queryParams
            });
        } else {
            this.changeQuantity(name, value);
        }

        this.setState({ currentQuantity })
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { basket } = this.props;
        if (!prevState.selectedId && this.state.isChanged) {
            this.setState({ isChanged: false });
        }
        if ((this.props.locationSearchData && prevProps.locationSearchData) && this.isStoresChanged(prevProps.locationSearchData, this.props.locationSearchData)) {
            const { quantityQTY, productQTY } = this.state;
            const ids = productQTY.split("/");
            const validStores = this.props.locationSearchData.filter(item => item.shipment && item.hasBasket);
            const products = getbasketLineItems();
            products.map(prod => prod.productId === Number(ids[1]) ? prod.quantity = quantityQTY : prod);
            if (Object.keys(this.props.locationSearchData).length && productQTY && quantityQTY && validStores && validStores.length) {
                const validStore = validStores.find(store => store.shipment && store.hasBasket);
                if (isExistProductInClickAndCollect(validStore.barcodeList, [...products])) {

                    const product: any = getMenuItemByProductId(basket, +ids[1])
                    if (product && Object.keys(product).length) {
                        const menuItem = getMenuItemByMenuIdElem(this.props.menuList, product.menuId)
                        const isBigger = this.state.currentQuantity > +quantityQTY
                        const quantityDifference = isBigger ? this.state.currentQuantity - +quantityQTY : +quantityQTY - this.state.currentQuantity
                        const dataT = getConvertedItemDataForGA4(menuItem, product, quantityDifference);
                        const valueT = dataT.price * dataT.quantity
                        if (isBigger) TrackRemoveFromCart(dataT, valueT)
                        else TrackAddToCart(dataT, valueT)
                    }

                    this.changeQuantity(productQTY, quantityQTY);
                    this.props.locationSearchDestroyedStart();
                    this.setState({ quantityQTY: "", productQTY: "" });
                } else {
                    this.setState({ showInfoPopUp: true, quantityQTY: "", productQTY: "", currentQuantity: 0 });
                    this.props.locationSearchDestroyedStart();
                }
            } else if (productQTY && quantityQTY) {
                this.setState({ showInfoPopUp: true, quantityQTY: "", productQTY: "", currentQuantity: 0 });
                this.props.locationSearchDestroyedStart();
            }
        }
    }
    handleRemoveItem = (productId, sizeElement) => {

        const { basket } = this.props;
        const { productId: sizeElementProductId } = sizeElement;

        const remItem = basket[productId] || {}
        const basketMenuItem = getMenuItemByMenuIdElem(this.props.menuList, remItem.menuId)
        const dataT = getConvertedItemDataForGA4(this.props.menuItem || basketMenuItem, remItem, sizeElement.quantity && sizeElement.quantity > 0 ? sizeElement.quantity : 1, sizeElement);
        const valueT = dataT.price * dataT.quantity;
        // eslint-disable-next-line no-undef
        TrackRemoveFromCart(dataT, valueT)

        this.props.removeItem(STORAGE_BASKET_INDEX, productId, sizeElementProductId);
    };

    setPackElem = (name, value) => {
        this.handleChangeQuantity(name, value)
    }

    render() {
        const { showInfoPopUp } = this.state;
        const { items, productQTY, quantityQTY } = this.props;
        const storeDelivery = items && items["store"] && items["store"]["StoreInfo"];
        const storeDeliveryType = items && items["store"] && items["store"]["storeDelivery"];

        return (
            <div className="summary">
                {items && Object.keys(items).length ? <div className="summary-header dflex align-items-center">
                    <h4 className="title">{i18next.t('product.basket.summary.1')}</h4>
                    <h4 className="title">{i18next.t('product.basket.summary.2')}</h4>
                    <h4 className="title">{i18next.t('product.basket.summary.3')}</h4>
                    <h4 className="title">{i18next.t('product.basket.summary.4')}</h4>
                </div> : ""
                }
                {
                    items && Object.keys(items).map((item) => (
                        items[item].sizes && items[item].sizes.length && items[item].sizes.map((sizeElement, i) => {
                            const ids = productQTY && productQTY.split("/");
                            const { parentElementQuantity, childElementQuantity, totalChildPrice, childElementStartingPrice } = getPackDiscountPrice("basket", items[item], item);
                            const isPackElem = items[item].packParent && childElementQuantity === parentElementQuantity;
                            const currentQty = ids && sizeElement.productId === Number(ids[1]) ? quantityQTY && quantityQTY.toString() : sizeElement.quantity.toString();
                            const qunatityOptions = [
                                ...Productoptions(storeDelivery ? PRODUCT_MAX_QUANTITY : sizeElement.webInventory)
                            ]
                            
                            return (
                                <div className="summary-item dflex align-items-center click-and-collect" key={i}>
                                    <div className="summary-item-about dflex align-items-stretch store-locator-basket">
                                        <div className="summary-item-img">
                                            <Photo src={items[item].photo} thumbnail={items[item].thumbnail} discountDetail={""} outlet={item[item] && item[item].outlet} />
                                        </div>
                                        <div className="summary-item-about-content dflex">
                                            <div className="summarry-info-container">
                                                <h4 className="title">
                                                    {items[item].name} {!sizeElement.webInventory && storeDeliveryType === 'STO'
                                                        ? ` (${i18next.t('catalogue.product.9')})` : ""}
                                                </h4>
                                                <div className="summary-item-about-text">
                                                    <p>{i18next.t("product.product.17")} : {sizeElement.size}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dflex align-items-center summary-item-price">
                                        <div className="select-wrapper">
                                            <Dropdown
                                                className="mainSelect mini"
                                                value={sizeElement.quantity.toString()}
                                                options={qunatityOptions.filter(elem => !items[item].packParent || Number(elem.value) >= parentElementQuantity)}
                                                compact
                                                selection
                                                name={`${item}/${sizeElement.productId}`}
                                                onChange={(e, { value, name }) => this.handleChangeQuantity(name, value, sizeElement.quantity)}
                                                icon={<Icon name='angle down' />}
                                            />
                                        </div>
                                        <div className="summary-box">
                                            <Price
                                                startP={sizeElement.startingPrice}
                                                oldP={sizeElement.oldPrice}
                                                discount={sizeElement.discountDetail}
                                                newP={sizeElement.listPriceAfterDiscount}
                                                quantity={sizeElement.quantity}
                                            />
                                            <p className="summary-item-price-text">{i18next.t('product.basket.summary.5')}</p>
                                        </div>

                                        <div className="summary-box">
                                            {
                                                items[item].packParent ?
                                                    <>
                                                        {
                                                            totalChildPrice !== childElementQuantity * childElementStartingPrice && HIDE_DISCOUNT === "0" ?
                                                                <span className="old-price"> {replaceComma(childElementQuantity * childElementStartingPrice)}</span> : null
                                                        }
                                                        <span className="new-price">{replaceComma(totalChildPrice)}</span>
                                                    </>
                                                    :
                                                    <Price
                                                        oldP={sizeElement.oldPrice}
                                                        startP={sizeElement.startingPrice}
                                                        discount={sizeElement.discountDetail}
                                                        newP={sizeElement.listPriceAfterDiscount}
                                                        quantity={sizeElement.quantity}
                                                    />
                                            }

                                        </div>
                                    </div>
                                    {
                                        isPackElem ?
                                            <Icon name="trash alternate"
                                                disabled={isPackElem}
                                                onClick={() => childElementQuantity > parentElementQuantity
                                                    ? this.setPackElem(`${item}/${sizeElement.productId}`, parentElementQuantity.toString())
                                                    : this.handleRemoveItem(item, sizeElement)
                                                }>
                                            </Icon> :
                                            <Icon name="trash alternate"
                                                onClick={() => parentElementQuantity && childElementQuantity > parentElementQuantity
                                                    ? this.setPackElem(`${item}/${sizeElement.productId}`, parentElementQuantity.toString())
                                                    : this.handleRemoveItem(item, sizeElement)
                                                }>
                                            </Icon>
                                    }
                                    {showInfoPopUp && (
                                        <ConfirmPopup
                                            handleCancel={() => this.setState({ showInfoPopUp: false })}
                                            text={i18next.t('modal.2')}
                                            title={""}
                                            footer={false}
                                        />
                                    )}

                                </div>
                            )
                        })
                    )
                    )
                }

            </div>
        );
    }
}

const mapStateToProps = (state: any): Props => ({
    menuList: getMenu(state),
    basket: getBasket(state),
    locationSearchData: getLocationSearch(state),
});

const mapDispatchToProps = (dispatch): typeof AddToCartActionCreators => {
    return bindActionCreators({ ...AddToCartActionCreators, validateDiscountStart: OrderActionCreators.validateDiscountStart }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
