import React from "react";
import '../product.scss'
import {connect} from "react-redux";
import Complect from "./complect";
import Parure from "./parure";
import Details from "./details";
import { getConvertedItemDataForGA4 } from "../../../../helpers/helpers";
import { TrackAddToCartComplect, TrackItemSelect, TrackItemview } from "../../../../helpers/ga4Tracker";
import { getIsSentByColor } from "../../redux/selectors";
import { ProductActionCreators } from "../../redux/actions";
import { bindActionCreators } from "redux";

interface Props {
    handleBasketToggle: any,
    data: any,
    menuItem?: any,
    recommendedProducts: any,
    isSentByColor?: boolean,
    selectColor?: any,
}

interface State {
    parureSrc:string,
}
class ProductC extends React.Component<Props> {
    state: State = {
        parureSrc:"",
    };

    public divRef = React.createRef<HTMLDivElement>();
    public refDescription = React.createRef<HTMLDivElement>();

    scrollToDescription = () => {
        return window.scrollTo(0, this.refDescription.current!.offsetTop - this.refDescription.current!.offsetHeight  );
    };

    componentDidUpdate(prevProps) {
        if(this.props.data && prevProps.data && this.props.data.productId !== prevProps.data.productId){
            const data = getConvertedItemDataForGA4(this.props.menuItem, this.props.data, 1, undefined);
            const value = data.price;
            if (this.props.isSentByColor) {
                TrackItemSelect(data, value)
                this.props.selectColor(false)
            }
            else TrackItemview(data, value)
            this.setState({
                isSended: true
            })
        }
    }

    componentDidMount() {
        const data = getConvertedItemDataForGA4(this.props.menuItem, this.props.data, 1, undefined);
        const value = data.price;
        TrackItemview(data, value)
        this.setState({
            isSended: true
        })
    }

    handleAddToCartEvent = (isComplect: boolean = false, changedSize: any = null, selectedQuantity: any = null, items: any = null) => {

        const itemsT = [] as any
        let value  = 0
        Object.keys(items).map((item: any) => {
            const dataT = getConvertedItemDataForGA4(this.props.menuItem, items[item], 
                items[item].size && items[item].size.quantity 
                && items[item].size.quantity > 0 
                    ? items[item].size.quantity 
                    : 1, 
            items[item].size);
            itemsT.push(dataT)
            let price = dataT.price * dataT.quantity
            value += price
        })
        return TrackAddToCartComplect(itemsT, value)
    }

    render() {
        const {data, menuItem,recommendedProducts} = this.props;
        return (
            <div className="products">
                <Complect handleBasketToggle={this.props.handleBasketToggle} data={data}
                          handleScrollToDescription={this.scrollToDescription}
                          recommendedProducts={recommendedProducts}
                          menuItem={menuItem}
                          handleAddToCartEvent={this.handleAddToCartEvent}
                />
                <Details refDescription={this.refDescription} data={data}/>
            </div>
        )
    }
}

const mapStateToProps = (state: any): any => ({
    isSentByColor: getIsSentByColor(state),
});

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(ProductActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductC);
