import { MesAddressTypes, MesAddressActionCreators } from "./actions";
import { takeLatest, put, call } from "redux-saga/effects";
import axios from 'axios';
import { WEB_CLIENT_ADDRESS } from "../../../../../../utils/constants/service";
import { BRAND_ID } from "../../../../../../utils/constants/variables";
import { isLoggedin } from "../../../../../../helpers/helpers";

export function* getMesAddress() {
    try {
        if (!isLoggedin()) {
            throw new Error()
        }
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        };

        const data = yield call(() => {
            return axios.get(WEB_CLIENT_ADDRESS, headers)
                .then(res => res.data)
        });

        yield put(MesAddressActionCreators.mesAddressSuccess(data));
    } catch (e) {
        yield put(MesAddressActionCreators.mesAddressFailure(e.message));
    }
}


export function* postMesAddress(params) {
    try {
        if (!isLoggedin()) {
            throw new Error()
        }
        const headers = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            }
        };

        const data = yield call(() => {
            return axios.post(`${WEB_CLIENT_ADDRESS}`, JSON.stringify(params.data), headers)
                .then(res => res.data)
        });

        yield put(MesAddressActionCreators.mesAddressPostSuccess(data));
        yield put(MesAddressActionCreators.mesAddressStart());
    } catch (e) {
        yield put(MesAddressActionCreators.mesAddressPostFailure(e.message));
    }
}
export function* removeMesAddress(params) {
    try {
        if (!isLoggedin()) {
            throw new Error()
        }
        const headers = {
            headers: {
                brand: BRAND_ID,
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            }
        };

        const data = yield call(() => {
            return axios.delete(`${WEB_CLIENT_ADDRESS}?addressType=${params.data.addressType}&clientAddressId=${params.data.clientAddressId}`, headers)
                .then(res => res.data)
        });

        yield put(MesAddressActionCreators.mesAddressRemoveSuccess(data));
        yield put(MesAddressActionCreators.mesAddressStart());
    } catch (e) {
        yield put(MesAddressActionCreators.mesAddressRemoveFailure(e.message));
    }
}

export function* mesAddressSaga() {
    yield takeLatest(MesAddressTypes.MES_ADDRESS_START, getMesAddress);
    yield takeLatest(MesAddressTypes.MES_ADDRESS_POST_START, postMesAddress);
    yield takeLatest(MesAddressTypes.MES_ADDRESS_REMOVE_START, removeMesAddress);
}

export default mesAddressSaga;