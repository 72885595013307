import React from 'react'
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css'
import {Link} from "react-router-dom";
import {getDataBySectionAndWidget, getLangPrefix} from "../../../../helpers/helpers";
import {BASE_API_GATEWAY} from "../../../../utils/constants/service";
import i18next from "i18next";


const BlogSlider = (props) => {

    const params = {
        direction:  "horizontal",
        slidesPerView: 3,
        speed: 600,
        setWrapperSize: true,
        resizeReInit: true,
        autoplay: {delay: 5000},
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            382: {
                slidesPerView: 1,
            },
            680: {
                slidesPerView: 3,
            },
            980: {
                slidesPerView:4,
            },
        },
    };

    const sortedObj = Object.keys(props.data).reverse();

    return (
        <div className="blogSlider container">
            <h4 className="recommendedSlidercontent--title ">{i18next.t('blog.2')}</h4>
            { sortedObj && sortedObj.length ?
            <Swiper {...params}>
                {  sortedObj.map((item, i) => (
                        <div className="img-box-blog" key={i}>
                            <Link to={`${getLangPrefix() + "/magazine" + "/" + item}`}>
                                {props.postId === +item && window.scrollTo(0,0)}
                                <img
                                    src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(+item, 1, props.data[item], "PHOTO").value}`}/></Link>
                            {<p className="title-blog-slider"
                                dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(+item, 1, props.data[item], "TEXT", "Title").value}}/>}
                        </div>
                    ))
                }
            </Swiper>: ""}
        </div>
    )
};


export default BlogSlider