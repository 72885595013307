import { createReducer } from 'reduxsauce';
import { updateStoreVariables, updateVariables } from '../../../../utils/constants/variables';
import { MenuTypes } from './actions';
import { BASE_API_GATEWAY } from '../../../../utils/constants/service';

export interface IMenuState {
    menu: any,
    searching: any,
    error: string,
    language: string,
    cookieContent: any,
    isLoading: boolean,
    stores: any[],
    brandDetail: any,
    brandDetailLoading: boolean,
    clusterBarcodes: string[],
    clusterPackBarcodes: Record<string, Array<string>>,
    notFoundData: Record<string, any>[],
    notFoundBanner: string,
    notFoundProduct: string,
}

export const menuR: IMenuState = {
    menu: [],
    searching: {},
    error: "",
    language: "it",
    cookieContent: [],
    isLoading: false,
    stores: [],
    brandDetail: [],
    brandDetailLoading: false,
    clusterBarcodes: [],
    clusterPackBarcodes: {},
    notFoundData: [],
    notFoundBanner: '',
    notFoundProduct: ''
};

export const menu = (state) => {
    return { ...state, isLoading: true };
};

export const menuSuccess = (state, action) => {
    return { ...state, menu: action.data, isLoading: false };
};

export const menuFailure = (state, action) => {
    return { ...state, error: action.error, menu: [], isLoading: false };
};

export const notFoundSuccess = (state, action) => {
    const { data } = action;
    const bannerImage = data.find(img => img.name === "404_banner")
    let notFoundBanner = '';
    if (bannerImage) notFoundBanner = bannerImage.value

    const bannerProduct = data.find(img => img.name === "404_product")
    let notFoundProduct = '';
    if (bannerProduct) notFoundProduct = bannerProduct.value
    return { 
        ...state, 
        notFoundData: data,
        notFoundBanner,
        notFoundProduct
    };
}

export const cookieContentStart = (state) => {
    return { ...state, cookieContent: [] };
};

export const cookieContentSuccess = (state, action) => {
    return { ...state, cookieContent: action.data };
};

export const cookieContentFailure = (state, action) => {
    return { ...state, error: action.error, cookieContent: [] };
};

export const searching = (state) => {
    return { ...state };
};


export const language = (state, action) => {
    localStorage.setItem("language", action.lang);
    return { ...state, language: action.lang };
};

export const getStores = (state) => {
    return { ...state, isLoading: true };
}

export const storesSuccess = (state, action) => {
    return { ...state, stores: action.data, isLoading: false };
}

export const storesFailure = (state) => {
    return { ...state, stores: [], isLoading: false };
}

export const brandDetail = (state) => {
    return { ...state, brandDetailLoading: true };
};

export const brandDetailSuccess = (state, action) => {
    updateVariables("PROMOTION_CLUSTER", action.data.promotionCluster);
    updateVariables("LANGUAGE", action.data.lowerlanguage);
    updateStoreVariables(action.data);
    return { ...state, brandDetail: action.data, brandDetailLoading: false };
};

export const brandDetailFailure = (state, action) => {
    return { ...state, error: action.error, brandDetail: [], brandDetailLoading: false };
};

export const productClusterStart = (state) => {
    return state;
}

export const productClusterSuccess = (state, action) => {
    return { ...state, clusterBarcodes: action.data };
}

export const productPackClusterSuccess = (state, action) => {
    return { ...state, clusterPackBarcodes: action.data }
}

export const productClusterFailure = (state) => {
    return { ...state, clusterBarcodes: [], clusterPackBarcodes: {} };
}
export const setMenuItem = (state, action) => {
    return { ...state, menuItem: action.data }

}

export const handlers = {
    [MenuTypes.MENU_START]: menu,
    [MenuTypes.MENU_SUCCESS]: menuSuccess,
    [MenuTypes.MENU_FAILURE]: menuFailure,
    [MenuTypes.NOT_FOUND_SUCCESS]: notFoundSuccess,
    [MenuTypes.SEARCHING_START]: searching,
    [MenuTypes.LANGUAGE_START]: language,
    [MenuTypes.COOKIE_CONTENT_START]: cookieContentStart,
    [MenuTypes.COOKIE_CONTENT_SUCCESS]: cookieContentSuccess,
    [MenuTypes.COOKIE_CONTENT_FAILURE]: cookieContentFailure,
    [MenuTypes.GET_STORES_START]: getStores,
    [MenuTypes.GET_STORES_SUCCESS]: storesSuccess,
    [MenuTypes.GET_STORES_FAILURE]: storesFailure,
    [MenuTypes.BRAND_DETAIL_START]: brandDetail,
    [MenuTypes.BRAND_DETAIL_SUCCESS]: brandDetailSuccess,
    [MenuTypes.BRAND_DETAIL_FAILURE]: brandDetailFailure,
    [MenuTypes.GET_BARCODES_BY_PRODUCT_CLUSTER_ID_START]: productClusterStart,
    [MenuTypes.GET_BARCODES_BY_PRODUCT_CLUSTER_ID_SUCCESS]: productClusterSuccess,
    [MenuTypes.GET_BARCODES_BY_PRODUCT_CLUSTER_ID_FAILURE]: productClusterFailure,
    [MenuTypes.GET_PACK_BARCODES_BY_PRODUCT_CLUSTER_ID_SUCCESS]: productPackClusterSuccess,
    [MenuTypes.SET_MENU_ITEM]: setMenuItem
};

export default createReducer(menuR, handlers);
