import {createActions} from 'reduxsauce';

export const {Types: AddToCartTypes, Creators: AddToCartActionCreators} = createActions ({
    addtocartStart: ["name", "key", "value", "setAsQuantity"],
    addtocartSuccess: ['data'],
    addtocartFailure: ['error'],
    getBasketStart: [null],
    getBasketSuccess: ["data"],
    basketUpdateStart: ['basket'],
    removeBasketStart: ["name", "key", "prSize"],
    updateBasketStoreStart: ["store"],
    updateBasketStoreSuccess: ["basket"],
    removeBasketSuccess: ["data"],
    destroyBasketStart: null,
    clickAndCollectStoresStart: ['info', 'callback'],
    locationSearchDestroyedStart: [null],
});
