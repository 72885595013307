import {groupByKey} from "../../../helpers/helpers";
export const getBlogData = state =>{
    const {data} = state.blogContent;
    const sortData = data.sort((a,b)=>b.section - a.section);
    return groupByKey(sortData,"section");
};
export const getItemPost = (state,postId) => {
   const {data} = state.blogContent;
   const  filterData = data.filter((item)=>item.section === +postId);
    return filterData
};
export const getRecommendedProductsData = state => state.homeContent.recommendedProducts;
export const getError = state => state.blogContent.error;
export const getLoading = state => state.blogContent.isLoading;
export const getInstagramData = state => state.blogContent.instagramData;
export const getFavoriteProducts = state => state.blogContent.favoriteProducts;
