import {createReducer} from 'reduxsauce';
import {HomeTypes} from './actions';


export interface IHomeState {
    data: any,
    instagramData?: any,
    recommendedProducts?: any,
    banner?: any,
    isLoading?: boolean,
    banners?: Record<string, any>[]
}

export const homeContent: IHomeState = {
    data: [],
    instagramData: [],
    recommendedProducts: [],
    banner: {},
    isLoading: false,
    banners: []
};

export const InstagramData = (state) => {
    return {...state};
};

export const InstagramSuccess = (state, action) => {
    return {...state, instagramData: action.data};
};

export const InstagramFailure = (state, action) => {
    return {...state, error: action.error, instagramData: []};
}

export const getHomeData = (state) => {
    return {...state, isLoading: true};
};

export const homeSuccess = (state, action) => {
    return {...state, data: action.data, isLoading:false};
};

export const homeFailure = (state, action) => {
    return {...state, error: action.error, data: [], isLoading: false};
};
export const getInstagramData = (state) => {
    return {...state};
};

export const homeInstagramSuccess = (state, action) => {
    return {...state, instagramData: action.data};
};

export const homeInstagramFailure = (state, action) => {
    return {...state, error: action.error, instagramData: []};
}

export const getRecommendedProductsData = (state) => {
    return {...state};
};

export const recommendedProductsSuccess = (state, action) => {
    return {...state, recommendedProducts: action.data};
};

export const recommendedProductsFailure = (state, action) => {
    return {...state, error: action.error, recommendedProducts: []};
};
export const getBanner = (state) => {
    return {...state};
};
export const getBannerData = (state, action) => {
    const BANNER1 = action.data.find(element => element.labelType === "BANNER1");
    const BANNER2 = action.data.find(element => element.labelType === "BANNER2");
    const BANNER3 = action.data.find(element => element.labelType === "BANNER3");
    const PROMOTION = action.data.find(element => element.labelType === "PROMOTION");
    const ON_SALE = action.data.find(element => element.labelType === "ON_SALE");
    const CAMPAIGN_MAILING_ID  = action.data.find(element => element.labelType === "CAMPAIGN_MAILING_ID");
    return {
        ...state, 
        banners: action.data,
        banner: {
            BANNER1: BANNER1.labelText,
            BANNER2: BANNER2.labelText,
            BANNER3: BANNER3.labelText,
            PROMOTION:PROMOTION.labelText,
            ON_SALE:ON_SALE.labelText,
            CAMPAIGN_MAILING_ID:CAMPAIGN_MAILING_ID.labelText
        }
    };

};
export const getBannerDataError = (state, action) => {
    return {...state, error: action.error, banner: {}};
};

export const handlers = {
    [HomeTypes.HOME_START]: getHomeData,
    [HomeTypes.HOME_SUCCESS]: homeSuccess,
    [HomeTypes.HOME_FAILURE]: homeFailure,
    [HomeTypes.HOME_INSTAGRAM_START]: getInstagramData,
    [HomeTypes.HOME_INSTAGRAM_SUCCESS]: homeInstagramSuccess,
    [HomeTypes.HOME_INSTAGRAM_FAILURE]: homeInstagramFailure,
    [HomeTypes.RECOMMENDED_PRODUCTS_START]: getRecommendedProductsData,
    [HomeTypes.RECOMMENDED_PRODUCTS_SUCCESS]: recommendedProductsSuccess,
    [HomeTypes.RECOMMENDED_PRODUCTS_FAILURE]: recommendedProductsFailure,
    [HomeTypes.BANNER_START]: getBanner,
    [HomeTypes.BANNER_SUCCESS]: getBannerData,
    [HomeTypes.BANNER_ERROR]: getBannerDataError,
};

export default createReducer(homeContent, handlers);
