import React, { useState, useEffect } from "react";
import { BASE_API_GATEWAY } from "../../../utils/constants/service";
import { LazyImage } from "react-lazy-images";
import { connect } from "react-redux";
import { getBanner, getBannerError } from "../../home/redux/selectors";

import {
  GlassMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from "react-image-magnifiers";
import DiscountDetail from "./discountDetail";

interface Props {
  src: string,
  outlet: boolean,
  discountDetail: string,
  onSpecial: string,
  thumbnail: string,
  alt: string,
  banner: any,
  zoom: boolean,
  isMobile: boolean,
  selectedProduct: any
}

function Photo({
  src,
  outlet = false,
  discountDetail = "",
  onSpecial = "",
  thumbnail = "",
  alt = "img",
  banner,
  zoom = false,
  isMobile = false,
  selectedProduct = {}
}: Props) {
  const [defaultSrc, setDefaultSrc] = useState(src);
  const [brokenData, setBrokenData] = useState(false)
  useEffect(() => {
    setDefaultSrc(src);
  }, [src]);
  return (
    <>
      {zoom && !isMobile ? (
        <LazyImage
          src={`${BASE_API_GATEWAY}${defaultSrc && defaultSrc}`}
          error={() => <span></span>}
          placeholder={({ imageProps, ref }) => (
            <img
              ref={ref}
              src={`${BASE_API_GATEWAY}${thumbnail && thumbnail}`}
              alt={imageProps.alt}
            />
          )}
          actual={({ imageProps }) => (
            <>
              <GlassMagnifier
                imageSrc={`${BASE_API_GATEWAY}${defaultSrc && defaultSrc}`}
                imageAlt={alt}
                largeImageSrc={`${BASE_API_GATEWAY}${defaultSrc && defaultSrc}`} // Optional
                mouseActivation={MOUSE_ACTIVATION.DOUBLE_CLICK} // Optional
                touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP} // Optional
                magnifierSize={"50%"}
              />
            </>
          )}
        />
      ) : thumbnail ? (
        <LazyImage
          src={`${BASE_API_GATEWAY}${defaultSrc && defaultSrc}`}
          error={() => <span></span>}
          placeholder={({ imageProps, ref }) => (
            <img
              ref={ref}
              src={`${BASE_API_GATEWAY}${thumbnail && thumbnail}`}
              alt={imageProps.alt}
            />
          )}
          actual={({ imageProps }) => <img {...imageProps} alt={alt} />}
        />
      ) : (
        <img
          src={`${BASE_API_GATEWAY}${defaultSrc && defaultSrc}`}
          onError={(e) => {
            setDefaultSrc("/productservice/api/files/download/32818")
            setBrokenData(true)
          }

          }
          alt="pour products image"
          data-broken={brokenData}
        />
      )}

      {
        selectedProduct
          && selectedProduct.listPriceAfterDiscount
          && selectedProduct.listPriceAfterDiscount >= selectedProduct.startingPrice
          ? ""
          : (<DiscountDetail product={{ discountDetail, outlet, onSpecial }} banner={banner} isPhoto={true} />)
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  banner: getBanner(state),
  error: getBannerError(state),
});
export default connect(mapStateToProps)(Photo);
