//@ts-nocheck
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import ErrorBoundary from './error-boundary';
import { clickStream } from '../../helpers/helpers'
import WrapperMenuItem from '../WraperMenuItem/WrapperMenuItem';

interface State {
    ip?: any,
}

interface Props extends RouteProps {
    canonicalUrl?: string,
    menuItem?: any,
    gon?: boolean,
}
class ErrorBoundaryRoute extends React.Component<Props> {
    state: State = {
        ip: 0,
    };

    componentWillReceiveProps = (nextProps) => {

        const opts = () => {
            const url = (window.location !== window.parent.location)
                ? document.referrer : document.location.href;

            return url
                && this.props.location
                && this.props.menuItem
                && nextProps.location
                && nextProps.location.pathname
                && nextProps.menuItem
                && nextProps.menuItem.id
                && nextProps.gon
                && !nextProps.computedMatch.params.productId
        };

        if (opts()) {
            const menuId = nextProps.menuItem && nextProps.menuItem.id || 0;
            clickStream(menuId)
        }
    };

    render() {
        const { component: Component, ...rest } = this.props;
        if (!Component) throw new Error(`A component needs to be specified for path ${(rest).path}`);
        return (
            <Route  {...rest} render={(props: any) => {
                if (rest.canonicalUrl) {
                    props.canonicalUrl = rest.canonicalUrl;
                }
                if (rest.menuItem) {
                    props.menuItem = rest.menuItem;
                }
                return (<ErrorBoundary>
                    <WrapperMenuItem menuItem={props.menuItem}>
                        <Component {...props} />
                    </WrapperMenuItem>

                </ErrorBoundary>)
            }
            }
            />
        );
    }
}

export default ErrorBoundaryRoute;


