import React from "react";
import Connection from "../../../connection/container/index"

export default class Identification extends React.Component {
    render() {
        return (
            <div>
                <Connection inBasket={true}/>
            </div>
        );
    }
}