import React from "react";
import { AddressTypes } from "../../../../utils/constants/variables";
import i18next from "i18next"

type Address = Record<string, number | string | boolean>;

interface Props {
  selectedClientId: number;
  item: Address;
  storeDelivery: string;
  type: AddressTypes;
  toAddresses: () => void;
  onUpdate: (item: Address, type: AddressTypes) => void
}

export const AddressCard: React.FC<Props> = ({
  selectedClientId,
  item,
  storeDelivery,
  type,
  onUpdate,
  toAddresses
}) => {
  const text = item && item.addressType === 'BILL' ? i18next.t("space.address.4") : i18next.t("space.address.7")

  return (
    <div
      className={`item ${selectedClientId === item.clientAddressId ? "item-default" : "item-choose-address"} ${storeDelivery === "STO" && type === AddressTypes.SHIP ? 'grayed' : ""}`}
      key={`shipping-${item.clientAddressId}`}
      onClick={() => onUpdate(item, type)}
    >
      <div className='item-content'>
        <div className="item-content-flex-box">
          <div className='info-box'>
            <p className="user-name">{item.name}</p>
            <p className="address">{`${item.address1} ${item.address2 || ''}`}</p>
            <p className="zip">{`${item.zip} ${item.city}`}</p>
            <p className="country">{item.country}</p>
            <p className="phone">{item.phone}</p>
          </div>
        </div>
        <span
          className="main-link"
          onClick={toAddresses}
        >{i18next.t("space.address.3")}
        </span>
      </div>
      {selectedClientId === item.clientAddressId ? (
        <button className="main-btn">{text}</button>
      ) : (
        <span className="main-link">{text}</span>
      )}
    </div>
  )
}