import {createReducer} from 'reduxsauce';
import {OrEtArgentTypes} from "./actions";

export interface IOrEtArgent {
    products: object,
    loading: boolean,
    error: string,
}

export const OrEtArgent: IOrEtArgent = {
    products: {},
    error: "",
    loading: false,
};


export const orEtArgent = (state) => {
    return {...state, loading: true};
};
//change names
export const orEtArgentSuccess = (state, action) => {
    return {
        ...state, products: action.data
    };
};
export const orEtArgentFailure = (state, action) => {
    return {...state, error: action.error, products: {}, loading: false};
};

export const handlers = {
    [OrEtArgentTypes.OR_ET_ARGENT_START]:orEtArgent,
    [OrEtArgentTypes.OR_ET_ARGENT_SUCCESS]: orEtArgentSuccess,
    [OrEtArgentTypes.OR_ET_ARGENT_FAILURE]: orEtArgentFailure,
};

export default createReducer(OrEtArgent, handlers);