import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InfiniteScroll from 'react-infinite-scroll-component';
import { MetroSpinner } from "react-spinners-kit";
import queryString from 'query-string';

import { Link } from "react-router-dom";
import i18next from "i18next";
import { SearchingCreators } from "../redux/actions";
import { getSearchingData, loading, loadMoreData } from "../redux/selectors";
import { ISearchingState } from "../redux/reducers";
import { BASE_API_GATEWAY } from "../../../utils/constants/service";
import { getCurrency, replaceComma } from "../../../helpers/helpers";

//style
import "./search.scss";
import { getBanner } from "../../home/redux/selectors";
import { HIDE_DISCOUNT } from "../../../utils/constants/variables";
import DiscountDetail from "../../common/product/discountDetail";
import { LazyImage } from "react-lazy-images";


interface Props extends ISearchingState {
    //dispatch: (props: any) => any;
    searching: any,
    loading: boolean,
    loadMoreData: boolean,
}

interface State {
    criteria?: any,
    page?: any,
    size?: any,
    searching?: any,
    loading?: boolean,
    ready?: boolean,
    banner?: any,
    loadMoreData?: boolean,
}


class Search extends React.Component<Props & typeof SearchingCreators, State> {
    state: State = {
        criteria: '',
        page: 0,
        size: 12,
        searching: {},
        loading: false,
        ready: false,
        loadMoreData: false
    };
    componentDidMount = () => {
        const values = queryString.parse(this.props.location.search);
        if (this.props.location.search) {
            const data = {
                size: values.size,
                page: values.page,
                criteria: values.criteria
            };
            this.props.searchingStart(data);
        } else {
            this.props.searchingStart(this.state);
        }


    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.search != this.props.location.search) {
            const values = queryString.parse(nextProps.location.search);
            if (this.props.location.search) {
                const data = {
                    size: values.size,
                    page: values.page,
                    criteria: values.criteria
                };
                this.props.searchingStart(data);
            }
        }


        if (nextProps.searching.products && nextProps.searching.products.content && nextProps.searching.products.content.length > 0) {
            this.setState({ ready: true });
        }
    }

    getProductCategoryName = (item) => {
        const found = item.find(element => element.searchFilter === 'Collection');
        const categoryName = found ? found.values[0].value : '';
        return categoryName;
    };
    fetchMoreData = async () => {
        const { page, size } = this.state;
        const { searching } = this.props;
        const data = {
            size,
            page: page + 1,
            criteria: this.props.location.search ? queryString.parse(this.props.location.search).criteria : ""
        };

        if (searching.products && searching.products.content && searching.products.totalElements - 1 < searching.products.content.length) {
            return
        }
        if (!loadMoreData) {
            return;
        }
        await this.props.searchingStart(data);
        this.setState({ page: page + 1 })

    };

    render() {
        const { loading, loadMoreData, searching, banner } = this.props;
        const { ready } = this.state;
        let size = searching.products ? searching.products.totalElements && searching.products.content.length && searching.products.content.length <= searching.products.totalElements
            ? searching.products.content.length : searching.products.totalElements
            : 0;
        return (
            <InfiniteScroll
                dataLength={size}
                next={this.fetchMoreData}
                hasMore={ready && loadMoreData}
                scrollThreshold={0.5}
                loader={
                    <MetroSpinner
                        size={50}
                        color="#686769"
                        loading={loading}
                    />
                }
            >
                <div className="container">
                    <div className="product-content dflex flex-wrap justify-content-between">
                        {
                            searching && searching.products && searching.products.content && searching.products.content.map((product, index) => (
                                product.listPriceAfterDiscount || product.startingPrice || ""
                            ) && (
                                    <Link className="productBox product-item advertisingBox  " key={index}
                                        to={`${product.menuCanonicalUrl && product.menuCanonicalUrl.slice(1)}/${product.canonicalUrl}`}>
                                        <div className="product-item-img">
                                            <LazyImage
                                                src={`${BASE_API_GATEWAY}${product && product.photo}`}
                                                alt="product-image"
                                                placeholder={({ imageProps, ref }) => (
                                                    <img
                                                        ref={ref}
                                                        src={`${BASE_API_GATEWAY}${product.thumbnail}`}
                                                        alt={imageProps.alt}
                                                    />
                                                )}
                                                actual={({ imageProps }) => (
                                                    <img {...imageProps} alt={product.name} />
                                                )}
                                            />
                                            {
                                                product.listPriceAfterDiscount <= product.startingPrice &&
                                                <DiscountDetail
                                                    product={product}
                                                    banner={banner}
                                                />
                                            }
                                            <div className="product-item-img-text">
                                                <h2 className="product-title">{product.name}</h2>
                                                <p className="product-categorye-name">
                                                    {
                                                        product.attributes &&
                                                        product.attributes.length &&
                                                        this.getProductCategoryName(product.attributes)
                                                    }
                                                </p>
                                                <p className="product-price">
                                                    {i18next.t('catalogue.product.3')}&nbsp;
                                                    {
                                                        (product.listPriceAfterDiscount) ?
                                                            <span>
                                                                {HIDE_DISCOUNT === "0" ?
                                                                    <span className='old-price'> {replaceComma(product.startingPrice)}</span> :
                                                                    ""
                                                                }
                                                                <span className="new-price">
                                                                    {replaceComma(product.listPriceAfterDiscount)}
                                                                    {getCurrency(product.currency)}
                                                                </span>
                                                            </span> :
                                                            <span className="new-price">
                                                                {replaceComma(product.startingPrice)}
                                                                {getCurrency(product.currency)}
                                                            </span>
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className="info-box">
                                            <div className="front">
                                                <h2 className="product-title">{product.name}</h2>
                                                <p className="product-categorye-name">
                                                    {
                                                        product.attributes &&
                                                        product.attributes.length &&
                                                        this.getProductCategoryName(product.attributes)
                                                    }
                                                </p>
                                                <div className="info-box-lg-content">
                                                    <p className="text">
                                                        {
                                                            product.attributes &&
                                                            product.attributes['General Material'] &&
                                                            product.attributes['General Material'].length &&
                                                            product.attributes['General Material'][0]
                                                        }
                                                    </p>
                                                    <p className="text multiline-ellipsis"
                                                        dangerouslySetInnerHTML={{ __html: (product.description && product.description.substring(0, 92)) + "..." }}>
                                                    </p>
                                                </div>
                                                <p className="product-price">{i18next.t('catalogue.product.3')}&nbsp;
                                                    {
                                                        (product.listPriceAfterDiscount) ?
                                                            <span>
                                                                {
                                                                    HIDE_DISCOUNT === "0" &&
                                                                        product.listPriceAfterDiscount < product.startingPrice ?
                                                                        <span className='old-price'>
                                                                            {replaceComma(product.startingPrice)}
                                                                        </span> :
                                                                        ""
                                                                }
                                                                <span className="new-price">
                                                                    {replaceComma(product.listPriceAfterDiscount)}
                                                                    {getCurrency(product.currency)}
                                                                </span>
                                                            </span> :
                                                            <span className="new-price">
                                                                {replaceComma(product.startingPrice)}
                                                                {getCurrency(product.currency)}
                                                            </span>
                                                    }
                                                </p>
                                                <div className="info-box-lg-content">
                                                    <p className="title-brd">{i18next.t("catalogue.product.4")}</p>
                                                    <span className="title-brd-image"></span>
                                                </div>
                                            </div>
                                            <div className="back">
                                                <p className="btt">{i18next.t("catalogue.product.5")}</p>
                                            </div>
                                        </div>
                                    </Link>
                                ))
                        }
                    </div>
                </div>
            </InfiniteScroll>
        )
    }

}

const mapStateToProps = (state: any): State => ({
    searching: getSearchingData(state),
    loading: loading(state),
    loadMoreData: loadMoreData(state),
    banner: getBanner(state),
});

const mapDispatchToProps = (dispatch): typeof SearchingCreators => {
    return bindActionCreators(SearchingCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
