import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import PageMetaData from "../../modules/common/pageMetaData/pageMetaData";
import historyLogo from "./../../assets/img/history_logo.svg";

export default function CommonCurrentPage({
    navTabs,
    selectedMenuItem,
    connectItem,
    showDescription,
    postData = [],
    productData,
    showPageMetaData
}) {
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const onResize = () => {
        setIsMobile(window.innerWidth < 920);
    }

    const getProductUrl = () => {
        const index = navTabs.findIndex((tab) => tab.productUrl);
        return navTabs[index - 1];
    }
    const productUrl = getProductUrl();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setIsMobile(window.innerWidth < 920);
        }
        window.addEventListener("resize", onResize);
        return () => {
            unmounted = true;
        }
    }, []);

    return (
        <section
            className={
                `product-info-box ${(connectItem && connectItem.key === "nouveaux_precieux") ||
                    (connectItem && connectItem.key === "faq") ? "white-product-info-box" : ""}`
            }
        >
            <aside className="main-box product-container">
                <ul className="categorye-list">
                    {navTabs && isMobile && productUrl
                        ? <li> <Link to={productUrl.url} > &gt; {productUrl.name} </Link> </li> : navTabs && !isMobile && navTabs.map((tab, index) => (
                            <li key={index}>{tab.url ? <Link to={tab.url} > {tab.name} </Link> : <span > {tab.name}</span>}</li>
                        ))
                    }
                </ul>
                {(selectedMenuItem || connectItem) && (showDescription || showDescription === 'wishlist') &&
                    <div className="info-box">
                        {selectedMenuItem && selectedMenuItem.key === 'histoire_de_la_marque' && <img src={historyLogo} alt="logo" />}
                        <h1 className="title">{(selectedMenuItem && selectedMenuItem.label) || (connectItem && connectItem.label)}</h1>
                        <div className="text" dangerouslySetInnerHTML={{ __html: (selectedMenuItem && selectedMenuItem.description) || (connectItem && connectItem.description) }}></div>
                    </div>
                }
            </aside>
            {selectedMenuItem && showPageMetaData ?
                <PageMetaData
                    postData={postData}
                    productData={productData}
                    navTabs={navTabs}
                    selectedMenuItem={selectedMenuItem}
                />
                : ""
            }
        </section>
    )
}