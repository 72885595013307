import React, {Component} from 'react';
import i18next from 'i18next';
import {connect} from 'react-redux'
import {bindActionCreators} from "redux";
import {ConnectionsActionCreators} from "../redux/actions";

import "./connection.scss";
import {getMenu, getSuccess, getFavoriteStore} from "../redux/selectors";
import CurrentPage from '../../../layouts/common/currentPage';
import Creation from "../../creation/container";
import {emailIsValid, getFilterValue, getRouteByMenuKey} from '../../../helpers/helpers';
import {IConnectionState} from '../redux/reducers';
import Soumettre from "../../../soumattre-popup/Soumetre"
import {Redirect} from 'react-router-dom';
import {isAuthenticated} from "../../../modules/login/redux/selectors";
import {getLoginError} from "../../../layouts/main/header/redux/selectors";

interface Props extends IConnectionState {
    menuItem?: any,
    canonicalUrl?: any,
    loginFailed?:any,
    getLoginError?:any,
    history?: any
}

interface ConnectionState {
    menuList?: any,
    getLoginError?:any,
    loginFailed?:any,
    inBasket?: any,
    location?: any,
    connection: any,
    favoriteStore: any,
    passwordResetStatus?: any,
    authenticated?: boolean,
}

interface State {
    showCreation: boolean,
    email: string,
    error: boolean,
    user: any,
    showResetPassword: boolean,
    validEmail: boolean,
    showSoumetre?: boolean,
    showMessage: boolean,
    loginFailed:string;
}

class Connection extends Component<Props & typeof ConnectionsActionCreators, State, ConnectionState> {
    state: State = {
        showCreation: false,
        loginFailed:"",
        email: '',
        error: false,
        user: {
            email: '',
            password: ''
        },
        showResetPassword: false,
        validEmail: false,
        showSoumetre: false,
        showMessage: false,
    };

    componentDidMount() {
        this.props.canonicalUrl && this.props.location.pathname !== this.props.canonicalUrl ? this.props.history.push(this.props.canonicalUrl) : "";
        this.props.favoriteStoreStart();
    }

    toggleCreation = () => this.setState({showCreation: !this.state.showCreation});


    handleEmailRegistration = (e) => {
        this.props.connectionStart(this.state.email);
        this.setState({error: false, showMessage: true,});
        this.toggleCreation();
        e.preventDefault();
    };


    handleChangeEmail = (e) => {
        const email = e.target.value;
        const validEmail = emailIsValid(email) && email.length <= 40;
        this.setState({email, validEmail});
    };

    handleChangeUser = (e) => {
        const name = e.target.name;
        const value = getFilterValue(e.target.value);
        this.setState((prevState: State) => ({
            user: {
                ...prevState.user,
                [name]: value
            }
        }))
    };
    setResetPassword = (e) => {
        this.state.showResetPassword = true;
        this.setState(this.state);
    };

    handleLoginSubmit = () => {
        this.props.loginStart(this.state.user);
    };


    handlePasswordResetForLegacyUser = (e) => {
        this.props.connectionPasswordResetStart(this.state.email);
        this.setState({error: false, showResetPassword: false});
    };

    handlePasswordResetPopupClose = (e) => {
        this.state.showResetPassword = false;
        this.setState(this.state);
    };
    closeModal = () => {
        this.setState({showSoumetre: false,});
    };
    handleShowSoumetre = () => {
        this.setState({showSoumetre: !this.state.showSoumetre,});
    };

    render(): React.ReactNode {
        if (this.props.authenticated) {
            return <Redirect to="/"/>
        }
        const {inBasket, menuItem, menuList, connection, favoriteStore, loginFailed} = this.props;
        const {showCreation, user, validEmail} = this.state;
        const {email, password} = user;

        return (

            <div>
                {this.state.showSoumetre && <Soumettre history={this.props.history} email='' legacyUser={false} handleClose={this.closeModal}/>}
                {!inBasket && Object.keys(menuItem).length ?
                    <CurrentPage selectedItem={menuItem}/>
                    : ""
                }
                {
                    (showCreation && connection.userstatus === "NEW_USER") ?
                        <Creation emailToRegister={this.state.email} favoriteStore={favoriteStore}/>
                        :
                        <div className="container">
                            <div className="connection-content connect-block">
                                <div className="login">
                                    <h4 className="connection-title">{i18next.t("signIn.signIpTitle")}</h4>
                                    <div className="input-box">
                                        <label htmlFor="email">{i18next.t("signIn.email")}</label>
                                        <input type="email" name="email" value={email} placeholder={i18next.t("signIn.4")} id="email"
                                               onChange={this.handleChangeUser}/>
                                    </div>
                                    <div className="input-box">
                                        <label htmlFor="password">{i18next.t("signIn.pass")}</label>
                                        <input type="password" name="password" value={password}
                                               placeholder={i18next.t("signIn.5")} id="password"
                                               onChange={this.handleChangeUser}/>
                                    </div>
                                    <div className="link"
                                         onClick={this.handleShowSoumetre}> {i18next.t("signIn.motDePasseOublie")}</div>
                                    {
                                        loginFailed == "FAILED" ?  <h5 className="login-failed-message">{i18next.t("signIn.1")}</h5> : ""
                                    }
                                    <button className="main-btn hoverButtonStyle"
                                            onClick={this.handleLoginSubmit}>{i18next.t("signIn.signIpButton")}</button>



                                </div>
                                <div className="forgot-password">
                                    <h4 className="connection-title">{i18next.t("signIn.title")}</h4>
                                    <p>{i18next.t("signIn.text")}</p>
                                    <div className="input-box">
                                        <label htmlFor="forgot-email">{i18next.t("signIn.email")}</label>
                                        <input name="email" value={this.state.email} type="email" placeholder={i18next.t("signIn.4")}
                                               onChange={this.handleChangeEmail} id="forgot-email"/>
                                        {this.state.email.length > 40 ? <span className="error-text">{i18next.t("forgotPassword.7")}</span>: ""}
                                    </div>
                                    <button className={`main-btn ${validEmail && 'hoverButtonStyle'} ${!validEmail && "disabled"}`} disabled={!validEmail}
                                            onClick={this.handleEmailRegistration}>{i18next.t("signIn.signUpButton")}</button>
                                    {
                                        connection.userstatus === "EXISTING_USER" && this.state.showMessage &&
                                        <p className="forgotMessage">{i18next.t("forgotPassword.1")}</p>
                                    }
                                    {
                                        connection.userstatus === "LEGACY_USER" && this.state.showMessage &&
                                        <div>
                                            <p className="forgotMessage"> {i18next.t("forgotPassword.2")}
                                                <a onClick={this.setResetPassword}> {i18next.t("forgotPassword.3")}</a> {i18next.t("forgotPassword.4")}
                                            </p>
                                        </div>
                                    }
                                    {
                                        this.state.showResetPassword &&
                                        <Soumettre
                                            email={this.state.email}
                                            legacyUser={true}
                                            handleClose={this.handlePasswordResetPopupClose}
                                            handleClick={this.handlePasswordResetForLegacyUser}
                                            history={this.props.history}
                                        />
                                    }
                                    {
                                        connection.userstatus === "RESET_PASSWORD_EMAIL_SENT" && this.state.showMessage &&
                                        <p className="forgotMessage">{i18next.t("forgotPassword.5")}</p>
                                    }
                                    {
                                        connection.userstatus === "RESET_PASSWORD_FAILED" && this.state.showMessage &&
                                        <p className="forgotMessage"> {i18next.t("forgotPassword.6")}</p>
                                    }

                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (connectionState: any): ConnectionState => ({
    menuList: getMenu(connectionState),
    connection: getSuccess(connectionState),
    authenticated: isAuthenticated(connectionState),
    favoriteStore: getFavoriteStore(connectionState),
    loginFailed: getLoginError(connectionState),
});

const mapDispatchToProps = (dispatch): typeof ConnectionsActionCreators => {
    return bindActionCreators(ConnectionsActionCreators, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Connection);


