import {createReducer} from 'reduxsauce';
import {TimelineTypes} from './actions'

export interface ITimeline {
    data: object,
    error: string
}

export const timeline: ITimeline = {
    data: [],
    error: ""
}

export const timelineStart = (state) => {
    return {...state};
};

export const timelineSuccess = (state, action) => {
    return {...state, data: action.data, error: ""};
};

export const timelineFailure = (state, action) => {
    return {...state, error: action.error, data: []};
};

export const handlers = {
    [TimelineTypes.TIMELINE_START]: timelineStart,
    [TimelineTypes.TIMELINE_SUCCESS]: timelineSuccess,
    [TimelineTypes.TIMELINE_FAILURE]: timelineFailure,
};

export default createReducer(timeline,handlers);